import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { InformationBlock } from '@src/components/InformationBlock/InformationBlock';
import { Loader } from '@src/components/Loader/Loader';
import { TableFlex } from '@src/components/TableFlex/TableFlex';
import { Bold } from '@src/components/Text/Bold';
import { CancelledMembership } from '@src/pages/Membership/Membership/components/CancelledMembership/CancelledMembership';
import { ContractDetails } from '@src/pages/Membership/Membership/components/ContractDetails/ContractDetails';
import { ActiveMembershipTable } from '@src/pages/Membership/Membership/components/MembershipTable/ActiveMembershipTable';
import { useMember } from '@src/services/member/MemberProvider';
import { isEmployee } from '@src/utils/helpers/memberHelpers';
import { useMBFFeatures } from '@src/utils/hooks/api/useMBFFeatures';
import { v4 as uuidv4 } from 'uuid';

import { toLocal } from 'services/date-converter';
import { CancelledMembershipTable } from '../MembershipTable/CancelledMembershipTable';
import { ReverseCancellation } from '../ReverseCancellation';

export const MembershipDetails = () => {
    const { t } = useTranslation();
    const { state: member } = useMember();
    const { reverseCancellation } = useMBFFeatures();

    const membershipCancelled = !member?.payment_plan?.cancels_automatically && member.end_date_g;

    const renewalContractData = member?.renewal_contract && [
        [
            t('43'),
            <Bold key={uuidv4()}>
                {toLocal(member?.renewal_contract?.peopleMembershipStartDate)}
            </Bold>
        ],
        [
            t('435'),
            <Bold key={uuidv4()}>
                {toLocal(member?.renewal_contract?.peopleMembershipContractEndDate)}
            </Bold>
        ]
    ];

    const shouldShowContractDetails = !isEmployee(member) && !membershipCancelled;

    return useMemo(
        () => (
            <>
                {reverseCancellation && <ReverseCancellation />}
                <Box variant="mint-top">
                    {!member.membership && (
                        <div className={`flex flex-col items-center justify-center`}>
                            <Loader />
                        </div>
                    )}
                    {membershipCancelled && <CancelledMembership />}
                    {shouldShowContractDetails && <ContractDetails />}

                    {renewalContractData && (
                        <InformationBlock className="mb-l">
                            {t('renewal.renewed.body')}
                            <TableFlex data={renewalContractData} size="s" />
                        </InformationBlock>
                    )}

                    <Heading size={7}>{t('membership.membershipDetails')}</Heading>

                    {membershipCancelled ? <CancelledMembershipTable /> : <ActiveMembershipTable />}
                </Box>
            </>
        ),
        [member, reverseCancellation]
    );
};
