import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { Modal } from '@src/components/Modals/Modal';
import { MemberPriceContract } from '@src/components/Price/MemberPriceContract';
import { StrokeText } from '@src/components/StrokeText/StrokeText';
import { TableFlex } from '@src/components/TableFlex/TableFlex';
import { Bold } from '@src/components/Text/Bold';
import { useMember } from '@src/services/member/MemberProvider';
import { getArticleIntervalText } from '@src/utils/helpers/articleHelpers';
import { captureException } from '@src/utils/helpers/newRelic';
import { getPrice } from '@src/utils/helpers/priceHelpers';
import { useArticles } from '@src/utils/hooks/api/useArticles';
import { usePostHandler } from '@src/utils/hooks/api/usePostHandler';
import { useAwaitSpinner } from '@src/utils/hooks/useAwaitSpinner';
import { mutate } from 'swr';
import { v4 as uuidv4 } from 'uuid';

import { toLocal } from 'services/date-converter';
import { ReverseCancellationErrorModal } from './components/ReverseCancellationErrorModal';
import { ReverseCancellationSuccessModal } from './components/ReverseCancellationSuccessModal';

type ModalProps = {
    isOpen: boolean;
    onClose?: () => void;
};

export const ReverseCancellationModal = ({ isOpen, onClose }: ModalProps) => {
    const {
        t,
        i18n: { language }
    } = useTranslation();
    const { state: member } = useMember();
    const { articles } = useArticles();
    const { awaiting: isLoading, awaitSpin } = useAwaitSpinner();
    const { submit } = usePostHandler();

    const [disabled, setDisabled] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState<string>();
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [oldMembershipTableData, setOldMembershipTableData] = useState([
        [t('40'), <Bold key={uuidv4()}>{member.membership.display_name}</Bold>],
        [
            t('reverseCancellation.membership.reactivationDate'),
            <Bold key={uuidv4()}>{toLocal(Date.now())}</Bold>
        ]
    ]);

    const [newMembershipTableData, setNewMembershipTableData] = useState([
        [member.membership.display_name, <MemberPriceContract key={uuidv4()} className="justify-end" />]
    ]);

    const periodicalArticles = useMemo(() => {
        const periodicalExtras = articles?.boughtArticles?.filter(
            (item) => !!item.peopleMembershipArticlePeriodical
        );

        return periodicalExtras;
    }, [articles?.boughtArticles]);

    useEffect(() => {
        if (periodicalArticles) {
            const extrasTotal = periodicalArticles.reduce((acc, curr) => acc + curr.articlePrice, 0);
            setTotalPrice(parseFloat(extrasTotal + member.payment_plan.term_fee));
        }
    }, [periodicalArticles, member.payment_plan.term_fee]);

    const handleModalClose = async () => {
        if (success) await mutate('/member/get-member');

        onClose?.();
    };

    useEffect(() => {
        if (periodicalArticles?.length > 0) {
            const oldSelectedExtras: any[] = [];
            periodicalArticles.forEach((item) => {
                oldSelectedExtras.push([
                    t('reverseCancellation.membership.extrasGeneric'),
                    <Bold key={uuidv4()}>{item.articleShortDescription}</Bold>
                ]);
            });

            const oldTableData = [...oldMembershipTableData];
            oldTableData.splice(1, 0, ...oldSelectedExtras);
            setOldMembershipTableData(oldTableData);

            const selectedExtras: any[] = [];
            periodicalArticles.forEach((item) => {
                const price = getPrice(item.articlePrice || 0, language, {
                    format: 'withDigits'
                });

                selectedExtras.push([
                    item.articleShortDescription,
                    <Bold key={uuidv4()}>
                        {price} {t(getArticleIntervalText(item, member))}
                    </Bold>
                ]);
            });

            const newTableData = [...newMembershipTableData];
            newTableData.splice(1, 0, ...selectedExtras);
            setNewMembershipTableData(newTableData);
        }
    }, [periodicalArticles]);

    const handleSubmit = async () => {
        setDisabled(true);
        await awaitSpin(async () => {
            const response = await submit('/memberships/reverse-cancellation', {
                peopleMembershipId: member.id_g
            });

            if (response.error) {
                setError(response.data?.error);

                return captureException('failed to reverse cancellation', {
                    component: 'ReverseCancellation',
                    peopleId: member.people_id_g,
                    reason: response.data.message
                });
            }

            captureException('successfully reversed cancellation', {
                component: 'ReverseCancellation',
                peopleId: member.people_id_g
            });
            setSuccess(true);
        });
    };

    return (
        <Modal isOpen={isOpen} onModalClose={handleModalClose}>
            {success && !error && <ReverseCancellationSuccessModal />}
            {error && <ReverseCancellationErrorModal error={error} />}
            {!success && !error && (
                <form>
                    <Heading size={4} className="mb-s">
                        <StrokeText
                            className="text-anthracite"
                            stroke="last"
                            color="anthracite"
                            text={t('reverseCancellation.title')}
                        />
                    </Heading>
                    <Text size={2}>{t('reverseCancellation.modal.body')}</Text>
                    <TableFlex data={oldMembershipTableData} size="s" />

                    <Heading size={6} className="mt-m">
                        {t('reverseCancellation.modal.newMembership')}
                    </Heading>
                    <TableFlex data={newMembershipTableData} size="s" />

                    <hr className="w-full my-xxs" />
                    <div className="flex justify-between">
                        <Bold key={uuidv4()}>{t('reverseCancellation.modal.total')}</Bold>
                        <Bold key={uuidv4()}>{getPrice(totalPrice, language)}</Bold>
                    </div>

                    <Button
                        className="mt-m"
                        text={t('reverseCancellation.buttonText')}
                        data-cy="reverse-cancellation__modal-confirm-button"
                        loading={isLoading}
                        disabled={disabled || success}
                        icon={success ? 'tick' : ''}
                        type="button"
                        onClick={handleSubmit}
                    />
                </form>
            )}
        </Modal>
    );
};
