import React from 'react';
import { ValidationRule } from 'react-hook-form';
import { textSizes } from '@basic-fit/design-system';

interface LabelProps {
    label: string | JSX.Element;
    name: string;
    size?: 1 | 2 | 3;
    required?: ValidationRule<boolean>;
    readOnly?: boolean;
    className?: string;
}

export const InputLabel = ({
    label,
    name,
    size = 2,
    readOnly,
    required,
    className = ''
}: LabelProps): JSX.Element => {
    return (
        <span id={`${name}-label`} className={`${textSizes[size]} ${className}`}>
            {required && (
                <span
                    className={`
                      ${readOnly ? 'text-grey-disabled' : 'text-red'}
                    `}
                >
                    *{' '}
                </span>
            )}
            {label}
        </span>
    );
};
