import React from 'react';

export const CurvedLines = () => (
    <div className="relative w-full h-full">
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 222 201"
            fill="none"
            className="relative z-[-1]"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M174.07 0V31.5901C174.07 45.3575 168.601 58.5609 158.866 68.2959C149.131 78.0309 135.927 83.5 122.16 83.5H91.5C69.3552 83.5027 48.1182 92.3008 32.4595 107.959C16.8008 123.618 8.00265 144.855 8 167V200.42"
                stroke="url(#paint0_linear_2041_4252)"
                strokeWidth="15"
                strokeMiterlimit="10"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_2041_4252"
                    x1="179.69"
                    y1="-3.97998"
                    x2="40.7389"
                    y2="226.298"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFAA30" />
                    <stop offset="0.361383" stopColor="#FE7000" />
                    <stop offset="1" stopColor="#FE7000" />
                </linearGradient>
            </defs>
        </svg>
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 222 201"
            fill="none"
            className="absolute top-0 left-0 z-[1]"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M214.07 0V31.5901C214.046 55.9588 204.355 79.3226 187.124 96.554C169.893 113.785 146.529 123.476 122.16 123.5H91.5C79.968 123.516 68.9128 128.104 60.7584 136.258C52.604 144.413 48.0159 155.468 48 167V200.42"
                stroke="#7ADEC9"
                strokeWidth="15"
                strokeMiterlimit="10"
            />
        </svg>
    </div>
);
