import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import PageHead from '../partials/PageHead';
import ParagraphNormal from '../partials/ParagraphNormal';
import SectionDivider from '../partials/SectionDivider';
import { compensationFinderAndMapper } from './compensationHelper';
import OneCompensation from './OneCompensation';

export default () => {
    const { member } = useSelector((state) => ({
        ...state.memberReducer
    }));

    const { t } = useTranslation();
    const chosenCompensations = member.chosenCompensations;

    const { possibleCompensations } = useSelector((state) => ({
        ...state.possibleCompensationsReducer
    }));

    return (
        <>
            {possibleCompensations &&
                chosenCompensations &&
                chosenCompensations.length > 0 &&
                !member.isEmployee &&
                !member.isOnlineOnly && (
                    <>
                        <PageHead headId="yourCompensationHead" title={t('569')} size="16px" />
                        <ParagraphNormal text={t('570')} />

                        {compensationFinderAndMapper(
                            chosenCompensations,
                            possibleCompensations
                        ).map((compensation, i) => {
                            return (
                                <>
                                    <OneCompensation
                                        choose={t('549')}
                                        compensation={compensation}
                                        index={i}
                                        chosen={t('566')}
                                    />
                                    <SectionDivider noTopSpace={true} />
                                </>
                            );
                        })}
                    </>
                )}
        </>
    );
};
