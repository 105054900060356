import React, {
    HTMLAttributes,
    PropsWithChildren,
    Ref,
    useEffect,
    useImperativeHandle
} from 'react';
import {
    FieldValues,
    FormProvider,
    FormState,
    Path,
    PathValue,
    useForm,
    UseFormReturn,
    ValidationMode
} from 'react-hook-form';
import {
    getFromSessionCache,
    removeFromSessionCache,
    setToSessionCache
} from '@src/services/utilities/sessionCache.service';

interface FormProps<FormData extends Record<string, unknown>>
    extends PropsWithChildren<HTMLAttributes<Omit<HTMLFormElement, 'onSubmit'>>> {
    onSubmit(data: FieldValues): Promise<void> | void;
    getFormState?(formState: FormState<FormData>): void;
    getFormValues?: (values: FormData) => void;
    name: string;
    mode?: keyof ValidationMode | undefined;
    instance?: Ref<UseFormReturn<FormData>>;
    useCache?: boolean;
}

export const Form = <FormData extends Record<string, unknown>>({
    children,
    name,
    onSubmit,
    getFormState,
    getFormValues,
    instance,
    mode = 'all',
    useCache = true,
    ...rest
}: FormProps<FormData>) => {
    const form = useForm<FormData>({ mode });
    const { handleSubmit, setValue, watch, getValues, formState } = form;
    useImperativeHandle(instance, () => form);

    const onFormSubmit = async (data: FormData) => {
        await onSubmit(data);
        if (useCache) {
            removeFromSessionCache(`_form_${name}`);
        }
    };

    useEffect(() => {
        if (useCache) {
            const sessionFormCache = getFromSessionCache(`_form_${name}`);
            if (sessionFormCache) {
                const formData: FormData = JSON.parse(sessionFormCache);
                Object.entries(formData).forEach(([key, value]) => {
                    setValue(key as Path<FormData>, value as PathValue<FormData, Path<FormData>>);
                });
            }
            // send (updated) form values on mount
            getFormValues?.(getValues());
        }
        return () => watcher?.unsubscribe();
    }, []);

    const setFormSessionCache = () => {
        if (useCache) {
            setToSessionCache(`_form_${name}`, getValues());
        }
    };

    const watcher = watch(setFormSessionCache);

    return (
        <form
            onSubmit={handleSubmit(onFormSubmit)}
            onKeyUp={getFormState ? () => getFormState?.(formState) : undefined}
            {...rest}
        >
            <FormProvider {...form}>{children}</FormProvider>
        </form>
    );
};
