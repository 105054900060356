import React, { ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { FriendsPage } from '@src/pages/Friends/Friends.page';

import { RouteProps } from '.';

const routes: RouteProps[] = [
    { path: '', Component: FriendsPage }
];

export const FriendsRouter = (): ReactElement => {
    return (
        <Routes>
            {routes.map(({ path, Component }, index) => (
                <Route key={`friends-route-${index}`} path={path} element={<Component />} />
            ))}
            <Route path="*" element={<Navigate to="" />} />
        </Routes>
    );
};
