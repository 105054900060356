import React from 'react';

export default function FlagFR() {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask id="a" maskUnits="userSpaceOnUse" x="0" y="4" width="24" height="16">
                <path fill="#C4C4C4" d="M0 4h24v16H0z" />
            </mask>
            <g mask="url(#a)">
                <path d="M24.27 2.425H-.12v19.151h24.39V2.427Z" fill="#ED2939" />
                <path d="M16.14 2.425H-.12v19.151h16.26V2.427Z" fill="#fff" />
                <path d="M8.01 2.425H-.12v19.151h8.13V2.427Z" fill="#002395" />
            </g>
        </svg>
    );
}
