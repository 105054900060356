import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Heading, Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { useCancellationContext } from '@src/components/ContextProvider/CancellationContextProvider';
import { RadioButton } from '@src/components/Form/RadioButton/RadioButton.component';
import { Header } from '@src/components/Header/Header';
import { Section } from '@src/components/Layout/Section';
import { LineSVG } from '@src/components/LineSVG/LineSVG';
import { Skeleton } from '@src/components/Skeletons/Skeleton';
import {
    CancellationData,
    CancellationDateProps
} from '@src/pages/CancelMembership/CancelMembership.types';
import { Path } from '@src/router/CancelMembership.router';
import { useMember } from '@src/services/member/MemberProvider';
import { useFetch } from '@src/utils/hooks/api/useFetch';
import { DateTime } from 'luxon';

import googleTagManagerPush from '../../../services/google-tag-manager-push';

export default function CancelMembershipDates(): JSX.Element {
    const { t } = useTranslation();
    const { chosenDate, setChosenDate, chosenReason } = useCancellationContext();
    const [showChooseDateAlert, setShowChooseDateAlert] = useState(false);
    const { state: member } = useMember();
    const navigate = useNavigate();

    const {
        data: { dates: cancellationDates },
        isLoading,
        isError
    } = useFetch<CancellationData>(
        `/memberships/get-cancel-membership-data?membershipId=${member.id_g}`
    );

    useEffect(() => {
        if (!chosenReason?.reason) navigate('..');
        googleTagManagerPush.cancelMembership('view', 'page dates');
    }, []);

    const goToCheckPage = () => {
        if (!chosenDate?.cancelDate) {
            return setShowChooseDateAlert(true);
        }
        googleTagManagerPush.cancelMembership('click', 'page dates - continue');
        navigate(Path.Base + Path.Check);
    };

    const goToPrevPage = () => {
        googleTagManagerPush.cancelMembership('click', 'page dates - go back');
        navigate(Path.Base + Path.Reasons);
    };

    const onButtonClick = (pickedDate: CancellationDateProps, idx: number) => {
        // MM-2495 | Add index to the picked date for the GTM
        setChosenDate(Object.assign(pickedDate, { listIndex: ++idx }));
    };

    return (
        <>
            {member.isAllIn ? (
                <div data-cy="cancel-membership__dates-houston">
                    {/* CANCEL MEMBERSHIP */}
                    <Header
                        data-cy="cancel-membership__reasons__header"
                        title={t('117')}
                        image={<LineSVG id="vertical-lines" height={267} />}
                    />
                    {/* You cannot cancel your membership */}
                    <Text className="mt-xs" size={1}>
                        {t('1809')}
                    </Text>
                </div>
            ) : (
                <div data-cy="cancel-membership__dates">
                    {/* cancelmembership & We are sorry to hear that... */}
                    <Header
                        data-cy="cancel-membership__dates__header"
                        title={t('117')}
                        text={t('118')}
                        image={<LineSVG id="vertical-lines" height={267} />}
                    />

                    <Section data-cy="cancel-membership__dates__dates-section">
                        {/* why to cancel ? */}
                        <Heading
                            data-cy="cancel-membership__dates__dates-heading"
                            className="mb-[20px] md:ml-[0] ml-[25px]"
                            size={5}
                        >
                            {t('2069')}
                        </Heading>

                        <div
                            data-cy="cancel-membership__dates__all-dates"
                            className="relative p-s md:p-0"
                        >
                            {/* loading skeleton */}
                            {isLoading && (
                                <div>
                                    <Skeleton type="button" className="mb-[4px]" />
                                    <Skeleton type="button" className="mb-[4px]" />
                                    <Skeleton type="button" className="mb-[4px]" />
                                    <Skeleton type="button" className="mb-[4px]" />
                                    <Skeleton type="button" className="mb-[4px]" />
                                </div>
                            )}
                            {/* We are experiencing a technical issue. Sorry for that! Please try again later. */}
                            {isError && <Text size={2}>{t('528')}</Text>}
                            {cancellationDates &&
                                cancellationDates.map(
                                    (date: CancellationDateProps, index: number) => {
                                        return (
                                            <div
                                                key={date.cancelDate}
                                                className={`
                                                  flex cursor-pointer items-center px-s py-xs
                                                  transition-[border] duration-300 bg-jersey-grey my-[4px]
                                                  `}
                                                onClick={() => onButtonClick(date, index)}
                                                data-cy="cancel-membership__dates-item"
                                            >
                                                <RadioButton
                                                    name="date"
                                                    label={DateTime.fromISO(
                                                        date.cancelDate
                                                    ).toLocaleString(DateTime.DATE_FULL)}
                                                    checked={
                                                        chosenDate?.cancelDate === date.cancelDate
                                                    }
                                                />
                                            </div>
                                        );
                                    }
                                )}

                            <div className="absolute right-[-60px] bottom-[-60px] z-[-1]">
                                <LineSVG id="curved-lines" height={200} />
                            </div>
                        </div>
                    </Section>

                    <Section
                        data-cy="cancel-membership__dates__buttons"
                        className="flex justify-center items-center"
                    >
                        <div className="w-[315px] flex-col justify-center flex">
                            {/* no chosen option message */}
                            <Text
                                data-cy="cancel-membership__dates__button-no-choice-alert"
                                size={2}
                                className={`
                                    text-red text-center mb-[10px] transition-opacity duration-300
                                    ${showChooseDateAlert ? 'opacity-[1]' : 'opacity-[0]'}
                                `}
                            >
                                {t('membership.cancel_membership.dates')}
                            </Text>

                            {/* next button */}
                            <Button
                                data-cy="cancel-membership__dates__button-forward"
                                onClick={goToCheckPage}
                                disabled={isLoading}
                                className="mb-[10px]"
                                text={t('128')}
                            />

                            {/* go back */}
                            <Button
                                data-cy="cancel-membership__dates__button-back"
                                onClick={goToPrevPage}
                                className="!bg-white hover:bg-white text-anthracite"
                                text={t('162')}
                            />
                        </div>
                    </Section>
                </div>
            )}
        </>
    );
}
