import { XSTATE_STATECHART_DEBUG } from '@src/services/utilities';
import { createActorContext } from '@xstate/react';
import { assign, createMachine } from 'xstate';

import { FlowTokenType, isRateLimitExceeded, ModalState } from '../machine-helpers';
import { sendVerificationTokenMachine } from '../sendVerificationTokenMachine';
import { updatePhoneNumberMachine } from './updatePhoneNumber.machine';

type Context = {
    flow: FlowTokenType;
    phoneNumber: string;
    email: string;
    tokenError: string;
    codeError: string;
    modal: ModalState | null;
    emailToken: string;
};

type Events =
    | { type: 'SUBMIT_PHONE'; phone: string }
    | { type: 'SUBMIT_CODE'; code: string }
    | { type: 'EDITING' }
    | { type: 'BACK' }
    | { type: 'OPEN_MODAL'; modal: ModalState }
    | { type: 'CLOSE_MODAL' };

type States =
    | { value: 'phone'; context: Context }
    | { value: 'phone.pending'; context: Context }
    | { value: 'phone.sendingToken'; context: Context }
    | { value: 'phone.error'; context: Context }
    | { value: 'code'; context: Context }
    | { value: 'code.pending'; context: Context }
    | { value: 'code.verifying'; context: Context }
    | { value: 'code.success'; context: Context }
    | { value: 'code.error'; context: Context };

const changePhoneNumberMachine = createMachine<Context, Events, States>({
    id: 'changePhoneNumber',
    preserveActionOrder: true,
    predictableActionArguments: true,
    context: {
        flow: FlowTokenType.changePhoneNumberToken,
        phoneNumber: '',
        // The current user email should be passed in from when constructing the machine in the Provider
        email: '',
        tokenError: '',
        codeError: '',
        modal: null,
        emailToken: ''
    },
    initial: 'phone',
    states: {
        phone: {
            initial: 'pending',
            states: {
                pending: {},
                error: {},
                sendingToken: {
                    invoke: {
                        src: sendVerificationTokenMachine,
                        data: (ctx) => ({
                            email: ctx.email,
                            emailTokenType: ctx.flow
                        }),
                        onError: [
                            {
                                target: 'error',
                                cond: isRateLimitExceeded,
                                actions: assign({
                                    tokenError: (_, ev) => ev.data.message,
                                    modal: ModalState.TOO_MANY_TRIES
                                })
                            },
                            {
                                target: 'error',
                                actions: assign({
                                    tokenError: (_, ev) => ev.data.message
                                })
                            }
                        ],
                        onDone: { target: '#changePhoneNumber.code' }
                    }
                }
            },
            on: {
                SUBMIT_PHONE: {
                    target: '.sendingToken',
                    actions: assign({
                        phoneNumber: (_, evt) => evt.phone
                    })
                }
            }
        },
        code: {
            initial: 'pending',
            states: {
                pending: {},
                success: {},
                error: {},
                verifying: {
                    invoke: {
                        description: 'verify the token given by the user',
                        src: updatePhoneNumberMachine,
                        data: (ctx) => ({
                            phoneNumber: ctx.phoneNumber,
                            emailToken: ctx.emailToken,
                            emailTokenType: ctx.flow
                        }),
                        onError: [
                            {
                                target: 'error',
                                cond: isRateLimitExceeded,
                                actions: assign({
                                    tokenError: (_, ev) => ev.data.message,
                                    modal: ModalState.TOO_MANY_TRIES
                                })
                            },
                            {
                                target: 'error',
                                actions: assign({
                                    codeError: (_, ev) => ev.data.message
                                })
                            }
                        ],
                        onDone: {
                            actions: assign({
                                modal: ModalState.SUCCESS
                            }),
                            target: 'success'
                        }
                    }
                }
            },
            on: {
                SUBMIT_CODE: {
                    target: '.verifying',
                    actions: assign({
                        emailToken: (_, ev) => ev.code
                    })
                },
                EDITING: { target: '.pending' },
                BACK: { target: 'phone' }
            }
        }
    },
    on: {
        OPEN_MODAL: {
            actions: assign({
                modal: (_, evt) => evt.modal
            })
        },
        CLOSE_MODAL: {
            actions: assign({
                modal: null
            })
        }
    }
});

export const ChangePhoneNumberContext = createActorContext(changePhoneNumberMachine, {
    devTools: XSTATE_STATECHART_DEBUG === 'true'
});
