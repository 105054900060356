import React from 'react';
import { useTranslation } from 'react-i18next';

import BackCancel from '../partials/BackCancel';
import ButtonPrimary from '../partials/ButtonPrimary';
import ParagraphNormal from '../partials/ParagraphNormal';
import ParagraphNormalMulti from '../partials/ParagraphNormalMulti';
import Popup from '../partials/Popup';
import PopupTitle from '../partials/PopupTitle';

export default ({ title, description, cancelFunction, confirmFunction, error, disabled }) => {
    const { t } = useTranslation();
    return (
        <Popup
            Contents={() => {
                return (
                    <>
                        <PopupTitle text={title} onClick={cancelFunction} />

                        <ParagraphNormalMulti text={description} />

                        <ButtonPrimary
                            text={t('1704') || 1704}
                            onClick={confirmFunction}
                            disabled={disabled}
                            background={!disabled ? '#7322C9' : '#ABABAB'}
                        />

                        {error && <ParagraphNormal text={error} color={'#FB2C1C'} />}

                        <BackCancel title={t('25')} onClick={cancelFunction} top="0px" />
                    </>
                );
            }}
        />
    );
};
