import React from 'react';
export default function RibbonGreen() {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 176 176"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M83.1075 4.78136C85.8275 2.1231 90.1725 2.1231 92.8925 4.78136L102.531 14.201C104.351 15.9792 106.992 16.6302 109.429 15.9012L122.342 12.0399C125.985 10.9502 129.833 12.9694 131.006 16.5872L135.163 29.4072C135.948 31.8273 137.984 33.6311 140.481 34.1184L153.708 36.6999C157.441 37.4285 159.909 41.0043 159.267 44.7529L156.99 58.0364C156.56 60.5439 157.525 63.0873 159.509 64.6792L170.022 73.1122C172.989 75.4921 173.513 79.8053 171.202 82.826L163.012 93.5298C161.467 95.5504 161.139 98.2507 162.156 100.583L167.546 112.935C169.067 116.421 167.526 120.484 164.076 122.084L151.85 127.756C149.543 128.827 147.997 131.066 147.815 133.603L146.846 147.046C146.573 150.839 143.321 153.72 139.522 153.534L126.061 152.875C123.52 152.751 121.111 154.015 119.77 156.177L112.666 167.63C110.661 170.862 106.442 171.901 103.165 169.971L91.5523 163.132C89.3601 161.841 86.6399 161.841 84.4477 163.132L72.8348 169.971C69.5576 171.901 65.339 170.862 63.3341 167.63L56.2298 156.177C54.8887 154.015 52.4801 152.751 49.939 152.875L36.4779 153.534C32.6792 153.72 29.427 150.839 29.1537 147.046L28.1854 133.603C28.0027 131.066 26.4575 128.827 24.1496 127.756L11.9241 122.084C8.47406 120.484 6.93333 116.421 8.4543 112.935L13.8439 100.583C14.8613 98.2507 14.5335 95.5504 12.9875 93.5298L4.79839 82.826C2.48737 79.8053 3.0111 75.4921 5.97785 73.1122L16.4906 64.6792C18.4752 63.0873 19.4398 60.5439 19.01 58.0364L16.7331 44.7529C16.0906 41.0043 18.5588 37.4285 22.2917 36.6999L35.5193 34.1184C38.0163 33.6311 40.0524 31.8273 40.8371 29.4072L44.9942 16.5872C46.1674 12.9694 50.0146 10.9502 53.6585 12.0399L66.5707 15.9013C69.0082 16.6302 71.6492 15.9792 73.4688 14.201L83.1075 4.78136Z"
                fill="#7ADEC9"
            >
                <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="rotate"
                    from="0 88 88"
                    to="360 88 88"
                    dur="20s"
                    repeatCount="indefinite"
                />
            </path>
        </svg>
    );
}
