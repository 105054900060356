import React from 'react';
import { Heading, Text } from '@basic-fit/design-system';

import { Box } from '../Box/Box';

type ButtonListProps = {
  title: string;
  text?: string;
  children: React.ReactNode;
};

export const ButtonList = ({ title, text, children }: ButtonListProps) => {
  return (
    <Box size="small" variant="white">
      <Heading size={6}>{title}</Heading>
      <Text size={2} className="mt-xxs">
        {text}
      </Text>
      <div className="grid auto-cols-fr gap-2 mt-xs">{children}</div>
    </Box>
  );
};
