import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PriceContract, PriceContractProps } from '@src/components/Price/PriceContract';
import { Price } from '@src/pages/ChangeMembership/components/Price';
import { useMember } from '@src/services/member/MemberProvider';
import { isEmpty } from '@src/utils/helpers/collections';
import { captureException } from '@src/utils/helpers/newRelic';
import { PaymentInterval, priceInterval } from '@src/utils/helpers/priceHelpers';
import { useFetch } from '@src/utils/hooks/api/useFetch';

import { Spinner } from '../Spinner/Spinner';
import { Bold } from '../Text/Bold';

interface MemberPriceContractProps
    extends Pick<PriceContractProps, 'className' | 'style' | 'size'> {
    variant?: 'inline' | 'caption';
    showDiscount?: boolean;
    duration?: boolean;
}

export const MemberPriceContract = ({
    className = '',
    style = 'bold',
    size = 2,
    variant = 'inline',
    showDiscount = true
}: MemberPriceContractProps) => {
    const {
        i18n: { language }
    } = useTranslation();

    const { state: member } = useMember();
    const {
        data: promotion,
        isLoading,
        isError
    } = useFetch<{ hasPromotion: boolean; amount: number }>('/memberships/get-promotion');

    useEffect(() => {
        if (isError) {
            captureException('failed to fetch promotion', {
                component: 'MemberPriceContract',
                peopleId: member.people_id_g
            });
        }
    }, [isError]);

    const [discountPrice, setDiscountPrice] = useState(0);

    useEffect(() => {
        if (!isEmpty(promotion) && !isLoading) {
            setDiscountPrice(promotion?.amount || 0);
        }
    }, [promotion, isLoading]);

    return promotion || !isLoading ? (
        isEmpty(member.payment_plan) ? (
            <Bold>N/A</Bold>
        ) : (
            <>
                {variant === 'inline' && (
                    <PriceContract
                        className={className}
                        style={style}
                        showDiscount={showDiscount}
                        size={size}
                        price={member.payment_plan.term_fee}
                        period={member.payment_plan.interval as PaymentInterval}
                        discountPrice={discountPrice}
                        language={language}
                    />
                )}
                {variant === 'caption' && (
                    <Price
                        className={className}
                        amount={member.payment_plan.term_fee - discountPrice}
                        caption={`/ ${priceInterval(
                            member.payment_plan.interval as PaymentInterval
                        )}`}
                    />
                )}
            </>
        )
    ) : (
        <span className={`flex ${className}`}>
            <Spinner size="s" />
        </span>
    );
};
