import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  font-weight: 800;
  ${({ size, sentenceCase, parent, textAlign, marginBottom, color, styles }) => `
    ${size ? `font-size: ${size}` : `font-size: 32px`};
    ${
        !sentenceCase
            ? `font-family: var(--font-heading-pro-treble);text-transform: uppercase`
            : `font-family: var(--font-heading-pro-double)`
    };
    ${parent === 'InfoTable' ? 'margin-bottom: 4px' : 'margin-bottom: 16px'};
    ${textAlign ? `text-align: ${textAlign}` : ''};
    ${marginBottom ? `margin-bottom: ${marginBottom}` : ''};
    ${color ? `color: ${color}` : `color: var(--color-black)`};
    ${styles ? styles : ''}
  `}
`;

export default ({ title = '', headId = '', ...rest }) => {
    return (
        <Container id={headId} {...rest}>
            {title}
        </Container>
    );
};
