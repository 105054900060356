import React, { ReactElement } from 'react';
import { Heading, Text } from '@basic-fit/design-system';

type ItemDetailsProps = {
    className?: string;
    type?: 'heading' | 'text';
    title: ReactElement | string;
    price: string;
    interval: string;
    intervalSeparator?: boolean;
};

export const ItemDetails = ({
    className = '',
    type = 'text',
    title,
    price,
    interval,
    intervalSeparator = true
}: ItemDetailsProps) => {
    return (
        <div className={`flex flex-col xs:flex-row xs:items-center justify-between ${className}`}>
            {type === 'text' && (
                <Text size={1} className="capitalize">
                    {title}
                </Text>
            )}
            {type === 'heading' && <Heading size={7}>{title}</Heading>}
            <Text size={2} className="xs:text-right">
                {price} {intervalSeparator ? `/ ${interval}` : interval}
            </Text>
        </div>
    );
};
