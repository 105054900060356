import React, { HTMLAttributes } from 'react';

type IWrapper = HTMLAttributes<HTMLDivElement>;

export const Wrapper = ({ children, className = '', ...rest }: IWrapper): JSX.Element => {
    return (
        <div className={`px-[15px] sm:px-[30px] md:px-[60px] ${className ?? ''} `} {...rest}>
            {children}
        </div>
    );
};
