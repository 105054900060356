import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { Modal } from '@src/components/Modals/Modal';
import { StrokeText } from '@src/components/StrokeText/StrokeText';
import { TableFlex } from '@src/components/TableFlex/TableFlex';
import { Bold } from '@src/components/Text/Bold';
import { MemberContext } from '@src/services/member/MemberProvider';
import { usePostHandler } from '@src/utils/hooks/api/usePostHandler';
import { useAwaitSpinner } from '@src/utils/hooks/useAwaitSpinner';
import { useSWRConfig } from 'swr';

type ModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const ReauthorizeMandateModal = ({ isOpen, onClose }: ModalProps) => {
    const { t } = useTranslation();
    const { state: member } = useContext(MemberContext);
    const { pathname } = useLocation();
    const { awaiting: isLoading, awaitSpin } = useAwaitSpinner();
    const { mutate } = useSWRConfig();
    const { submit } = usePostHandler();

    const navigate = useNavigate();
    const returnUrl = encodeURIComponent(`${pathname}#reauthorize-mandate`);

    const [disabled, setDisabled] = useState(false);

    const changeMandate = async () => {
        setDisabled(true);

        const response = await awaitSpin(() =>
            submit('/member/update-member-info', {
                peopleNoDd: false
            })
        );

        if (response.error) {
            navigate('#reauthorize-mandate-failed');
        } else {
            await mutate('/member/get-member');
            navigate('#reauthorize-mandate-success');
        }

        setDisabled(false);
    };

    return isOpen ? (
        <Modal isOpen={isOpen} onModalClose={onClose}>
            <StrokeText
                stroke="last"
                color="anthracite"
                className="text-anthracite mb-4"
                text={t('debit.block.modal.title')}
            />
            <Text size={2} className="mb-s">
                {t('debit.block.modal.description')}
            </Text>
            <Text size={2}>{t('debit.block.modal.term')}</Text>
            <TableFlex
                size="s"
                className="border-solid border-b-[1px] border-grey mb-s"
                data={[['IBAN', <Bold key={member.iban}>{member.iban}</Bold>]]}
            />
            <Button
                className="mb-s"
                text={t('debit.block.modal.submit')}
                onClick={changeMandate}
                loading={isLoading}
                disabled={disabled}
            />
            <Button
                theme="outline"
                text={t('debit.block.modal.change')}
                onClick={() => navigate(`/information/change-payment-info?returnUrl=${returnUrl}`)}
            />
        </Modal>
    ) : null;
};
