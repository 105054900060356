import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, InputText } from '@basic-fit/design-system';
import { Text } from '@basic-fit/design-system';
import { Form } from '@src/components/Form/Form';
import { Section } from '@src/components/Layout/Section';
import { useMember } from '@src/services/member/MemberProvider';
import {
    getFromSessionCache,
    setToSessionCache
} from '@src/services/utilities/sessionCache.service';
import { collectFeedback } from '@src/utils/helpers/newRelic';

import { FeedbackThankYouBlock } from './ThankYouBlock.friends';
import { FeedbackImagesSVG, FeedbackTypes } from './UserFeedbackSVG.friends';

export const UserFeedbackForm = (): JSX.Element => {
    const { t } = useTranslation();
    const feedbackText = useRef<HTMLInputElement>(null);
    const shouldShowThankyouBlock = getFromSessionCache('isThankyouAvailable') === 'true';
    const [pickedFeedbackType, setPickedFeedbackType] = useState<FeedbackTypes>(FeedbackTypes.None);
    const {
        state: { people_id_g, country }
    } = useMember();
    const [isThankyouAvailable, setIsThankyouAvailable] = useState(shouldShowThankyouBlock);
    const [isbadFeedBack, setIsBadFeedback] = useState(false);

    async function clickImageHandler(value: FeedbackTypes) {
        setPickedFeedbackType(value);

        collectFeedback({
            event: 'feedback',
            type: value as 'neutral' | 'good' | 'bad',
            peopleId: people_id_g,
            country: country
        });

        if (value === FeedbackTypes.Bad) {
            return setIsBadFeedback(true);
        }

        await closeFeedbackForm();
    }

    async function submitHandler() {
        collectFeedback({
            event: 'feedback',
            type: 'feedback',
            message: feedbackText.current?.value || '',
            peopleId: people_id_g,
            country: country
        });

        await closeFeedbackForm();
    }

    async function closeFeedbackForm() {
        setToSessionCache('isThankyouAvailable', 'true');
        setIsThankyouAvailable(true);
    }

    const shouldShowBadFeebackForm = isbadFeedBack && isThankyouAvailable === false;

    return (
        <>
            <Section className={`relative z-0 opacity-1`}>
                <div
                    className="w-full flex justify-center flex-wrap p-y-xs sm:p-y-s p-x-0 sm:p-x-s"
                    data-cy="pickedFeedbackType-wrapper"
                >
                    {/* How is your experience with inviting friends? */}
                    <Text size={1} bold={true} className="px-[20px] sm:px-0 mb-[20px] sm:mb-[30px]">
                        {t('feedback.friends.title')}
                    </Text>

                    <div className="w-full flex justify-center flex-wrap">
                        {pickedFeedbackType === 'none' && !isThankyouAvailable && (
                            <div
                                className="grid grid-cols-3 sm:gap-x-5 gap-x-8 w-[70%] sm:w-[500px]
                sm:h-[130px] h-[75px] my-30px sm:my-0"
                            >
                                {Object.values(FeedbackTypes)?.map((imageToDisplay) => (
                                    <FeedbackImagesSVG
                                        key={imageToDisplay}
                                        onClick={clickImageHandler}
                                        active={
                                            pickedFeedbackType === imageToDisplay ||
                                            pickedFeedbackType === 'none'
                                        }
                                        imageToDisplay={imageToDisplay}
                                    />
                                ))}
                            </div>
                        )}

                        {isThankyouAvailable && <FeedbackThankYouBlock />}

                        {shouldShowBadFeebackForm ? (
                            <Form
                                name="UserFriendsFeedbackForm"
                                onSubmit={submitHandler}
                                className="w-full sm:w-[600px] px-[20px] sm:px-0 flex flex-col"
                            >
                                {/* Sorry to hear that, how can we improve? */}
                                <InputText
                                    label={t('feedback.friends.placeholder')}
                                    name="pickedFeedbackTypeText"
                                    ref={feedbackText}
                                />
                                {/* Submit */}
                                <Button
                                    type="submit"
                                    className="w-full sm:w-[300px] mb-xxs mt-s self-center"
                                >
                                    {t('feedback.friends.submit')}
                                </Button>
                            </Form>
                        ) : null}
                    </div>
                </div>
            </Section>
        </>
    );
};
