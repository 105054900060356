import React from 'react';

type DefaultProps = {
    className?: string;
    width?: string;
    height?: string;
};

type SkeletonElementProps = DefaultProps & {
    children?: React.ReactNode;
};

type SkeletonTextProps = DefaultProps & {
    lines?: number;
};

type SkeletonProps = SkeletonTextProps & {
    type: 'single' | 'image' | 'page' | 'button' | 'text';
};

export const Skeleton = ({
    className = '',
    type = 'page',
    lines,
    width,
    height
}: SkeletonProps) => {
    switch (type) {
        case 'image':
            return <SkeletonImage width={width} height={height} className={className} />;
        case 'page':
            return (
                <div data-cy="page-skeleton" className={`grid grid-cols-1 gap-4 ${className}`}>
                    <SkeletonImage width="w-full" height="h-40" />
                    <SkeletonElement />
                    <SkeletonElement />
                    <SkeletonElement />
                </div>
            );
        case 'button':
            return <SkeletonButton className={className} />;
        case 'text':
            return <SkeletonText className={className} lines={lines} />;
        case 'single':
        default:
            return <SkeletonElement width={width} height={height} className={className} />;
    }
};

const SkeletonText = ({ className = 'w-full', lines = 1 }: SkeletonTextProps) => {
    const totalLines = Math.floor(lines);
    const partialLine = lines - totalLines;

    return (
        <>
            {totalLines > 0 &&
                [...Array(totalLines).fill('')].map((key, index) => (
                    <span
                        key={`text-skeleton-${index}-${totalLines}-${partialLine}`}
                        className={`h-[1em] w-full inline-block rounded items-center justify-center bg-gradient-to-r from-grey via-secondary-jersey-gray to-grey bg-skeleton animate-gradient ${className}`}
                        aria-busy="true"
                        data-cy="skeleton"
                    />
                ))}
            {partialLine > 0 && (
                <span
                    className={`h-[1em] inline-block rounded items-center justify-center bg-gradient-to-r from-grey via-secondary-jersey-gray to-grey bg-skeleton animate-gradient ${className}`}
                    style={{
                        width: `${100 * partialLine}%`
                    }}
                    aria-busy="true"
                    data-cy="text-skeleton"
                />
            )}
        </>
    );
};

const SkeletonButton = ({ className = '' }: SkeletonElementProps) => {
    return (
        <div
            className={`flex box-content h-[1em] py-[16px] font-bold font-heading-treble text-[1rem] items-center justify-center bg-gradient-to-r from-grey via-secondary-jersey-gray to-grey bg-skeleton animate-gradient ${className}`}
            aria-busy="true"
            data-cy="button-skeleton"
        />
    );
};

const SkeletonElement = ({
    className = '',
    width = 'w-full',
    height = 'h-m',
    children
}: SkeletonElementProps) => {
    return (
        <div
            className={`flex rounded items-center justify-center bg-gradient-to-r from-grey via-secondary-jersey-gray to-grey bg-skeleton animate-gradient ${width} ${height} ${className}`}
            aria-busy="true"
            data-cy="element-skeleton"
        >
            {children}
        </div>
    );
};

const SkeletonImage = ({ className = '', width = 'w-80', height = 'h-60' }: DefaultProps) => {
    return (
        <SkeletonElement
            className={`rounded ${className}`}
            width={width}
            height={height}
            data-cy="image-skeleton"
            aria-busy="true"
        >
            <div className="w-10 h-10">
                <svg
                    width="100%"
                    height="100%"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    className="fill-white"
                >
                    <path d="M447.1 32h-384C28.64 32-.01 60.65-.01 96v320c0 35.35 28.65 64 63.1 64h384c35.35 0 64-28.65 64-64V96c.01-35.35-27.79-64-63.99-64zm-336 64c26.51 0 48 21.49 48 48s-20.6 48-48 48-48-21.49-48-48 22.38-48 48-48zm335 311.6c-2.8 5.2-8.2 8.4-14.1 8.4H82.01a15.993 15.993 0 0 1-14.26-8.75 16 16 0 0 1 1.334-16.68l70-96C142.1 290.4 146.9 288 152 288s9.916 2.441 12.93 6.574l32.46 44.51 93.3-139.1C293.7 194.7 298.7 192 304 192s10.35 2.672 13.31 7.125l128 192c3.29 4.875 3.59 11.175.79 16.475z" />
                </svg>
            </div>
        </SkeletonElement>
    );
};
