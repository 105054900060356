/**
 *
 * @param input String that has data that must be interpolated'Hello { first_name } { last_name } '
 * @param data Object that provides dynamic data, where key corresponds to dynamic values in an input string and value is a dynamic value to be inserted
 * for example { "{ first_name }": "Johnny", "{ last_name }": "Doe" }
 * @returns Interpolated string, for example 'Hello Johnny Doe'
 */

export const interpolate = (input: string, data: Record<string, string>): string => {
    Object.entries(data).forEach(([key, value]) => {
        input = input.replace(key, value);
    });
    return input;
};
