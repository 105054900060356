import React, { useId, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Text } from '@basic-fit/design-system';
import { Error } from '@src/components/Form/Error/Error.component';
import { Form } from '@src/components/Form/Form';
import { TextInput } from '@src/components/Form/TextInput/TextInput';
import { Header } from '@src/components/Header/Header';
import { Section } from '@src/components/Layout/Section';
import { LineSVG } from '@src/components/LineSVG/LineSVG';
import { StrokeText } from '@src/components/StrokeText/StrokeText';
import { TableFlex } from '@src/components/TableFlex/TableFlex';
import { Bold } from '@src/components/Text/Bold';
import { SuccessModal } from '@src/feature/ChangeMemberModals/Success.modal';
import { TooManyTriesModal } from '@src/feature/ChangeMemberModals/TooManyTries.modal';
import { useMember } from '@src/services/member/MemberProvider';
import { useAwaitSpinner } from '@src/utils/hooks/useAwaitSpinner';
import { usePreventEmployeeChangeDetails } from '@src/utils/hooks/usePreventEmployeeChangeDetails';
import axios from 'axios';
import { useSWRConfig } from 'swr';

import { domain, headers } from 'services/get-api-elements';
import googleTagManagerPush from 'services/google-tag-manager-push';

interface ChangeMyInformationProps {
    isAllowedToChangeAddress?: boolean;
    redirectOnChange?: string;
}

export default function ChangeMyInformation({
    isAllowedToChangeAddress = true,
    redirectOnChange
}: ChangeMyInformationProps): JSX.Element {
    const { t } = useTranslation();

    usePreventEmployeeChangeDetails();

    return (
        <div className="h-full">
            <div className="hidden lg:block">
                <Header
                    title={t('166')}
                    image={<LineSVG id="eclipse" className="absolute right-0 top-0" />}
                />
            </div>
            <Section className="p-4 lg:p-0">
                <div className="mint-top" data-cy="member-email-block">
                    <ChangeAddressComponent
                        isAllowedToChangeAddress={isAllowedToChangeAddress}
                        redirectOnChange={redirectOnChange}
                    />
                </div>
            </Section>
        </div>
    );
}

const ChangeAddressComponent = ({
    isAllowedToChangeAddress,
    redirectOnChange
}: ChangeMyInformationProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { mutate } = useSWRConfig();
    const { state: member } = useMember();
    const [success, setSuccess] = useState(false);
    const { awaiting: isLoading } = useAwaitSpinner();
    const [successModal, setSuccessModal] = useState(false);
    const [timeoutModal, setTimeoutModal] = useState(false);
    const [sendFormError, setSendFormError] = useState<string | null>(null);

    const submitAddressChange = async (values: any) => {
        setSendFormError(null);
        setSuccess(false);
        googleTagManagerPush.changeMemberInfo();

        const { ppl_address1, ppl_address2, ppl_postcode, ppl_city } = values;

        /** Naming is as per to GM API */
        const bodyObject = {
            emailTokenType: 'changeAddress',
            pplAddress1: ppl_address1,
            pplAddress2: ppl_address2,
            pplPostcode: ppl_postcode,
            pplCity: ppl_city
        };

        try {
            /** API call */
            const { data } = await axios({
                method: 'POST',
                url: `${domain}/member/update-member-address`,
                headers,
                data: bodyObject
            });

            // POP SUCCESS MODAL
            if (data.success) {
                await mutate('/member/get-member');
                setSuccessModal(true);
                setSuccess(true);
            }
        } catch (error: any) {
            setSuccessModal(false);
            setSuccess(false);
            // POP TIME OUT ERROR
            if (axios.isAxiosError(error) && error.response?.status === 429) {
                return setTimeoutModal(true);
            }
            setSendFormError(t('details.change.unexpectedError'));
        }
    };

    const doesUserHaveAAddress = member.mailing_street && member.mailing_house_number;

    const emailTable = [
        [
            <div key={useId()} className="flex justify-start items-center h-full w-full">
                {t('153')}
            </div>,
            <>
                <Bold key={useId()} className="group relative">
                    {member.mailing_street} {member.mailing_house_number}
                </Bold>
                <br />
                <Bold key={useId()} className="group relative">
                    {member.poste_code} {member.mailing_city}
                </Bold>
            </>
        ]
    ];

    return (
        <>
            <StrokeText
                stroke="last2"
                color="anthracite"
                className="mb-m"
                text={t('details.change.address.title')}
            />
            <Text size={2} className="mb-4 leading-[130%]">
                {t('details.change.address.description')}
            </Text>
            {doesUserHaveAAddress && (
                <div data-cy="member-current-address" className="mb-6">
                    <Text
                        size={2}
                        className="!text-regular-m lowercase first-letter:uppercase font-bold"
                    >
                        {t('details.change.address.tableHeader')}
                    </Text>
                    <TableFlex
                        size="s"
                        data={emailTable}
                        className="border-b border-anthracite-74"
                    />
                </div>
            )}

            <Form name="changeMyInformation" onSubmit={submitAddressChange} className="space-y-3">
                <Text
                    size={2}
                    className="!text-regular-m lowercase first-letter:uppercase font-bold"
                >
                    {t('details.change.address.formHeader')}
                </Text>
                <TextInput
                    data-cy="member-input-address1"
                    label={t('380')}
                    name="ppl_address1" //street
                    required={true}
                    readOnly={!isAllowedToChangeAddress}
                    pattern={{
                        value: /[A-Za-z]/,
                        message: t('2034')
                    }}
                />

                <TextInput
                    data-cy="member-input-address2"
                    className="mb-[20px]"
                    label={t('457')}
                    name="ppl_address2" // street number
                    required={true}
                    readOnly={!isAllowedToChangeAddress}
                    pattern={{
                        // eslint-disable-next-line
                        value: /^\d[A-Za-z]?[A-Za-z0-9 ,\-]{0,18}$/,
                        message: t('2034')
                    }}
                />

                <TextInput
                    data-cy="member-input-post-code"
                    label={t('383')}
                    name="ppl_postcode" // zip code
                    required={true}
                    pattern={{
                        value: /(^\d{4}[ ]?[A-Z]{2}$|^\d{2}$|^\d{2}[ ]?\d{3}$|^\d{5}$|^\d{4}$)/,
                        message: t('2034')
                    }}
                    readOnly={!isAllowedToChangeAddress}
                />

                <TextInput
                    data-cy="member-input-city"
                    label={t('381')}
                    name="ppl_city"
                    required={true}
                    pattern={{
                        value: /[A-Za-z]/,
                        message: t('2034')
                    }}
                    readOnly={!isAllowedToChangeAddress}
                />

                <div className="mt-m">
                    <Button
                        data-cy="member-input-submit-button"
                        className="mt-[50px] w-full"
                        loading={isLoading}
                        type="submit"
                        icon={success ? 'tick' : undefined}
                    >
                        {success ? t('242') : t('385')}
                    </Button>
                    {sendFormError && <Error error={sendFormError} />}
                </div>
            </Form>
            <Button
                className="mt-[20px] w-full"
                onClick={() => navigate('/information')}
                theme="light"
            >
                {t('162')}
            </Button>

            {successModal && (
                <SuccessModal
                    autoClose={true}
                    onCloseCb={() => {
                        navigate(redirectOnChange || '/information');
                    }}
                />
            )}
            {timeoutModal && (
                <TooManyTriesModal
                    onCloseCb={() => {
                        navigate(redirectOnChange || '/information');
                    }}
                />
            )}
        </>
    );
};
