import React from 'react';

type PageHeadingProps = {
    index: number;
    children: React.ReactNode;
};

export const PageHeading = ({ index, children }: PageHeadingProps) => {
    return (
        <div className={`flex items-center p-xs sm:py-s sm:px-0 md:p-0 md:my-xl`}>
            <span
                className={`font-heading-treble font-extrabold text-[32px] mr-xxs leading-none text-orange md:text-[56px] md:mr-m`}
            >
                {index}
            </span>
            <h1
                className={`font-heading-treble font-extrabold uppercase text-[16px] sm:text-[24px] md:text-[32px]`}
            >
                {children}
            </h1>
        </div>
    );
};
