import React, { HTMLAttributes, PropsWithChildren } from 'react';

import { Icon } from '../Icons/Icons';

export const InformationBlock = ({
    children,
    className = '',
    ...rest
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => (
    <div
        className={`flex bg-jersey-grey p-xs sm:p-s space-x-xs sm:space-x-s ${className}`}
        {...rest}
    >
        <Icon
            id="info"
            className="text-orange text-regular-m md:text-regular-l !scale-[1] shrink-0"
        />
        <div className="flex-grow">{children}</div>
    </div>
);
