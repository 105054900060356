import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { ButtonLink } from '@src/components/Buttons/ButtonLink';
import { useFetch } from '@src/utils/hooks/api/useFetch';

import getBrowser from 'services/get-browser';
import { Box } from '../Box/Box';

interface IFrozenOptions extends HTMLAttributes<HTMLDivElement> {
    link?: boolean;
}

export const FrozenOptions = ({ link = false }: IFrozenOptions): JSX.Element => {
    const { t } = useTranslation();
    const { data: homeClubHasActiveFrozenCampaign } = useFetch<any>(
        '/extra/is-club-in-active-frozen-campaign'
    );

    const browser = getBrowser();
    const isSafari = browser.includes('safari');

    const handleFrozenClicked = () => {
        window.open(homeClubHasActiveFrozenCampaign.frozenLink, isSafari ? '_self' : '_blank');
    };

    return (
        <Box>
            <Heading size={5}>{t('1515')}</Heading>
            <Text size={1}>{t('1516')}</Text>
            {link ? (
                <ButtonLink type="link" onClick={handleFrozenClicked} className="mt-xxs">
                    {t('1518')}
                </ButtonLink>
            ) : (
                <Button onClick={handleFrozenClicked} className="mt-xxs">
                    {t('1524')}
                </Button>
            )}
        </Box>
    );
};
