import React from 'react';

import ParagraphNormal from './ParagraphNormal';

export default ({ text }) => {
    return text && text.split('%%paragraph%%') && text.split('%%paragraph%%')[1] ? (
        text.split('%%paragraph%%').map((para, index) => {
            return <ParagraphNormal text={para.trim()} key={index} />;
        })
    ) : (
        <ParagraphNormal text={text} />
    );
};
