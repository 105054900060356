import React, { useEffect, useState } from 'react';
import { Toggle } from '@src/components/Form/Toggle/Toggle.component';

export type ToggleButtonProps = {
    on: string;
    off: string;
    active?: boolean;
    onClick?: (active: boolean) => void;
    changeOnClick?: boolean;
    className?: string;
    variant?: 'gray' | 'white' | undefined;
};

export const ToggleButton = ({
    on,
    off,
    active = false,
    onClick,
    changeOnClick = true,
    className = '',
    variant = 'gray'
}: ToggleButtonProps) => {
    const [activeState, setActiveState] = useState(active);

    useEffect(() => setActiveState(active), [active]);

    const handleClick = () => {
        const isActive = activeState ? false : true;
        if (changeOnClick) {
            setActiveState(isActive);
        }
        onClick?.(isActive);
    };

    return (
        <div className={`flex items-center cursor-pointer ${className}`} onClick={handleClick}>
            <span className="font-heading-treble font-bold text-heading-xs mr-xs">
                {activeState ? off : on}
            </span>
            <Toggle variant={variant} state={activeState} />
        </div>
    );
};
