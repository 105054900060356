import React from 'react';

export default function BestChoice() {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.3475 37.9693C12.4292 37.7609 5.33536 30.3277 5.54376 21.4094C5.75215 12.4911 13.1854 5.39726 22.1037 5.60566C31.022 5.81405 38.1158 13.2473 37.9074 22.1656C37.699 31.0839 30.2658 38.1777 21.3475 37.9693ZM22.0592 7.5094C14.1806 7.3253 7.6316 13.5753 7.4475 21.4539C7.2634 29.3324 13.5134 35.8815 21.392 36.0656C29.2705 36.2497 35.8196 29.9997 36.0037 22.1211C36.1878 14.2425 29.9378 7.6935 22.0592 7.5094Z"
                fill="#2D2D2D"
            />
            <path
                d="M15.5716 21.7913C16.7848 21.8196 17.7912 20.8591 17.8196 19.646C17.8479 18.4328 16.8875 17.4264 15.6743 17.398C14.4611 17.3697 13.4547 18.3301 13.4263 19.5433C13.398 20.7565 14.3585 21.7629 15.5716 21.7913Z"
                fill="#2D2D2D"
            />
            <path
                d="M27.7981 22.1497C29.0112 22.178 30.0177 21.2175 30.046 20.0044C30.0744 18.7912 29.1139 17.7848 27.9007 17.7564C26.6876 17.7281 25.6811 18.6885 25.6528 19.9017C25.6244 21.1149 26.5849 22.1213 27.7981 22.1497Z"
                fill="#2D2D2D"
            />
            <path
                d="M28.6534 25.686C28.5628 25.8011 26.2729 28.4876 21.5721 28.3777C16.9153 28.2689 15.0305 25.5289 14.9454 25.4096L13.3532 26.4567C13.4523 26.6055 15.8164 30.148 21.5276 30.2815C27.1949 30.4139 30.0138 27.0365 30.1197 26.8924L28.6534 25.686Z"
                fill="#2D2D2D"
            />
        </svg>
    );
}
