import React from 'react';

export default function ChevronLeftIcon() {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 18 12"
            fill="none"
            className="origin-[center] transition-[transform] duration-[300ms] group-hover:translate-x-[-2px] rotate-90"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M2 2L9 8.96643L16 2" stroke="currentColor" strokeWidth="3" />
        </svg>
    );
}
