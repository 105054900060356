import React from 'react';
import { ILayout } from '@src/types/layout.type';
import { Header } from 'refactor/components/Layout/Header';

export const PublicLayout = ({ children }: ILayout): JSX.Element => {
    return (
        <div className="flex items-center flex-col flex-grow w-full h-full">
            <Header />
            <div
                id="page"
                className="flex justify-start items-center flex-col flex-grow w-full overflow-x-hidden overflow-y-auto"
            >
                <div className="flex items-center flex-col w-full p-[0] sm:p-[30px] pb-[0] md:p-[60px] md:pb-[0] max-w-[1100px]">
                    {children}
                </div>
            </div>
        </div>
    );
};
