import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMember } from '@src/services/member/MemberProvider';
import { useVisits } from '@src/utils/hooks/useVisits';
import { VisitBlock } from 'refactor/components/Visits/VisitBlock';

export const VisitsMetrics = (): JSX.Element | null => {
    const { t } = useTranslation();
    const { state: member } = useMember();
    const { isError, totalVisits, visitsLastWeek, visitsThisWeek } = useVisits();

    const data = [
        { title: t('445'), number: totalVisits, tag: t('31') },
        { title: t('446'), number: visitsLastWeek, tag: t('31') },
        { title: t('447'), number: visitsThisWeek, tag: t('31') },
        { title: t('448'), number: member.gxr_views_s, tag: t('449') }
    ];

    if (isError) {
        return null;
    }

    return <VisitBlock data={data} title={t('31')} buttonText={t('64')} buttonLink="/visits" />;
};
