import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@basic-fit/design-system';
import { captureException } from '@src/utils/helpers/newRelic';
import { formatAmountForPayment } from '@src/utils/helpers/transactionHelpers';
import { isApp } from '@src/utils/helpers/window';
import useWSConnector, { ConnectProps } from '@src/utils/hooks/useConnectToWS';

import getAdyenLinks from 'services/API/get-adyen-link';
import getBrowser from 'services/get-browser';

type AdyenPaymentButtonProps = {
    className?: string;
    amount: number;
    peopleId: string;
    setNoSupport?: ConnectProps['setNoSupportToWebSocket'];
};

export const AdyenPaymentButton = ({
    className = '',
    amount,
    peopleId,
    setNoSupport = () => {
        /* handle websocket support gracefully when setNoSupport is ignored */
    }
}: AdyenPaymentButtonProps) => {
    const { connect } = useWSConnector();
    const { t } = useTranslation();

    const [fetchingPaymentLink, setFetchingPaymentLink] = useState(false);
    const [getPaymentLinkError, setPaymentLinkError] = useState(false);
    const [paymentLink, setPaymentLink] = useState('');

    let windowReferenceForPayment: Window | null;

    // when payment is done, refresh the origin page
    const refreshTransactions = async () => {
        window.location.reload();
    };

    const handleAppRedirect = (url: string) => {
        const payload = JSON.stringify({
            file: 'pdf', // todo: change signature with app team (something like type: 'url')
            url
        });
        window.ReactNativeWebView?.postMessage(payload);
    };

    const handlePayment = async () => {
        if (paymentLink) {
            if (isApp()) {
                handleAppRedirect(paymentLink);
            } else {
                return window.open(paymentLink);
            }
        }
        if (fetchingPaymentLink) {
            return;
        }

        if (!amount) {
            return console.log('No balance to pay!');
        }

        const browser = getBrowser();
        const isSafari = browser.includes('safari');
        if (isSafari) {
            windowReferenceForPayment = window.open();
        }

        setFetchingPaymentLink(true);

        const adyenLinks = await getAdyenLinks(formatAmountForPayment(amount)).catch((error) => {
            setPaymentLinkError(true);
            setFetchingPaymentLink(false);
            captureException(error, { component: 'AdyenPaymentButton' });
        });

        if (adyenLinks && adyenLinks.link && adyenLinks.domain && adyenLinks.signature) {
            setPaymentLink(adyenLinks.link);
            setFetchingPaymentLink(false);

            if (isApp()) {
                handleAppRedirect(adyenLinks.link);
            } else if (isSafari && windowReferenceForPayment) {
                windowReferenceForPayment.location = adyenLinks.link;
            } else {
                window.open(adyenLinks.link);
            }

            connect({
                domain: adyenLinks.domain,
                reference: adyenLinks.reference,
                signature: adyenLinks.signature,
                value: formatAmountForPayment(amount),
                peopleId,
                refreshTransactions,
                setNoSupportToWebSocket: setNoSupport
            });
        }
    };

    return (
        <>
            <Button
                onClick={handlePayment}
                className={`w-full ${className}`}
                data-cy="adyen-payment-button"
                variant="primary"
            >
                {fetchingPaymentLink ? t('232') : getPaymentLinkError ? t('504') : t('1980')}
            </Button>
        </>
    );
};
