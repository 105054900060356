import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { Modal } from '@src/components/Modals/Modal';
import { Skeleton } from '@src/components/Skeletons/Skeleton';
import { generateInviteLink } from '@src/services/secondaryMember.service';
import { useFetch } from '@src/utils/hooks/api/useFetch';

type ModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const InviteSecondaryMemberModal = ({ isOpen, onClose }: ModalProps) => {
    const { t } = useTranslation();

    const [linkCopied, setLinkCopied] = useState(false);
    const [inviteLink, setInviteLink] = useState<string>('');

    const {
        data,
        isError: error,
        isLoading
    } = useFetch<{ token: string }>(isOpen ? '/secondary-member/invite-token' : null);

    useEffect(() => {
        if (!isLoading && !error && data && isOpen) {
            setInviteLink(generateInviteLink(data.token));
        }
    }, [isLoading, error, data, isOpen]);

    const copyToClipboard = () => {
        const textField = document.createElement('textarea');
        textField.innerText = inviteLink;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        setLinkCopied(true);
    };

    const handleModalClose = () => {
        setLinkCopied(false);
        onClose?.();
    };

    return (
        <Modal isOpen={isOpen} onModalClose={handleModalClose}>
            {isLoading && <Skeleton type="text" lines={10}></Skeleton>}
            {!isLoading && (
                <>
                    <Heading size={6} className="mb-s">
                        {t('secondary_member.invite_link_modal_heading')}
                    </Heading>

                    {error && <Text size={2}>{t('generic.something_went_wrong')}</Text>}

                    {data && (
                        <>
                            <Text size={2}>{t('secondary_member.invite_link_modal_text')}</Text>

                            <Text size={2} className="break-all bg-jersey-grey p-xs my-xs">
                                {inviteLink}
                            </Text>

                            <Button
                                text={
                                    linkCopied ? t('generic.link_copied') : t('generic.copy_link')
                                }
                                onClick={() => copyToClipboard()}
                                id={linkCopied ? 'tick' : 'chevron-right'}
                            />
                        </>
                    )}
                </>
            )}
        </Modal>
    );
};
