import React from 'react';

export default function ClockIcon() {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8 16C6.41775 16 4.87103 15.5308 3.55544 14.6518C2.23985 13.7727 1.21447 12.5233 0.608967 11.0615C0.0034663 9.59966 -0.15496 7.99113 0.153721 6.43928C0.462403 4.88743 1.22433 3.46197 2.34315 2.34315C3.46197 1.22433 4.88743 0.462403 6.43928 0.153721C7.99113 -0.15496 9.59966 0.0034663 11.0615 0.608967C12.5233 1.21447 13.7727 2.23985 14.6518 3.55544C15.5308 4.87103 16 6.41775 16 8C15.9975 10.121 15.1539 12.1544 13.6541 13.6541C12.1544 15.1539 10.121 15.9975 8 16ZM8 0.94118C6.6039 0.94118 5.23915 1.35517 4.07833 2.13081C2.91751 2.90644 2.01277 4.00888 1.4785 5.29871C0.944235 6.58854 0.804447 8.00783 1.07681 9.37711C1.34918 10.7464 2.02147 12.0041 3.00866 12.9913C3.99586 13.9785 5.25362 14.6508 6.62289 14.9232C7.99217 15.1956 9.41146 15.0558 10.7013 14.5215C11.9911 13.9872 13.0936 13.0825 13.8692 11.9217C14.6448 10.7609 15.0588 9.39611 15.0588 8C15.0567 6.12853 14.3123 4.33432 12.989 3.01099C11.6657 1.68766 9.87147 0.943288 8 0.94118Z"
                fill="url(#paint0_linear)"
            />
            <path
                d="M7.96364 7.96378V4.34387H7.02246V7.96378V8.61536V8.90496H11.5835V7.96378H7.96364Z"
                fill="#7ADEC9"
            />
            <path
                d="M13.9367 7.94578H12.9955C12.994 6.61657 12.4653 5.34223 11.5254 4.40234C10.5855 3.46244 9.31114 2.93374 7.98193 2.9322V1.99103C9.56064 1.99294 11.0741 2.62093 12.1905 3.73725C13.3068 4.85357 13.9348 6.36707 13.9367 7.94578Z"
                fill="url(#paint1_linear)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="16"
                    y1="8"
                    x2="3.8147e-06"
                    y2="8"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFAA30" />
                    <stop offset="0.08" stopColor="#FF9D26" />
                    <stop offset="0.24" stopColor="#FE8915" />
                    <stop offset="0.42" stopColor="#FE7B09" />
                    <stop offset="0.64" stopColor="#FE7302" />
                    <stop offset="1" stopColor="#FE7000" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="1154.27"
                    y1="410.665"
                    x2="664.493"
                    y2="410.665"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFAA30" />
                    <stop offset="0.08" stopColor="#FF9D26" />
                    <stop offset="0.24" stopColor="#FE8915" />
                    <stop offset="0.42" stopColor="#FE7B09" />
                    <stop offset="0.64" stopColor="#FE7302" />
                    <stop offset="1" stopColor="#FE7000" />
                </linearGradient>
            </defs>
        </svg>
    );
}
