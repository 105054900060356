import React from 'react';
import { Text } from '@basic-fit/design-system';

type TransactionRowProps = {
    className?: string;
    display?: 'header' | 'row';
    date?: React.ReactElement<typeof Text> | string;
    title: React.ReactNode;
    amount: React.ReactNode;
    balance?: React.ReactNode;
    isDebitRow?: boolean
};

export const TransactionRow = ({
    className = '',
    display = 'row',
    date,
    title,
    amount,
    balance,
    isDebitRow
}: TransactionRowProps) => {
    return (
        <div
            className={`flex flex-row flex-wrap content-start md:items-center cursor-pointer ${className}`}
        >
            <div
                className={`${isDebitRow ? '' : 'w-28'} order-3 md:order-1 md:block ${display === 'header' ? 'hidden' : 'block'
                    }`}
            >
                {date}
            </div>
            <div
                className={`md:w-min order-1 grow flex items-center ${display === 'header' ? 'w-1/2' : 'w-4/6'
                    }`}
            >
                {title}
            </div>
            <div
                className={`md:w-32 order-2 text-right ${display === 'header' && !isDebitRow ? 'pr-2' : 'w-2/6'
                    }`}
            >
                {amount}
            </div>
            <div
                className={`${isDebitRow ? '' : 'w-28'} ml-l order-4 hidden md:block text-right ${display === 'header' && !isDebitRow ? 'pr-2' : ''
                    }`}
            >
                {balance}
            </div>
        </div>
    );
};
