import { useEffect } from 'react';
import { redirect } from '@src/utils/helpers/redirect';

/**
 * Alternative redirect for use with React Router
 * Sometimes using <Navigate /> with <Route> causes an infinite loop
 */
export const Redirect = ({ to }: { to: string }) => {
    useEffect(() => {
        redirect(to);
    }, []);

    return null;
};
