import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@basic-fit/design-system';
import { ButtonLink } from '@src/components/Buttons/ButtonLink';
import { InformationBlock } from '@src/components/InformationBlock/InformationBlock';

interface DebitBlockBannerProps {
    onClick?: (e: React.MouseEvent) => void;
}

export const DebitBlockBanner = ({ onClick }: DebitBlockBannerProps) => {
    const { t } = useTranslation();
    return (
        <InformationBlock>
            <Text size={2}>{t('debit.block.banner.description')}</Text>
            <ButtonLink onClick={onClick ? onClick : (e) => e.preventDefault()}>
                {t('debit.block.banner.button')}
            </ButtonLink>{' '}
        </InformationBlock>
    );
};
