import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Text } from '@basic-fit/design-system';
import { ButtonList } from '@src/components/ButtonList/ButtonList';
import { IconButton } from '@src/components/Buttons/IconButton';
import { Icon } from '@src/components/Icons/Icons';
import { isEmpty } from '@src/utils/helpers/collections';
import { isApp } from '@src/utils/helpers/window';
import { MembershipContract } from '@src/utils/hooks/api/types/membershipContract';
import { useFetch } from '@src/utils/hooks/api/useFetch';
import { saveAs } from 'file-saver';

export const DownloadContract = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const sepaEnabled = false;
    const [downloadError, setDownloadError] = useState(false);

    const { data, isLoading, isError } = useFetch<MembershipContract>(
        !isApp() ? '/memberships/has-membership-contract' : null
    );

    const getContract = async () => {
        const response = await fetch(
            '/memberships/get-membership-contract?id=' + data.letterRequestId
        );
        if (response.ok) {
            const file = await response.blob();
            const url = URL.createObjectURL(file);
            saveAs(url, 'basic-fit.pdf');
        } else {
            setDownloadError(true);
        }
    };

    if (isLoading || isError || isEmpty(data)) return null;

    return (
        <ButtonList
            title={t('membership.download.contract.title')}
            text={
                isApp()
                    ? t('membership.download.contract.app')
                    : t('membership.download.contract.text')
            }
        >
            {!isApp() && (
                <IconButton
                    icon={<Icon id="contract" />}
                    title={t('membership.download.contract.button.membership')}
                    onClick={getContract}
                    action="download"
                />
            )}

            {sepaEnabled && (
                <IconButton
                    icon={<Icon id="contract" />}
                    title={t('membership.download.contract.button.sepa')}
                    onClick={() => navigate('/membership/change-home-club')}
                    action="download"
                />
            )}

            {downloadError && (
                <Text size={2} className="text-orange mt-xxs">{`${t('238')} ${t('257')}`}</Text>
            )}
        </ButtonList>
    );
};
