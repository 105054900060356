export const UNIT_TO_SECONDS = {
    second: 1,
    minute: 60,
    hour: 3600,
    day: 86400,
    week: 604800,
    month: 2592000,
    year: 31536000
} as const;

export const PAYMENT_INTERVAL_TO_SECONDS = {
    '1day': UNIT_TO_SECONDS.day,
    oneWeek: UNIT_TO_SECONDS.week,
    twoWeeks: UNIT_TO_SECONDS.week * 2,
    '3weeks': UNIT_TO_SECONDS.week * 3,
    fourWeeks: UNIT_TO_SECONDS.week * 4,
    oneMonth: UNIT_TO_SECONDS.month,
    '5weeks': UNIT_TO_SECONDS.week * 5,
    '6week': UNIT_TO_SECONDS.week * 6,
    '7weeks': UNIT_TO_SECONDS.week * 7,
    '8week': UNIT_TO_SECONDS.week * 8,
    twoMonths: UNIT_TO_SECONDS.month * 2,
    '10week': UNIT_TO_SECONDS.week * 10,
    '12weeks': UNIT_TO_SECONDS.week * 12,
    '3month': UNIT_TO_SECONDS.month * 3,
    quarter: UNIT_TO_SECONDS.month * 3,
    '14weeks': UNIT_TO_SECONDS.week * 14,
    fourMonths: UNIT_TO_SECONDS.month * 4,
    '5month': UNIT_TO_SECONDS.month * 5,
    '24week': UNIT_TO_SECONDS.week * 24,
    halfYear: UNIT_TO_SECONDS.month * 6,
    '30week': UNIT_TO_SECONDS.week * 30,
    '7month': UNIT_TO_SECONDS.month * 7,
    '9month': UNIT_TO_SECONDS.month * 9,
    '10month': UNIT_TO_SECONDS.month * 10,
    '52weeks': UNIT_TO_SECONDS.week * 52,
    year: UNIT_TO_SECONDS.year,
    '56weeks': UNIT_TO_SECONDS.week * 56,
    '13month': UNIT_TO_SECONDS.month * 13,
    '15month': UNIT_TO_SECONDS.month * 15,
    '18month': UNIT_TO_SECONDS.month * 18,
    '21month': UNIT_TO_SECONDS.month * 21,
    '23month': UNIT_TO_SECONDS.month * 23,
    twoYears: UNIT_TO_SECONDS.year * 2,
    '25month': UNIT_TO_SECONDS.month * 25,
    '26month': UNIT_TO_SECONDS.month * 26,
    '3year': UNIT_TO_SECONDS.year * 3,
    '4years': UNIT_TO_SECONDS.year * 4,
    '5year': UNIT_TO_SECONDS.year * 5,
    '10year': UNIT_TO_SECONDS.year * 10,
    '50year': UNIT_TO_SECONDS.year * 50
} as const;
