import React, { useEffect, useRef, useState } from 'react';
import { mediaQueries } from 'parameters/css-parameters';
import styled from 'styled-components';

import Loader from './Loader';

const OverlayBox = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgb(from var(--color--anthracite) r g b / 50%); 
  background: rgba(45, 45, 45, 0.5); /* Hardcoded fallback */
  z-index: 99999;
  backdrop-filter: blur(4px);
`;

const Wrapper = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  max-height: calc(100vh - 210px);
  overflow-y: auto;
`;

const Popup = styled.div`
  position: relative;
  width: 100%;
  max-width: 768px;
  background: #ffffff;
  margin: auto;
  ${mediaQueries['md']} {
    padding: 80px;
  }
  padding: 32px;
`;

export default ({
    Contents,
    showLoader = false,
    loaderSuccess = false,
    loaderText = '',
    successText = ''
}) => {
    const [noFadeIn, setNoFadeIn] = useState(false);

    const fadeInTimer = useRef(0);

    useEffect(() => {
        fadeInTimer.current = setTimeout(() => {
            // remove fade-in after loading to prevent re-flashing
            setNoFadeIn(true);
        }, 300);

        return () => {
            clearTimeout(fadeInTimer.current);
        };
    }, []);

    return (
        <OverlayBox className={!noFadeIn ? 'just-fade-in' : ''}>
            {showLoader && (
                <Loader success={loaderSuccess} successText={successText} text={loaderText} />
            )}
            <Wrapper>
                <div>
                    <Popup>
                        <Contents />
                    </Popup>
                </div>
            </Wrapper>
        </OverlayBox>
    );
};
