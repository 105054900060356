export const getAppUrl = (language) => {
    const activeLanguage = language || 'en';
    const androidNew = `https://play.google.com/store/apps/details?id=com.basicfit.trainingApp&hl=${activeLanguage}`;
    const iosNew = `https://apps.apple.com/${
        activeLanguage === 'en' ? 'us' : activeLanguage
    }/app/id1588263601`;

    return {
        android: androidNew,
        ios: iosNew
    };
};

export const getDevice = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/windows phone/i.test(userAgent)) {
        return 'win-mob';
    }
    if (/android/i.test(userAgent)) {
        return 'android';
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'ios';
    }
    if (navigator.platform.indexOf('Mac') > -1) {
        return 'mac';
    }
    if (navigator.platform.indexOf('Win') > -1) {
        return 'win';
    }
    return 'false'; //making this a string so you can use methods like .includes().
};
