export const hasFrozenCompensations = (compensations: any): boolean => {
    let isFrozen = false;
    if (compensations?.length) {
        for (let i = 0; i < compensations.length; i++) {
            if (
                compensations[i].source__c &&
                compensations[i].source__c === 'FrozenIII' &&
                compensations[i].wave_campaign__r &&
                compensations[i].wave_campaign__r.FrozenII_reactivation_date__c &&
                new Date(compensations[i].wave_campaign__r.FrozenII_reactivation_date__c) >
                    new Date()
            ) {
                isFrozen = true;
            }
        }
    }
    return isFrozen;
};

export const hasActiveCompensations = (chosenCompensations: any, activeCampaign: any): boolean => {
    let hasCompensation = false;
    if (activeCampaign && chosenCompensations) {
        const currentChosenCompensation = chosenCompensations.some(
            ({ wave_campaign__r }: any) =>
                wave_campaign__r && wave_campaign__r.Id === activeCampaign.id
        );

        if (currentChosenCompensation) {
            hasCompensation = true;
        }
    }
    return hasCompensation;
};
