import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@src/components/Buttons/Button';
import { MyChanges } from '@src/pages/ChangeMembership/components/MyChanges';
import { PageHeading } from '@src/pages/ChangeMembership/components/PageHeading';
import { ChangeMembershipContext } from '@src/pages/ChangeMembership/machines/ChangeMembershipProvider';

import { Extras } from '../components/Extras';

export const MembershipExtras = () => {
    const { t } = useTranslation();
    const {
        changeService: { send }
    } = useContext(ChangeMembershipContext);

    return (
        <>
            <PageHeading index={2}>{t('membership.change.title.extra')}</PageHeading>

            <Extras />
            <MyChanges className={`my-l`} />

            <div className="px-xs pb-l m-auto max-w-[345px]">
                <Button
                    type="submit"
                    text={t('membership.change.to.overview')}
                    onClick={() => send({ type: 'NEXT_STEP' })}
                />
                <Button
                    text={t('162')}
                    color="naked"
                    className="w-auto m-auto"
                    onClick={() => send({ type: 'PREVIOUS_STEP' })}
                />
            </div>
        </>
    );
};
