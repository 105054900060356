import React from 'react';

export const Search = () => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#a)">
            <path
                d="m15.7 14.36-3.62-3.61a6.73 6.73 0 1 0-1.36 1.36l3.62 3.61a.94.94 0 0 0 .68.28 1 1 0 0 0 .68-1.64ZM1.98 6.73a4.73 4.73 0 1 1 4.72 4.72A4.73 4.73 0 0 1 2 6.73h-.02Z"
                fill="#2D2D2D"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h16v16H0z" />
            </clipPath>
        </defs>
    </svg>
);
