import React from 'react';
import { useLocation } from 'react-router-dom';

export default function MenuItem({ item }: any) {
    const location = useLocation();

    return (
        <div
            className={`px-[24px] py-0 cursor-pointer hover:bg-cod-grey ${
                location.pathname === item.path ? 'bg-cod-grey' : 'bg-anthracite'
            }`}
            id={item.id}
            data-cy={item.name}
        >
            <div
                className={`text-white py-[10px] px-0 border-b-[1px] border-jersey-grey ${
                    item.mainMenu
                        ? 'font-heading-treble text-[16px] font-extrabold leading-[28px] uppercase'
                        : 'font-heading-double text-[14px] font-normal leading-[24px]'
                }`}
            >
                {item.text}
            </div>
        </div>
    );
}
