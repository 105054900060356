import React, { HTMLAttributes, useEffect } from 'react';
import { useController, useFormContext, ValidationRule } from 'react-hook-form';
import { Icon } from '@src/components/Icons/Icons';

import { Error, ErrorProps } from '../Error/Error.component';
import { InputLabel } from '../Label/Label.components';

interface CheckboxProps
    extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange' | 'defaultValue'>,
        ErrorProps {
    name: string;
    required?: ValidationRule<boolean>;
    label?: string | JSX.Element;
    defaultValue?: boolean;
}

export const CheckBox = ({
    name,
    label,
    className = '',
    required,
    defaultValue = false,
    error,
    ...rest
}: CheckboxProps): JSX.Element => {
    const { control, setError } = useFormContext();
    const {
        field: { onChange, value },
        fieldState: { error: stateError }
    } = useController({ name, control, defaultValue, rules: { required } });

    useEffect(() => {
        if (error) {
            const errorMessage = typeof error === 'string' ? error : '';
            setError(name, { message: errorMessage });
        }
    }, [error]);

    return (
        <div>
            <div
                className={`
                    cursor-pointer z-[5] mr-[8px] leading-[2em]
                    flex items-start select-none
                    ${className}
                `}
                onClick={() => onChange(!value)}
                {...rest}
            >
                <div
                    className="w-[18px] h-[18px] border-[2px] mt-[3px] border-grey relative flex-shrink-0"
                    role="checkbox"
                    aria-checked={value}
                    tabIndex={0}
                    aria-labelledby={label && `${name}-label`}
                >
                    {value && (
                        <Icon
                            className="absolute bottom-[0px] left-[2px] w-[100%] text-orange"
                            id="tick"
                        />
                    )}
                </div>
                {label && (
                    <InputLabel
                        name={name}
                        label={label}
                        required={required}
                        className="ml-xxs text-regular-s"
                    />
                )}
            </div>
            <Error error={stateError?.message || error} />
        </div>
    );
};
