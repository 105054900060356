import React, { Fragment } from 'react';
import styled from 'styled-components';

import { mediaQueries } from '../../parameters/css-parameters';
import { ReactComponent as Cancel } from '../img/X-thick.svg';
import TitleComponent from './Title';

const PopupTop = styled.div`
  position: relative;
  ${mediaQueries['md']} {
    top: -60px;
    left: 60px;
  }
  left: 20px;
  top: -20px;
`;

const PopupClose = styled.button`
  float: right;
  display: inline;
`;

export default ({ text = '', onClick = () => {}, stroke = '', color = 'anthracite' }) => {
    return (
        <Fragment>
            <PopupTop>
                <PopupClose onClick={onClick} type="button">
                    <Cancel />
                </PopupClose>
            </PopupTop>
            <TitleComponent text={text} stroke={stroke} color={color} size="M" mdSize="M" />
        </Fragment>
    );
};
