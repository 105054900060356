import { getTranslatedPaymentInterval } from '@src/utils/helpers/memberHelpers';
import i18next from 'i18next';

/**
 * The payment interval (pmsd_interval) is used to determine the length of:
 * - minimal contract period
 * - payment schedule interval
 * - direct debit interval
 * todo: move to more generic location in codebase, something like src/utils/helpers/enums.ts
 */

export enum PaymentInterval {
    FiftyTwoWeeks = '52weeks',
    FiftyYear = '50year', // test members
    FourWeeks = 'fourWeeks',
    FourYears = '4years',
    HalfYear = 'halfYear',
    OneMonth = 'oneMonth',
    OneWeek = 'oneWeek',
    Quarter = 'quarter',
    ThreeMonths = '3month',
    ThreeYear = '3year',
    TwoMonths = 'twoMonths',
    TwoWeeks = 'twoWeeks',
    TwoYears = 'twoYears',
    Year = 'year'
}

export const priceInterval = (interval: PaymentInterval) => {
    return `${i18next.t('prefix.per')} ${getTranslatedPaymentInterval(interval)}`;
};

/**
 * Returns a formatted price for given locale
 * fr-FR -> 1234,56 €
 * en-GB -> €1,234.56
 */
export const getPrice = (amount: number, lang: string, options?: { format: 'withDigits' }) => {
    const { format } = options || {};
    const fractionDigits = format === 'withDigits' || !Number.isInteger(amount);
    return new Intl.NumberFormat(lang, {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: fractionDigits ? 2 : 0,
        maximumFractionDigits: fractionDigits ? 2 : 0
    }).format(amount);
};

/**
 * Returns a formatted number for given locale
 */
export const getNumber = (amount: number, lang: string) =>
    amount.toLocaleString(lang, { minimumFractionDigits: 2 });
