export const hasValue = (value?: string): boolean =>
    !!(
        value &&
        value !== null &&
        value.trim() !== 'null' &&
        value.trim() !== 'undefined' &&
        value.trim() !== ''
    );

export const getClubName = (name: string): string => name.replace('Basic-Fit', '').trim();
export const clone = (data: object) => JSON.parse(JSON.stringify(data));
export const fuzzyMatch = (item: string, itemToCompare: string) =>
    item.toLowerCase() === itemToCompare.toLowerCase();
