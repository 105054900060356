import React from 'react';

import Training_02 from './images/4af356bf9443e234235a60e3fc106f78.png';
import Training_01 from './images/320b8537af7cbf876d12da53b7bbfc57.png';
import Yanga_01 from './images/842c85cce1858cbbfd73c9f270f4238d.png';
import App from './images/app.png';
import Bag from './images/Bag.png';
import Basic from './images/basic.png';
import Physio_01 from './images/BF_Website_Add-Ons_Physio.png';
import Personal_01 from './images/BF_Website_Add-Ons_PT.png';
import AddOns_POC from './images/BF_Website_Add-ons-POC-2.png';
import Yanga_02 from './images/BF_Website_Add-ons-YANGA-2.png';
import Yanga_03 from './images/BF_Website_HomepageBanner-15.png';
import Training_03 from './images/BF_Website_HomepageBanner-22.png';
import ChangeDetails from './images/changeDetails.png';
import ChangeExtras from './images/changeExtras.png';
import Comfort from './images/comfort.png';
import CreateMembership from './images/createMembership.png';
import DayPass from './images/daypass.png';
import Friends from './images/friends.png';
import Bike from './images/hero_bike.png';
import LiveGX from './images/liveGX.png';
import Login from './images/login.png';
import NewCard from './images/newCard.png';
import OverviewHero from './images/overview_hero.png';
import Personal_02 from './images/personalTraining.png';
import QRCode from './images/qrCode.png';
import RenewedMemberships from './images/renewed-membership.png';
import Sunbed from './images/sunbed.png';
import Terminate from './images/terminateMembership.png';
import Voucher from './images/voucher.png';

export interface StockImageProps {
    className?: string;
    width?: number;
    height?: number;
    id: typeof images[number]['id'];
}

const images = [
    { id: 'training-01', value: Training_01 },
    { id: 'training-02', value: Training_02 },
    { id: 'training_03', value: Training_03 },
    { id: 'yanga-01', value: Yanga_01 },
    { id: 'yanga-02', value: Yanga_02 },
    { id: 'yanga-03', value: Yanga_03 },
    { id: 'add-ons-poc', value: AddOns_POC },
    { id: 'physio_01', value: Physio_01 },
    { id: 'personal_01', value: Personal_01 },
    { id: 'personal_02', value: Personal_02 },
    { id: 'bag', value: Bag },
    { id: 'basic', value: Basic },
    { id: 'bike', value: Bike },
    { id: 'change_details', value: ChangeDetails },
    { id: 'change_extras', value: ChangeExtras },
    { id: 'comfort', value: Comfort },
    { id: 'create_membership', value: CreateMembership },
    { id: 'day_pass', value: DayPass },
    { id: 'live_gx', value: LiveGX },
    { id: 'login', value: Login },
    { id: 'new_card', value: NewCard },
    { id: 'friends', value: Friends },
    { id: 'sunbed', value: Sunbed },
    { id: 'terminate', value: Terminate },
    { id: 'voucher', value: Voucher },
    { id: 'renewed', value: RenewedMemberships },
    { id: 'overview-hero', value: OverviewHero },
    { id: 'app', value: App },
    { id: 'qr_code', value: QRCode }
];

export const StockImageIds = () => images.map((image) => image.id);

export const StockImage = ({ className = '', width, height, id }: StockImageProps) => {
    const image = images.find((image) => image.id === id);

    return React.createElement('img', {
        className: `object-contain ${className}`,
        width,
        height,
        src: image?.value || ''
    });
};
