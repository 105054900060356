import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '@basic-fit/design-system';
import { getPrice } from '@src/utils/helpers/priceHelpers';

type PriceProps = {
    className?: string;
    amount: number;
    caption: string;
    originalPrice?: number;
};

export const Price = ({ className = '', amount, caption, originalPrice }: PriceProps) => {
    const { i18n } = useTranslation();

    const formatPrice = getPrice(amount, i18n.language, { format: 'withDigits' });
    const currencyIndex = formatPrice.indexOf('€'); // only Euro's for now

    let price, digits;

    if (currencyIndex > 0) {
        price = formatPrice.slice(0, currencyIndex - 3);
        digits = formatPrice.slice(currencyIndex - 3, formatPrice.length);
    } else {
        price = formatPrice.slice(0, -2);
        digits = formatPrice.slice(formatPrice.length - 2, formatPrice.length);
    }

    return (
        <div className={`flex flex-col`}>
            <div className={`flex items-start ${className}`}>
                <span
                    className={`font-heading-treble font-extrabold leading-none text-[28px] ${
                        originalPrice ? 'text-orange' : 'text-anthracite'
                    }`}
                >
                    {price}
                </span>
                <span
                    className={`font-heading-treble font-extrabold leading-tight text-[14px] ${
                        originalPrice ? 'text-orange' : 'text-anthracite'
                    }`}
                >
                    {digits}
                </span>
                <span className="ml-xxs text-[14px] text-grey-4 leading-tight">{caption}</span>
            </div>
            {originalPrice && (
                <div className={`flex`}>
                    <Heading
                        size={7}
                        className={`relative after:content-[''] after:border-anthracite after:border-t-2 after:absolute after:left-0 after:top-0 after:w-full after:origin-center after:-rotate-[10deg] after:translate-y-[13px]`}
                    >
                        {getPrice(originalPrice, i18n.language, { format: 'withDigits' })}
                    </Heading>
                </div>
            )}
        </div>
    );
};
