import React from 'react';
import { Text } from '@basic-fit/design-system';
import { StrokeText } from '@src/components/StrokeText/StrokeText';

interface HeroProps {
    className?: string;
    title: string;
    text?: string;
    image?: React.ReactNode;
}

export const Hero = ({ className = '', image, title, text }: HeroProps): JSX.Element => {
    return (
        <div
            className={`mbf-banner relative flex md:pb-[0px!important] md:min-h-[300px] xl:min-h-[370px] bg-gradient-orange max-h-[500px] ${
                className ?? ''
            }`}
        >
            <div className="flex flex-col flex-shrink-0 w-[100%] xl:w-[50%] md:w-[60%] px-[15px] pt-[15px] sm:pt-[30px] sm:px-[30px] md:pt-[60px] md:pl-[60px] justify-center">
                <div>
                    <StrokeText stroke="last" color="white" text={title} />
                    <Text size={2} className="w-full sm:w-5/6 my-[20px] text-white">
                        {text}
                    </Text>
                </div>
            </div>
            {image && (
                <div className="flex-grow hidden md:block pointer-events-none relative">
                    {image}
                </div>
            )}
        </div>
    );
};
