import React from 'react';

export default function FlagBE() {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask id="a" maskUnits="userSpaceOnUse" x="0" y="4" width="24" height="16">
                <path fill="#C4C4C4" d="M0 4h24v16H0z" />
            </mask>
            <g mask="url(#a)">
                <path d="M-.117-.446h24.233v24.892H-.117V-.446Z" fill="#ED2939" />
                <path d="M-.117-.446h16.156v24.892H-.117V-.446Z" fill="#FAE042" />
                <path d="M-.117-.446H7.96v24.892H-.117V-.446Z" fill="#000" />
            </g>
        </svg>
    );
}
