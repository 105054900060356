import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { Button } from '@src/components/Buttons/Button';
import { Header } from '@src/components/Header/Header';
import { Section } from '@src/components/Layout/Section';
import { StockImage } from '@src/components/StockImage/StockImage';
import { redirect } from '@src/utils/helpers/redirect';
import { getParam } from 'refactor/services/utilities/params.service';

export const MemberGetMemberFriendPage = (): JSX.Element => {
    const { t } = useTranslation();

    const member = getParam('member');
    const name = getParam('name');

    const buttonClickedHandler = () => {
        redirect(
            `https://www.basic-fit.com/${t('441')}/subscription/membership?referral=${member}`
        );
    };

    return (
        <div className="p-[15px] sm:p-[0] h-full w-full">
            {!(member && member.length === 36 && name) ? (
                <Text size={2} className="text-red mt-xs">
                    {`${t('238')} ${t('257')}`}
                </Text>
            ) : (
                <>
                    <Header
                        title={t('1507')}
                        image={<StockImage id="friends" className="h-full w-full !object-cover" />}
                    />
                    <Section>
                        <Box variant="grey">
                            <Text size={1}>
                                {t('1508') &&
                                    t('1508').replace(/{memberName}/g, decodeURIComponent(name))}
                            </Text>

                            <Heading size={5} className="mt-m">
                                {t('1509')}
                            </Heading>

                            <ul style={{ marginLeft: '20px' }}>
                                {t('1510') &&
                                    t('1510')
                                        .split(';')
                                        .map((item: string, index: number) => {
                                            return (
                                                <li
                                                    key={`member-get-member-friend-list-item-${item}-${index}`}
                                                    style={{
                                                        marginTop: '10px',
                                                        listStyle: 'disc'
                                                    }}
                                                >
                                                    {item}
                                                </li>
                                            );
                                        })}
                            </ul>

                            <Heading size={5} className="mt-m">
                                {t('1511')}
                            </Heading>
                            <Text size={1} className="mt-xxs">
                                {t('1512')}
                            </Text>
                            <Text size={1}>{t('1513')}</Text>

                            <Button
                                className="mt-m"
                                icon="gift"
                                text={t('1514')}
                                onClick={() => buttonClickedHandler()}
                            />
                        </Box>
                    </Section>
                </>
            )}
        </div>
    );
};
