import React from 'react';

export default function DownloadPlain() {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.77778 17.2222L5.77778 11L4 11L4 17.2222C4 18.2 4.8 19 5.77778 19L18.2222 19C19.2 19 20 18.2 20 17.2222L20 11L18.2222 11L18.2222 17.2222L5.77778 17.2222Z"
                fill="currentColor"
            />
            <path
                d="M15.5557 11.1111L14.3023 9.85778L12.889 11.2622L12.889 4L11.1112 4L11.1112 11.2622L9.70677 9.85778L8.44455 11.1111L12.0001 14.6667L15.5557 11.1111Z"
                fill="currentColor"
            />
        </svg>
    );
}
