import React from 'react';

export default function Download() {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 12 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m12.704 10.904-4.88 5.24V0H6.12v16.142L1.25 10.904 0 12.074l6.972 7.485 6.982-7.485-1.25-1.17Z"
                fill="url(#a)"
            />
            <path d="M14 22.296H.034V24H14v-1.704Z" fill="#7ADEC9" />
            <defs>
                <linearGradient
                    id="a"
                    x1="-.04"
                    y1="13.576"
                    x2="10.309"
                    y2="7.594"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FE7000" />
                    <stop offset=".36" stopColor="#FE7302" />
                    <stop offset=".58" stopColor="#FE7B09" />
                    <stop offset=".76" stopColor="#FE8915" />
                    <stop offset=".92" stopColor="#FF9D26" />
                    <stop offset="1" stopColor="#FFAA30" />
                </linearGradient>
            </defs>
        </svg>
    );
}
