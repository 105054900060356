import React from 'react';
import { useTranslation } from 'react-i18next';
import { Footer } from '@src/components/Footer/Footer';
import { StockImage } from '@src/components/StockImage/StockImage';
import { isApp } from '@src/utils/helpers/window';
import { Section } from 'refactor/components/Layout/Section';

import { getAppUrl, getDevice } from 'services/device.service';
import openNewTabSimple from 'services/open-new-tab-simple';

const Image = () => {
    return <StockImage id="app" className="scale-[1.4] translate-y-[10%] translate-x-[-15%]" />;
};

export const OverviewFooter = (): JSX.Element => {
    const {
        t,
        i18n: { language }
    } = useTranslation();

    const goToMobileApp = () => {
        const device = getDevice();
        const appUrl = getAppUrl(language) as any;
        const url = appUrl[device] || t('1862');
        openNewTabSimple(url);
    };

    if (isApp()) return <></>;

    return (
        <Section className="mb-[30px]">
            <Footer
                title="Basic-Fit App"
                text={t('71')}
                button={{ onClick: goToMobileApp, text: t('72') }}
                image={<Image />}
            />
        </Section>
    );
};
