import React from 'react';

export default function OpenEye() {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 -50 446 265"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M223 64.8C212.359 64.8 202.84 67.0383 193.879 71.5188C197.801 75.4407 200.598 81.0383 200.598 87.1988C200.598 99.5188 190.52 109.597 178.2 109.597C172.04 109.597 166.438 107.359 162.52 102.878C158.039 111.839 155.801 121.358 155.801 131.999C155.801 168.96 186.039 199.198 223 199.198C259.961 199.198 290.199 168.96 290.199 131.999C290.199 95.038 259.961 64.8 223 64.8Z" />
            <path d="M223 0.399994C132.84 0.399994 48.28 49.119 2.91999 128.08L0.677795 132.002L2.9161 136.482C48.2751 214.88 132.836 264.162 222.996 264.162C313.156 264.162 397.716 215.443 443.076 136.482L445.314 132.002L443.076 127.521C397.721 49.1234 313.166 0.401398 223.006 0.401398L223 0.399994ZM19.72 132C53.88 76 109.322 36.801 172.6 22.8C131.162 41.839 102.604 83.839 102.604 132C102.604 180.161 131.163 222.16 172.604 241.2C109.881 227.2 53.884 188.001 19.724 132H19.72ZM119.4 132C119.4 74.879 165.88 28.4 223 28.4C280.12 28.4 326.6 74.88 326.6 132C326.6 189.12 280.12 235.6 223 235.6C165.88 235.6 119.4 189.12 119.4 132ZM273.4 241.2C314.841 222.161 343.4 180.161 343.4 132C343.4 83.839 314.841 41.84 273.4 22.8C336.123 36.8 392.12 75.999 426.28 132C392.12 188 336.12 227.199 273.4 241.2Z" />
        </svg>
    );
}
