import { captureException } from '@src/utils/helpers/newRelic';
import type { Article } from '@src/utils/hooks/api/useArticles';

import { dateToString } from '../date-converter';
import { domain, headers } from '../get-api-elements';

export default async function removeArticle(article: Article) {
    if (!article.possibleEnddates || article.possibleEnddates.length === 0) {
        const errorMessage = 'no possible end dates for article';
        captureException(new Error(errorMessage), {
            fn: 'removeArticle',
            path: 'services/API/remove-article.ts'
        });
        throw new Error(errorMessage);
    }

    try {
        await fetch(`${domain}/memberships/remove-article`, {
            method: 'POST',
            body: JSON.stringify({
                peopleMembershipArticleId: article.peopleMembershipArticleId,
                endDate: dateToString(new Date(article.possibleEnddates[0]))
            }),
            headers
        });

        return true;
    } catch (error) {
        const captureError = error instanceof Error ? error : new Error(String(error));

        captureException(captureError, {
            fn: 'removeArticle',
            path: 'services/API/remove-article.ts'
        });

        throw captureError;
    }
}
