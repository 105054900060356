import React, { HTMLAttributes } from 'react';

type BoldProps = {
    children: React.ReactNode;
    className?: string;
} & React.DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

export const Bold = ({ children, className = '', ...rest }: BoldProps) => {
    return (
        <strong className={`font-heading-treble font-bold ${className}`} {...rest}>
            {children}
        </strong>
    );
};
