import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { Modal } from '@src/components/Modals/Modal';
import { StrokeText } from '@src/components/StrokeText/StrokeText';

import googleTagManagerPush from '../../../../services/google-tag-manager-push';

type DowngradeModalProps = {
    isOpen: boolean;
    onClose(): void;
    onConfirm(): void;
};
export const DowngradeModal = ({ isOpen, onClose, onConfirm }: DowngradeModalProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        googleTagManagerPush.cancelMembership('view', 'downgrade modal');
    }, []);

    return (
        <Modal isOpen={isOpen} onModalClose={onClose}>
            <StrokeText
                className="text-anthracite mb-4"
                stroke="last"
                color="anthracite"
                text={t('membership.cancellation.downgrade_modal_title')}
            />
            <Text className="mb-8" size={2}>
                {t('membership.cancellation.downgrade_modal_text')}
            </Text>
            <div className="space-y-[24px]">
                <Button
                    text={t('1625')}
                    onClick={() => {
                        googleTagManagerPush.cancelMembership(
                            'click',
                            'downgrade modal - change membership'
                        );
                        navigate('/change-membership');
                    }}
                />
                <Button
                    color="naked"
                    text={t('membership.cancellation.proceed')}
                    onClick={() => {
                        googleTagManagerPush.cancelMembership(
                            'click',
                            'downgrade modal - continue'
                        );
                        onConfirm?.();
                    }}
                />
            </div>
        </Modal>
    );
};
