import React from 'react';
import { Button, Heading, Text } from '@basic-fit/design-system';

interface FooterProps {
    className?: string;
    title: string;
    text?: string;
    image?: React.ReactNode;
    button?: {
        text: string;
        onClick?: () => void;
    };
}

export const Footer = ({
    className = '',
    image,
    title,
    text,
    button
}: FooterProps): JSX.Element => {
    return (
        <div
            className={`
              mbf-banner relative flex
              md:pb-[0px!important]
              md:min-h-[300px]
              max-h-[360px]
              bg-gradient-anthracite
              ${className}
            `}
        >
            <div
                className={`
                    flex flex-col flex-shrink-0 w-[100%] xl:w-[50%] md:w-[60%]
                    p-[15px] sm:p-[30px] md:py-[60px] md:pl-[60px]
                    justify-center items-center
                `}
            >
                <div className="w-full md:max-w-[240px] h-full flex flex-col justify-between">
                    <div>
                        <Heading size={4} className="text-white">
                            {title}
                        </Heading>
                        <Text size={2} className="w-full sm:w-5/6 my-[20px] text-white">
                            {text}
                        </Text>
                    </div>
                    {button && (
                        <div
                            className={`
                                absolute top-[100%] w-[80%] translate-y-[-50%] left-[50%] translate-x-[-50%]
                                md:translate-x-[0] md:translate-y-[0] md:static md:w-full
                            `}
                        >
                            <Button onClick={button.onClick} variant="primary" className="w-full">
                                {button.text}
                            </Button>
                        </div>
                    )}
                </div>
            </div>

            {image && (
                <div className="flex-grow justify-end hidden md:flex pointer-events-none relative">
                    {image}
                </div>
            )}
        </div>
    );
};
