/** Converts "DD-MM-YYYY" to date */
export default (stringDate: string) => {
    const split = stringDate.split('-');
    const arrange = `${split[2]}/${split[1]}/${split[0]}`; // yyyy/mm/dd
    return new Date(arrange);
};

/** date to YYYY-MM-DD */
export const dateToString = (date: Date) => {
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
};

/** any date to local with - */
export const toLocal = (date: string | number | Date) => {
    const dateFormat = new Date(date);
    // Default to show date in Dutch format
    return dateFormat.toLocaleDateString('nl-NL');
};
