import React from 'react';

export default function Contract() {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 25 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.8.684v7.894H.856v10.529A2.635 2.635 0 0 0 3.49 21.74h17.746c2 0 3.62-1.621 3.62-3.621V.693H4.801V.684ZM2.177 19.098V9.89h2.625v9.207c0 .722-.59 1.312-1.312 1.312-.723 0-1.313-.59-1.313-1.312Zm21.358-.987a2.3 2.3 0 0 1-2.299 2.3H5.77c0-.01.008-.018.008-.018l.053-.106c.01-.009.009-.026.018-.035a.682.682 0 0 0 .062-.141c0-.009.008-.018.008-.027.018-.035.027-.079.044-.114.01-.018.01-.026.018-.044.009-.035.026-.07.035-.106 0-.018.009-.026.009-.044a.923.923 0 0 0 .035-.15v-.017c.01-.044.018-.088.027-.133 0-.017 0-.035.008-.043a.503.503 0 0 0 .01-.115v-.044c0-.053.008-.106.008-.159v-17.1h17.412V18.11h.009Z"
                fill="url(#a)"
            />
            <path
                d="M20.918 5.61H8.746v1.33h12.172V5.61ZM14.013 9.556H8.746v1.33h5.267v-1.33ZM14.013 12.023H8.746v1.33h5.267v-1.33ZM14.013 14.49H8.746v1.33h5.267v-1.33ZM20.918 9.556h-5.266v1.33h5.266v-1.33ZM20.918 12.023h-5.266v1.33h5.266v-1.33ZM20.918 14.49h-5.266v1.33h5.266v-1.33Z"
                fill="#7ADEC9"
            />
            <defs>
                <linearGradient
                    id="a"
                    x1="25.002"
                    y1="19.338"
                    x2="3.718"
                    y2="-2.135"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFAA30" />
                    <stop offset=".361" stopColor="#FE7000" />
                    <stop offset="1" stopColor="#FE7000" />
                </linearGradient>
            </defs>
        </svg>
    );
}
