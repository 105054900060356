import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { Modal } from '@src/components/Modals/Modal';
import { useMember } from '@src/services/member/MemberProvider';
import { copyToClipboard } from '@src/services/utilities/clipboard.service';

interface MemberGetMemberModalProps {
    setShowModal: Dispatch<SetStateAction<boolean>>;
    showModal: boolean;
}

export const MemberGetMemberModal = ({
    setShowModal,
    showModal
}: MemberGetMemberModalProps): JSX.Element => {
    const { t } = useTranslation();
    const [linkCopied, setLinkCopied] = useState(false);

    const { state: member } = useMember();

    const mgmLink = `${window.location.origin}/member-get-member-friend?member=${
        member.people_id_g
    }&name=${encodeURI(member.first_name)}&langCountry=${t('441')}`;

    const whatsappLink = `https://wa.me/?text=${t('1501')} ${encodeURIComponent(mgmLink)}`;
    const facebookLink = `
      http://www.facebook.com/dialog/send?
      app_id=129990055605975
      &redirect_uri=${encodeURIComponent(mgmLink)}
      &link=${encodeURIComponent(mgmLink)}
    `;

    const emailLink = `
      mailto:?subject=${t('1568')}&body=${t('1501')} ${encodeURIComponent(mgmLink)}
    `.trim();

    const onCopyToClipboard = async () => {
        await copyToClipboard(encodeURI(mgmLink));
        setLinkCopied(true);
    };

    return (
        <Modal isOpen={showModal} onModalClose={() => setShowModal(false)}>
            {/* add friend */}
            <Heading size={6} className="mbf-my-info__title">
                {t('1499')}
            </Heading>
            {/* copy link... */}
            <Text size={2} className="mb-[30px]">
                {t('1500')}
            </Text>

            <div onClick={() => onCopyToClipboard()} className="cursor-pointer">
                <div className="relative">
                    <div
                        className={`
                        transition-opacity duration-300
                    `}
                    >
                        {/* registration link... */}
                        <Text size={2} className="mb-[30px]">
                            {encodeURI(mgmLink)}
                        </Text>
                    </div>
                </div>

                {/* copy link */}
                <Button
                    text={linkCopied ? t('237') : t('201')}
                    disabled={linkCopied}
                    className={`
                      mt-xs cursor-pointer disabled:cursor-not-allowed
                      ${linkCopied ? 'shadow-[inset_0_0_0_3px] shadow-mint' : ''}
                    `}
                />
            </div>
            <div className="space-y-xxs mt-m">
                {/* whatsapp */}
                <a className="block" href={whatsappLink} target="_blank" rel="noopener noreferrer">
                    <Button icon="whatsapp" text={t('1503')} color="orange" />
                </a>

                {/* facebook */}
                <a className="block" href={facebookLink} target="_blank" rel="noopener noreferrer">
                    <Button icon="facebook" text={t('1567')} color="orange" />
                </a>

                {/* email */}
                <a className="block" href={emailLink} target="_blank" rel="noopener noreferrer">
                    <Button icon="email" text={t('1569')} color="orange" />
                </a>
            </div>
        </Modal>
    );
};
