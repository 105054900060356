import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Heading, Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { Modal } from '@src/components/Modals/Modal';

export const DisabledPaymentModal = (): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [modalOpen, setModalOpen] = useState(true);

    const modalText = t('1699').replace('%%paragraph%%', '');

    return (
        <Modal isOpen={modalOpen} onModalClose={() => setModalOpen(false)}>
            <Heading size={5} className="text-center mb-s">
                {t('29')}
            </Heading>

            <Text size={1}>{modalText}</Text>

            <Button onClick={() => navigate('/overview')} color="grey">
                {t('25')}
            </Button>
        </Modal>
    );
};
