import React from 'react';
import { useMediaQuery } from 'react-responsive';
import params from 'parameters/css-parameters';
import styled from 'styled-components';

const Box = styled.div`
  ${({ noTopSpace, topSpace }) => `
    padding-top: ${noTopSpace ? '0px' : topSpace ? topSpace : '23px'};
    height: ${noTopSpace ? '24px' : '48px'};
  `}
`;

const Line = styled.div`
  background: #d5d5e5;
  ${({ wide, lineWidth }) => `
    height: ${wide ? '2px' : '1px'};
    ${lineWidth ? `width: ${lineWidth}` : ''}
  `}
`;

const Divider = styled.div`
  width: 100%;
  ${({ notAuth }) => `
    ${
        notAuth &&
        `
      max-width: ${params.maxWidthNotLogged};
      position: relative;
    `
    };
  `}
`;

export default function SectionDivider({
    noTopSpace = false,
    notAuth = false,
    topSpace = '',
    lineWidth = false
}) {
    const wide = useMediaQuery({ minWidth: params.showMobileMenuOnWidth });

    return (
        <Box noTopSpace={noTopSpace} topSpace={topSpace}>
            <Divider notAuth={notAuth}>
                <Line wide={wide} lineWidth={lineWidth} />
            </Divider>
        </Box>
    );
}
