const cacheHelper = {
    set: (key, value) => {
        try {
            const toString = typeof value == 'object' ? JSON.stringify(value) : value;
            localStorage.setItem(key, toString);
        } catch (e) {}
    },

    get: (key) => {
        const item = localStorage.getItem(key);
        return item || false;
    },

    del: (key) => {
        localStorage.removeItem(key);
    },

    clear: () => {
        localStorage.clear();
        window.localStorage.clear();
        sessionStorage.clear();

        // store locale for showing preferred language when not logged in
        const { locale } = window.__MEMBER_CONTEXT__ || {};
        if (locale) {
            localStorage.setItem('localeID', locale);
        }
    }
};

export default cacheHelper;
