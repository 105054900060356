import React, { HTMLAttributes } from 'react';
import { useNavigate } from 'react-router-dom';

import { Logo } from '../Logo/Logo';

type IHeader = HTMLAttributes<HTMLDivElement>;

export const Header = ({ className = '', ...rest }: IHeader): JSX.Element => {
    const navigate = useNavigate();
    return (
        <div
            className={`
                w-full h-[60px] relative
                flex items-start py-[15px] px-[15px] shadow-md
                md:px-[24px] md:before:hidden z-[99]
                before:content-[''] before:absolute before:top-0
                before:left-0 before:w-full before:h-[3px] before:bg-orange
                ${className}
            `}
            {...rest}
        >
            <Logo className="h-full w-[110px] cursor-pointer" onClick={() => navigate('/login')} />
        </div>
    );
};
