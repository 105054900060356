import { createActorContext } from '@xstate/react';
import { isValidIBAN } from 'ibantools';
import { XSTATE_STATECHART_DEBUG } from 'refactor/services/utilities';
import { assign, createMachine } from 'xstate';

import { CHANGE_MEMBER_ERROR_MESSAGE_MAP } from 'types/change-member-response';
import { FlowTokenType, isRateLimitExceeded, ModalState } from '../machine-helpers';
import { sendVerificationTokenMachine } from '../sendVerificationTokenMachine';
import { updateMemberAccountMachine } from './updateMemberPaymentInfo.machine';

type Context = {
    flow: FlowTokenType;
    email: string;
    iban: string;
    ibanError: string;
    accountHolder: string;
    codeError: CHANGE_MEMBER_ERROR_MESSAGE_MAP | null;
    tokenError: CHANGE_MEMBER_ERROR_MESSAGE_MAP | null;
    modal: ModalState | null;
    emailToken: string;
};

type Events =
    | { type: 'SUBMIT'; iban: string; accountHolder: string }
    | { type: 'SUBMIT_CODE'; code: string }
    | { type: 'BACK' }
    | { type: 'EDITING' }
    | { type: 'OPEN_MODAL'; modal: ModalState }
    | { type: 'CLOSE_MODAL' };

type States =
    | { value: 'paymentInfo'; context: Context }
    | { value: 'paymentInfo.idle'; context: Context }
    | { value: 'paymentInfo.verifying'; context: Context }
    | { value: 'paymentInfo.error'; context: Context }
    | { value: 'code'; context: Context }
    | { value: 'code.idle'; context: Context }
    | { value: 'code.verifying'; context: Context }
    | { value: 'code.success'; context: Context }
    | { value: 'code.error'; context: Context }
    | { value: 'success'; context: Context };

export const changePaymentInformationMachine = createMachine<Context, Events, States>({
    id: 'paymentInformation',
    preserveActionOrder: true,
    predictableActionArguments: true,
    context: {
        flow: FlowTokenType.changeIbanToken,
        email: '',
        iban: '',
        ibanError: '',
        accountHolder: '',
        codeError: null,
        tokenError: null,
        modal: null,
        emailToken: ''
    },
    initial: 'paymentInfo',
    states: {
        paymentInfo: {
            initial: 'idle',
            states: {
                idle: {},
                verifying: {
                    invoke: {
                        src: sendVerificationTokenMachine,
                        data: (ctx) => ({
                            email: ctx.email,
                            emailTokenType: ctx.flow
                        }),
                        onError: [
                            {
                                description: 'rate limit exceeded, show modal',
                                cond: isRateLimitExceeded,
                                actions: assign({
                                    tokenError: (_, ev) => ev.data.message,
                                    modal: ModalState.TOO_MANY_TRIES
                                }),
                                target: 'error'
                            },
                            {
                                description: 'all other errors are shown in the form',
                                actions: assign({
                                    tokenError: (_, ev) => ev.data.message
                                }),
                                target: 'error'
                            }
                        ],
                        onDone: { target: '#paymentInformation.code' }
                    }
                },
                error: {}
            },
            on: {
                SUBMIT: [
                    {
                        description:
                            'validate that the iban is a valid iban before submitting the form',
                        target: '.error',
                        cond: (_, evt) => isValidIBAN(evt.iban) === false,
                        actions: assign({
                            ibanError: CHANGE_MEMBER_ERROR_MESSAGE_MAP.DETAILS_IBAN_INVALID
                        })
                    },
                    {
                        description: 'submit the form',
                        target: '.verifying',
                        actions: assign({
                            iban: (_, evt) => evt.iban,
                            accountHolder: (_, evt) => evt.accountHolder
                        })
                    }
                ]
            }
        },
        code: {
            initial: 'idle',
            states: {
                idle: {},
                success: {},
                error: {},
                verifying: {
                    invoke: {
                        description: 'verify the token given by the user',
                        src: updateMemberAccountMachine,
                        data: (ctx) => ({
                            iban: ctx.iban,
                            accountHolder: ctx.accountHolder,
                            emailToken: ctx.emailToken,
                            emailTokenType: ctx.flow
                        }),
                        onError: [
                            {
                                target: 'error',
                                cond: isRateLimitExceeded,
                                actions: assign({
                                    codeError: (_, ev) => ev.data.message,
                                    modal: ModalState.TOO_MANY_TRIES
                                })
                            },
                            {
                                target: 'error',
                                actions: assign({
                                    codeError: (_, ev) => ev.data.message
                                })
                            }
                        ],
                        onDone: {
                            actions: assign({
                                modal: ModalState.SUCCESS
                            }),
                            target: 'success'
                        }
                    }
                }
            },
            on: {
                SUBMIT_CODE: {
                    target: '.verifying',
                    actions: assign({
                        emailToken: (_, ev) => ev.code
                    })
                },
                BACK: { target: 'paymentInfo' },
                EDITING: { target: '.idle' }
            }
        },
        success: { type: 'final' }
    },
    on: {
        OPEN_MODAL: {
            actions: assign({
                modal: (_, evt) => evt.modal
            })
        },
        CLOSE_MODAL: {
            actions: assign({
                modal: null
            })
        }
    }
});

export const ChangePaymentInformationContext = createActorContext(changePaymentInformationMachine, {
    devTools: XSTATE_STATECHART_DEBUG === 'true'
});
