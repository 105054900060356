import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { Button } from '@src/components/Buttons/Button';
import { Header } from '@src/components/Header/Header';
import { Section } from '@src/components/Layout/Section';
import { StockImage } from '@src/components/StockImage/StockImage';

import { MemberGetMemberModal } from './components/MemberGetMember.modal';

export const MemberGetMemberPage = (): JSX.Element => {
    const { t } = useTranslation();
    const [showMemberGetMemberModal, setShowMemberGetMemberModal] = useState(false);

    return (
        <>
            <Header
                title={t('1496')}
                image={<StockImage id="friends" className="h-full w-full !object-cover" />}
            />
            <Section>
                <Box variant="grey">
                    <Text size={1}>{t('1497')}</Text>
                    <Text size={1}>{t('1498')}</Text>

                    <Button
                        className="mt-m"
                        icon="gift"
                        text={t('1499')}
                        onClick={() => setShowMemberGetMemberModal(!showMemberGetMemberModal)}
                    />
                </Box>
            </Section>

            {showMemberGetMemberModal && (
                <MemberGetMemberModal
                    showModal={showMemberGetMemberModal}
                    setShowModal={setShowMemberGetMemberModal}
                />
            )}
        </>
    );
};
