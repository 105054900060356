import React from 'react';
import { WhipMint10 } from '@src/components/LineSVG/svgs/Whip.mint.10';

import { CurvedLines } from './svgs/CurvedLines';
import { Eclipse } from './svgs/Eclipse';
import { WhiteVerticalLines } from './svgs/ThreeVerticalLines';
import { VerticalLines } from './svgs/VerticalLines';

export interface LineSVGProps {
    className?: string;
    width?: number;
    height?: number;
    id: typeof svgs[number]['id'];
}

const svgs = [
    { id: 'vertical-lines', value: VerticalLines },
    { id: 'eclipse', value: Eclipse },
    { id: 'curved-lines', value: CurvedLines },
    { id: 'white-vertical-lines', value: WhiteVerticalLines },
    { id: 'whip-mint-10', value: WhipMint10 }
];

export const LineSVGIds = () => svgs.map((svg) => svg.id);

export const LineSVG = ({ className, width, height, id }: LineSVGProps) => {
    const svg = svgs.find((svg) => svg.id === id);
    const SVGElement = svg?.value;

    return (
        <div
            style={{
                width: width ? `${width}px` : '100%',
                height: `${height}px`
            }}
            className={className}
        >
            {SVGElement && <SVGElement />}
        </div>
    );
};
