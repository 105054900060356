import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ButtonList } from '@src/components/ButtonList/ButtonList';
import { IconButton } from '@src/components/Buttons/IconButton';
import { Icon } from '@src/components/Icons/Icons';
import { Section } from '@src/components/Layout/Section';
import { AUTH_SERVICE_FE_DOMAIN } from '@src/services/utilities/env.service';

export default function DataManagement(): JSX.Element {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Section>
            <ButtonList title={t('145')}>
                <a
                    href="/member/get-data-json"
                    type="json"
                    download="my-basic-fit-data.json"
                    target="_blank"
                    rel="noreferrer"
                    data-cy="export-my-information"
                >
                    <IconButton icon={<Icon id="clock" />} title={t('146') + ' (JSON)'} />
                </a>

                <IconButton
                    icon={<Icon id="clock" />}
                    title={t('marketing.preferences')}
                    onClick={() => navigate('/information/marketing-preferences')}
                    data-cy="email-settings"
                />

                <IconButton
                    icon={<Icon id="clock" />}
                    data-cy="change-password"
                    title={t('541')}
                    onClick={() => location.assign(`${AUTH_SERVICE_FE_DOMAIN}/reset-password`)}
                />
            </ButtonList>
        </Section>
    );
}
