import React from 'react';

export default function FlagLU() {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask id="a" maskUnits="userSpaceOnUse" x="0" y="4" width="24" height="16">
                <path fill="#C4C4C4" d="M0 4h24v16H0z" />
            </mask>
            <g mask="url(#a)">
                <path d="M24.32 12H-.17v8.653h24.49V12Z" fill="#00A1DE" />
                <path d="M24.32 3.346H-.17V12h24.49V3.346Z" fill="#ED2939" />
                <path d="M24.32 9.116H-.17v5.768h24.49V9.116Z" fill="#fff" />
            </g>
        </svg>
    );
}
