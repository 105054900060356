import React from 'react';
import { useTranslation } from 'react-i18next';
import { Logo } from '@src/components/Logo/Logo';
import { getMemberName } from '@src/utils/helpers/memberHelpers';
import { getNumber, getPrice } from '@src/utils/helpers/priceHelpers';
import { DateTime, Interval } from 'luxon';

import StampFR from './images/stamp-fr.png';

export type PaymentDocumentProps = {
    member: {
        id: string;
        gender_s: string;
        firstname: string;
        middle_name: string;
        last_name: string;
        mailing_street: string;
        mailing_house_number: string;
        poste_code: string;
        mailing_city: string;
        home_club: string;
        membership_number_s: string;
        membership_name_g: string;
        contract_end_date_g: string;
        start_date_g: string;
        payment_interval: string;
        payment_contract: string;
        month: string;
        year: string;
    };
    transactions: {
        amount: number;
        comment: string;
        dateTime: string;
        eventTypeDescription: string;
        eventTypeId: string;
        id: string;
        tax: 0;
        taxPercentage1: number | null;
        taxPercentage2: number | null;
        vatName: string | null;
    }[];
};

export const PaymentDocument = ({ member, transactions }: PaymentDocumentProps) => {
    const { t } = useTranslation();

    // todo: should be provided from translations
    const locale = 'fr-FR';
    const dateToday = new Intl.DateTimeFormat(locale).format(Date.now());

    const { month, year, membership_number_s, contract_end_date_g, start_date_g } = member || {};

    const invoiceId = `${membership_number_s}-${year}${month}`;
    const contractPeriod =
        DateTime.fromISO(start_date_g).setLocale(locale).toLocaleString() +
        ' - ' +
        DateTime.fromISO(contract_end_date_g).setLocale(locale).toLocaleString();

    /**
     * Filter products based on given month/year
     */

    const period = DateTime.fromJSDate(new Date(`${year}-${month}`));
    const dateRequestPeriod = period
        .setLocale(locale)
        .toLocaleString({ month: 'long', year: 'numeric' });

    const products = transactions?.filter((transaction) => {
        const tdate = DateTime.fromISO(transaction.dateTime);
        const diff = Interval.fromDateTimes(period, tdate).length('months');
        if (diff < 1) return transaction;
    });

    const totalAmount = products.reduce((a, b) => a + b.amount, 0);
    const totalVatLow = products.reduce((a, b) => (b.taxPercentage1 ? a + b.tax : 0), 0);
    const totalVatHigh = products.reduce((a, b) => (b.taxPercentage2 ? a + b.tax : 0), 0);
    const totalAmountVat = totalVatLow + totalVatHigh;
    const totalAmountNoVat = totalAmount - totalAmountVat;

    /**
     * Set document size to A4 at 150 PPI (1240 x 1754 px)
     */
    return (
        <div className="invoice flex flex-col w-[1240px] h-[1754px] p-[20mm]">
            <div className="flex flex-row">
                <div className="flex flex-col w-1/3">
                    <Logo />
                    <div className="border-2 border-anthracite p-3 my-6 text-center">
                        {t('invoice.title')}
                        <br /> {dateRequestPeriod}
                    </div>
                    <div className="flex flex-row">
                        <div className="w-1/3">{t('invoice.to')}:</div>
                        <div>{getMemberName(member)}</div>
                    </div>
                    <div className="flex flex-row">
                        <div className="w-1/3">{t('invoice.address')}:</div>
                        <div>
                            {member?.mailing_house_number} {member?.mailing_street}
                            <br />
                            {member?.poste_code} {member?.mailing_city}
                        </div>
                    </div>
                </div>
                <div className="w-2/3 text-right">
                    <div>{t('invoice.company.name')}</div>
                    <div>{t('invoice.company.street')}</div>
                    <div>{t('invoice.company.city')}</div>
                    <div>{t('invoice.company.country')}</div>
                    <br />
                    <div>{t('invoice.head_office.po_box')}</div>
                    <div>{t('invoice.head_office.address')}</div>
                    <div>{t('invoice.head_office.website')}</div>
                    <div>{t('invoice.head_office.email')}</div>
                </div>
            </div>
            <div className="flex flex-col items-end my-6">
                <div className="flex flex-row invoice__club">
                    <div className="w-1/3">{t('invoice.club')}:</div>
                    <div className="w-2/3">{member?.home_club}</div>
                </div>
                <div className="flex flex-row invoice__club">
                    <div className="w-1/3">{t('invoice.company_id')}:</div>
                    <div>{t('invoice.company_tax_nr')}</div>
                </div>
            </div>
            <div className="flex my-6">
                <table className="invoice__head" width="100%">
                    <tbody>
                        <tr>
                            <td colSpan={6} className="text-center">
                                <span className="text-orange">{t('invoice.head.information')}</span>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <strong>{t('invoice.head.interval')}:</strong>&nbsp;
                                {member.payment_interval === '52weeks'
                                    ? t('invoice.head.52weeks')
                                    : t('invoice.head.fourWeeks')}
                                <br />
                                <strong>{t('invoice.head.contract')}:</strong>&nbsp;
                                {t('invoice.head.contract_duration')}
                            </td>
                            <td colSpan={4}>
                                <strong>{t('invoice.head.end_date')}:</strong> {contractPeriod}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span className="text-orange">{t('invoice.head.client_nr')}</span>
                            </td>
                            <td>{t('invoice.head.reference')}</td>
                            <td>{t('invoice.head.date')}</td>
                            <td>{t('invoice.tax')}</td>
                            <td>{t('invoice.tax')}</td>
                            <td>
                                <span className="text-orange">{t('invoice.head.invoice_nr')}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span className="text-orange">{member?.membership_number_s}</span>
                            </td>
                            <td>{member?.membership_name_g}</td>
                            <td>{dateToday}</td>
                            <td>{t('invoice.percentage.tax_low')}%</td>
                            <td>{t('invoice.percentage.tax_high')}%</td>
                            <td>
                                <span className="text-orange">{invoiceId}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="flex my-6">
                <table className="invoice__table" width="100%">
                    <tbody>
                        <tr className="invoice__table__head">
                            <td>{t('invoice.product.description')}</td>
                            <td>{t('invoice.product.quantity')}</td>
                            <td>{t('invoice.product.price_no_tax')}</td>
                            <td>{t('invoice.tax')}</td>
                            <td>{t('invoice.tax')}</td>
                            <td>{t('invoice.product.price_with_tax')}</td>
                        </tr>
                        {products.map((product) => (
                            <tr key={product.id}>
                                <td>{product.comment}</td>
                                <td>1</td>
                                <td>{getNumber(product.amount, locale)}</td>
                                <td>
                                    {getNumber(product.taxPercentage2 ? product.tax : 0, locale)}
                                </td>
                                <td>
                                    {getNumber(product.taxPercentage1 ? product.tax : 0, locale)}
                                </td>
                                <td>{getNumber(product.amount - product.tax, locale)}</td>
                            </tr>
                        ))}
                        <tr>
                            <td>{t('invoice.product.total_no_tax')}</td>
                            <td colSpan={2}>{t('invoice.tax')}</td>
                            <td>&nbsp;</td>
                            <td>{t('invoice.product.total_with_tax')}</td>
                            <td>{getNumber(totalAmountNoVat, locale)}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>
                                    {getPrice(totalAmount, locale)}
                                    <br />
                                    *** {t('invoice.product.paid_invoice')} ***
                                </strong>
                            </td>
                            <td>
                                {t('invoice.percentage.tax_high')}%<br />
                                {t('invoice.percentage.tax_low')}%
                            </td>
                            <td>
                                {getNumber(totalVatLow, locale)}
                                <br />
                                {getNumber(totalVatHigh, locale)}
                            </td>
                            <td>&nbsp;</td>
                            <td colSpan={2} className="!p-0">
                                <table
                                    width="100%"
                                    className="invoice__table__nested invoice--no-border"
                                >
                                    <tbody>
                                        <tr>
                                            <td>{t('invoice.product.total_tax')}</td>
                                            <td>{getNumber(totalAmountVat, locale)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t('invoice.product.total_payment')}</td>
                                            <td>{getPrice(totalAmount, locale)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="flex grow">
                <div className="flex-col mt-auto w-full relative">
                    <div>{t('invoice.footer.tax_refund')}</div>
                    <div className="flex justify-between w-full border-t-[1px] border-b-[1px] border-anthracite mt-[2mm] py-[2mm]">
                        <div>
                            {t('invoice.club')}: {member?.home_club}
                            <br />
                            {t('invoice.footer.iban')}
                            <br />
                            {t('invoice.footer.bic')}
                            <br />
                            {t('invoice.footer.vat_nr')}
                        </div>
                        <div className="mt-[1mm]">
                            <img src={StampFR} className="h-[85px]" />
                        </div>
                    </div>
                    <div className="mt-[2mm]">{t('payments.pop.explanation')}</div>
                </div>
            </div>
        </div>
    );
};
