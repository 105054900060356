import type { MouseEvent } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { ButtonLink } from '@src/components/Buttons/ButtonLink';
import { Section } from '@src/components/Layout/Section';
import { Skeleton } from '@src/components/Skeletons/Skeleton';
import { Table } from '@src/components/Table/Table';
import { useVisits } from '@src/utils/hooks/useVisits';
import { DateTime } from 'luxon';

export const VisitsOverview = (): JSX.Element => {
    const {
        t,
        i18n: { language }
    } = useTranslation();
    const initialVisitsNumber = 5;
    const [shownVisits, setShownVisits] = useState(false);
    const { isError, isLoading, recentVisits } = useVisits();

    const toggleViewMore = (e: MouseEvent) => {
        e.preventDefault();
        setShownVisits((prev) => !prev);
    };

    return (
        <Section>
            {isLoading && (
                <>
                    <Skeleton type="text" lines={5} />
                </>
            )}
            {isError && <Text size={2}>{t('528')}</Text>}
            {recentVisits && recentVisits?.length > 0 && (
                <Box variant="orange-top">
                    <Heading className="mb-[30px]" size={6}>
                        {t('177')}
                    </Heading>

                    {/* visits table */}

                    <Table
                        data={recentVisits
                            .slice(0, shownVisits ? recentVisits.length : initialVisitsNumber)
                            .map((row, index) => [
                                <strong key={index}>{row.club}</strong>,
                                DateTime.fromISO(row.timestamp, {
                                    locale: language === 'en' ? 'en-GB' : language
                                }).toFormat('f')
                            ])}
                        style="white"
                    />

                    {/* show more visits */}
                    {recentVisits.length > initialVisitsNumber && (
                        <ButtonLink onClick={toggleViewMore}>
                            {shownVisits ? t('420') : t('178')}
                        </ButtonLink>
                    )}
                </Box>
            )}
        </Section>
    );
};
