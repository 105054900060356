import { domain, headers } from '@src/services/data';
import { ArticleByPaymentSchedule } from '@src/utils/hooks/api/types/articleByPaymentSchedule';
import { Article } from '@src/utils/hooks/api/useArticles';

export const getArticleOrderLink = async (article: Article) => {
    try {
        const response = await fetch(`${domain}/payments/get-order-link/article`, {
            method: 'post',
            body: JSON.stringify({
                articles: [{ id: article.articleId }],
                ...(article.startDate ? { startDate: article.startDate } : {}),
                ...(article.articleEnddate ? { endDate: article.articleEnddate } : {})
            }),
            headers
        });

        if (!response.ok) {
            throw new Error('Failed to get article order link');
        }

        const data = (await response.json()) as string;

        return data;
    } catch (error) {
        console.error('Failed to get article order link', error);
        throw error;
    }
};

export const getMembershipChangeOrderLink = async (
    paymentScheduleId: string,
    articles: ArticleByPaymentSchedule[],
    referenceDate?: string
) => {
    try {
        const response = await fetch(`${domain}/payments/get-order-link/membership-change`, {
            method: 'post',
            body: JSON.stringify({
                paymentScheduleId,
                articles: articles.map((article) => ({
                    id: article.artc_id
                })),
                referenceDate
            }),
            headers
        });

        if (!response.ok) {
            throw new Error('Failed to get membership change order link');
        }

        const data = (await response.json()) as string;

        return data;
    } catch (error) {
        console.error('Failed to get membership change order link', error);
        throw error;
    }
};
