import React from 'react';
import styled from 'styled-components';

export default ({ text = '', bold = false }) => {
    const Box = styled.div`
    font-family: Argumentum;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
    ${bold && `font-family: ArgumentumMedium;`};
  `;

    return <Box>{text}</Box>;
};
