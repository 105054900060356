import React from 'react';
import {
    lgStyles,
    mdStyles,
    mediaQueries,
    styles,
    xlStyles
} from '../../parameters/css-parameters';
import styled from 'styled-components';

const textSize = {
    XXL: `font: 900 72px/74px var(--font-heading-pro-treble)`,
    XL: `font: 900 48px/50px var(--font-heading-pro-treble)`,
    L: `font: 900 32px/36px var(--font-heading-pro-treble)`,
    M: `font: 900 24px/24px var(--font-heading-pro-treble)`,
    S: `font: 700 20px/24px var(--font-heading-pro-treble)`,
    XS: `font: 400 16px/22px var(--font-heading-pro-treble)`,
    XSFAT: `font: 700 16px/22px var(--font-heading-pro-treble)`,
    XXS: `font: 400 12px/20px var(--font-heading-pro-treble)`
};

const Title = styled.div`
  text-transform: uppercase;
  ${({ color, size }) => `
    ${color ? `color: var(--color-${color});` : 'color: var(--color-white);'};
    ${size ? textSize[size] : textSize['L']};
  `}

  ${mediaQueries['md']} {
    ${({ mdSize }) => `
      ${mdSize ? textSize[mdSize] : textSize['XL']};
    `}
  }

  ${mediaQueries['lg']} {
    ${({ lgSize }) => `
      ${lgSize ? textSize[lgSize] : ''};
    `}
  }

  ${styles}
  ${mdStyles}
  ${lgStyles}
  ${xlStyles}
`;

const StrokeTextContainer = styled.span`
  -webkit-text-stroke-width: 1px;
  color: transparent;
  ${({ color }) =>
      `-webkit-text-stroke-color: ${color ? `var(--color-${color})` : 'var(--color-white)'}`};
`;

const StrokeText = ({ text, stroke, color, strokeLength }) => {
    text = text && text.trim();
    const textArr = text.split(' ');

    if (stroke === 'all' || (strokeLength && textArr.length === strokeLength)) {
        return <StrokeTextContainer color={color}>{text}</StrokeTextContainer>;
    }

    const strokeIndex =
        stroke === 'last'
            ? textArr.length - (strokeLength || 1)
            : strokeLength
            ? strokeLength - 1
            : 0;

    const strokeText = textArr.reduce((result, item, i) => {
        result =
            (stroke === 'last' && i >= strokeIndex) || (stroke !== 'last' && i <= strokeIndex)
                ? `${result} ${item}`
                : result;
        return result.trim();
    }, '');

    return (
        <>
            {stroke === 'last' && text.replace(strokeText, '')}
            <StrokeTextContainer color={color}>{strokeText}</StrokeTextContainer>
            {stroke !== 'last' && text.replace(strokeText, '')}
        </>
    );
};

const TitleComponent = ({ children, className = '', text = '', stroke, color, ...rest }) => {
    return (
        <Title {...rest} color={color} className={`mbf-title ${className}`}>
            {stroke ? <StrokeText text={text} stroke={stroke} color={color} {...rest} /> : text}
            {children}
        </Title>
    );
};

export default TitleComponent;
