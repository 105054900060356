import React, { useEffect } from 'react';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { NODE_ENV } from '@src/services/utilities';
import i18next from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { v4 as uuidv4 } from 'uuid';

import fallbackJSON from './fallback/dev.json';

/**
 * Translations are stored as local JSON files
 * - If there is a stored locale, load that specific translation (member is not logged in)
 * - If there is a locale from a member, load member preferred translation (member is logged in)
 * - Translations are cached into localStorage for 5 minutes
 * - If a page refreshed within 5 minutes, the cached version will be used first
 * - After 5 minutes, a fresh (version) translation file will be fetched
 */

export const I18Provider = ({
    children,
    env = 'craco'
}: {
    children: React.ReactNode;
    env?: 'storybook' | 'craco';
}) => {
    /**
     * If we get a language setting from an url parameter
     * The back-end will set this value to __MEMBER_CONTEXT__.locale
     * We need to reset the i18next cache to fetch the correct translation
     */
    const [searchParams] = useSearchParams();
    const forceRefresh = !!searchParams.get('langCountry') || !!searchParams.get('lang');

    const { language, locale } = window.__MEMBER_CONTEXT__ || { language: 'en', locale: 'en-GB' };

    useEffect(() => {
        i18next
            .use(ChainedBackend)
            .use(initReactI18next)
            .init({
                backend: {
                    backends: [LocalStorageBackend, HttpBackend],
                    backendOptions: [
                        { expirationTime: forceRefresh ? 0 : 5 * 60 * 1000 },
                        {
                            loadPath: `/locales/${locale}.json?v=` + uuidv4(),
                            customHeaders: {
                                'Content-Type': 'application/json'
                            }
                        }
                    ]
                },
                debug: false,
                initImmediate: env === 'craco',
                ns: 'translation',
                lng: language,
                fallbackLng: 'dev',
                preload: [language]
            });

        /**
         * Load fallback labels that are not present in SalesForce labels
         * - On development fallback labels are prioritized over labels in /locales
         * - On production fallback labels are only used when the key is not present in /locales
         */
        i18next.addResourceBundle(
            NODE_ENV === 'development' ? language : 'dev',
            'translation',
            fallbackJSON
        );
    }, []);

    return <I18nextProvider i18n={i18next}>{children}</I18nextProvider>;
};
