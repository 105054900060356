import React from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { Icon } from '@src/components/Icons/Icons';

interface IButtonLink extends Omit<LinkProps, 'to'> {
    to?: LinkProps['to'];
    icon?: string;
}

export const ButtonLink = ({
    children,
    to = '/#',
    onClick,
    icon = 'arrow-right',
    className = '',
    ...rest
}: IButtonLink) => {
    const { pathname } = useLocation();
    return pathname !== to ? (
        <Link
            to={to}
            onClick={onClick}
            className={`
                        group relative cursor-pointer inline-block text-[14px] leading-[24px]
                        before:content-empty before:absolute before:top-[-10px] before:left-[-10px]
                        before:w-[calc(100%+20px)] before:h-[calc(100%+20px)] 
                        font-heading-treble font-bold underline underline-offset-2 decoration-1
                        ${className.includes('mt') ? '' : 'mt-[10px]'}
                        ${className.includes('text-white') ? '' : 'text-orange'}
                        ${className}
                    `}
            {...rest}
        >
            <>
                {children}
                <Icon
                    id={icon}
                    className={`
                            transform transition-transform duration-300
                            text-[13px] translate-x-[4px] translate-y-[2px]
                            group-hover:translate-x-[4px]
                            ${className.includes('text-white') ? 'text-white' : 'text-orange'}
                        `}
                />
            </>
        </Link>
    ) : null;
};
