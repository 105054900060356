import React, { useMemo } from 'react';
import { Text } from '@basic-fit/design-system';
import { getPrice, PaymentInterval, priceInterval } from '@src/utils/helpers/priceHelpers';

export interface PriceContractProps {
    className?: string;
    size?: 1 | 2 | 3;
    style?: 'normal' | 'bold';
    price: number;
    period?: PaymentInterval;
    discountPrice: number;
    showDiscount?: boolean;
    language: string;
}

export const PriceContract = ({
    className = '',
    size = 2,
    style = 'normal',
    price,
    period,
    discountPrice,
    showDiscount = true,
    language
}: PriceContractProps) => {
    const strikeThroughStyles = useMemo(() => {
        switch (size) {
            case 1:
                return 'after:border-t-[2px] after:translate-y-[13px]';
            case 3:
                return 'after:border-t-[1px] after:translate-y-[12px]';
            default:
                return 'after:border-t-[1px] after:translate-y-[11px]';
        }
    }, [size]);

    const currentPrice = `${getPrice(price - discountPrice, language, {
        format: 'withDigits'
    })} ${period ? priceInterval(period) : ''}`;

    const regularPrice = getPrice(price, language, { format: 'withDigits' });

    return (
        <div className={`flex ${className}`}>
            {showDiscount && discountPrice > 0 && (
                <Text
                    size={size}
                    as="span"
                    className={`inline relative px-[3px] text-grey-7 after:content-[''] after:border-grey-7 after:absolute after:left-0 after:top-0 after:w-full after:origin-center after:-rotate-[10deg] ${strikeThroughStyles}`}
                >
                    {style === 'bold' ? (
                        <strong className="font-bold font-heading-treble">{regularPrice}</strong>
                    ) : (
                        regularPrice
                    )}
                </Text>
            )}
            <Text size={size} as="span" className={`${discountPrice > 0 ? 'ml-xxxs' : ''}`}>
                {style === 'bold' ? (
                    <strong className="font-bold font-heading-treble">{currentPrice}</strong>
                ) : (
                    currentPrice
                )}
            </Text>
        </div>
    );
};
