export const isEmpty = (collection: any | undefined | null) => {
    return (
        collection &&
        typeof collection === 'object' &&
        Object.keys(collection).length === 0 &&
        Object.getPrototypeOf(collection) === Object.prototype
    );
};

export const isEqual = (a: Record<string, unknown>, b: Record<string, unknown>) =>
    Object.entries(a).sort().toString() === Object.entries(b).sort().toString();

export const isUnique = (array: any[]) =>
    Array.from(new Map(array.map((v) => [JSON.stringify(v), v])).values());

export const removeFalsy = (array: any[]) => array.filter((value) => value);
