import axios from 'axios';

import { getFromSessionCache, setToSessionCache } from '../utilities/sessionCache.service';
import { domain, headers } from '.';

export const getFAQAnswer = async (id: string, language: string) => {
    const cachedAnswers = getFromSessionCache(`_FAQAnswer_${id}`);
    if (cachedAnswers) {
        return cachedAnswers;
    }

    let FAQAnswer;
    try {
        FAQAnswer = await axios({
            method: 'GET',
            url: `${domain}/faq/answer`,
            headers,
            params: { id, language }
        });

        setToSessionCache(`_FAQAnswer_${id}`, FAQAnswer.data);
    } catch (error) {
        console.log("Can't get FAQ:", error);
        throw error;
    }

    return FAQAnswer.data;
};
