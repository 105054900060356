import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@src/components/Buttons/Button';
import { Form } from '@src/components/Form/Form';
import { TextInput } from '@src/components/Form/TextInput/TextInput';
import { TableFlex } from '@src/components/TableFlex/TableFlex';
import { Bold } from '@src/components/Text/Bold';
import { Text } from '@src/components/Text/Text';
import { TooManyTriesModal } from '@src/feature/ChangeMemberModals/TooManyTries.modal';
import { useMember } from '@src/services/member/MemberProvider';

import { CHANGE_MEMBER_ERROR_MESSAGE_MAP } from 'types/change-member-response';
import { ModalState } from '../machine-helpers';
import { ChangePhoneNumberContext } from './changePhoneNumber.machine';

const phoneRegex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;

export const NewPhoneNumber = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state: member } = useMember();
    const [state, send] = ChangePhoneNumberContext.useActor();

    const phoneError = ChangePhoneNumberContext.useSelector((state) => {
        if (!state.matches('phone.error')) return undefined;
        if (!state.context.tokenError) return undefined;
        if (
            state.context.tokenError === CHANGE_MEMBER_ERROR_MESSAGE_MAP.DETAILS_RATE_LIMIT_EXCEEDED
        ) {
            // NOTE: rate limit error is handled in the modal
            return undefined;
        }

        return t('details.change.phone.new.unexpectedError');
    });

    const shouldShowTooManyTries = ChangePhoneNumberContext.useSelector((state) => {
        return state.context.modal === ModalState.TOO_MANY_TRIES;
    });

    return (
        <div data-cy="change-phone-new">
            <Text size="m" className="mb-4 leading-[130%]">
                {t('details.change.address.description')}
            </Text>
            {member.tel_home && (
                <div data-cy="current-phone-number" className="mb-6">
                    <Text
                        size="m"
                        className="!text-regular-m lowercase first-letter:uppercase font-bold"
                    >
                        {t('details.change.phone.new.tableHeader')}
                    </Text>
                    <TableFlex
                        size="s"
                        data={[[t('1743'), <Bold key="">{member.tel_home}</Bold>]]}
                        className="border-b border-anthracite-74"
                    />
                </div>
            )}
            <Form
                onSubmit={({ phone }) => send({ type: 'SUBMIT_PHONE', phone })}
                name="phoneForm"
                className="space-y-3"
            >
                <Text
                    size="m"
                    className="!text-regular-m lowercase first-letter:uppercase font-bold"
                >
                    {t('details.change.phone.new.formHeader')}
                </Text>
                <TextInput
                    required
                    type="tel"
                    pattern={{
                        value: phoneRegex,
                        message: t('membership.change.allin.phone.invalid')
                    }}
                    name="phone"
                    label={t('details.change.phone.new.phoneLabel')}
                    error={phoneError}
                    defaultValue={state.context.phoneNumber}
                    data-cy="new-phone"
                />
                <Button
                    type="submit"
                    text={t('128')}
                    data-cy="submit-phone"
                    loading={state.matches('phone.sendingToken')}
                    disabled={state.matches('phone.sendingToken')}
                />
                <Button
                    type="button"
                    onClick={() => navigate('/information')}
                    className="!bg-white hover:bg-white !text-anthracite"
                    text={t('162')}
                    data-cy="back"
                />
                {shouldShowTooManyTries && (
                    <TooManyTriesModal
                        onCloseCb={() => {
                            send('CLOSE_MODAL');
                        }}
                    />
                )}
            </Form>
        </div>
    );
};
