import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Section } from '@src/components/Layout/Section';
import { DebitBlockBanner } from '@src/feature/DebitBlock/DebitBlockBanner';
import { ReauthorizeFailModal } from '@src/feature/DebitBlock/ReauthorizeFailModal';
import { ReauthorizeMandateModal } from '@src/feature/DebitBlock/ReauthorizeMandateModal';
import { ReauthorizeSuccessModal } from '@src/feature/DebitBlock/ReauthorizeSuccessModal';
import { MemberContext } from '@src/services/member/MemberProvider';
import { useMBFFeatures } from '@src/utils/hooks/api/useMBFFeatures';

export const DebitBlock = () => {
    const { pathname, hash } = useLocation();
    const { state: member } = useContext(MemberContext);
    const { debitBlock } = useMBFFeatures();

    const navigate = useNavigate();

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFailed, setIsFailed] = useState(false);

    useEffect(() => {
        if (['/payments', '/information'].includes(pathname)) {
            switch (hash) {
                case '#reauthorize-mandate-success':
                    setModalIsOpen(false);
                    setIsSuccess(true);
                    break;
                case '#reauthorize-mandate-failed':
                    setModalIsOpen(false);
                    setIsFailed(true);
                    break;
                case '#reauthorize-mandate':
                    setModalIsOpen(true);
            }
        }
    }, [pathname, hash]);

    const openModal = (e: React.MouseEvent) => {
        navigate('#reauthorize-mandate');
        e.preventDefault();
    };

    const closeModal = () => {
        navigate(pathname);
        setModalIsOpen(false);
        setIsSuccess(false);
        setIsFailed(false);
    };

    return (
        <>
            {member.noDirectDebit && debitBlock && (
                <Section>
                    <DebitBlockBanner onClick={openModal} />
                    <ReauthorizeMandateModal isOpen={modalIsOpen} onClose={closeModal} />
                </Section>
            )}
            {isSuccess && <ReauthorizeSuccessModal isOpen={isSuccess} onClose={closeModal} />}
            {isFailed && <ReauthorizeFailModal isOpen={isFailed} onClose={closeModal} />}
        </>
    );
};
