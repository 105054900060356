import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { MemberPriceContract } from '@src/components/Price/MemberPriceContract';
import { ChangeMembershipContext } from '@src/pages/ChangeMembership/machines/ChangeMembershipProvider';
import {
    extrasSelector,
    membershipSelector,
    renewSelector
} from '@src/pages/ChangeMembership/machines/changeMembershipSelectors';
import { useMember } from '@src/services/member/MemberProvider';
import { getArticleIntervalText, isOneTimeArticle } from '@src/utils/helpers/articleHelpers';
import { getPrice, PaymentInterval, priceInterval } from '@src/utils/helpers/priceHelpers';
import { ArticleByPaymentSchedule } from '@src/utils/hooks/api/types/articleByPaymentSchedule';
import { useSelector } from '@xstate/react';
import { DateTime } from 'luxon';

import { usePaymentAmounts } from '../hooks/usePaymentsAmounts.hook';
import { ItemDetails } from './ItemDetails';

/**
 * Child components for MyChanges
 * todo: Check if we can reuse these anywhere else, and move them to Storybook
 */
type ChangeBoxProps = {
    className?: string;
    children: React.ReactNode;
};

const ChangesBox = ({ className = '', children }: ChangeBoxProps) => {
    return <div className={`bg-jersey-grey p-m ${className}`}>{children}</div>;
};

export const MyChanges = ({ className = '' }: { className?: string }) => {
    const { state: member } = useMember();
    const {
        t,
        i18n: { language }
    } = useTranslation();

    const membershipService = useContext(ChangeMembershipContext);
    const renew = useSelector(membershipService.changeService, renewSelector);
    const selectedMembership = useSelector(membershipService.changeService, membershipSelector);
    const selectedExtras: ArticleByPaymentSchedule[] = useSelector(
        membershipService.changeService,
        extrasSelector
    );

    const { totalPrice, oneTimePrice } = usePaymentAmounts(renew);

    const MembershipDetails = () => {
        return (
            <>
                {renew && selectedMembership ? (
                    <>
                        <div
                            className={`flex flex-col xs:flex-row xs:items-center justify-between`}
                        >
                            <Text size={1} className="capitalize">
                                {t('renewal.extend.startdate')}
                            </Text>
                            <Text size={2} className="xs:text-right">
                                {DateTime.fromISO(selectedMembership.peopleMembership.startDate)
                                    .setLocale(language)
                                    .toLocaleString()}
                            </Text>
                        </div>
                        <div
                            className={`flex flex-col xs:flex-row xs:items-center justify-between`}
                        >
                            <Text size={1} className="capitalize">
                                {t('renewal.extend.enddate')}
                            </Text>
                            <Text size={2} className="xs:text-right">
                                {DateTime.fromISO(
                                    selectedMembership.peopleMembership.contractEndDate
                                )
                                    .setLocale(language)
                                    .toLocaleString()}
                            </Text>
                        </div>
                        <div className="flex flex-col xs:flex-row xs:items-center justify-between">
                            <Text size={1} className="capitalize">
                                <span>{member.membership_type_g}</span>
                            </Text>
                            <MemberPriceContract showDiscount={false} style="normal" />
                        </div>
                    </>
                ) : (
                    <ItemDetails
                        title={selectedMembership?.membershipType || ''}
                        price={
                            selectedMembership?.termFee
                                ? getPrice(selectedMembership?.termFee, language)
                                : ''
                        }
                        interval={t(
                            selectedMembership?.paymentInterval === PaymentInterval.FourWeeks
                                ? 'per4Weeks'
                                : '370'
                        )}
                    />
                )}

                {selectedExtras?.map((item) => (
                    <ItemDetails
                        key={item.artc_id}
                        title={item.artc_short_description || ''}
                        price={getPrice(item.artc_price, language)}
                        intervalSeparator={!renew}
                        interval={
                            renew
                                ? isOneTimeArticle(item)
                                    ? t('78')
                                    : priceInterval(member.payment_plan.interval as PaymentInterval)
                                : t(getArticleIntervalText(item, selectedMembership))
                        }
                    />
                ))}

                <ItemDetails
                    className="border-t border-grey-4 mt-xs pt-xs"
                    type="heading"
                    title={t('445')}
                    price={getPrice(totalPrice, language)}
                    intervalSeparator={!renew}
                    interval={
                        renew
                            ? priceInterval(member.payment_plan.interval as PaymentInterval)
                            : t(
                                  selectedMembership?.paymentInterval === PaymentInterval.FourWeeks
                                      ? 'per4Weeks'
                                      : '370'
                              )
                    }
                />

                {oneTimePrice > 0 && (
                    <ItemDetails
                        type="heading"
                        intervalSeparator={!renew}
                        title={t('membership.one.time.costs')}
                        price={getPrice(oneTimePrice, language)}
                        interval={t('78')}
                    />
                )}
            </>
        );
    };

    return (
        <Box
            variant="orange"
            className={`${className ?? ''}`}
            data-cy="change-membership-my-changes"
        >
            <Heading size={6}>{t('1631')}</Heading>
            <div className="mt-s grid items-start grid-cols-1 xl:grid-cols-2 gap-6">
                <ChangesBox className="text-grey-4">
                    <Heading size={7} className="opacity-50">
                        {t('1633')}
                    </Heading>
                    {renew && (
                        <>
                            <div className="flex flex-col xs:flex-row xs:items-center justify-between">
                                <Text size={1} className="capitalize opacity-50">
                                    {t('43')}
                                </Text>
                                <Text size={2} className="xs:text-right opacity-50">
                                    {DateTime.fromISO(member.start_date_g).setLocale(language).toLocaleString()}
                                </Text>
                            </div>
                            <div className="flex flex-col xs:flex-row xs:items-center justify-between">
                                <Text size={1} className="capitalize opacity-50">
                                    {t('44')}
                                </Text>
                                <Text size={2} className="xs:text-right opacity-50">
                                    {DateTime.fromISO(member.contract_end_date_g)
                                        .setLocale(language)
                                        .toLocaleString()}
                                </Text>
                            </div>
                        </>
                    )}
                    <div className="flex flex-col xs:flex-row xs:items-center justify-between">
                        <Text
                            as="span"
                            size={1}
                            className={`capitalize opacity-50 ${!renew && 'line-through'}`}
                        >
                            {member.membership_type_g}
                        </Text>
                        <MemberPriceContract showDiscount={!renew} style="normal" className="opacity-50" />
                    </div>
                </ChangesBox>
                <ChangesBox>
                    <Heading size={7}>{t(renew ? 'renewal.extend.membership' : '1629')}</Heading>
                    {renew && (
                        <Text size={2} className="italic mb-xxs text-anthracite">
                            {t('renewal.extend.description')}
                        </Text>
                    )}
                    {selectedMembership ? (
                        <MembershipDetails />
                    ) : (
                        <div>{t('membership.change.select.new')}</div>
                    )}
                </ChangesBox>
            </div>
        </Box>
    );
};
