import React from 'react';

export const WhiteVerticalLines = () => (
    <svg
        width="98"
        height="267"
        viewBox="0 0 98 267"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M48 0V142" stroke="#7ADEC9" strokeWidth="15" strokeMiterlimit="10" />
        <path d="M90 25V112" stroke="white" strokeWidth="15" strokeMiterlimit="10" />
        <path d="M8 98V186" stroke="white" strokeWidth="15" strokeMiterlimit="10" />
        <path d="M48 174L48 267" stroke="#7ADEC9" strokeWidth="15" strokeMiterlimit="10" />
    </svg>
);
