export const REDUX_DEV_TOOLS = process.env.REACT_APP_REDUX_DEV_TOOL === 'true';
export const {
    NODE_ENV,
    REACT_APP_NAME: APP_NAME,
    REACT_APP_RELEASE_VERSION: APP_RELEASE_VERSION,
    REACT_APP_OPTIMIZE_EXPERIMENT_ID: OPTIMIZE_EXPERIMENT_ID,
    REACT_APP_AUTH_SERVICE_FE_DOMAIN: AUTH_SERVICE_FE_DOMAIN,
    REACT_APP_AUTH_SERVICE_CLIENT_ID: AUTH_SERVICE_CLIENT_ID,
    REACT_APP_AUTH_SERVICE_REDIRECT_URI: AUTH_SERVICE_REDIRECT_URI,
    REACT_APP_XSTATE_STATECHART_DEBUG: XSTATE_STATECHART_DEBUG,
    REACT_APP_GRACE_PERIOD_MONTHS: GRACE_PERIOD_MONTHS,
    REACT_APP_AUTO_CANCELLATION_COUNTRIES: AUTO_CANCELLATION_COUNTRIES
} = process.env;
