import React, { HTMLAttributes, useEffect } from 'react';
import { Path, PathValue, useFormContext, Validate, ValidationRule } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Text } from '@basic-fit/design-system';

import { Error, ErrorProps } from '../Error/Error.component';
import { InputLabel } from '../Label/Label.components';

interface TextInputProps<FormData extends Record<string, unknown>, Name extends keyof FormData>
  extends Omit<HTMLAttributes<HTMLInputElement>, 'onChange' | 'defaultValue'>,
  ErrorProps {
  name: Name;
  label: string;
  required?: ValidationRule<boolean>;
  pattern?: ValidationRule<RegExp>;
  readOnly?: boolean;
  instructions?: string;
  defaultValue?: string;
  maxLength?: ValidationRule<number>;
  minLength?: ValidationRule<number>;
  validate?: Validate<string, any> | Record<string, Validate<string, any>>;
  type?: string;
}

export const TextInput = <FormData extends Record<string, unknown>, Name extends Path<FormData>>({
  name,
  label,
  className = '',
  required,
  instructions,
  defaultValue,
  maxLength,
  minLength,
  pattern,
  readOnly,
  validate,
  error,
  ...rest
}: TextInputProps<FormData, Name>): JSX.Element => {
  const { t } = useTranslation();
  const { type: inputType } = rest || {};

  if (required === true) {
    required = { value: true, message: t('generic.form.field_is_required') };
  }

  const {
    register,
    watch,
    formState: { errors: stateErrors },
    setError
  } = useFormContext<FormData>();

  const watchInput = watch<Name>(name);

  useEffect(() => {
    if (error) {
      const errorMessage = typeof error === 'string' ? error : '';
      setError(name, { message: errorMessage });
    }
  }, [error]);

  return (
    <div
      className={`
                w-full
                ${readOnly ? 'cursor-not-allowed' : 'cursor-text'}
            `}
    >
      <div
        className={`
                    w-full block relative
                    ${readOnly ? 'pointer-events-none' : ''}
                `}
      >
        <input
          id={name}
          type={inputType || 'text'}
          aria-labelledby={`${name}-label`}
          className={`
                        text-[16px] font-heading-treble text-anthracite font-bold w-full
                        pt-[24px] pb-[10px] border-0 peer !outline-none
                        focus:outline-none focus:border-0
                        transition-shadow duration-300 shadow-transparent shadow-[inset_2px_0_0_0] px-xs 
                        autofill:!shadow-[inset_2px_0_0_0] autofill:hover:!shadow-[inset_2px_0_0_0]
                        autofill:active:!shadow-[inset_2px_0_0_0] autofill:focus:!shadow-[inset_2px_0_0_0]
                        autofill:!shadow-transparent autofill:hover:!shadow-transparent
                        read-only:cursor-not-allowed read-only:text-grey-disabled
                        ${defaultValue ? 'read-only:!text-grey-disabled !text-anthracite' : ''}
                        ${stateErrors[name] || error ? '!bg-red-error' : '!bg-jersey-grey'}
                        ${stateErrors[name] || error
              ? `focus:shadow-red active:shadow-red autofill:focus:!shadow-red autofill:active:!shadow-red`
              : `focus:shadow-orange active:shadow-orange autofill:focus:!shadow-orange autofill:active:!shadow-orange`
            }
                    `}
          readOnly={readOnly}
          {...register(name, {
            required,
            maxLength,
            minLength,
            pattern,
            value: defaultValue as PathValue<FormData, Name>,
            validate: validate as Validate<PathValue<FormData, Name>, any>
          })}
          {...rest}
        />

        <span
          className={`
                        absolute font-heading-double text-anthracite 
                        top-[50%] left-[16px] transition-[all] duration-[300ms]
                        peer-focus:translate-y-[-120%] peer-focus:text-[12px]
                        peer-autofill:translate-y-[-120%] peer-autofill:text-[12px]
                        peer-read-only:text-grey-disabled cursor-text pointer-events-none
                        ${watchInput || defaultValue
              ? 'translate-y-[-115%] !text-[12px]'
              : 'translate-y-[calc(-50%+1px)] !text-[14px]'
            }
                        ${className}
                    `}
        >
          <InputLabel name={name} label={label} required={required} readOnly={readOnly} />
        </span>
      </div>
      <div
        className={`font-heading-double transition-[padding] duration-300 ${instructions || stateErrors[name] || error ? 'py-xxxs' : 'pt-0'
          }`}
      >
        {instructions && <Text size={2}>{instructions}</Text>}
        <Error
          data-cy={`error-${name}`}
          error={(stateErrors[name]?.message as string) || error}
        />
      </div>
    </div>
  );
};
