import React from 'react';

export default function InfoOutlineIcon() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_6632_263)">
                <path
                    d="M8.00001 16C9.58226 16 11.129 15.5308 12.4446 14.6518C13.7602 13.7727 14.7856 12.5233 15.3911 11.0615C15.9966 9.59965 16.1549 7.99112 15.8463 6.43927C15.5376 4.88742 14.7757 3.46196 13.6569 2.34314C12.5381 1.22432 11.1126 0.462398 9.56075 0.153716C8.0089 -0.154965 6.40036 0.00347626 4.93855 0.608977C3.47674 1.21448 2.22733 2.23986 1.34828 3.55545C0.469231 4.87104 1.51428e-05 6.41775 1.50045e-05 8C1.4819e-05 10.1217 0.842863 12.1566 2.34315 13.6569C3.84344 15.1572 5.87828 16 8.00001 16ZM8.00001 14.4C6.73421 14.4 5.49683 14.0247 4.44435 13.3214C3.39188 12.6182 2.57157 11.6186 2.08717 10.4492C1.60277 9.27974 1.47603 7.99291 1.72298 6.75143C1.96992 5.50996 2.57945 4.36957 3.4745 3.47452C4.36956 2.57946 5.50994 1.96991 6.75142 1.72296C7.9929 1.47602 9.27973 1.60276 10.4492 2.08716C11.6186 2.57156 12.6182 3.39186 13.3214 4.44434C14.0247 5.49681 14.4 6.7342 14.4 8C14.3974 9.69657 13.7223 11.3229 12.5226 12.5226C11.3229 13.7222 9.69659 14.3973 8.00001 14.4Z"
                    fill="currentColor"
                />
                <path
                    d="M8.80005 3.19995L7.20007 3.19995L7.20007 9.49994L8.80005 9.49994L8.80005 3.19995Z"
                    fill="currentColor"
                />
                <path
                    d="M7.20005 12.7L8.80005 12.7L8.80005 11.1L7.20005 11.1L7.20005 12.7Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    );
}
