import React, { ReactElement } from 'react';

type NavMenuProps = {
    className?: string;
    children: React.ReactNode;
    activeId?: number;
};

export const NavMenu = ({ className = '', children, activeId = 0 }: NavMenuProps) => {
    return (
        <ul className={`flex justify-center ${className}`}>
            {React.Children.map(children, (child, index) => {
                if (index === activeId && React.isValidElement(child)) {
                    return React.cloneElement(child as ReactElement, {
                        active: true
                    });
                }
                return child;
            })}
        </ul>
    );
};
