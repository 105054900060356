import React from 'react';

export const Eclipse = () => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 167 197"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35 71C35 42.4146 48.037 16.8741 68.4896 0H83.9881C60.0155 14.5538 44 40.9066 44 71C44 116.84 81.1604 154 127 154C141.503 154 155.137 150.28 167 143.743V153.873C154.905 159.721 141.335 163 127 163C76.1898 163 35 121.81 35 71Z"
            fill="#5ED1B9"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 70.5C0 44.3993 7.9048 20.1448 21.451 0H32.4914C17.7407 19.638 9 44.0483 9 70.5C9 71.4696 9.01174 72.4364 9.0351 73.4004L0.122691 76.122C0.0411809 74.2582 0 72.3839 0 70.5ZM126.5 188C140.733 188 154.375 185.469 167 180.834V190.378C154.285 194.672 140.664 197 126.5 197C79.669 197 38.7816 171.552 16.912 133.73L25.7677 131.026C46.3246 165.165 83.7456 188 126.5 188Z"
            fill="white"
        />
    </svg>
);
