import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Heading, Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { InformationBlock } from '@src/components/InformationBlock/InformationBlock';
import { StockImage } from '@src/components/StockImage/StockImage';
import { StrokeText } from '@src/components/StrokeText/StrokeText';
import { useMember } from '@src/services/member/MemberProvider';
import { mutate } from 'swr';

export const ReverseCancellationSuccessModal = () => {
    const { t } = useTranslation();
    const { state: member } = useMember();

    const refreshMembership = async () => {
        await mutate('/member/get-member');
    };

    return (
        <span data-cy="reverse-cancellation__modal-success">
            <Heading size={4} className="mb-s">
                <StrokeText
                    className="text-anthracite"
                    text={t('reverseCancellation.modal.successTitle')}
                    color="anthracite"
                    stroke="first"
                />
            </Heading>

            <div>
                <StockImage className="mx-auto min-h-[15rem]" id="renewed" />
            </div>
            <Text size={2}>{t('reverseCancellation.modal.successBody')}</Text>

            {member.inDebt && (
                <>
                    <InformationBlock className="my-xxs">
                        {t('reverseCancellation.modal.debtBody')}
                    </InformationBlock>
                    <Link onClick={refreshMembership} to="/payments">
                        <Button
                            text={t('reverseCancellation.modal.debtButton')}
                            className="mt-xxs"
                        />
                    </Link>
                </>
            )}
        </span>
    );
};
