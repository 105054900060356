import React from 'react';
import {
    lgStyles,
    mdStyles,
    mediaQueries,
    styles,
    xlStyles
} from '../../parameters/css-parameters';
import styled, { css } from 'styled-components';

const spacing = 60;

// Container Size is not set then it will be full width container
// Container Size is "M" then it will medium container with 60px margin on left & right
// Container Size is "S" then it will small container with 120px margin on left & right
// Container Size is "XS" then it will extra small container with 180px margin on left & right

const couponStyle = css`
  padding: 16px 24px;
  background: var(--color-jersey-grey);
  ${({ bgHighlightColor }) => `
    border-left: 10px solid ${bgHighlightColor ? bgHighlightColor : 'var(--color-mint)'};
  `};
`;

const Container = styled.div`
  ${(props) => (props.type === 'highlightPanel' ? `position: relative;padding-top: 1px;` : '')};
  ${(props) =>
      props.type === 'panel' ? `background: var(--color-jersey-grey); padding: 15px;` : ''};
  ${(props) => (props.type === 'coupon' ? couponStyle : '')};

  ${({ gradient }) => `
    ${gradient ? `background: var(--gradient-${gradient})` : ''};
  `};

  ${mediaQueries['md']} {
    ${({ size, type }) => `
        margin-left: ${size === 'XS' ? `${spacing}px` : ''};
        margin-right: ${size === 'XS' ? `${spacing}px` : ''};
        ${type === 'panel' ? 'padding: 20px; margin: 0' : ''};
    `};
  }

  ${mediaQueries['lg']} {
    ${({ size, type }) => `
        margin-left: ${size === 'S' ? `${spacing}px` : size === 'XS' ? `${spacing * 2}px` : ''};
        margin-right: ${size === 'S' ? `${spacing}px` : size === 'XS' ? `${spacing * 2}px` : ''};
        ${type === 'panel' ? 'margin: 0 30px' : ''};
    `};
  }

  ${mediaQueries['xl']} {
    ${({ size, type }) => `
        margin-left: ${
            size === 'M'
                ? `${spacing}px`
                : size === 'S'
                ? `${spacing * 2}px`
                : size === 'XS'
                ? `${spacing * 3}px`
                : ''
        };
        margin-right: ${
            size === 'M'
                ? `${spacing}px`
                : size === 'S'
                ? `${spacing * 2}px`
                : size === 'XS'
                ? `${spacing * 3}px`
                : ''
        };
        ${type === 'panel' ? 'margin: 0 60px' : ''};
    `};
  }

  ${styles}
  ${mdStyles}
  ${lgStyles}
  ${xlStyles}
`;

const Background = styled.div`
  position: absolute;
  height: 25%;
  width: 100%;
  padding: 15px;
  top: -1px;
  ${({ bgHighlightColor, bgHighlightHeight }) => `
    background: var(--color-${bgHighlightColor});
    ${bgHighlightHeight && `height: ${bgHighlightHeight}`};
  `};

  ${mediaQueries['md']} {
    padding: 60px;
    ${({ mdBgHighlightColor }) => `
      ${mdBgHighlightColor ? `background: var(--color-${mdBgHighlightColor})` : 'display: none;'};
    `};
  }
`;

const HighlightedPanel = styled(Container)`
  margin: 15px 15px 0;
  background: var(--color-white);
  position: relative;

  ${mediaQueries['md']} {
    ${({ mdBgHighlightColor }) => `
      ${mdBgHighlightColor ? `margin: 60px 60px 0;` : 'margin: 0;'};
    `};
  }
`;

const ContainerComponent = ({ children, className = '', type, ...rest }) => {
    return (
        <Container {...rest} type={type} className={`mbf-container  ${className}`}>
            {type === 'highlightPanel' && (
                <>
                    <Background {...rest} type={type} />
                    <HighlightedPanel {...rest}>{children}</HighlightedPanel>
                </>
            )}
            {type !== 'highlightPanel' && <>{children}</>}
        </Container>
    );
};

export default ContainerComponent;
