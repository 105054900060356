import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Heading, Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { useCancellationContext } from '@src/components/ContextProvider/CancellationContextProvider';
import { RadioButton } from '@src/components/Form/RadioButton/RadioButton.component';
import { Header } from '@src/components/Header/Header';
import { Section } from '@src/components/Layout/Section';
import { LineSVG } from '@src/components/LineSVG/LineSVG';
import { Skeleton } from '@src/components/Skeletons/Skeleton';
import {
    CancellationData,
    CancellationReasonProps
} from '@src/pages/CancelMembership/CancelMembership.types';
import { Path } from '@src/router/CancelMembership.router';
import { useMember } from '@src/services/member/MemberProvider';
import { useFetch } from '@src/utils/hooks/api/useFetch';

import googleTagManagerPush from '../../../services/google-tag-manager-push';
import { DowngradeModal } from './Modals/DowngradeModal';

export default function CancelMembershipReasons(): JSX.Element {
    const { t } = useTranslation();
    const {
        chosenReason,
        reasonDescription,
        setReasonDescription,
        setChosenReason,
        resetCancelMembershipCache
    } = useCancellationContext();
    const navigate = useNavigate();
    const [showChooseReasonsAlert, setShowChooseReasonsAlert] = useState(false);
    const [showDowngradeModal, setShowDowngradeModal] = useState(false);
    const { state: member } = useMember();

    const currentMembershipType = member.membership_type_g;

    const {
        data: { reasons: _reasons },
        isLoading,
        isError
    } = useFetch<CancellationData>(
        `/memberships/get-cancel-membership-data?membershipId=${member.id_g}`
    );

    const reasons = useMemo(() => {
        return _reasons?.sort((a, b) => {
            if (a.reason === 'other_reason') {
                return 1;
            }
            if (b.reason === 'other_reason') {
                return -1;
            }
            return Math.random() - 0.5;
        });
    }, [_reasons]);

    const pickReason = (pickedChoice: CancellationReasonProps) => {
        googleTagManagerPush.cancelMembership(
            'click',
            `page reasons - clicked reason: ${pickedChoice.reason}`
        );

        if (pickedChoice !== chosenReason) setReasonDescription('');

        setChosenReason(pickedChoice);

        const downgradeReasons = ['cost', 'other_reason', 'workout_other_place'];
        if (currentMembershipType === 'premium' && downgradeReasons.includes(pickedChoice.reason)) {
            setShowDowngradeModal(true);
        }
    };

    const handleReasonDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setReasonDescription(e.target.value);
    };

    useEffect(() => {
        googleTagManagerPush.cancelMembership('view', 'page reasons');
    }, []);

    useEffect(() => {
        if (chosenReason?.reason) {
            setShowChooseReasonsAlert(false);
        }
    }, [chosenReason]);

    const goToCheckPage = () => {
        if (!chosenReason?.reason) {
            setShowChooseReasonsAlert(true);
            return;
        }
        googleTagManagerPush.cancelMembership('click', `page reasons - continue`);
        navigate(Path.Base + Path.Dates);
    };

    const goBackToMembership = () => {
        googleTagManagerPush.cancelMembership('click', `page reasons - go back`);
        resetCancelMembershipCache();
        navigate('/membership');
    };

    return (
        <>
            {member.isAllIn ? (
                <div data-cy="cancel-membership__reasons__houston">
                    {/* CANCEL MEMBERSHIP */}
                    <Header
                        data-cy="cancel-membership__reasons__header"
                        title={t('117')}
                        image={<LineSVG id="vertical-lines" height={267} />}
                    />
                    {/* You cannot cancel your membership */}
                    <Text className="mt-xs" size={1}>
                        {t('1809')}
                    </Text>
                </div>
            ) : (
                <div data-cy="cancel-membership__reasons">
                    {/* cancelmembership & We are sorry to hear that... */}
                    <Header
                        data-cy="cancel-membership__reasons__header"
                        title={t('117')}
                        text={t('118')}
                        image={<LineSVG id="vertical-lines" height={267} />}
                    />

                    <Section data-cy="cancel-membership__reasons__section">
                        {/* why to cancel ? */}
                        <div className="px-s md:p-0">
                            <Heading
                                data-cy="cancel-membership__reasons__section-heading"
                                className="mb-xs"
                                size={5}
                            >
                                {t('membership.cancellation.reasons.title')}
                            </Heading>
                            <Text size={2}>{t('membership.cancellation.reasons.subtitle')}</Text>
                        </div>

                        <div
                            data-cy="cancel-membership__reasons__reasons"
                            className="relative p-s md:p-0 my-xs"
                        >
                            {/* loading skeleton */}
                            {isLoading && (
                                <div>
                                    <Skeleton type="button" className="mb-[4px]" />
                                    <Skeleton type="button" className="mb-[4px]" />
                                    <Skeleton type="button" className="mb-[4px]" />
                                    <Skeleton type="button" className="mb-[4px]" />
                                    <Skeleton type="button" className="mb-[4px]" />
                                    <Skeleton type="button" className="mb-[4px]" />
                                    <Skeleton type="button" className="mb-[4px]" />
                                    <Skeleton type="button" className="mb-[4px]" />
                                    <Skeleton type="button" className="mb-[4px]" />
                                    <Skeleton type="button" className="mb-[4px]" />
                                    <Skeleton type="button" className="mb-[4px]" />
                                </div>
                            )}
                            {/* We are experiencing a technical issue. Sorry for that! Please try again later. */}
                            {isError && <Text size={2}>{t('528')}</Text>}
                            {reasons &&
                                reasons.map((reason: CancellationReasonProps) => {
                                    return (
                                        <div key={reason.reason} className="my-[4px]">
                                            <div
                                                className={`
                                            flex cursor-pointer items-center px-s py-xs
                                            transition-[border] duration-300 bg-jersey-grey
                                            `}
                                                data-cy="cancel-membership__reasons__reason"
                                                onClick={() => pickReason(reason)}
                                            >
                                                <RadioButton
                                                    name={reason.reason}
                                                    label={reason.text}
                                                    checked={chosenReason?.reason == reason.reason}
                                                />
                                            </div>
                                            {chosenReason?.reason == reason.reason && (
                                                <div className="bg-jersey-grey p-[4px]">
                                                    <textarea
                                                        onChange={handleReasonDescriptionChange}
                                                        placeholder={t(
                                                            'membership.cancellation.reasons.description-input'
                                                        )}
                                                        className="p-[16px] w-full focus:outline-none focus:border-0 focus:ring-0 text-regular-s"
                                                        defaultValue={reasonDescription}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            <div className="absolute right-[-60px] bottom-[-60px]">
                                <LineSVG id="curved-lines" height={200} />
                            </div>
                        </div>
                    </Section>

                    <Section
                        data-cy="cancel-membership__reasons__buttons"
                        className="flex justify-center items-center"
                    >
                        <div className="w-[315px] flex-col justify-center flex">
                            {/*Please select your reasons! */}
                            <Text
                                data-cy="cancel-membership__reasons__button-no-choice-alert"
                                className={`
                                    text-red text-center mb-[10px] transition-opacity duration-300
                                    ${showChooseReasonsAlert ? 'opacity-[1]' : 'opacity-[0]'}
                                `}
                                size={2}
                            >
                                {t('306')}
                            </Text>

                            {/* next button */}
                            <Button
                                data-cy="cancel-membership__reasons__button-forward"
                                onClick={goToCheckPage}
                                className="mb-[10px]"
                                text={t('128')}
                            />

                            {/* go back */}
                            <Button
                                data-cy="cancel-membership__reasons__button-back"
                                onClick={goBackToMembership}
                                color="naked"
                                text={t('162')}
                            />
                        </div>
                    </Section>
                </div>
            )}
            {/* downgrade modal */}
            {showDowngradeModal && (
                <DowngradeModal
                    isOpen={showDowngradeModal}
                    data-cy="cancel-membership__reasons__downgrade-modal"
                    onClose={() => {
                        googleTagManagerPush.cancelMembership('click', 'downgrade modal - close');
                        setShowDowngradeModal(false);
                    }}
                    onConfirm={() => {
                        setShowDowngradeModal(false);
                    }}
                />
            )}
        </>
    );
}
