import React from 'react';
import { useSelector } from 'react-redux';
import { ILayout } from '@src/types/layout.type';
import { RootState } from '@src/types/store.type';
import { isApp } from '@src/utils/helpers/window';

import { Menu } from './components/Menu';

export const DashboardLayout = ({ children }: ILayout): JSX.Element => {
    /**
     * Get global Redux state when /?app=true is set in AppRouter
     */
    const { isAppView } = useSelector((state: RootState) => ({
        ...state.isAppView
    }));

    const showMenu = !isAppView && !isApp();

    return (
        <div className="flex flex-col w-full h-full lg:flex-row">
            <div
                className={`w-[auto] lg:w-[calc(50%-400px)] lg:min-w-[300px] flex justify-end flex-shrink-0 ${
                    showMenu ? 'block' : 'hidden'
                }`}
            >
                <Menu />
            </div>
            <div
                id="page"
                className="flex overflow-y-auto overflow-x-hidden flex-grow justify-start"
            >
                <div className="flex flex-col w-full p-[0] pb-[0] max-w-[1100px] sm:p-[30px] md:p-[60px] md:pb-[0]">
                    <div className="flex-grow">{children}</div>
                </div>
            </div>
        </div>
    );
};
