import React from 'react';
import ArrowRightIcon from '@src/components/Icons/icons/ArrowRight.icon';
import Contract from '@src/components/Icons/icons/Contract.icon';
import Download from '@src/components/Icons/icons/Download.icon';
import DownloadPlain from '@src/components/Icons/icons/DownloadPlain.icon';
import RibbonGreen from '@src/components/Icons/icons/RibbonGreen.icon';
import { Search } from '@src/components/Icons/icons/Search.icon';

import Arrow from './icons/Arrow.icon';
import BestChoice from './icons/BestChoice.icon';
import Bin from './icons/Bin.icon';
import Calendar from './icons/Calendar.icon';
import Cancel from './icons/Cancel.icon';
import Change from './icons/Change.icon';
import ChevronDown from './icons/ChevronDown.icon';
import ChevronLeft from './icons/ChevronLeft.icon';
import ChevronRight from './icons/ChevronRight.icon';
import ChevronUp from './icons/ChevronUp.icon';
import Clock from './icons/Clock.icon';
import Close from './icons/Close.icon';
import ClosedEye from './icons/ClosedEye.icon';
import DateIcon from './icons/Date.icon';
import FlagBE from './icons/FlagBE.icon';
import FlagDE from './icons/FlagDE.icon';
import FlagES from './icons/FlagES.icon';
import FlagFR from './icons/FlagFR.icon';
import FlagLU from './icons/FlagLU.icon';
import FlagNL from './icons/FlagNL.icon';
import FlagUK from './icons/FlagUK.icon';
import Hamburger from './icons/Hamburger.icon';
import Heart from './icons/Heart.icon';
import HomeClub from './icons/HomeClub.icon';
import Info from './icons/Info.icon';
import InfoOutline from './icons/InfoOutline.icon';
import OpenEye from './icons/OpenEye.icon';
import Plus from './icons/Plus.icon';
import QuestionIcon from './icons/Question.icon';
import ThumbsUpIcon from './icons/ThumbsUp.icon';
import Tick from './icons/Tick.icon';

export interface IconProps {
    className?: string;
    //  todo: remove string option to have a better type safety (involves refactoring some other files)
    id: (typeof icons)[number]['id'] | string;
    onClick?: () => void;
}

const outline = [{ id: 'info-outline', value: InfoOutline }];

const solid = [
    { id: 'arrow', value: Arrow },
    { id: 'bin', value: Bin },
    { id: 'chevron-down', value: ChevronDown },
    { id: 'chevron-left', value: ChevronLeft },
    { id: 'chevron-right', value: ChevronRight },
    { id: 'chevron-up', value: ChevronUp },
    { id: 'close', value: Close },
    { id: 'hamburger', value: Hamburger },
    { id: 'info', value: Info },
    { id: 'plus', value: Plus },
    { id: 'tick', value: Tick },
    { id: 'open-eye', value: OpenEye },
    { id: 'closed-eye', value: ClosedEye },
    { id: 'search', value: Search },
    { id: 'question', value: QuestionIcon },
    { id: 'best-choice', value: BestChoice },
    { id: 'ribbon-green', value: RibbonGreen }
] as const;

const flags = [
    { id: 'en', value: FlagUK },
    { id: 'es', value: FlagES },
    { id: 'fr', value: FlagFR },
    { id: 'nl', value: FlagNL },
    { id: 'be', value: FlagBE },
    { id: 'lu', value: FlagLU },
    { id: 'de', value: FlagDE }
] as const;

const colour = [
    { id: 'calendar', value: Calendar },
    { id: 'clock', value: Clock },
    { id: 'change', value: Change },
    { id: 'cancel', value: Cancel },
    { id: 'thumbs-up', value: ThumbsUpIcon },
    { id: 'home-club', value: HomeClub },
    { id: 'arrow-right', value: ArrowRightIcon },
    { id: 'contract', value: Contract },
    { id: 'download', value: Download },
    { id: 'download-plain', value: DownloadPlain },
    { id: 'date', value: DateIcon },
    { id: 'heart', value: Heart }
] as const;

const icons = [...solid, ...outline, ...flags, ...colour];

export const IconIds = () => icons.map((icon) => icon.id);

export const Icon = ({ id, className = '', onClick }: IconProps): JSX.Element => {
    const svg = icons.find((icon) => icon.id === id);
    const IconElement = svg?.value;

    return (
        <>
            {IconElement && (
                <span
                    onClick={onClick}
                    data-cy={id}
                    className={`w-[1em] h-[1em] inline-block transform scale-[1.25] ${
                        className ?? ''
                    }`}
                >
                    <IconElement />
                </span>
            )}
        </>
    );
};
