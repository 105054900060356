import {
    hasActiveCompensations,
    hasFrozenCompensations
} from '@src/services/member/helpers/compensations';
import { getActiveMembership } from '@src/services/membership.service';
import type { BalanceSheetProps } from '@src/utils/hooks/api/types/balanceSheet';
import type { ChosenCompensationsProps } from '@src/utils/hooks/api/types/chosenCompensations';
import type { CompensationOptionsProps } from '@src/utils/hooks/api/types/compensationOptions';
import type { DebitsProps } from '@src/utils/hooks/api/types/debits';
import type { VisitsResponse } from '@src/utils/hooks/api/types/visits';
import { parseBalanceSheet } from '@src/utils/hooks/api/useBalanceSheet';

import type { FriendsDataProps } from '../../../types/friends.types';
import type { MemberDataProps, PeopleMembership } from '../../../types/member.types';

type MemberAction =
    | { type: 'get-member'; payload: MemberDataProps }
    | { type: 'set-debits'; payload: DebitsProps }
    | { type: 'get-membership' }
    | { type: 'set-employee' }
    | { type: 'visits'; payload: VisitsResponse }
    | { type: 'friends'; payload: FriendsDataProps }
    | { type: 'balance'; payload: BalanceSheetProps }
    | { type: 'compensations'; payload: ChosenCompensationsProps }
    | { type: 'campaign'; payload: boolean }
    | { type: 'compensationOptions'; payload: CompensationOptionsProps }
    | { type: 'people-membership'; payload: PeopleMembership };

export const memberReducer = (state: any, action: MemberAction) => {
    switch (action.type) {
        case 'get-member':
            return { ...state, ...action.payload };
        case 'get-membership':
            return { ...state, ...{ membership: getActiveMembership(state) } };
        case 'set-employee':
            return {
                ...state,
                ...{ isEmployee: state.membership?.display_name?.toLowerCase() === 'employee' }
            };
        case 'friends': {
            const friends = Array.from(new Set(action.payload));
            return { ...state, ...{ friends: [...friends] } };
        }
        case 'balance': {
            const balance = action.payload;

            const balanceSheet = parseBalanceSheet(balance);

            return {
                ...state,
                ...{
                    balanceSheet: balance,
                    balance: balanceSheet.balance,
                    inDebt: balanceSheet.inDebt
                }
            };
        }
        case 'compensations':
            return {
                ...state,
                ...{
                    chosenCompensations: action.payload,
                    membershipIsFrozen: hasFrozenCompensations(action.payload)
                }
            };
        case 'campaign':
            return {
                ...state,
                ...{
                    hasCompensationForCurrentCampaign: hasActiveCompensations(
                        state.chosenCompensations,
                        action.payload
                    )
                }
            };

        case 'compensationOptions':
            return { ...state, ...{ compensationOptions: action.payload } };
        case 'visits':
            return {
                ...state,
                ...{ visits: action.payload.recentVisits, totalVisits: action.payload.totalVisits }
            };
        case 'set-debits':
            return {
                ...state,
                debits: action.payload
            };
        case 'people-membership':
            return { ...state, ...{ peopleMembership: action.payload } };
    }
};
