import React, { HTMLAttributes } from 'react';

import { InputLabel } from '../Label/Label.components';

export interface RadioButtonProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
    required?: boolean;
    name: string;
    checked?: boolean;
    label?: string;
    size?: 's' | 'l';
}

export const RadioButton = ({
    className = '',
    required,
    onClick,
    checked = false,
    label,
    name,
    size = 's',
    ...rest
}: RadioButtonProps): JSX.Element => {
    return (
        <div className="flex items-center cursor-pointer" onClick={onClick}>
            <div
                className={`
                     rounded-[100%] p-[2px] border-anthracite 
                    transition-all duration-300 bg-white
                    ${
                        size === 's'
                            ? 'w-[15px] h-[15px] border-[1px]'
                            : 'w-[20px] h-[20px] border-2'
                    }
                    ${className}
                `}
                {...rest}
                role="radio"
                aria-checked={checked}
                tabIndex={0}
                aria-labelledby={label && `${name}-label`}
            >
                <div
                    className={`rounded-[100%] bg-orange ${
                        size === 's' ? 'w-[9px] h-[9px]' : 'w-[12px] h-[12px]'
                    } ${checked ? 'opacity-100' : 'opacity-0'}`}
                ></div>
            </div>
            {label && (
                <InputLabel
                    name={name}
                    label={label}
                    required={required}
                    className={`ml-xxs ${
                        size === 's'
                            ? 'capitalize'
                            : 'font-heading-treble font-extrabold !text-[16px] uppercase'
                    } `}
                />
            )}
        </div>
    );
};
