interface NewRelicFunnelActionProps {
    event: 'payment';
    extras?: string[];
    [key: string]: any;
}

interface NewRelicFunnelNavigateProps extends Omit<NewRelicFunnelActionProps, 'event'> {
    event: 'navigate';
    to: string;
}

interface NewRelicFunnelModalProps extends Omit<NewRelicFunnelActionProps, 'event'> {
    event: 'close' | 'submit';
    modal: 'renewed-membership';
}

type NewRelicFunnelProps =
    | NewRelicFunnelActionProps
    | NewRelicFunnelNavigateProps
    | NewRelicFunnelModalProps;

interface NewRelicUserFeedbackProps {
    event: 'feedback';
    type: 'neutral' | 'good' | 'bad' | 'feedback';
    message?: string;
    peopleId: string;
    country: string;
}

export const collectFunnelData = (data: Partial<NewRelicFunnelProps>) => {
    if (!window?.newrelic) return;
    const { addPageAction } = window.newrelic;
    const { event, ...rest } = data;
    addPageAction(event, rest);
};

export const collectFeedback = (data: NewRelicUserFeedbackProps) => {
    if (!window?.newrelic) return;
    const { addPageAction } = window.newrelic;
    const { event, ...rest } = data;
    addPageAction(event, rest);
};

export const captureException = (
    error: Error | string,
    customAttributes?: Record<string, newrelic.SimpleType | undefined>
) => {
    if (!window.newrelic) {
        return;
    }

    const { noticeError } = window.newrelic;
    noticeError(error, customAttributes);
};
