import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { Skeleton } from '@src/components/Skeletons/Skeleton';
import { StepsNav } from '@src/components/StepsNav';
import {
    ChangeMembershipContext,
    ChangeMembershipProvider
} from '@src/pages/ChangeMembership/machines/ChangeMembershipProvider';
import { ChooseMembership } from '@src/pages/ChangeMembership/steps/ChooseMembership';
import { MembershipExtras } from '@src/pages/ChangeMembership/steps/MembershipExtras';
import { MembershipOverview } from '@src/pages/ChangeMembership/steps/MembershipOverview';
import { scrollToTop } from '@src/utils/helpers/window';
import { useChangeMembershipGuard } from '@src/utils/hooks/useChangeMembershipGuard';
import { useSelector } from '@xstate/react';

import { generateChangeMembershipMachine } from './machines/changeMembershipMachine';
import { renewSelector } from './machines/changeMembershipSelectors';

const ChangeMembershipRoutes = () => {
    const membershipService = useContext(ChangeMembershipContext);
    const index = useSelector(membershipService.changeService, (state) => state.context.index);
    const renew = useSelector(membershipService.changeService, renewSelector);
    const { t } = useTranslation();

    return (
        <>
            <StepsNav
                index={index}
                items={[
                    t(renew ? 'renewal.extend.button' : 'choose.membership'),
                    t('membership.change.title.extra'),
                    t('379')
                ]}
            />
            <Routes>
                <Route path="/" element={<ChooseMembership />} />
                <Route path="extras" element={<MembershipExtras />} />
                <Route path="overview" element={<MembershipOverview />} />
            </Routes>
        </>
    );
};

interface ChangeMembershipFlowProps {
    renew?: boolean;
}

export const ChangeMembershipFlow = ({ renew }: ChangeMembershipFlowProps) => {
    useEffect(() => {
        scrollToTop();
    }, []);

    // Guard against users trying to access this page directly
    useChangeMembershipGuard(renew);

    const [machineInitialized, setMachineInitialized] = useState(false);
    useLayoutEffect(() => {
        // Initialize machine
        generateChangeMembershipMachine({ renew });
        setMachineInitialized(true);
    }, []);

    // Wait for machine to be initialized
    if (!machineInitialized) return <Skeleton type="page" />;

    return (
        <ChangeMembershipProvider>
            <ChangeMembershipRoutes />
        </ChangeMembershipProvider>
    );
};
