import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { captureException } from '@src/utils/helpers/newRelic';
import { DateTime } from 'luxon';

import { useFetch } from './useFetch';

interface activeFeatureDate {
    end: string;
    jsonDetails: string;
    start: string;
}

export const useMBFFeatures = (): Record<string, any> => {
    const { data: MBFFeaturesData, isError } =
        useFetch<activeFeatureDate[]>('/extra/active-features');

    useEffect(() => {
        if (isError) {
            captureException('failed to fetch active features', {
                fn: 'useMBFFeatures'
            });
        }
    }, [isError]);

    /**
     * If a variant is passed in the url, add it to the MBFFeatures object
     * This way you can test a feature even if it's not active in Salesforce
     * note: We should actually use SF sandbox for this instead of production ¯\_(ツ)_/¯
     */
    const [searchParams] = useSearchParams();
    const variant = searchParams.get('variant');

    const sortFeatures = useCallback(() => {
        if (MBFFeaturesData) {
            const cleanedMBFFeatures: Record<string, boolean | any> = {};
            Object.entries(MBFFeaturesData).forEach(([key, value]) => {
                // check if this feature is currently active
                const isActive = value.end && DateTime.fromISO(value.end) >= DateTime.now();
                // return boolean or jsonDetails
                cleanedMBFFeatures[key] =
                    isActive && value.jsonDetails ? JSON.parse(value.jsonDetails) : isActive;
            });

            if (variant) {
                return {
                    ...cleanedMBFFeatures,
                    ...{ [variant]: true }
                };
            } else {
                return cleanedMBFFeatures;
            }
        }
    }, [MBFFeaturesData]);

    return sortFeatures() || {};
};
