import React from 'react';
import { textSizes } from '@basic-fit/design-system';

type TextProps = {
    className?: string;
    children: React.ReactNode;
    size: TextSizes;
    paragraph?: boolean;
};

type TextSizes = 1 | 2 | 3;

export const InlineText = ({ className = '', children, size }: TextProps) => {
    return React.createElement(
        'p',
        {
            className: `${textSizes[size]} font-heading-double m-0 ${className}`
        },
        children
    );
};
