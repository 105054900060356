/**
 * NOTE!!! This is copied and pasted from Server Implementation
 *  See `src/services/error-message-map.ts` and `src/services/response.helpers.ts` in the Server Project
 */
export const CHANGE_MEMBER_ERROR_MESSAGE_MAP = {
    DETAILS_BODY_INVALID: 'DETAILS_BODY_INVALID',
    DETAILS_RATE_LIMIT_EXCEEDED: 'DETAILS_RATE_LIMIT_EXCEEDED',
    DETAILS_IBAN_INVALID: 'DETAILS_IBAN_INVALID',
    DETAILS_IBAN_DOES_NOT_MATCH: 'DETAILS_IBAN_DOES_NOT_MATCH',
    DETAILS_MEMBER_HAS_NO_IBAN: 'DETAILS_MEMBER_HAS_NO_IBAN',
    DETAILS_TOKEN_INVALID: 'DETAILS_TOKEN_INVALID',
    DETAILS_ENUM_FAILURE: 'DETAILS_ENUM_FAILURE',
    DETAILS_FAILED_TO_SEND_EMAIL: 'DETAILS_FAILED_TO_SEND_EMAIL',
    DETAILS_EMAIL_INVALID: 'DETAILS_EMAIL_INVALID',
    DETAILS_EMAIL_TOKEN_INVALID: 'DETAILS_EMAIL_TOKEN_INVALID',
    DETAILS_EMAIL_TOKEN_EXPIRED: 'DETAILS_EMAIL_TOKEN_EXPIRED',
    DETAILS_EMAIL_TOKEN_NOT_FOUND: 'DETAILS_EMAIL_TOKEN_NOT_FOUND',
    DETAILS_NO_CURRENT_MEMBER_FOUND: 'DETAILS_NO_CURRENT_MEMBER_FOUND',
    DETAILS_EMAIL_TOKEN_PASSED_INVALID: 'DETAILS_EMAIL_TOKEN_PASSED_INVALID',
    DETAILS_NO_EMAIL_TOKEN_FOUND: 'DETAILS_NO_EMAIL_TOKEN_FOUND',
    DETAILS_EMAIL_UPDATE_FAILED: 'DETAILS_EMAIL_UPDATE_FAILED',
    DETAILS_VALIDATE_IBAN_FAILED: 'DETAILS_VALIDATE_IBAN_FAILED',
    DETAILS_NEW_EMAIL_SET_INCORRECTLY: 'DETAILS_NEW_EMAIL_SET_INCORRECTLY',
    DETAILS_REACTIVATE_TO_MC_FAILED: 'DETAILS_REACTIVATE_TO_MC_FAILED',
    DETAILS_ACCOUNT_HOLDER_INVALID: 'DETAILS_ACCOUNT_HOLDER_INVALID',
    DETAIL_PHONE_NUMBER_INVALID: 'DETAIL_PHONE_NUMBER_INVALID',
    DETAIL_PHONE_NUMBER_FAILED: 'DETAIL_PHONE_NUMBER_FAILED',
    DETAILS_IBAN_UPDATE_FAILED: 'DETAILS_IBAN_UPDATE_FAILED',
    DETAILS_ADDRESS_UPDATE_FAILED: 'DETAILS_ADDRESS_UPDATE_FAILED',
    DETAILS_EMAIL_EXISTS: 'DETAILS_EMAIL_EXISTS',
    DETAILS_EMAIL_INITIAL_BOARDING_FAILED: 'DETAILS_EMAIL_INITIAL_BOARDING_FAILED',
    DETAILS_EMAIL_FINAL_BOARDING_FAILED: 'DETAILS_EMAIL_FINAL_BOARDING_FAILED',
    DETAILS_PASSWORD_INVALID: 'DETAILS_PASSWORD_INVALID',
    DETAILS_PASSWORD_NO_MATCH: 'DETAILS_PASSWORD_NO_MATCH',
    DETAILS_FAILED_TO_CHECK_PAST_MEMBERSHIPS: 'DETAILS_FAILED_TO_CHECK_PAST_MEMBERSHIPS',
    // NOTE: This is not part of the server implementation
    // It is fallback for unexpected errors (e.g. no response, timeout, network error, etc.)
    UNEXPECTED_ERROR: 'UNEXPECTED_ERROR'
} as const;

export const CHANGE_MEMBER_SUCCESS_MESSAGE_MAP = {
    DETAILS_IBAN_VALID: 'DETAILS_IBAN_VALID',
    DETAILS_EMAIL_TOKEN_SENT: 'DETAILS_EMAIL_TOKEN_SENT',
    DETAILS_EMAIL_UPDATE_SUCCESS: 'DETAILS_EMAIL_UPDATE_SUCCESS',
    DETAILS_IBAN_UPDATE_SUCCESS: 'DETAILS_IBAN_UPDATE_SUCCESS',
    DETAILS_ADDRESS_UPDATE_SUCCESS: 'DETAILS_ADDRESS_UPDATE_SUCCESS',
    DETAILS_PHONE_NUMBER_UPDATE_SUCCESS: 'DETAILS_PHONE_NUMBER_UPDATE_SUCCESS'
} as const;

export type CHANGE_MEMBER_ERROR_MESSAGE_MAP =
    (typeof CHANGE_MEMBER_ERROR_MESSAGE_MAP)[keyof typeof CHANGE_MEMBER_ERROR_MESSAGE_MAP];

export type CHANGE_MEMBER_SUCCESS_MESSAGE_MAP =
    (typeof CHANGE_MEMBER_SUCCESS_MESSAGE_MAP)[keyof typeof CHANGE_MEMBER_SUCCESS_MESSAGE_MAP];

export interface IResponseJson<T> {
    success: boolean;
    message: CHANGE_MEMBER_ERROR_MESSAGE_MAP | CHANGE_MEMBER_SUCCESS_MESSAGE_MAP;
    error: boolean;
    data?: T;
}

export function responseJsonStandard<T>({ success, message, error, data }: IResponseJson<T>) {
    return {
        success,
        message,
        error,
        data
    };
}
