import axios from 'axios';

import { domain, headers } from '.';

export const getFriendVisitLink = async (friendId: string) => {
    const { data } = await axios({
        url: `${domain}/friends/get-friend-visit-link/${friendId}`,
        headers
    });

    return data.link as string;
};
