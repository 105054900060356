import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { Table } from '@src/components/Table/Table';
import { useMember } from '@src/services/member/MemberProvider';

import { toLocal } from 'services/date-converter';

export default function MyInfoTable(): JSX.Element {
    const { state: member } = useMember();
    const { t } = useTranslation();

    const data = [
        [
            t('151'),
            <strong key="">
                {member.first_name + ' ' + member.middle_name + ' ' + member.last_name}
            </strong>
        ],
        [t('152'), <strong key="">{toLocal(member.birth_date)}</strong>],
        [
            t('153'),
            <strong key="">{member.mailing_street + ' ' + member.mailing_house_number}</strong>
        ],
        [t('154'), <strong key="">{member.poste_code}</strong>],
        [t('155'), <strong key="">{member.mailing_city}</strong>],
        [t('9'), <strong key="">{member.email}</strong>],
        [t('394'), <strong key="">{member.tel_home}</strong>]
    ];

    return (
        <div className="w-[100%]">
            <Box variant="mint-top">
                <Heading size={6} className="mb-[10px]">
                    {t('30')}
                </Heading>
                <Table data={data} style="white" />
            </Box>
        </div>
    );
}
