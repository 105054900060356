import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { Button } from '@src/components/Buttons/Button';
import { InlineText } from '@src/components/InlineText/InlineText';
import { Skeleton } from '@src/components/Skeletons/Skeleton';
import { DisableSecondaryMemberModal } from '@src/pages/Membership/Membership/components/Modals/DisableSecondaryMember.modal';
import { InviteSecondaryMemberModal } from '@src/pages/Membership/Membership/components/Modals/InviteSecondaryMember.modal';
import { useMember } from '@src/services/member/MemberProvider';
import { getSecondaryMemberName } from '@src/services/secondaryMember.service';
import { isEmpty } from '@src/utils/helpers/collections';
import { isAccessTypeQRCode } from '@src/utils/helpers/memberHelpers';
import { SecondaryMemberProps } from '@src/utils/hooks/api/types/secondaryMember';
import { useFetch } from '@src/utils/hooks/api/useFetch';

export const SecondaryMember = () => {
    const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
    const [isDisableModalOpen, setDisableModalOpen] = useState(false);

    const { state: mainMember } = useMember();
    const { t } = useTranslation();

    const { data: secondaryMember, isLoading } = useFetch<SecondaryMemberProps>(
        '/secondary-member/get-secondary-member'
    );

    const [hasSecondaryMember, setHasSecondaryMember] = useState(false);
    useEffect(() => {
        setHasSecondaryMember(!isEmpty(secondaryMember));
    }, [secondaryMember]);

    return (
        <Box size="small">
            <Heading size={6} className="mb-xxs">
                {t('secondary_member.section_heading')}
            </Heading>

            {isLoading ? (
                <Skeleton type="text" lines={3} />
            ) : (
                <>
                    <Text size={2}>{t('secondary_member.section_text')}</Text>

                    {hasSecondaryMember && (
                        <div className="bg-jersey-grey p-xs mt-xs">
                            <InlineText size={2}>
                                <div className="flex flex-col w-full justify-between md:flex-row">
                                    <div>{getSecondaryMemberName(secondaryMember)}</div>
                                    <div className="md:self-end">
                                        <a
                                            href={`mailto:${secondaryMember?.email || ''}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {secondaryMember?.email || ''}
                                        </a>
                                    </div>
                                </div>
                            </InlineText>
                        </div>
                    )}

                    {!isAccessTypeQRCode(mainMember) && !hasSecondaryMember && (
                        <Text size={2} className="mt-xs">
                            {t('secondary_member.qr_code_required_to_invite')}
                        </Text>
                    )}

                    {isAccessTypeQRCode(mainMember) && !hasSecondaryMember && (
                        <Button
                            color="orange"
                            icon="chevron-right"
                            text={t('secondary_member.invite_button_text')}
                            onClick={() => setIsInviteModalOpen(true)}
                            className="mt-xs"
                        />
                    )}

                    {hasSecondaryMember && (
                        <Button
                            icon="chevron-right"
                            text={t('secondary_member.disable_button_text')}
                            onClick={() => setDisableModalOpen(true)}
                            className="mt-xs"
                        />
                    )}
                </>
            )}

            {!hasSecondaryMember && (
                <InviteSecondaryMemberModal
                    isOpen={isInviteModalOpen}
                    onClose={() => setIsInviteModalOpen(false)}
                />
            )}

            {hasSecondaryMember && (
                <DisableSecondaryMemberModal
                    isOpen={isDisableModalOpen}
                    onClose={() => setDisableModalOpen(false)}
                    secondaryMember={secondaryMember}
                />
            )}
        </Box>
    );
};
