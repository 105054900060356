import React, { useEffect } from 'react';
import { NoteDocument } from '@src/services/pdf/NoteDocument';
import { useFetch } from '@src/utils/hooks/api/useFetch';

export const Note = () => {
    const { data, isLoading } = useFetch<any>('/payments/provide-note');
    const { member, debits } = data || {};

    useEffect(() => {
        document.body.classList.add('payment-note');
    }, [data]);

    if (isLoading) return null;

    return <NoteDocument member={member} debits={debits} />;
};
