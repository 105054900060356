import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MultiValue } from 'react-select';
import { SingleValue } from 'react-select/dist/declarations/src/types';
import { Heading, Text } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { Button } from '@src/components/Buttons/Button';
import { CheckBox } from '@src/components/Form/Checkbox/Checkbox';
import { SearchOption } from '@src/components/Form/SearchElement/SearchComponents';
import { SearchElement } from '@src/components/Form/SearchElement/SearchElement';
import { Header } from '@src/components/Header/Header';
import { Section } from '@src/components/Layout/Section';
import { LineSVG } from '@src/components/LineSVG/LineSVG';
import { useMember } from '@src/services/member/MemberProvider';
import { changeClubIsAllowed } from '@src/utils/helpers/memberHelpers';
import { Club } from '@src/utils/hooks/api/types/club';
import { useFetch } from '@src/utils/hooks/api/useFetch';
import { useAwaitSpinner } from '@src/utils/hooks/useAwaitSpinner';
import { mutate } from 'swr';

import { getCountryForApiRequest } from '../../../../../../parameters/languages';
import updateMemberInfo from '../../../../../../services/API/update-member-info';

type FormData = {
    confirmChange: boolean;
    homeClub: SearchOption;
};

export const ChangeHomeClub = () => {
    const { t } = useTranslation();
    const { state: member } = useMember();
    const navigate = useNavigate();

    useEffect(() => {
        if (!changeClubIsAllowed(member.clubChangePossibleFrom)) {
            navigate('/overview');
        }
    }, []);

    /**
     * Fetch clubs for member's country
     */
    const { data, isLoading, isError } = useFetch<Club[]>(
        `/door-policy/get-clubs-by-country?country=${getCountryForApiRequest(member)}`
    );

    const [clubs, setClubs] = useState<MultiValue<SearchOption> | undefined>();

    useEffect(() => {
        if (!isLoading && !isError) {
            const searchData: MultiValue<SearchOption> = data
                .filter(({ id }) => id !== member.home_club_id)
                .map((item) => ({
                    label: item.name,
                    value: item.id
                }));
            setClubs(searchData);
        }
    }, [data]);

    /**
     * Create custom form because we have a react-select inside the form
     * We need to register and update the value for react-hook-form validation
     */
    const { awaiting: isSubmitting, awaitSpin } = useAwaitSpinner();
    const [disabled, setDisabled] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const form = useForm<FormData>();
    const {
        register,
        trigger,
        formState: { errors },
        handleSubmit,
        setValue
    } = form;

    register('homeClub', { required: true });

    const onSubmit = async (data: { confirmChange: boolean; homeClub: SearchOption }) => {
        try {
            setDisabled(true);
            await awaitSpin(async () => {
                await updateMemberInfo({
                    peopleClubId: data.homeClub.value,
                    peopleClubName: data.homeClub.label
                });
                await mutate('/member/get-member');
            });
            setSuccess(true);
        } catch (e) {
            setError(true);
            setDisabled(false);
            console.log('Error updating member info: ', e);
        }
    };

    return (
        <>
            <Header
                className="overflow-x-hidden"
                title={t('change_home_club.title')}
                text={t('change_home_club.header_text')}
                image={
                    <LineSVG
                        id="whip-mint-10"
                        height={200}
                        className="!w-auto relative right-[-60px] top-[10px] hidden md:block"
                    />
                }
            />
            <Section>
                <Box variant="mint-top">
                    <Heading size={5}>{t('change_home_club.title')}</Heading>
                    <Text size={2} className="mt-xs">
                        {t('change_home_club.explanation')}
                    </Text>

                    <Heading size={7} className="font-bold normal-case mt-xs">
                        {t('change_home_club.current_club')}
                    </Heading>
                    <Text size={2}>{member.home_club || t('change_home_club.not_found')}</Text>

                    <Heading size={7} className="font-bold normal-case mt-xs">
                        {t('change_home_club.choose_new_club')}
                    </Heading>
                    <Text size={2}>{t('change_home_club.choose_explanation')}</Text>

                    <FormProvider {...form}>
                        <form
                            onSubmit={handleSubmit(async (data) => await onSubmit(data))}
                            name="change-home-club"
                        >
                            <SearchElement
                                name="homeClub"
                                className="mt-m mb-xxs"
                                options={clubs}
                                menuPosition={'fixed'}
                                placeholder={t('change_home_club.search_placeholder')}
                                isLoading={isLoading}
                                onChange={(option: SingleValue<SearchOption>) => {
                                    if (option) {
                                        setValue('homeClub', option);
                                        void trigger('homeClub');
                                    }
                                }}
                            />

                            {errors.homeClub && (
                                <span className="text-regular-s text-red">
                                    {t('change_home_club.error_select_club')}
                                </span>
                            )}

                            <CheckBox
                                required={true}
                                name="confirmChange"
                                label={t('change_home_club.checkbox_agree')}
                                className={errors.confirmChange ? `mt-xs text-red` : 'mt-xs'}
                            />
                            <Button
                                type="submit"
                                color="purple"
                                className="mt-xs"
                                loading={isSubmitting}
                                disabled={success ? true : disabled}
                                text={success ? t('242') : t('change_home_club.title')}
                                icon={success ? 'tick' : ''}
                            />

                            {error && (
                                <Text size={2} className="text-orange mt-xs">{`${t('238')} ${t(
                                    '257'
                                )}`}</Text>
                            )}
                        </form>
                    </FormProvider>

                    <Button
                        type="button"
                        color="naked"
                        className="mt-xs !w-auto m-auto"
                        text={t('change_home_club.button_back')}
                        onClick={() => navigate('/membership')}
                    />
                </Box>
            </Section>
        </>
    );
};
