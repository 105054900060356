import axios from 'axios';

import { domain, headers } from '../get-api-elements';

export default async (entries) => {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            data: entries,
            url: `${domain}/member/update-member-info`,
            headers,
            json: true
        };

        axios(options)
            .then((res) => {
                const updatedMember = res.data;
                delete updatedMember.password;
                delete updatedMember.people_pass_s;
                delete updatedMember.password_reset_key;
                delete updatedMember.next_login_attempt;

                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
