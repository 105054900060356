import React from 'react';

export default function ArrowIcon() {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M13.4421 6.00781V13.3628H6.05151" stroke="currentColor" strokeWidth="2" />
            <path d="M13.1418 13.0641L4.69531 4.6582" stroke="currentColor" strokeWidth="2" />
        </svg>
    );
}
