import { domain, headers } from '@src/services/data';
import { captureException } from '@src/utils/helpers/newRelic';
import axios from 'axios';
import { actions, createMachine } from 'xstate';

import { CHANGE_MEMBER_ERROR_MESSAGE_MAP } from 'types/change-member-response';

export const updateMemberEmailMachine = createMachine({
    id: 'updateMemberEmail',
    preserveActionOrder: true,
    predictableActionArguments: true,
    context: {
        email: '',
        emailToken: '',
        emailTokenType: ''
    },
    initial: 'active',
    states: {
        active: {
            invoke: {
                src: async function updateMemberEmail(ctx) {
                    const { data } = await axios({
                        method: 'POST',
                        url: `${domain}/member/update-member-email`,
                        headers,
                        data: {
                            email: ctx.email,
                            emailToken: ctx.emailToken,
                            emailTokenType: ctx.emailTokenType
                        }
                    });

                    if (!data.success) {
                        captureException({
                            message: data.message ?? 'n/a',
                            name: 'updateMemberEmail'
                        });
                        throw new Error();
                    }
                },
                onError: {
                    description:
                        'handle errors from sendVerificationToken request and escalate to parent',
                    actions: actions.escalate((_, ev) => {
                        if (!axios.isAxiosError(ev.data)) {
                            captureException('failed to complete request', {
                                fn: 'updateMemberEmail'
                            });
                            return {
                                message: CHANGE_MEMBER_ERROR_MESSAGE_MAP.UNEXPECTED_ERROR
                            };
                        }

                        const message = ev.data.response?.data?.message;
                        if (message in CHANGE_MEMBER_ERROR_MESSAGE_MAP) {
                            captureException(message, {
                                fn: 'updateMemberEmail'
                            });
                            return { message };
                        }

                        captureException('unexpected response', {
                            fn: 'updateMemberEmail'
                        });
                        return { message: CHANGE_MEMBER_ERROR_MESSAGE_MAP.UNEXPECTED_ERROR };
                    })
                },
                onDone: { target: 'success' }
            }
        },
        success: { type: 'final' }
    }
});
