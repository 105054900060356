import React from 'react';

export default function InfoIcon() {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8 0C3.58 0 0 3.58 0 8C0 12.42 3.58 16 8 16C12.42 16 16 12.42 16 8C16 3.58 12.42 0 8 0ZM6.64667 10.6733C7.02 9.48 7.73333 8.05333 7.86 7.69333C8.04 7.16667 7.72 6.93333 6.7 7.83333L6.47333 7.4C7.63333 6.13333 10.0333 5.84667 9.21333 7.80667C8.70667 9.03333 8.34 9.85333 8.13333 10.4933C7.83333 11.42 8.59333 11.0467 9.34667 10.3533C9.44667 10.52 9.48 10.5733 9.58667 10.7667C7.92 12.3533 6.06667 12.4933 6.64667 10.6733ZM9.80667 5.22667C9.45333 5.52667 8.92667 5.52 8.63333 5.21333C8.34 4.9 8.38667 4.40667 8.74 4.10667C9.09333 3.80667 9.62 3.81333 9.91333 4.12C10.2067 4.43333 10.16 4.92667 9.80667 5.22667Z"
                fill="currentColor"
            />
        </svg>
    );
}
