import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { Modal } from '@src/components/Modals/Modal';
import { StockImage } from '@src/components/StockImage/StockImage';
import { StrokeText } from '@src/components/StrokeText/StrokeText';
import { sessionCacheKey } from '@src/pages/ChangeMembership/ChangeMembershipFlow.types';
import {
    getFromSessionCache,
    setToSessionCache
} from '@src/services/utilities/sessionCache.service';
import { collectFunnelData } from '@src/utils/helpers/newRelic';
import { DateTime } from 'luxon';

export const RenewMembershipModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname !== '/membership/renewed') return;

        const sessionData = JSON.parse(getFromSessionCache(sessionCacheKey) || '{}');

        if (
            !sessionData.modalShown ||
            DateTime.fromISO(sessionData.modalShown).diffNow('seconds').seconds < -10
        ) {
            setToSessionCache(sessionCacheKey, { membershipIsChanged: true });
        }
    }, [location]);

    /**
     * When membership has changed, revalidate member data and show modal
     */
    useEffect(() => {
        if (location.pathname === '/membership/renewed') {
            const { membershipIsChanged = false } = JSON.parse(
                getFromSessionCache(sessionCacheKey) || '{}'
            );

            if (membershipIsChanged) {
                setIsOpen(true);
            }
        }
    }, [location.pathname]);

    const onConfirm = (event: 'close' | 'submit') => {
        collectFunnelData({
            event,
            modal: 'renewed-membership'
        });

        setIsOpen(false);
        setToSessionCache(sessionCacheKey, { modalShown: new Date().toISOString() });
    };

    return (
        <>
            {isOpen ? (
                <Modal isOpen={isOpen} onModalClose={() => onConfirm('close')}>
                    <StrokeText
                        stroke="last"
                        color="anthracite"
                        className="text-anthracite mb-4"
                        text={t('renewal.success.title')}
                    />
                    <div className="flex justify-center">
                        <StockImage id="renewed" />
                    </div>
                    <Text size={2} className="mb-m">
                        {t('renewal.success.body')}
                    </Text>
                    <Button
                        className="w-[50%] mx-auto min-w-[200px]"
                        text={t('membership.change.overview.modalButton')}
                        onClick={() => onConfirm('submit')}
                    />
                </Modal>
            ) : null}
        </>
    );
};
