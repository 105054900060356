import React from 'react';
import { StepItem } from '@src/components/Steps/StepItem';
import { Steps } from '@src/components/Steps/Steps';

interface StepsNavProps {
    items: string[];
    index: number;
}

export const StepsNav = ({ items, index }: StepsNavProps) => {
    return (
        <div className={`px-xs pt-xs sm:p-0`}>
            <Steps activeId={index}>
                {items.map((text, index) => (
                    <StepItem key={`steps-nav-item-${index}`} text={text} />
                ))}
            </Steps>
        </div>
    );
};
