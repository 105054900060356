import React, { useEffect, useState } from 'react';
import { Text } from '@basic-fit/design-system';

import LoaderImage from './images/loader.png';

type LoaderProps = {
    className?: string;
    text?: string;
    delay?: boolean;
};

export const Loader = ({ className = '', text, delay = false }: LoaderProps) => {
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        if (!delay) {
            setShowLoader(true);
            return;
        }

        const showLoaderTimer = setTimeout(() => {
            setShowLoader(true);
        }, 400);

        return () => {
            clearTimeout(showLoaderTimer);
        };
    }, []);

    return (
        <div
            className={`
                transition-opacity duration-300
                ${showLoader ? 'opacity-[1]' : 'opacity-[0]'}
                ${className}
            `}
        >
            <img width="200px" src={LoaderImage} alt="Loader" />
            <Text size={2} className="text-center">
                {text}
            </Text>
        </div>
    );
};
