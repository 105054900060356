import React from 'react';

export default function HamburgerIcon() {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M2 5H22M2 11.5H22M2 18H22" stroke="#313136" strokeWidth="2" />
        </svg>
    );
}
