import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Heading, Text } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { Button } from '@src/components/Buttons/Button';
import { Error } from '@src/components/Form/Error/Error.component';
import { Section } from '@src/components/Layout/Section';
import { Wrapper } from '@src/components/Layout/Wrapper';
import { Skeleton } from '@src/components/Skeletons/Skeleton';
import { useMember } from '@src/services/member/MemberProvider';
import { isMobile } from '@src/utils/helpers/device';
import { useFetch } from '@src/utils/hooks/api/useFetch';

import { FriendDataProps, FriendsDataProps } from 'types/friends.types';
import { UserFeedbackForm } from '../UserFeedbackForm/FeedbackForm.friends';
import { AddFriendModal } from './AddFriend.modal';
import { InviteFriendModal } from './InviteFriend.modal';

export const YourFriendList = (): JSX.Element => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { state: member } = useMember();
    const { isEmployee, is_friends_allowed } =
        member;

    const {
        data: friends,
        isError: isErrorFriends,
        isLoading: isLoadingFriends
    } = useFetch<FriendsDataProps>(is_friends_allowed ? '/friends/get-friends' : null);

    const [friendsInView, setFriendsInView] = useState<FriendsDataProps>([]);
    const [addFriend, setAddFriend] = useState(false);
    const [activeFriend, setActiveFriend] = useState<FriendDataProps>();
    const hasMaxFriends = friendsInView.length > 49; // limit in GymManager

    useEffect(() => {
        if (friends) {
            setFriendsInView(friends);
        }
    }, [friends]);

    const friendList = (
        <>
            <Heading size={5} className="mb-xs">
                {t('213')}
            </Heading>

            {isLoadingFriends && is_friends_allowed ? (
                <>
                    <Text size={1} className="block">
                        <Skeleton type="text" lines={2.5} />
                    </Text>
                    <div className="mt-xxs md:mt-xs space-y-[2px]">
                        <Skeleton type="button" />
                        <Skeleton type="button" />
                        <Skeleton type="button" />
                        <Skeleton type="button" />
                    </div>
                </>
            ) : (
                <>
                    {/* == ERROR == */}
                    {isErrorFriends && <Error error={t('1527')} />}

                    {/* == FRIENDS NOT ALLOWED == */}
                    {!friendsInView && !is_friends_allowed && (
                        <>
                            {isEmployee ? (
                                <>
                                    {/* employees can't use friends */}
                                    <Text size={1} className="block">
                                        {t('498')}
                                    </Text>
                                </>
                            ) : (
                                <div>
                                    {/* upgrade and enjoy */}
                                    <Text size={1} className="block">
                                        {t('375')}
                                    </Text>

                                    {/* upgrade now */}
                                    <div className="flex justify-center">
                                        <Button
                                            className="uppercase mt-[10px]"
                                            onClick={() => navigate('/change-membership')}
                                            text={t('376')}
                                        />
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    {/* == FRIENDS NOT ALLOWED - END == */}

                    {/* == FRIENDS ALLOWED GREEN FRIENDS LIST == */}

                    {/* friend list  */}
                    {friendsInView && (
                        <>
                            {/* you didn't invite anybody */}
                            {!friendsInView.length && (
                                <Text size={1} className="w-full">
                                    {t('212')}
                                </Text>
                            )}

                            <div className="mt-xxs md:mt-xs grid gap-0.5 grid-cols-1">
                                {friendsInView.map((friend: any, index: number) => (
                                    <Button
                                        data-cy="friends-list"
                                        key={index}
                                        className="!text-orange"
                                        onClick={() => setActiveFriend(friend)}
                                        icon="chevron-right"
                                        color="grey"
                                        text={
                                            <span className="text-anthracite">{friend.name_g}</span>
                                        }
                                    />
                                ))}

                                {/* add friend */}
                                {hasMaxFriends && (
                                    <Text size={2} className="block my-xs">
                                        {t('friends.maxFriends')}
                                    </Text>
                                )}

                                <Button
                                    data-cy="add-friend"
                                    onClick={() => setAddFriend(true)}
                                    text={t('197')}
                                    icon="plus"
                                    disabled={hasMaxFriends}
                                />
                            </div>
                        </>
                    )}
                </>
            )}
            {/* == FRIENDS ALLOWED GREEN FREINDS LIST - END == */}
        </>
    );

    return (
        <>
            <UserFeedbackForm />

            <Section className="relative z-0">
                {!isMobile() ? (
                    <Box variant="mint-top">{friendList}</Box>
                ) : (
                    <Wrapper>{friendList}</Wrapper>
                )}

                {activeFriend && friendsInView.length > 0 && (
                    <InviteFriendModal
                        activeFriend={activeFriend}
                        setActiveFriend={setActiveFriend}
                        onFriendDeleted={() =>
                            setFriendsInView(
                                friendsInView.filter(({ id_g }) => id_g !== activeFriend.id_g)
                            )
                        }
                    />
                )}

                {addFriend && (
                    <AddFriendModal
                        addFriend={addFriend}
                        setAddFriend={setAddFriend}
                    />
                )}
            </Section>
        </>
    );
};
