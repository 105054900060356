export const getParam = (param: string): string | null => {
    const params = new URLSearchParams(location.search);
    return params.get(param);
};

export const setParam = (name: string, value: string): void => {
    const { origin, pathname, search, hash } = location;
    const params = new URLSearchParams(search);
    params.set(name, value);

    // set params to url without reloading the page
    history.replaceState(null, document.title, `${origin + pathname}?${params.toString() + hash}`);
};

export const removeParam = (name: string): void => {
    const { origin, pathname, search, hash } = location;
    const params = new URLSearchParams(search);
    params.delete(name);
    // set params to url without reloading the page
    history.replaceState(
        null,
        document.title,
        `${
            origin +
            pathname +
            (Array.from(params.entries()).length ? '?' + params.toString() + hash : hash)
        }`
    );
};
