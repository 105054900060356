import React from 'react';
import { lgStyles, mdStyles, styles, xlStyles } from 'parameters/css-parameters';
import styled from 'styled-components';

import { ReactComponent as LinkIcon } from 'components/img/rebrand/link.svg';

const StyledButton = styled.button`
  font-family: var(--font-heading-pro-treble);
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  border: none;
  text-transform: uppercase;
  padding: 14px 24px;
  ${({ fontSize, width, color, backgroundColor, disabled }) => `
    ${fontSize ? `font-size: ${fontSize}` : `font-size: 16px`};
    ${width ? `width: ${width}` : `min-width: 300px`};
    ${color ? `color: ${color}` : `color: var(--color-white)`};
    ${
        !disabled
            ? backgroundColor
                ? `background: ${backgroundColor}`
                : `background: var(--color-primary)`
            : ''
    };
    ${disabled ? `background: var(--color-anthracite-74)` : ''};
  `};

  &:hover {
    ${({ backgroundHoverColor, disabled }) => `
      ${
          !disabled
              ? backgroundHoverColor
                  ? `background: ${backgroundHoverColor}`
                  : `background: var(--color-primary-hover)`
              : ''
      };
    `};
  }

  ${styles}
  ${mdStyles}
  ${lgStyles}
  ${xlStyles}
`;

const StyledLink = styled.a`
  font-family: var(--font-heading-pro-double)
  font-weight: 400;
  color: var(--color-anthracite);
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  ${({ fontSize, color }) => `
    ${fontSize ? `font-size: ${fontSize}` : `font-size: 14px`};
    ${color ? `color: ${color}` : ''};
  `};

  &:before {
    display: block;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--color-orange);
    opacity: 0;
    transition: opacity var(--animation-time), transform var(--animation-time);
    transform: translate3d(-100%,0,0);
  }

  &:hover {
    &:before {
      opacity: 1;
      transform: translateZ(0);
    }
  }

  ${styles}
  ${mdStyles}
  ${lgStyles}
  ${xlStyles}
`;

const LinkContent = styled.span`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.span`
  margin-left: 8px;
  ${({ iconStyles }) => `
    ${iconStyles ? iconStyles : ''}
  `};
`;

const Link = ({ children, icon, ...rest }) => (
    <StyledLink {...rest}>
        <LinkContent>
            <span>{children}</span>
            {icon && <IconWrapper {...rest}>{icon}</IconWrapper>}
        </LinkContent>
    </StyledLink>
);

const Button = ({
    type = 'button',
    children,
    className = '',
    icon = <LinkIcon />,
    disabled,
    ...rest
}) => {
    return type === 'link' ? (
        <Link {...rest} icon={icon} className={`mbf-button ${className}`}>
            {children}
        </Link>
    ) : (
        <StyledButton {...rest} disabled={disabled} className={`mbf-button ${className}`}>
            {children}
        </StyledButton>
    );
};

export default Button;
