import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Heading, Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { StrokeText } from '@src/components/StrokeText/StrokeText';

interface IReverseCancellationErrorModalProps {
    hideCustomerSupportButton?: boolean;
    body?: string | ReactNode;
}

export const ModalErrorState = ({
    hideCustomerSupportButton = false,
    body
}: IReverseCancellationErrorModalProps) => {
    const { t } = useTranslation();

    const bodyText = body && (typeof body === 'string' ? <Text size={2}>{body}</Text> : body);
    return (
        <>
            <Heading size={4} className="mb-s">
                <StrokeText
                    className="text-anthracite"
                    stroke="last"
                    color="anthracite"
                    text={t('somethingWentWrong')}
                />
            </Heading>
            {bodyText}
            {!hideCustomerSupportButton && (
                <Link to={t('customer.support.url')}>
                    <Button
                        text={t('membership.cancellation.contact_support')}
                        theme="outline"
                        className="mt-xxs"
                    />
                </Link>
            )}
        </>
    );
};
