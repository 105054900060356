window.dataLayer = window.dataLayer || [];

export default {
    menuClick(label) {
        window.dataLayer.push({
            eventCategory: 'my basic-fit || main menu',
            eventAction: 'button click',
            eventLabel: label,
            eventValue: undefined,
            eventNonInteraction: false,
            event: 'eventPush'
        });
    },

    clubsInTheNeighbourhood() {
        window.dataLayer.push({
            eventCategory: 'my basic-fit',
            eventAction: 'button click',
            eventLabel: 'clubs in your neighborhood',
            eventValue: undefined,
            eventNonInteraction: false,
            event: 'eventPush'
        });
    },

    howToChangeMembershipClick() {
        window.dataLayer.push({
            eventCategory: 'my basic-fit',
            eventAction: 'button click',
            eventLabel: 'how do i change my membership?',
            eventValue: undefined,
            eventNonInteraction: false,
            event: 'eventPush'
        });
    },

    logo() {
        window.dataLayer.push({
            eventCategory: 'my basic-fit || main menu',
            eventAction: 'button click',
            eventLabel: 'logo',
            eventValue: undefined,
            eventNonInteraction: false,
            event: 'eventPush'
        });
    },

    question(faq) {
        window.dataLayer.push({
            eventCategory: 'my basic-fit || faq',
            eventAction: 'button click',
            eventLabel: faq, //note: ‘use english translation’
            eventValue: undefined,
            eventNonInteraction: false,
            event: 'eventPush'
        });
    },

    askRuby() {
        window.dataLayer.push({
            eventCategory: 'my basic-fit || faq',
            eventAction: 'button click',
            eventLabel: 'ask ruby a question',
            eventValue: undefined,
            eventNonInteraction: false,
            event: 'eventPush'
        });
    },

    addFriendLinkCopy() {
        window.dataLayer.push({
            eventCategory: 'my basic-fit',
            eventAction: 'button click',
            eventLabel: 'add friend to your list',
            eventValue: undefined,
            eventNonInteraction: false,
            event: 'eventPush'
        });
    },

    gxr() {
        window.dataLayer.push({
            eventCategory: 'my basic-fit',
            eventAction: 'button click',
            eventLabel: 'to GXR',
            eventValue: undefined,
            eventNonInteraction: false,
            event: 'eventPush'
        });
    },

    advantage() {
        window.dataLayer.push({
            eventCategory: 'my basic-fit',
            eventAction: 'button click',
            eventLabel: 'to member advantages',
            eventValue: undefined,
            eventNonInteraction: false,
            event: 'eventPush'
        });
    },

    changePaymentInfo() {
        window.dataLayer.push({
            eventCategory: 'my basic-fit',
            eventAction: 'button click',
            eventLabel: 'changing payment data',
            eventValue: undefined,
            eventNonInteraction: false,
            event: 'eventPush'
        });
    },

    cancelMembership(action, label) {
        window.dataLayer.push({
            eventCategory: 'my basic-fit || cancel membership',
            eventAction: action || 'click',
            eventLabel: label || '',
            eventValue: undefined,
            eventNonInteraction: false,
            event: 'eventPush'
        });
    },

    sendMessageToClientService() {
        window.dataLayer.push({
            eventCategory: 'my basic-fit || member interaction',
            eventAction: 'send message',
            eventLabel: undefined,
            eventValue: undefined,
            eventNonInteraction: false,
            event: 'eventPush'
        });
    },

    changeMemberInfo() {
        window.dataLayer.push({
            eventCategory: 'my basic-fit || member interaction',
            eventAction: 'changing personal details',
            eventLabel: undefined,
            eventValue: undefined,
            eventNonInteraction: false,
            event: 'eventPush'
        });
    },

    buttonClick(buttonName) {
        window.dataLayer.push({
            eventCategory: 'my basic-fit',
            eventAction: 'button click',
            eventLabel: buttonName,
            eventValue: undefined,
            eventNonInteraction: false,
            event: 'eventPush'
        });
    },

    addAddOn(addOn) {
        window.dataLayer.push({
            eventCategory: 'my basic-fit || member interaction',
            eventAction: 'add add-on',
            eventLabel: addOn,
            eventValue: undefined,
            eventNonInteraction: false,
            event: 'eventPush'
        });
    },

    cancelAddOn(addOn) {
        window.dataLayer.push({
            eventCategory: 'my basic-fit || member interaction',
            eventAction: 'cancel add-on',
            eventLabel: addOn,
            eventValue: undefined,
            eventNonInteraction: false,
            event: 'eventPush'
        });
    },

    advantageClicked(eventLabel, eventAction) {
        window.dataLayer.push({
            eventCategory: 'my basic-fit || member interaction',
            eventAction: `product advantage campaign ${eventAction ? '|| ' + eventAction : ''}`,
            eventLabel: eventLabel,
            eventValue: undefined,
            eventNonInteraction: false,
            event: 'eventPush'
        });
    },
    allInStep1Clicked() {
        window.dataLayer.push({
            eventCategory: 'my basic-fit || member interaction',
            eventAction: 'All in membership',
            eventLabel: 'STEP 1|| personal details',
            eventValue: undefined,
            eventNonInteraction: false,
            event: 'eventPush'
        });
    },
    allInStep2Clicked() {
        window.dataLayer.push({
            eventCategory: 'my basic-fit || member interaction',
            eventAction: 'All in membership',
            eventLabel: 'STEP 2|| payment method',
            eventValue: undefined,
            eventNonInteraction: false,
            event: 'eventPush'
        });
    },
    allInDeliveryOrPickupSelected(deliveryOrPickup) {
        window.dataLayer.push({
            eventCategory: 'my basic-fit || member interaction',
            eventAction: 'All in membership',
            eventLabel: `checkout option || ${deliveryOrPickup}`,
            eventValue: undefined,
            eventNonInteraction: false,
            event: 'eventPush'
        });
    },
    allInSinglePaymentSelected() {
        window.dataLayer.push({
            eventCategory: 'my basic-fit || member interaction',
            eventAction: 'All in membership',
            eventLabel: 'checkout option || single payment',
            eventValue: undefined,
            eventNonInteraction: false,
            event: 'eventPush'
        });
    },
    allInPer4WeeksPaymentSelected() {
        window.dataLayer.push({
            eventCategory: 'my basic-fit || member interaction',
            eventAction: 'All in membership',
            eventLabel: 'checkout option || per 4 weeks',
            eventValue: undefined,
            eventNonInteraction: false,
            event: 'eventPush'
        });
    },
    allInStep3() {
        window.dataLayer.push({
            eventCategory: 'my basic-fit || member interaction',
            eventAction: 'All in membership',
            eventLabel: 'STEP 3|| overview',
            eventValue: undefined,
            eventNonInteraction: false,
            event: 'eventPush'
        });
    },
    allInConfirmation(amountToPay) {
        window.dataLayer.push({
            eventCategory: 'my basic-fit || member interaction',
            eventAction: 'All in membership',
            eventLabel: 'STEP 4|| Confirmation',
            eventValue: amountToPay,
            eventNonInteraction: false,
            event: 'eventPush'
        });
    },
    allInSetPaymentMethod(input) {
        window.dataLayer.push({
            eventCategory: 'my basic-fit || member interaction',
            eventAction: 'All in membership',
            eventLabel: `checkout option || ${input}`,
            eventValue: undefined,
            eventNonInteraction: false,
            event: 'eventPush'
        });
    },
    proofOfPaymentDownload() {
        window.dataLayer.push({
            event: 'Proof of Payment download'
        });
    },
    proofOfPaymentError(message) {
        window.dataLayer.push({
            event: 'Proof of Payment Error',
            Message: message
        });
    },
    noteDownload() {
        window.dataLayer.push({
            event: 'Note download'
        });
    },
    noteDownloadError(message) {
        window.dataLayer.push({
            event: 'Note download Error',
            Message: message
        });
    },
    viewPageEvent(pageName) {
        window.dataLayer.push({
            event: 'custom_page_view',
            page_name: pageName
        });
    },
    clickEvent(clickType) {
        window.dataLayer.push({
            event: 'custom_click',
            click_type: clickType
        });
    },
    errorEvent(errorState) {
        window.dataLayer.push({
            event: 'custom_error',
            error_state: errorState
        });
    }
};
