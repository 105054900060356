import { LogTransaction } from '@src/utils/hooks/api/types/transactions';
import { DateTime } from 'luxon';

import { MemberDataProps } from '../../../types/member.types';

/**
 * Apply business rules to get month/year for Proof of Payment
 */
export const getPaymentDates = (
    member: MemberDataProps,
    transactions: LogTransaction[]
): LogTransaction[] => {
    const currentMonth = DateTime.fromJSDate(new Date()).startOf('month');
    const csdate = DateTime.fromISO(member.start_date_g);
    const startMonth = csdate.startOf('month');
    const endMonth = csdate.endOf('month');

    const hasFirstMonthPayment =
        transactions
            .filter((t) => {
                const date = DateTime.fromISO(t.date);
                return date >= startMonth && date <= endMonth;
            })
            .filter((t) => t.code === 'ab').length > 0;

    return (
        transactions
            // only show previous months
            .filter((t) => DateTime.fromISO(t.date) < currentMonth)
            // hide the first contract month, the payment will be shown in the upcoming month
            .filter((t) => {
                const date = DateTime.fromISO(t.date);
                if (date >= startMonth && date <= endMonth) {
                    return hasFirstMonthPayment;
                }
                return true;
            })
    );
};
/**
 * Format Payment Amounts in a correct way for Payments Gateway (adyen) to understand (in cents)
 * @param {number} amount - Amount to pay in format of xxx.xx
 * @returns {number} in format of xxxxx (in cents)
 *
 */
export const formatAmountForPayment = (amount: number): number => {
    return Math.abs(amount) * 100;
};
