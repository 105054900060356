import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { NODE_ENV, REDUX_DEV_TOOLS } from 'refactor/services/utilities';

import { reducers } from 'store/reducers';

export const store = configureStore({
    reducer: reducers,
    devTools: REDUX_DEV_TOOLS || NODE_ENV !== 'production',
    middleware: getDefaultMiddleware({
        serializableCheck: false
    })
});
