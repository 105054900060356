import axios from 'axios';

import { domain, headers } from '../get-api-elements';

export default (cents) => {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET',
            url: `${domain}/payments/get-adyen-payment-link/${cents}`,
            headers
        };

        axios(options)
            .then((res) => {
                if (!res.data.domain || !res.data.reference || !res.data.signature) {
                    // validate on link
                    return reject(res.data);
                }
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
