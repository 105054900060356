import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { Modal } from '@src/components/Modals/Modal';
import { getSecondaryMemberName } from '@src/services/secondaryMember.service';
import { SecondaryMemberProps } from '@src/utils/hooks/api/types/secondaryMember';
import { usePostHandler } from '@src/utils/hooks/api/usePostHandler';
import { useAwaitSpinner } from '@src/utils/hooks/useAwaitSpinner';
import { useSWRConfig } from 'swr';

type ModalProps = {
    isOpen: boolean;
    onClose: () => void;
    secondaryMember: SecondaryMemberProps;
};

export const DisableSecondaryMemberModal = ({ isOpen, onClose, secondaryMember }: ModalProps) => {
    const { awaiting: isLoading, awaitSpin } = useAwaitSpinner();
    const [disabled, setDisabled] = useState(false);
    const [success, setSuccess] = useState(false);
    const [apiError, setApiError] = useState(false);

    const { t } = useTranslation();
    const { mutate } = useSWRConfig();
    const { submit } = usePostHandler();
    const { peopleMembershipId } = secondaryMember?.membership || {};

    const handleModalClose = () => {
        setDisabled(false);
        setSuccess(false);
        setApiError(false);
        onClose?.();
    };

    const disableSecondaryMember = async () => {
        setDisabled(true);

        const response = await awaitSpin(() =>
            submit('/secondary-member/disable-secondary-member', {
                peopleMembershipId
            })
        );

        setDisabled(!response.error);
        setSuccess(!response.error);
        setApiError(response.error);

        // re-fetch secondary member
        if (!response.error) {
            await mutate('/secondary-member/get-secondary-member', null);
        }
    };

    return (
        <Modal isOpen={isOpen} onModalClose={handleModalClose}>
            <Heading size={6} className="mb-s">
                {t('secondary_member.disable_modal_heading')}
            </Heading>
            <Text size={2} className="my-xs">
                {t('secondary_member.disable_modal_text').replace(
                    '{secondary_member}',
                    getSecondaryMemberName(secondaryMember)
                )}
            </Text>
            <Button
                icon={success ? 'tick' : ''}
                text={success ? t('242') : t('secondary_member.disable_modal_button_text')}
                onClick={disableSecondaryMember}
                className="mt-xs"
                loading={isLoading}
                disabled={disabled}
            />

            {apiError && (
                <Text size={2} className="text-orange mt-xs">
                    {t('secondary_member.disable_modal_error')}
                </Text>
            )}
        </Modal>
    );
};
