import React from 'react';

export enum FeedbackTypes {
    None = 'none',
    Bad = 'bad',
    Neutral = 'neutral',
    Good = 'good'
}

export const FeedbackImagesSVG = ({
    imageToDisplay,
    onClick,
    active
}: {
    imageToDisplay: FeedbackTypes;
    onClick: (x: FeedbackTypes) => void;
    active: boolean;
}): JSX.Element => {
    switch (imageToDisplay) {
        case 'bad': {
            const fillColor = active ? '#EE3444' : '#ECE9E8';
            return (
                <svg
                    data-cy="bad-feedback"
                    className="cursor-pointer"
                    onClick={() => onClick(imageToDisplay)}
                    data-value={active ? 'bad_active' : 'bad_inactive'}
                    width="100%"
                    height="100%"
                    viewBox="0 0 125 130"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M62.4233 119.144C33.7537 119.144 10.4038 94.8838 10.4038 64.9875C10.4038 35.0912 33.7537 10.8311 62.4233 10.8311C91.0929 10.8311 114.443 35.1402 114.443 64.9875C114.443 94.8348 91.0929 119.144 62.4233 119.144ZM62.4233 17.2024C37.0962 17.2024 16.5238 38.6689 16.5238 64.9875C16.5238 91.306 37.0962 112.773 62.4233 112.773C87.7505 112.773 108.323 91.355 108.323 64.9875C108.323 38.6199 87.7505 17.2024 62.4233 17.2024Z"
                        fill={fillColor}
                    />
                    <path
                        d="M42.6511 62.7695C46.551 62.7695 49.7125 59.4781 49.7125 55.418C49.7125 51.3578 46.551 48.0664 42.6511 48.0664C38.7511 48.0664 35.5896 51.3578 35.5896 55.418C35.5896 59.4781 38.7511 62.7695 42.6511 62.7695Z"
                        fill={fillColor}
                    />
                    <path
                        d="M81.9602 63.0151C85.8601 63.0151 89.0216 59.7237 89.0216 55.6636C89.0216 51.6034 85.8601 48.312 81.9602 48.312C78.0602 48.312 74.8987 51.6034 74.8987 55.6636C74.8987 59.7237 78.0602 63.0151 81.9602 63.0151Z"
                        fill={fillColor}
                    />
                    <path
                        d="M62.4234 73.1108C44.9168 73.1108 36.7637 91.3307 36.4136 92.0986L41.7656 94.7469C41.7656 94.6145 49.9187 79.3077 62.4234 79.3077C74.9281 79.3077 83.0311 94.6145 83.0812 94.7469L88.4332 92.0986C88.0831 91.3307 79.93 73.1108 62.4234 73.1108Z"
                        fill={fillColor}
                    />
                </svg>
            );
        }
        case 'neutral': {
            const fillColor = active ? '#FDCC0C' : '#ECE9E8';
            return (
                <svg
                    data-cy="feedback-neutral"
                    className="cursor-pointer"
                    onClick={() => onClick(imageToDisplay)}
                    data-value={active ? 'neutral_active' : 'neutral_inactive'}
                    width="100%"
                    height="100%"
                    viewBox="0 0 126 130"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M62.9997 119.144C34.3302 119.144 10.9802 94.8348 10.9802 64.9875C10.9802 35.1402 34.2831 10.8311 62.9997 10.8311C91.6693 10.8311 115.019 35.1402 115.019 64.9875C115.019 94.8348 91.6693 119.144 62.9997 119.144ZM62.9997 17.2024C37.6726 17.2024 17.1002 38.6199 17.1002 64.9875C17.1002 91.355 37.6726 112.773 62.9997 112.773C88.3269 112.773 108.899 91.355 108.899 64.9875C108.899 38.6199 88.2798 17.2024 62.9997 17.2024Z"
                        fill={fillColor}
                    />
                    <path
                        d="M43.2275 62.7695C47.1274 62.7695 50.289 59.4781 50.289 55.418C50.289 51.3578 47.1274 48.0664 43.2275 48.0664C39.3275 48.0664 36.166 51.3578 36.166 55.418C36.166 59.4781 39.3275 62.7695 43.2275 62.7695Z"
                        fill={fillColor}
                    />
                    <path
                        d="M82.5366 63.0151C86.4365 63.0151 89.598 59.7237 89.598 55.6636C89.598 51.6034 86.4365 48.312 82.5366 48.312C78.6366 48.312 75.4751 51.6034 75.4751 55.6636C75.4751 59.7237 78.6366 63.0151 82.5366 63.0151Z"
                        fill={fillColor}
                    />
                    <path d="M82.5365 82.1289H42.5215V88.5002H82.5365V82.1289Z" fill={fillColor} />
                </svg>
            );
        }
        case 'good': {
            const fillColor = active ? '#4CC471' : '#ECE9E8';
            return (
                <svg
                    data-cy="feedback-good"
                    className="cursor-pointer"
                    onClick={() => onClick(imageToDisplay)}
                    data-value={active ? 'good_active' : 'good_inactive'}
                    width="100%"
                    height="100%"
                    viewBox="0 0 125 130"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M62.0583 119.144C33.3887 119.144 10.0388 94.8348 10.0388 64.9875C10.0388 35.1402 33.3887 10.8311 62.0583 10.8311C90.7279 10.8311 114.078 35.1402 114.078 64.9875C114.078 94.8348 90.775 119.144 62.0583 119.144ZM62.0583 17.2024C36.7312 17.2024 16.1588 38.6199 16.1588 64.9875C16.1588 91.355 36.7312 112.773 62.0583 112.773C87.3854 112.773 107.958 91.355 107.958 64.9875C107.958 38.6199 87.3854 17.2024 62.0583 17.2024Z"
                        fill={fillColor}
                    />
                    <path
                        d="M42.2861 62.7695C46.186 62.7695 49.3475 59.4781 49.3475 55.418C49.3475 51.3578 46.186 48.0664 42.2861 48.0664C38.3861 48.0664 35.2246 51.3578 35.2246 55.418C35.2246 59.4781 38.3861 62.7695 42.2861 62.7695Z"
                        fill={fillColor}
                    />
                    <path
                        d="M81.5952 63.0151C85.4951 63.0151 88.6566 59.7237 88.6566 55.6636C88.6566 51.6034 85.4951 48.312 81.5952 48.312C77.6952 48.312 74.5337 51.6034 74.5337 55.6636C74.5337 59.7237 77.6952 63.0151 81.5952 63.0151Z"
                        fill={fillColor}
                    />
                    <path
                        d="M62.5764 100.189C80.083 100.189 88.2361 81.9468 88.5862 81.1779L83.2342 78.5264C83.2342 78.659 75.0811 93.9845 62.5764 93.9845C50.0718 93.9845 41.9687 78.659 41.9187 78.5264L36.5667 81.1779C36.9168 81.9468 45.0699 100.189 62.5764 100.189Z"
                        fill={fillColor}
                    />
                </svg>
            );
        }
        default:
            return <></>;
    }
};
