import useSWR from 'swr';

import { fetcher } from '../fetcher';

export const useFetch = <T>(url: string | null, status?: boolean) => {
    const { data, error } = useSWR(url, (url) => fetcher(url, status), {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        shouldRetryOnError: false
    });
    return {
        data: data as T,
        isLoading: url ? !error && !(typeof data !== 'undefined') : false,
        isError: error
    };
};
