import { css } from 'styled-components';

const cssParameters = {
    maxWidthNotLogged: '420px',
    menuAnimationTime: 150,
    thinMenuWidth: '250px',
    showMobileMenuOnWidth: 680 // number
};

// Device screen size
export const breakpoints = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280
};

export const mediaQueries = {
    sm: `@media (min-width: ${breakpoints['sm']}px)`,
    md: `@media (min-width: ${breakpoints['md']}px)`,
    lg: `@media (min-width: ${breakpoints['lg']}px)`,
    xl: `@media (min-width: ${breakpoints['xl']}px)`
};

export const styles = css`
  ${({ styles }) => `
    ${styles ? styles : ''};
  `};
`;

export const mdStyles = css`
  @media (min-width: ${breakpoints['md']}px) {
    ${({ mdStyles }) => `
      ${mdStyles ? mdStyles : ''};
    `};
  }
`;

export const lgStyles = css`
  @media (min-width: ${breakpoints['lg']}px) {
    ${({ lgStyles }) => `
      ${lgStyles ? lgStyles : ''};
    `};
  }
`;

export const xlStyles = css`
  @media (min-width: ${breakpoints['xl']}px) {
    ${({ xlStyles }) => `
      ${xlStyles ? xlStyles : ''};
    `}
  }
`;

export default cssParameters;
