import React from 'react';
import { useTranslation } from 'react-i18next';
import { Hero } from '@src/components/Hero/Hero';
import { StockImage } from '@src/components/StockImage/StockImage';
import { useMember } from '@src/services/member/MemberProvider';

export const OverviewHeader = (): JSX.Element => {
    const { state: member } = useMember();
    const { t } = useTranslation();
    return (
        <Hero
            className="mbf-home-banner bg-gradient-orange mb-[35px] md:mb-[0]"
            title={`${t('18')} ${member?.first_name ?? ''}!`}
            text={t('444')}
            image={
                <StockImage
                    id="overview-hero"
                    className="absolute right-0 h-full max-w-[110%] translate-y-[18%]"
                />
            }
        />
    );
};
