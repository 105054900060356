import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Text } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { Button } from '@src/components/Buttons/Button';
import { Header } from '@src/components/Header/Header';
import { LineSVG } from '@src/components/LineSVG/LineSVG';
import { useMember } from '@src/services/member/MemberProvider';

import { getMemberRole, MemberRoles } from '../../../services/memberRoles';

export const NotFound = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const state = useMember();

    return (
        <>
            <Header
                title={'404'}
                text={'Page not found'}
                image={<LineSVG id="vertical-lines" height={267} />}
            />
            <Box variant="white" className="mt-xl">
                <Text size={2}>{t('2')}</Text>

                {state?.state?.member &&
                    getMemberRole(state?.state.member) === MemberRoles.EMPLOYEE && (
                        <Text size={2} className="mt-s">
                            {t('not.found.employees')}
                        </Text>
                    )}

                <Button text={t('3')} onClick={() => navigate('/overview')} className="mt-s" />
            </Box>
        </>
    );
};
