import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CTACard } from '@src/components/Cards/CTACard.component';

import googleTagManagerPush from 'services/google-tag-manager-push';

export const CTA = (): JSX.Element => {
    const navigate = useNavigate();

    const { t } = useTranslation();

    const goToAdvantage = (event: MouseEvent) => {
        event.preventDefault();
        googleTagManagerPush.advantage();
        navigate('/advantage');
    };

    const goToGxr = (event: MouseEvent) => {
        event.preventDefault();

        googleTagManagerPush.gxr();
        window.open('/gxr');
    };

    const Cards = [
        {
            image: '/img/rebrand/mbf-gxr.jpg',
            title: t('video.workouts.brand.name'),
            content: t('67'),
            button: {
                to: '/gxr',
                onClick: goToGxr,
                text: `${t('68')} ${t('video.workouts.brand.name')}`
            }
        },
        {
            image: '/img/rebrand/mbf-advantages.jpg',
            title: t('35'),
            content: t('69'),
            button: {
                to: '/advantage',
                onClick: goToAdvantage,
                text: `${t('68')} ${t('35')}`
            }
        }
    ];

    return (
        <div className="px-[0] xs:px-[15px] sm:px-[20px] md:px-[0px] grid grid-cols-1 md:grid-cols-2 gap-l">
            {Cards.map((props, index) => (
                <CTACard key={`CTA-card-${index}`} {...props} />
            ))}
        </div>
    );
};
