import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMember } from '@src/services/member/MemberProvider';
import { RootState } from '@src/types/store.type';
import { useFetch } from '@src/utils/hooks/api/useFetch';

import { getFilteredCompensations } from '../../../../../../components/compensation/compensationHelper';
import ConfirmPopup from '../../../../../../components/compensation/ConfirmPopup';
import OneCompensation from '../../../../../../components/compensation/OneCompensation';
import Loader from '../../../../../../components/partials/Loader';
import PageHead from '../../../../../../components/partials/PageHead';
import ParagraphNormal from '../../../../../../components/partials/ParagraphNormal';
import SectionDivider from '../../../../../../components/partials/SectionDivider';
import cacheHelper from '../../../../../../services/API/cache-helper';
import submitCompensation from '../../../../../../services/API/submit-compensation-choice';

export const Compensations = () => {
    const { t } = useTranslation();
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [chosenCompensation, setChosenCompensation] = useState(false);
    const [loader, setLoader] = useState(false);
    const [compensationSetSuccess, setCompensationSetSuccess] = useState(false);
    const [hasCompensationForCurrentCampaign, setHasCompensationForCurrentCampaign] =
        useState(false);

    const { state: member } = useMember();

    const { data: compensations } = useFetch<any[]>('/extra/get-compensation-options');

    const { data: clubHasActiveCompensationCampaign } = useFetch<any>(
        '/extra/is-club-in-active-compensation-campaign'
    );

    useEffect(() => {
        if (clubHasActiveCompensationCampaign && member.chosenCompensations) {
            const currentChosenCompensation = member.chosenCompensations.some(
                ({ wave_campaign__r }: any) =>
                    wave_campaign__r && wave_campaign__r.Id === clubHasActiveCompensationCampaign.id
            );

            if (currentChosenCompensation) {
                setChosenCompensation(currentChosenCompensation);
                setHasCompensationForCurrentCampaign(true);
            }
        }
        if (clubHasActiveCompensationCampaign && !compensations.length) {
            const records =
                clubHasActiveCompensationCampaign.campaign_compensation_options__r.records;
            const filteredCompensations = getFilteredCompensations(
                records,
                member,
                clubHasActiveCompensationCampaign
            );
            dispatch({
                type: 'SET_ACTIVE_COMPENSATIONS',
                payload: filteredCompensations
            });
        }
    }, [compensations, clubHasActiveCompensationCampaign]);

    const { showFreezeMembershipPopup } = useSelector((state: RootState) => ({
        ...state.showFreezeMembershipPopup
    }));

    const chosenCompensations = member?.chosenCompensations;

    useEffect(() => {
        if (showFreezeMembershipPopup) {
            const frozenCompensation = compensations.find(({ choice }) =>
                choice.toLowerCase().includes('freeze')
            );
            compensationClicked(frozenCompensation);
        }
    }, []);

    const dispatch = useDispatch();

    const somethingWrong = () => {
        dispatch({
            type: 'SOMETHING_WRONG',
            payload: false
        });
        dispatch({
            type: 'SOMETHING_WRONG',
            payload: true
        });
    };

    const compensationClicked = (compensation: any) => {
        setChosenCompensation(compensation);
        setShowConfirmPopup(true);
    };

    const setCompensation = async (compensation: any) => {
        setShowConfirmPopup(false);
        if (showFreezeMembershipPopup) {
            dispatch({
                type: 'SHOW_FREEZE_MEMBERSHIP_POPUP',
                payload: false
            });
        }
        setLoader(true);
        const result = await submitCompensation(compensation.choice, compensation.campaignId).catch(
            console.log
        );
        if (!result || result.status !== 200) {
            setLoader(false);
            somethingWrong();
            return;
        }
        setCompensationSetSuccess(true);
        const comp = {
            ...clubHasActiveCompensationCampaign.campaign_compensation_options__r.records[
                compensation.i
            ],
            show_on_mbf__c: true,
            preferred_compensation__c: compensation.choice,
            wave_campaign__r: { Id: compensation.campaignId }
        };
        try {
            const member = JSON.parse(cacheHelper.get('_member') as string);
            const compensation_object = JSON.parse(member.compensation_object);
            compensation_object.push(comp);
            cacheHelper.set('_member', {
                ...member,
                compensation_object: JSON.stringify(compensation_object)
            });
        } catch (err) {
            console.log(err);
        }

        setTimeout(() => {
            setLoader(false);
            dispatch({
                type: 'CHOSEN_COMPENSATIONS',
                payload: chosenCompensations ? [...chosenCompensations, comp] : [comp]
            });
            dispatch({
                type: 'CURRENT_CHOSEN_COMPENSATION',
                payload: chosenCompensation
            });
        }, 1600);
    };

    member.membershipIsFrozen = false;

    if (
        !member.membershipIsFrozen &&
        compensations &&
        clubHasActiveCompensationCampaign &&
        !hasCompensationForCurrentCampaign
    ) {
        // alowed to set
        return (
            <>
                {loader && <Loader success={compensationSetSuccess} text={t('565')} />}
                {showConfirmPopup && chosenCompensation && (
                    <ConfirmPopup
                        chosenCompensation={chosenCompensation}
                        confirmSetCompensation={setCompensation}
                        setShowPopup={setShowConfirmPopup}
                    />
                )}
                <PageHead headId="chooseCompensationHead" title={t('546')} size="16px" />

                {/** Below is a list of */}
                <ParagraphNormal text={t('548')} />

                {compensations.map((compensation: any, i: any) => {
                    return (
                        <OneCompensation
                            compensations={compensations}
                            choose={t('549')}
                            compensation={compensation}
                            index={i}
                            key={i}
                            onChooseFunction={() => {
                                compensationClicked(compensation);
                            }}
                            chosen
                        />
                    );
                })}
                <SectionDivider />
            </>
        );
    }
    if (chosenCompensation) {
        return (
            <>
                <PageHead headId="successHead" title={t('392')} size="32px" />

                <ParagraphNormal text={t('568')} />

                <OneCompensation
                    choose={t('549')}
                    compensation={chosenCompensation}
                    chosen={t('566')}
                    compensations={compensations}
                    index={1}
                    onChooseFunction={() => null}
                />
            </>
        );
    }
    // not allowed to set
    return <></>;
};
