import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonLink } from '@src/components/Buttons/ButtonLink';
import { captureException } from '@src/utils/helpers/newRelic';

export const ErrorPage = (): JSX.Element => {
    const navigate = useNavigate();

    useEffect(() => {
        captureException('error page', {
            component: 'ErrorPage'
        });
    }, []);

    return (
        <div className="h-full w-full flex flex-col items-center justify-center p-[20px]">
            <b>An unknown ERROR occurred</b>
            <ButtonLink onClick={() => navigate('/overview')} className="mt-xxs">
                Go back home
            </ButtonLink>
        </div>
    );
};
