import axios from 'axios';

import { domain, headers } from '.';

interface cancelMembershipProps {
    cancelReason: string;
    reasonDescription: string;
    peopleMembershipId: string;
    endDate: string;
}
export const cancelMembership = async ({
    cancelReason,
    reasonDescription,
    peopleMembershipId,
    endDate
}: cancelMembershipProps) => {
    try {
        const response = await axios({
            method: 'POST',
            url: `${domain}/memberships/insert-cancellation`,
            headers,
            data: { cancelReason, peopleMembershipId, endDate, reasonDescription }
        });
        return response;
    } catch (e) {
        return e;
    }
};
