type Action = {
    type: 'SHOW_FREEZE_MEMBERSHIP_POPUP';
    // TODO: Define the payload type
    payload: boolean;
};

export function showFreezeMembershipPopup(
    state: any = { showFreezeMembershipPopup: false },
    action: Action
) {
    switch (action.type) {
        case 'SHOW_FREEZE_MEMBERSHIP_POPUP':
            return {
                ...state,
                showFreezeMembershipPopup: action.payload
            };
        default:
            return state;
    }
}
