import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { getParam as getQueryParam } from '@src/services/utilities/params.service';

export enum RegistrationError {
    INVALID_TOKEN = 'INVALID_TOKEN',
    WRONG_ACCESS_TYPE = 'WRONG_ACCESS_TYPE',
    MEMBERSHIP_NOT_ACTIVE = 'MEMBERSHIP_NOT_ACTIVE',
    MEMBERSHIP_EXISTS = 'MEMBERSHIP_EXISTS',
    SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG'
}

const errorMessages: Record<string, string> = {
    [RegistrationError.INVALID_TOKEN]: 'secondary_member.register.errors.invalid_url',
    [RegistrationError.WRONG_ACCESS_TYPE]: 'secondary_member.register.errors.wrong_access_type',
    [RegistrationError.MEMBERSHIP_NOT_ACTIVE]:
        'secondary_member.register.errors.membership_not_active',
    [RegistrationError.MEMBERSHIP_EXISTS]:
        'secondary_member.register.errors.secondary_member_already_exist',
    [RegistrationError.SOMETHING_WENT_WRONG]:
        'secondary_member.register.errors.something_went_wrong'
};

export const SecondaryMemberRegisterError = () => {
    const { t } = useTranslation();
    const errorCode = getQueryParam('errorCode');
    const errorMessage =
        errorCode && errorMessages[errorCode]
            ? errorMessages[errorCode]
            : RegistrationError.SOMETHING_WENT_WRONG;

    return (
        <>
            <Text size={2}>{t(errorMessage)}</Text>
            <Button
                className="mt-xs"
                onClick={() => window.open('https://www.basic-fit.com')}
                text={t('generic.go_to_basic_fit')}
            />
        </>
    );
};
