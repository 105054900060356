import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonList } from '@src/components/ButtonList/ButtonList';
import { IconButton } from '@src/components/Buttons/IconButton';
import { FAQ } from '@src/components/FAQ/FAQ.component';
import { Icon } from '@src/components/Icons/Icons';
import { Section } from '@src/components/Layout/Section';
import { VisitsMetrics } from '@src/components/Visits/Visits';
import { ChangeAccess } from '@src/pages/Membership/Membership/components/ChangeAccess/ChangeAccess';
import { SecondaryMemberDeactivateAccount } from '@src/pages/Membership/Membership/components/Modals/SecondaryMemberDeactivateAccount';
import DataManagement from '@src/pages/MyInformation/MyInformation/components/DataManagement.myinformation';
import MyInfoTable from '@src/pages/MyInformation/MyInformation/components/MyInfoTable.myinformation';
import { useMember } from '@src/services/member/MemberProvider';
import { useMBFFeatures } from '@src/utils/hooks/api/useMBFFeatures';

export const SecondaryMemberOverviewContent = (): JSX.Element => {
    const { state: member } = useMember();
    const [isCancelMembershipModalOpen, setIsCancelMembershipModalOpen] = React.useState(false);
    const { changeDevice } = useMBFFeatures();
    const { t } = useTranslation();

    return (
        <>
            <Section>
                <MyInfoTable />
            </Section>

            <Section>
                <DataManagement />
            </Section>

            <Section>
                <VisitsMetrics />
            </Section>

            {changeDevice && (
                <Section>
                    <ChangeAccess />
                </Section>
            )}

            <Section>
                <ButtonList title={t('secondary_member.deactivate_account')}>
                    <IconButton
                        icon={<Icon id="cancel" />}
                        title={t('secondary_member.deactivate_account')}
                        onClick={() => setIsCancelMembershipModalOpen(true)}
                        data-testid="deactivate-secondary-member__button"
                    />

                    <SecondaryMemberDeactivateAccount
                        isOpen={isCancelMembershipModalOpen}
                        onClose={() => setIsCancelMembershipModalOpen(false)}
                        member={member}
                    />
                </ButtonList>
            </Section>

            <Section>
                <FAQ category="all" />
            </Section>
        </>
    );
};
