import React, { useEffect } from 'react';
import { useController, useFormContext, ValidationRule } from 'react-hook-form';
import {
    RadioButton,
    RadioButtonProps
} from '@src/components/Form/RadioButton/RadioButton.component';

import { Error, ErrorProps } from '../Error/Error.component';

export interface RadioButtonInputProps
    extends Omit<RadioButtonProps, 'label' | 'checked' | 'defaultValue' | 'required'>,
        ErrorProps {
    radios: { value: string; label: string }[];
    defaultValue?: string | null;
    required?: ValidationRule<boolean>;
}

export const RadioButtonInput = ({
    className = 'space-x-xs',
    name,
    radios,
    required,
    error,
    defaultValue = null,
    ...rest
}: RadioButtonInputProps): JSX.Element => {
    const { control, setError } = useFormContext();
    const {
        field: { onChange, value: radioValue },
        fieldState: { error: stateError }
    } = useController({ name, control, defaultValue, rules: { required } });

    useEffect(() => {
        if (error) {
            const errorMessage = typeof error === 'string' ? error : '';
            setError(name, { message: errorMessage });
        }
    }, [error]);

    return (
        <div>
            <div {...rest} className={`flex ${className}`}>
                {radios.map(({ value, label }) => (
                    <div
                        key={`radio-inputs-${name}-${value}`}
                        className={`
                            flex cursor-pointer items-center px-s py-xs border-l-2 border-transparent
                            transition-[border] duration-300
                            ${stateError || error ? 'bg-red-error' : 'bg-jersey-grey'}
                            ${value === radioValue && (stateError || error) ? '!border-red' : ''}
                            ${
                                value === radioValue && !(stateError || error)
                                    ? '!border-orange'
                                    : ''
                            }
                        `}
                        onClick={() => onChange(value)}
                    >
                        <RadioButton label={label} name={name} checked={value === radioValue} />
                    </div>
                ))}
            </div>
            <Error error={stateError?.message || error} />
        </div>
    );
};
