import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { Button } from '@src/components/Buttons/Button';
import { useMember } from '@src/services/member/MemberProvider';
import { isSevenDaysBefore } from '@src/utils/helpers/date';
import { isEmployee } from '@src/utils/helpers/memberHelpers';
import { DateTime } from 'luxon';

import { ReverseCancellationModal } from '../Modals/ReverseCancellation.modal';

export const ReverseCancellation = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { state: member } = useMember();

  const canReverseCancellation = useMemo(() => {
    const { end_date_g, contract_end_date_g, cancel_date_g } = member;
    // cancellation date is not set, is not valid or is in the future
    const cancelDate = DateTime.fromISO(cancel_date_g ?? '');
    if (!cancelDate.isValid || cancelDate.toMillis() > Date.now()) return false;
    // Do not show reverse cancellation for members with payment plan that cancels automatically
    if (member?.payment_plan?.cancels_automatically || isEmployee(member)) return false;

    return isSevenDaysBefore(contract_end_date_g) || isSevenDaysBefore(end_date_g);
  }, [member]);

  if (!canReverseCancellation) return null;

  return (
    <Box variant="orange" size="small" data-cy="reverse-cancellation__section" className="mb-xxl">
      <Heading size={6} className="mb-xxs">
        {t('reverseCancellation.title')}
      </Heading>
      <Text size={2} className="mb-xs">
        {t('reverseCancellation.body')}
      </Text>
      <Button
        text={t('reverseCancellation.buttonText')}
        onClick={() => setIsOpen(true)}
        className="mb-m"
        data-cy="reverse-cancellation__button"
      />
      {isOpen && <ReverseCancellationModal isOpen={isOpen} onClose={() => setIsOpen(false)} />}
    </Box>
  );
};
