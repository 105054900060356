import React, { useState } from 'react';
import styled from 'styled-components';

/**
 * Small btn
 */
export default ({ text, onClick, subject }) => {
    const [mouseHover, setMouseHover] = useState(false);

    const Box = styled.div`
    width: 98px;
    height: 32px;
    background: ${mouseHover ? '#954EE1' : '#7322c9'};

    font-family: Impact;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 16px;
    color: white;
    text-align: center;
    line-height: 32px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    cursor: pointer;
  `;

    const handleMouseHover = () => {
        setMouseHover(true);
    };

    const handleMouseLeave = () => {
        setMouseHover(false);
    };

    return (
        <Box
            onMouseEnter={handleMouseHover}
            onMouseLeave={handleMouseLeave}
            className="small-btn"
            onClick={() => onClick(subject)}
        >
            {text}
        </Box>
    );
};
