import React, { HTMLAttributes, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@basic-fit/design-system';
import { Icon } from '@src/components/Icons/Icons';

export const FeedbackThankYouBlock = ({
  children,
  className = '',
  ...rest
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div
      className={`bg-jersey-grey w-full sm:w-[] sm:max-w-[600px] mx-s p-xs sm:p-s ${className}`}
      {...rest}
    >
      <Box className='flex content-center mb-s'>
        <Icon
          id="heart"
          className="text-orange h-[30px] w-[30px]"
        />
        {/* Thank you for sharing your feedback with us! */}
        <Text size={1} bold={true} className="ml-s" textAlign='left'>
          {t('feedback.friends.feedback.title')}
        </Text>
      </Box>

      {/* We will do our best to keep improving our service in the future. */}
      <Text size={1}>
        {t('feedback.friends.feedback.text')}
      </Text>
    </div>
  );
}