import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorPage } from '@src/pages/Error/Error.page';
import { useFetch } from '@src/utils/hooks/api/useFetch';
import { inspect } from '@xstate/inspect';
import { AppRouter } from 'refactor/router';
import { XSTATE_STATECHART_DEBUG } from 'refactor/services/utilities';
import { RootState } from 'refactor/types/store.type';

import SomethingWrong from 'components/partials/SomethingWrong';

import './App.css';
import 'refactor/css/tailwind.css';

export const App = (): JSX.Element => {
    const dispatch = useDispatch();
    const { data: isAuth, isError } = useFetch<boolean>('/authentication/is-auth');

    const { somethingWrong } = useSelector((state: RootState) => ({
        ...state.somethingWrongReducer
    }));

    useEffect(() => {
        if (XSTATE_STATECHART_DEBUG === 'true') {
            inspect({
                url: 'https://statecharts.io/inspect',
                iframe: false
            });
        }
    }, []);

    useEffect(() => {
        if (typeof isAuth !== 'undefined' || isError) {
            dispatch({
                type: 'HAS_AUTH',
                payload: isError ? false : isAuth
            });
        }
    }, [isAuth, isError]);

    return (
        <div className="w-full flex flex-col items-center h-full">
            <ErrorBoundary FallbackComponent={() => <ErrorPage />}>
                {somethingWrong && <SomethingWrong />}
                {typeof isAuth !== 'undefined' && <AppRouter />}
            </ErrorBoundary>
        </div>
    );
};
