import React, { HTMLAttributes } from 'react';

interface IToggle extends HTMLAttributes<HTMLDivElement> {
    state: boolean;
    variant?: 'gray' | 'white';
}

export const Toggle = ({ state, variant = 'gray', ...rest }: IToggle): JSX.Element => {
    return (
        <>
            <div
                className={`
                   z-[0] relative w-[24px] h-[8px] flex items-center rounded-[4px] 
                   ${variant === 'gray' ? 'bg-jersey-grey' : state ? 'bg-orange' : 'bg-white'}
                `}
                {...rest}
            >
                <div
                    className={`
                    transition-all duration-300
                    absolute top-[-5px] left-[-9px] z-[2] rounded-[100%] w-[18px] h-[18px]
                    ${
                        state
                            ? 'bg-orange translate-x-[24px] shadow-md'
                            : `${
                                  variant === 'gray'
                                      ? 'bg-grey translate-x-[0]'
                                      : 'bg-white translate-x-[0]'
                              }`
                    }

                `}
                ></div>
            </div>
        </>
    );
};
