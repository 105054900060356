import { swrOptions } from '@src/utils/hooks/api/useSWROptions';
import { fetcher } from '@src/utils/hooks/fetcher';
import useSWR from 'swr';

export type Article = AvailableArticle & BoughtArticles;

export type Articles = {
    availableArticles: AvailableArticle[];
    boughtArticles: BoughtArticles[];
    peopleMembershipId: string;
};

export type AvailableArticle = {
    paymentScheduleArticleId: string;
    articleId: string;
    articleDescription: string;
    articleShortDescription: string;
    articleLongDescription: string;
    articleGroupDescription: string;
    articleGroupId: string;
    articlePrice: number;
    originalPrice?: number;
    discountText?: string;
    paymentscheduleArticlePeriodical: boolean;
    paymentscheduleArticleFree: boolean;
    articleStartdate?: any;
    articleEnddate?: any;
    articleChoice: boolean;
    articleAlways: boolean;
    freePeriodCount: number;
    freePeriodNewContractDate: boolean;
    prorataFactor: number;
    prorataPeriods: number;
    hasFreePeriod: boolean;
    freeUntil?: any;
    allowFreePeriod: boolean;
    articleTax: number;
    fixedCorrectionIsAmount: boolean;
    fixedCorrectionValue: number;
    fixedCorrectionEnddate?: any;
    noCorrectionOnProrata: boolean;
    deferredIncomePeriods: number;
    deferredIncomeDate?: any;
    contractEnddate?: any;
    autoCancel: boolean;
    hideKiosk: boolean;
    startDate: Date;
    prorataAmount: number;
    deferredIncome: number;
    articleVat: number;
    prorataVat: number;
    deferredIncomeVat: number;
    noticeperiod: string;
    minimalContractperiod: string;
    freePeriods?: any;
    firstDirectDebit?: any;
    articlePicture: string;
    articleUrl?: any;
    name: string;
    type: string;
};

export type BoughtArticles = {
    peopleMembershipArticleId: string;
    articleId: string;
    articleArticle: string;
    articleDescription: string;
    articleShortDescription: string;
    articleLongDescription: string;
    articlePrice: number;
    articleGroupDescription: string;
    articleGroupId: string;
    peopleMembershipArticleMinimalContractPeriod: string;
    peopleMembershipArticlePeriodical: boolean;
    peopleMembershipArticleFree: boolean;
    peopleMembershipArticleStartdate: Date;
    peopleMembershipArticleEnddate?: any;
    articleTax: number;
    fixedCorrectionIsAmount: boolean;
    fixedCorrectionValue: number;
    fixedCorrectionEnddate?: any;
    peopleMembershipArticleAutocancel: boolean;
    peopleMembershipArticleHideKiosk: boolean;
    articleVat: number;
    articlePicture: string;
    possibleEnddates: any[];
};

export const useArticles = () => {
    const {
        data,
        error,
        mutate: mutateArticles
    } = useSWR(`/memberships/get-articles`, fetcher, swrOptions);

    return {
        articles: data as { boughtArticles: Article[]; availableArticles: Article[] },
        isLoading: !error && !data,
        isError: error,
        mutateArticles
    };
};
