import React from 'react';

export default function DateIcon() {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 60 59"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.5807 14.75H13.82C12.5762 14.7487 11.3838 14.2526 10.5044 13.3706C9.62492 12.4885 9.13025 11.2926 9.12891 10.0453V4.70475C9.13025 3.45739 9.62492 2.2615 10.5044 1.37948C11.3838 0.497456 12.5762 0.00134632 13.82 0L14.5807 0C15.8244 0.00134632 17.0169 0.497456 17.8963 1.37948C18.7758 2.2615 19.2704 3.45739 19.2718 4.70475V10.0453C19.2704 11.2926 18.7758 12.4885 17.8963 13.3706C17.0169 14.2526 15.8244 14.7487 14.5807 14.75V14.75ZM13.82 3.30604C13.4503 3.30671 13.096 3.45429 12.8346 3.71646C12.5732 3.97862 12.426 4.334 12.4253 4.70475V10.0453C12.426 10.416 12.5732 10.7714 12.8346 11.0336C13.096 11.2957 13.4503 11.4433 13.82 11.444H14.5807C14.9504 11.4433 15.3047 11.2957 15.5661 11.0336C15.8275 10.7714 15.9747 10.416 15.9753 10.0453V4.70475C15.9747 4.334 15.8275 3.97862 15.5661 3.71646C15.3047 3.45429 14.9504 3.30671 14.5807 3.30604H13.82Z"
                fill="currentColor"
            />
            <path
                d="M46.277 14.75H45.5163C44.2725 14.7487 43.0801 14.2526 42.2007 13.3706C41.3212 12.4885 40.8265 11.2926 40.8252 10.0453V4.70475C40.8265 3.45739 41.3212 2.2615 42.2007 1.37948C43.0801 0.497456 44.2725 0.00134632 45.5163 0L46.277 0C47.5207 0.00134632 48.7131 0.497456 49.5926 1.37948C50.4721 2.2615 50.9667 3.45739 50.9681 4.70475V10.0453C50.9667 11.2926 50.4721 12.4885 49.5926 13.3706C48.7131 14.2526 47.5207 14.7487 46.277 14.75V14.75ZM45.5163 3.30604C45.1466 3.30671 44.7923 3.45429 44.5309 3.71646C44.2695 3.97862 44.1223 4.334 44.1216 4.70475V10.0453C44.1223 10.416 44.2695 10.7714 44.5309 11.0336C44.7923 11.2957 45.1466 11.4433 45.5163 11.444H46.277C46.6467 11.4433 47.001 11.2957 47.2624 11.0336C47.5238 10.7714 47.671 10.416 47.6716 10.0453V4.70475C47.671 4.334 47.5238 3.97862 47.2624 3.71646C47.001 3.45429 46.6467 3.30671 46.277 3.30604H45.5163Z"
                fill="currentColor"
            />
            <path
                d="M54.2644 6.14398V9.49834C54.9146 9.65716 55.4928 10.0302 55.9066 10.5576C56.3203 11.0851 56.5457 11.7365 56.5465 12.4077V19.8361H3.29643V12.4077C3.29776 11.6921 3.55401 11.0005 4.01897 10.4577C4.48393 9.91492 5.12699 9.55664 5.83215 9.44748V6.12109C4.24837 6.23728 2.76699 6.94987 1.68527 8.11587C0.603555 9.28188 0.00152906 10.815 0 12.4077L0 52.6956C0.00201314 54.367 0.664938 55.9693 1.84336 57.1512C3.02179 58.3331 4.6195 58.9979 6.28605 58.9999H53.5569C55.2235 58.9979 56.8212 58.3331 57.9996 57.1512C59.178 55.9693 59.8409 54.367 59.843 52.6956V12.4077C59.8411 10.8591 59.2718 9.3652 58.2431 8.21027C57.2145 7.05533 55.7985 6.31993 54.2644 6.14398V6.14398ZM53.5569 55.6939H6.28605C5.49336 55.6932 4.73333 55.3771 4.17281 54.815C3.6123 54.2528 3.29711 53.4906 3.29643 52.6956V23.1421H56.5465V52.6956C56.5459 53.4906 56.2307 54.2528 55.6701 54.815C55.1096 55.3771 54.3496 55.6932 53.5569 55.6939V55.6939Z"
                fill="currentColor"
            />
            <path d="M37.5281 6.10352H22.5674V9.40956H37.5281V6.10352Z" fill="currentColor" />
            <path
                d="M15.9747 30.7709V34.0769H12.6783V30.7709H15.9747ZM19.2711 27.4648H9.38184V37.383H19.2711V27.4648Z"
                fill="#7ADEC9"
            />
            <path
                d="M31.4425 30.7709V34.0769H28.146V30.7709H31.4425ZM34.7389 27.4648H24.8496V37.383H34.7389V27.4648Z"
                fill="#7ADEC9"
            />
            <path
                d="M46.9112 30.7709V34.0769H43.6148V30.7709H46.9112ZM50.2077 27.4648H40.3184V37.383H50.2077V27.4648Z"
                fill="#7ADEC9"
            />
            <path
                d="M15.9747 44.7592V48.0652H12.6783V44.7592H15.9747ZM19.2711 41.4531H9.38184V51.3712H19.2711V41.4531Z"
                fill="#7ADEC9"
            />
            <path
                d="M31.4425 44.7592V48.0652H28.146V44.7592H31.4425ZM34.7389 41.4531H24.8496V51.3712H34.7389V41.4531Z"
                fill="#7ADEC9"
            />
            <path
                d="M46.9112 44.7592V48.0652H43.6148V44.7592H46.9112ZM50.2077 41.4531H40.3184V51.3712H50.2077V41.4531Z"
                fill="#7ADEC9"
            />
        </svg>
    );
}
