import React from 'react';

type StepItemProps = {
    id?: number;
    active?: boolean;
    disabled?: boolean;
    to?: string;
    text: string;
};

/**
 * StepItem should only be used as a child for Steps component
 * @params id, active, disabled are controlled by Steps component
 */

export const StepItem = ({ id, active, text }: StepItemProps) => {
    return (
        <li
            className={`text-[13px] font-heading-treble font-bold uppercase ${
                active ? 'text-anthracite grow md:grow-0' : 'text-nav-inactive'
            }`}
        >
            <div className="flex items-center">
                <span
                    className={`rounded-full min-w-[24px] min-h-[24px] leading-[24px] text-center text-white mr-xxs ${
                        active ? 'bg-orange' : 'bg-nav-inactive'
                    }`}
                >
                    {id}
                </span>
                <span className={`leading-tight mr-xxs ${active ? 'block' : 'hidden sm:block'}`}>
                    {text}
                </span>
            </div>
        </li>
    );
};
