import React from 'react';

export default function CancelIcon() {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M49.855 41.314 41.169 50 0 8.832 8.686.146l41.17 41.168Zm-8.687 4.891 4.89-4.89L8.69 3.944 3.795 8.832l37.373 37.373Z"
                fill="url(#a)"
            />
            <path
                d="m27.556 13.758 1.897 1.9L41.314 3.797l4.891 4.889-11.86 11.863 1.897 1.897L50 8.686 41.314 0 27.556 13.758ZM20.474 34.416 8.832 46.058l-4.89-4.89 11.641-11.643-1.897-1.897-13.54 13.54 8.686 8.687 13.54-13.541-1.898-1.898Z"
                fill="#7ADEC9"
            />
            <defs>
                <linearGradient
                    id="a"
                    x1="49.855"
                    y1="25.074"
                    x2="0"
                    y2="25.074"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFAA30" />
                    <stop offset=".08" stopColor="#FF9D26" />
                    <stop offset=".24" stopColor="#FE8915" />
                    <stop offset=".42" stopColor="#FE7B09" />
                    <stop offset=".64" stopColor="#FE7302" />
                    <stop offset="1" stopColor="#FE7000" />
                </linearGradient>
            </defs>
        </svg>
    );
}
