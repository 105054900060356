import React, { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Header } from '@src/components/Header/Header';
import { Section } from '@src/components/Layout/Section';
import { LineSVG } from '@src/components/LineSVG/LineSVG';
import { StrokeText } from '@src/components/StrokeText/StrokeText';
import { Text } from '@src/components/Text/Text';
import { useMember } from '@src/services/member/MemberProvider';
import { captureException } from '@src/utils/helpers/newRelic';
import { usePreventEmployeeChangeDetails } from '@src/utils/hooks/usePreventEmployeeChangeDetails';

import { ChangeEmailContext } from './changeEmail.machine';
import { NewEmail } from './NewEmail.component';
import { VerifyCode } from './VerifyCode.component';
import { VerifyIban } from './VerifyIban.component';

export default function ChangeEmailPage() {
    const { t } = useTranslation();

    const { state: member } = useMember();
    const navigate = useNavigate();

    usePreventEmployeeChangeDetails();

    useLayoutEffect(() => {
        // Prevent user from accessing this page without an IBAN
        if (!member?.iban) navigate('/information');
    }, []);

    return (
        <ChangeEmailContext.Provider>
            <div className="h-full">
                <div className="hidden lg:block">
                    <Header
                        title={t('30')}
                        text={t('377')}
                        image={<LineSVG id="eclipse" className="absolute right-0 top-0" />}
                    />
                </div>
                <Section className="p-4 lg:p-0">
                    <div className="mint-top">
                        <StrokeText
                            stroke="last2"
                            color="anthracite"
                            className="mb-m"
                            text={t('details.change.email.email.title')}
                        />
                        <Text size="m" className="mb-4 leading-[130%]">
                            {t('details.change.email.email.description')}
                        </Text>
                        <ChangeEmailFlow />
                    </div>
                </Section>
            </div>
        </ChangeEmailContext.Provider>
    );
}

const ChangeEmailFlow = () => {
    const shouldShowEmail = ChangeEmailContext.useSelector((ctx) => ctx.matches('email'));
    const shouldShowIban = ChangeEmailContext.useSelector((ctx) => ctx.matches('iban'));
    const shouldShowCode = ChangeEmailContext.useSelector((ctx) => ctx.matches('code'));

    const currentState = ChangeEmailContext.useSelector((state) => state.value);

    if (shouldShowEmail) return <NewEmail />;
    if (shouldShowIban) return <VerifyIban />;
    if (shouldShowCode) return <VerifyCode />;

    captureException(new Error('invalid state'), {
        fn: 'ChangeEmailFlow',
        currentState: String(currentState)
    });

    return null;
};
