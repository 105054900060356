import React, { createContext, PropsWithChildren, useEffect } from 'react';
import { useNavigate, useNavigationType } from 'react-router-dom';
import { changeMembershipMachine } from '@src/pages/ChangeMembership/machines/changeMembershipMachine';
import { useInterpret } from '@xstate/react';
import { Action } from 'history';
import { InterpreterFrom } from 'xstate';

// https://xstate.js.org/docs/recipes/react.html#context-provider
export const ChangeMembershipContext = createContext({
    changeService: {} as InterpreterFrom<typeof changeMembershipMachine>
});

export const ChangeMembershipProvider = ({ children }: PropsWithChildren) => {
    const navigate = useNavigate();
    const navigationType = useNavigationType();

    /**
     * XState can't use React Router directly inside the state machine
     * Using named actions, we can implement them inside this Context
     * https://xstate.js.org/docs/recipes/react.html#other-hooks
     */
    const changeService = useInterpret(changeMembershipMachine, {
        devTools: true,
        actions: {
            updateRoute: (context) => {
                navigate(context.route);
            }
        }
    });

    /**
     * Intercept browsers back button and update state machine
     */
    const { send } = changeService;

    useEffect(() => {
        if (navigationType === Action.Pop) {
            send({ type: 'PREVIOUS_STEP' });
        }
    }, [navigationType]);

    return (
        <ChangeMembershipContext.Provider value={{ changeService }}>
            {children}
        </ChangeMembershipContext.Provider>
    );
};
