import { Membership } from '@src/pages/ChangeMembership/ChangeMembershipFlow.types';

import { CountryName } from './countryHelpers';

const DEFAULT_BASIC_USP_KEYS = ['usp.basic.homeclub', 'usp.gxr.lessons'];
const DEFAULT_COMFORT_USP_KEYS = [
    'usp.comfort.clubs',
    'usp.comfort.virtual.gxr',
    'usp.comfort.gxr',
    'usp.comfort.app'
];
const DEFAULT_PREMIUM_USP_KEYS = [
    'usp.unlimited.access',
    'usp.app.workouts',
    'usp.gxr.lessons',
    'usp.free.friend',
    'usp.massage.chair',
    'usp.yanga.discount',
    'usp.nxt.voucher'
];

const DEFAULT_ALLIN_USP_KEYS = [
    'usp.unlimited.access',
    'usp.app.workouts',
    'usp.gxr.lessons',
    'usp.free.friend',
    'usp.massage.chair',
    'usp.yanga.discount',
    'usp.nxt.voucher',
    'usp.smart.bike',
    'usp.app.bike'
];

const BE_LU_PREMIUM_USP_KEYS = [
    'usp.unlimited.access',
    'usp.app.workouts',
    'usp.gxr.lessons',
    'usp.free.friend',
    'usp.massage.chair',
    'usp.yanga.discount'
];

const BE_LU_ALLIN_USP_KEYS = [
    'usp.unlimited.access',
    'usp.app.workouts',
    'usp.gxr.lessons',
    'usp.free.friend',
    'usp.massage.chair',
    'usp.yanga.discount',
    'usp.smart.bike',
    'usp.app.bike'
];

type USPCountryMapType = Readonly<Record<CountryName, Record<Membership, string[]>>>;

const USP_KEYS_BY_COUNTRY: USPCountryMapType = Object.freeze({
    Belgie: {
        [Membership.BASIC]: DEFAULT_BASIC_USP_KEYS,
        [Membership.COMFORT]: DEFAULT_COMFORT_USP_KEYS,
        [Membership.PREMIUM]: BE_LU_PREMIUM_USP_KEYS,
        [Membership.ALLIN]: BE_LU_ALLIN_USP_KEYS
    },
    France: {
        [Membership.BASIC]: DEFAULT_BASIC_USP_KEYS,
        [Membership.COMFORT]: DEFAULT_COMFORT_USP_KEYS,
        [Membership.PREMIUM]: DEFAULT_PREMIUM_USP_KEYS,
        [Membership.ALLIN]: DEFAULT_ALLIN_USP_KEYS
    },
    Germany: {
        [Membership.BASIC]: DEFAULT_BASIC_USP_KEYS,
        // Comfort is not available in Germany
        [Membership.COMFORT]: [],
        [Membership.PREMIUM]: DEFAULT_PREMIUM_USP_KEYS,
        [Membership.ALLIN]: DEFAULT_ALLIN_USP_KEYS
    },
    Luxembourg: {
        [Membership.BASIC]: DEFAULT_BASIC_USP_KEYS,
        [Membership.COMFORT]: DEFAULT_COMFORT_USP_KEYS,
        [Membership.PREMIUM]: BE_LU_PREMIUM_USP_KEYS,
        [Membership.ALLIN]: BE_LU_ALLIN_USP_KEYS
    },
    Nederland: {
        [Membership.BASIC]: DEFAULT_BASIC_USP_KEYS,
        [Membership.COMFORT]: DEFAULT_COMFORT_USP_KEYS,
        [Membership.PREMIUM]: DEFAULT_PREMIUM_USP_KEYS,
        [Membership.ALLIN]: DEFAULT_ALLIN_USP_KEYS
    },
    Spain: {
        [Membership.BASIC]: DEFAULT_BASIC_USP_KEYS,
        [Membership.COMFORT]: DEFAULT_COMFORT_USP_KEYS,
        [Membership.PREMIUM]: DEFAULT_PREMIUM_USP_KEYS,
        [Membership.ALLIN]: DEFAULT_ALLIN_USP_KEYS
    }
});

export const getMembershipUspByCountry = (membershipType: Membership, countryName: CountryName) => {
    return USP_KEYS_BY_COUNTRY[countryName ?? CountryName.Nederland][membershipType] ?? [];
};
