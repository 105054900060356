import React from 'react';
import { lgStyles, mdStyles, styles, xlStyles } from '../../parameters/css-parameters';
import styled from 'styled-components';

const Text = styled.div`
  font: 400 14px/24px var(--font-heading-pro-double);
  color: var(--color-anthracite);
  
  ${styles}
  ${mdStyles}
  ${lgStyles}
  ${xlStyles}
`;

const TextComponent = ({ children, className = '', ...rest }) => {
    return (
        <Text {...rest} className={`mbf-text ${className}`}>
            {children}
        </Text>
    );
};

export default TextComponent;
