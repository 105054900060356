import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { Modal } from '@src/components/Modals/Modal';
import { StrokeText } from '@src/components/StrokeText/StrokeText';

export const CreditChangeModal = ({
    isOpen,
    onClose
}: {
    isOpen: boolean;
    onClose?: () => void;
}) => {
    const { t } = useTranslation();
    return (
        <Modal isOpen={isOpen} onModalClose={onClose}>
            <StrokeText
                stroke="last"
                color="anthracite"
                className="text-anthracite mb-4"
                text={t('membership.change.overview.modalTitle')}
            />
            <Text size={2} className="mb-m">
                {t('membership.change.overview.modalText')}
            </Text>
            <Button
                className="w-[50%] mx-auto min-w-[200px]"
                text={t('membership.change.overview.modalButton')}
                onClick={onClose}
            />
        </Modal>
    );
};
