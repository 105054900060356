import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@src/components/Buttons/Button';
import { Form } from '@src/components/Form/Form';
import { TextInput } from '@src/components/Form/TextInput/TextInput';
import { TableFlex } from '@src/components/TableFlex/TableFlex';
import { Bold } from '@src/components/Text/Bold';
import { Text } from '@src/components/Text/Text';
import { TooManyTriesModal } from '@src/feature/ChangeMemberModals/TooManyTries.modal';
import { useMember } from '@src/services/member/MemberProvider';

import googleTagManagerPush from 'services/google-tag-manager-push';
import { CHANGE_MEMBER_ERROR_MESSAGE_MAP } from 'types/change-member-response';
import { ModalState } from '../machine-helpers';
import { ChangeEmailContext } from './changeEmail.machine';

export const VerifyIban = () => {
    const { t } = useTranslation();
    const { state: member } = useMember();
    const [state, send] = ChangeEmailContext.useActor();

    const effectRan = useRef(false);

    useEffect(() => {
        if (!effectRan.current) {
            googleTagManagerPush.viewPageEvent('verification step');
        }
        effectRan.current = true;
    }, []);

    const ibanError = ChangeEmailContext.useSelector((state) => {
        if (!state.context.ibanError) return undefined;
        if (!state.matches('iban.error')) return undefined;
        if (state.context.ibanError === CHANGE_MEMBER_ERROR_MESSAGE_MAP.DETAILS_PASSWORD_NO_MATCH) {
            return undefined;
        }

        const customerSupportLink = (
            <>
                &nbsp;
                <a
                    className="underline text-red"
                    href={t('customer.support.url')}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="contact customer support"
                    onClick={() => googleTagManagerPush.clickEvent('to CS')}
                >
                    {t('membership.cancellation.contact_support')}
                </a>
            </>
        );

        if (state.context.ibanError === CHANGE_MEMBER_ERROR_MESSAGE_MAP.DETAILS_IBAN_INVALID) {
            return (
                <>
                    {t('details.change.email.iban.invalid')}
                    {customerSupportLink}
                </>
            );
        }

        if (
            state.context.ibanError === CHANGE_MEMBER_ERROR_MESSAGE_MAP.DETAILS_IBAN_DOES_NOT_MATCH
        ) {
            return (
                <>
                    {t('details.change.email.iban.mismatch')}
                    {customerSupportLink}
                </>
            );
        }

        // rate limit error is handled in the modal
        if (
            state.context.ibanError === CHANGE_MEMBER_ERROR_MESSAGE_MAP.DETAILS_RATE_LIMIT_EXCEEDED
        ) {
            return (
                <>
                    {t('details.change.email.iban.tooManyAttempts')}
                    {customerSupportLink}
                </>
            );
        }

        return (
            <>
                {t('details.change.email.iban.unexpectedError')}
                {customerSupportLink}
            </>
        );
    });

    const passwordError = ChangeEmailContext.useSelector((state) => {
        if (!state.context.ibanError) return undefined;
        if (!state.matches('iban.error')) return undefined;

        type Error = keyof typeof CHANGE_MEMBER_ERROR_MESSAGE_MAP;
        const passwordErrors: Error[] = [
            CHANGE_MEMBER_ERROR_MESSAGE_MAP.DETAILS_PASSWORD_INVALID,
            CHANGE_MEMBER_ERROR_MESSAGE_MAP.DETAILS_PASSWORD_NO_MATCH
        ];
        if (passwordErrors.includes(state.context.ibanError)) {
            return t('details.change.email.iban.invalidPassword');
        }
        return undefined;
    });

    const shouldShowTooManyTries = ChangeEmailContext.useSelector((state) => {
        return state.context.modal === ModalState.TOO_MANY_TRIES;
    });

    return (
        <div data-cy="change-email-iban">
            <Text size="m" className="leading-[130%]">
                {t('details.change.email.iban.tableHeader')}
            </Text>
            <TableFlex
                size="s"
                data={[[t('397'), <Bold key="">{member.iban}</Bold>]]}
                className="border-b border-anthracite-74"
            />
            <Form
                onSubmit={({ iban, password }) => send({ type: 'SUBMIT_IBAN', iban, password })}
                name="email"
                className="space-y-3 pt-6"
            >
                <TextInput
                    required
                    type="text"
                    name="iban"
                    label={t('details.change.full.iban')}
                    error={ibanError}
                    defaultValue={state.context.iban}
                    onInput={() => send('EDITING')}
                    data-cy="iban"
                />
                <TextInput
                    required
                    type="password"
                    aria-autocomplete="current-password"
                    // @ts-expect-error - TextInput types are not correct
                    autoComplete="current-password"
                    name="password"
                    label={t('details.change.current.password')}
                    error={passwordError}
                    defaultValue={state.context.password}
                    onInput={() => send('EDITING')}
                    data-cy="password"
                />
                <Button
                    type="submit"
                    text={t('128')}
                    loading={state.matches('iban.verifying')}
                    disabled={state.matches('iban.verifying')}
                    data-cy="submit-iban"
                    onClick={() => googleTagManagerPush.clickEvent('confirm iban')}
                />
                <Button
                    type="button"
                    onClick={() => send('BACK')}
                    className="!bg-white hover:bg-white !text-anthracite"
                    text={t('162')}
                    data-cy="back"
                />
            </Form>
            {shouldShowTooManyTries && (
                <TooManyTriesModal
                    onCloseCb={() => {
                        send('CLOSE_MODAL');
                    }}
                    onOpen={() => googleTagManagerPush.viewPageEvent('too many tries modal')}
                />
            )}
        </div>
    );
};
