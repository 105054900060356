import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Redirect } from '@src/components/Redirect';
import { PublicLayout } from '@src/layouts/Public.layout';
import { Debt } from '@src/pages/Debt/Debt';
import { ErrorPage } from '@src/pages/Error/Error.page';
import { FriendLinkExpired } from '@src/pages/Friends/components/Register/components/FriendLinkExpired.component';
import { MemberGetMemberFriendPage } from '@src/pages/MemberGetMember/MemberGetMemberFriend.Page';
import { NotFound } from '@src/pages/NotFound/NotFound';
import { SecondaryMemberRegister } from '@src/pages/SecondaryMember/SecondaryMemberRegister.page';
import { Note } from '@src/services/pdf/Note';
import { ProofOfPayment } from '@src/services/pdf/ProofOfPayment';

import { RouteProps } from '.';

const routes: RouteProps[] = [
    {
        path: 'error',
        Component: ErrorPage
    },
    {
        path: 'member-get-member-friend',
        Component: MemberGetMemberFriendPage,
        Layout: PublicLayout
    },
    {
        path: 'not-found',
        Component: NotFound,
        Layout: PublicLayout
    },
    {
        path: 'secondary-member/register',
        Component: SecondaryMemberRegister,
        Layout: PublicLayout
    },
    {
        path: 'friends/register/:token',
        Component: FriendLinkExpired,
        Layout: PublicLayout
    },
    {
        path: 'friends/confirmation/:token',
        Component: FriendLinkExpired,
        Layout: PublicLayout
    },
    {
        path: 'friend-confirmation/:token',
        Component: FriendLinkExpired,
        Layout: PublicLayout
    },
    {
        path: 'friend-registered-successfully',
        Component: FriendLinkExpired,
        Layout: PublicLayout
    },
    {
        path: 'friend-registered-link-expired',
        Component: FriendLinkExpired,
        Layout: PublicLayout
    },
    {
        path: 'payments/proof-of-payment',
        Component: ProofOfPayment
    },
    {
        path: 'payments/note',
        Component: Note
    },
    {
        path: 'debt/:id/:token',
        Component: Debt,
        Layout: PublicLayout
    },
    /**
     * When /authentication/is-auth returns false and none of the routes above match, redirect to /sso
     * This also applies for all routes in DashboardRouter, because you're not authenticated ¯\_(ツ)_/¯
     */
    {
        path: '*',
        Component: () => <Redirect to="/sso" />
    }
];

export const PublicRouter = () => {
    return (
        <Routes>
            {routes.map(({ path, Component, Layout }, index) => (
                <Route
                    path={path}
                    key={`login-${index}`}
                    element={
                        Layout ? (
                            <Layout>
                                {' '}
                                <Component />
                            </Layout>
                        ) : (
                            <Component />
                        )
                    }
                />
            ))}
        </Routes>
    );
};
