import { useEffect, useState } from 'react';
import { useFetch } from '@src/utils/hooks/api/useFetch';

type SignatureResponse = {
    token: string | null;
};

export const useSsoUrl = (getToken = false) => {
    const [ssoUrl, setSsoUrl] = useState('');
    const { data, isLoading, isError } = useFetch<SignatureResponse>(
        getToken ? '/authentication/get-token' : null
    );

    useEffect(() => {
        if (data?.token) {
            setSsoUrl(
                `${location.origin}/sso?token=${data?.token}&returl=${encodeURIComponent(
                    `${location.origin}${location.pathname}`
                )}`
            );
        }
        if (isError) {
            setSsoUrl(location.href);
        }
    }, [data]);

    return { ssoUrl, isFetching: isLoading };
};
