import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@src/components/Buttons/Button';
import { Modal } from '@src/components/Modals/Modal';
import { StrokeText } from '@src/components/StrokeText/StrokeText';
import { Text } from '@src/components/Text/Text';

import googleTagManagerPush from 'services/google-tag-manager-push';

interface ITooManyTriesModal {
    onCloseCb: () => void;
    onOpen?: () => void;
}

/**
 * Modal for when the user has tried to change their details too many times
 * @param onCloseCb - The callback when user closes the modal
 */
export const TooManyTriesModal = ({ onCloseCb, onOpen }: ITooManyTriesModal) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    function closeModal() {
        onCloseCb();
        return navigate('/information');
    }

    return (
        <Modal isOpen={true} onModalClose={closeModal} onModalOpen={onOpen}>
            <div className="space-y-5 break-words" data-cy="change-details-rate-limit-modal">
                <StrokeText
                    className="text-2xl"
                    stroke="first"
                    color="anthracite"
                    text={t('details.change.email.too.many.tries.title')}
                />
                <Text size="m" className="leading-[130%]">
                    {t('details.change.email.too.many.tries.description')}
                </Text>
                <Button
                    text={t('details.change.too.many.tries.primary')}
                    onClick={closeModal}
                    data-cy="close-rate-limit-modal"
                />
                <Button
                    theme="solid"
                    color="naked"
                    text={
                        // Security: This is a basic-fit link, so we are fine with the target blank
                        // eslint-disable-next-line react/jsx-no-target-blank
                        <a
                            href={t('customer.support.url')}
                            target="_blank"
                            data-cy="support-rate-limit-modal-link"
                            onClick={() => googleTagManagerPush.clickEvent('to CS')}
                        >
                            {t('details.change.too.many.tries.secondary')}
                        </a>
                    }
                    data-cy="support-rate-limit-modal"
                />
            </div>
        </Modal>
    );
};
