type Action = {
    type: 'SOMETHING_WRONG';
    // TODO: Define the payload type
    payload: any;
};

export function somethingWrongReducer(state = { somethingWrong: false }, action: Action) {
    switch (action.type) {
        case 'SOMETHING_WRONG':
            return {
                ...state,
                somethingWrong: action.payload
            };
        default:
            return state;
    }
}
