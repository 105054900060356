import React from 'react';
import { useTranslation } from 'react-i18next';
import { FAQ } from '@src/components/FAQ/FAQ.component';
import { Header } from '@src/components/Header/Header';
import { Section } from '@src/components/Layout/Section';
import { Wrapper } from '@src/components/Layout/Wrapper';
import { LineSVG } from '@src/components/LineSVG/LineSVG';
import { VisitsMetrics } from '@src/components/Visits/Visits';

import { VisitsMessage } from './components/VisitsMessage';
import { VisitsOverview } from './components/VisitsOverview.visits';

export const VisitsPage = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div>
            <Header
                title={t('31')}
                text={t('174')}
                image={
                    <div>
                        <LineSVG
                            id="white-vertical-lines"
                            height={267}
                            className="hidden xl:block"
                        />
                    </div>
                }
            />

            {/* visits metrics */}
            <Section>
                <Wrapper>
                    <VisitsMetrics />
                </Wrapper>
            </Section>

            {/* visits motivational message */}
            <VisitsMessage />

            {/* visits rows */}
            <VisitsOverview />

            {/* FAQ */}
            <FAQ category="visits" />
        </div>
    );
};
