import i18next from 'i18next';

const filterCompensations = (records, member) =>
    records.filter((comp) => {
        if (comp.Compensation_option__r.remove_if__c !== null) {
            for (const toRemove of comp.Compensation_option__r.remove_if__c.split(';')) {
                switch (toRemove) {
                    case 'premiumOrDuo':
                        return !member.premiumOrDuo;
                    case 'notPremiumOrDuo':
                        return member.premiumOrDuo;
                    case 'annualContract':
                        return !member.annualContract;
                    case 'notAnnualContract':
                        return member.annualContract;
                    default:
                        return true;
                }
            }
        }
        return true;
    });

export const compensationMapper = (compensation, campaignId, i, chosenCompensation) => {
    return {
        icon: compensation.icon__c || 'gift',
        emoji: compensation.emoji__c,
        description: i18next.t(compensation.description_label__c),
        title: i18next.t(compensation.title_label__c),
        subTitle: i18next.t(compensation.sub_title_label__c),
        choice: compensation.choice__c,
        allowToDisagree: compensation.allow_to_disagree__c,
        disagreeTitleLabel: compensation.disagree_title_label__c,
        disagreeDescriptionLabel: compensation.disagree_description_label__c,
        agreeTitleLabel: compensation.agree_title_label__c,
        agreeDescriptionLabel: compensation.agree_description_label__c,
        chosenCompensation,
        campaignId,
        i
    };
};

export const compensationFinderAndMapper = (chosenCompensations, possibleCompensations) => {
    const compensations = [];
    for (const chosenCompensation of chosenCompensations) {
        if (chosenCompensation.show_on_mbf__c) {
            for (const possibleCompensation of possibleCompensations) {
                if (
                    chosenCompensation.preferred_compensation__c === possibleCompensation.choice__c
                ) {
                    compensations.push(
                        compensationMapper(possibleCompensation, null, null, chosenCompensation)
                    );
                    break;
                }
            }
        }
    }
    return compensations;
};

export const getFilteredCompensations = (
    records,
    member,
    homeClubHasActiveCompensationCampaign
) => {
    const filtered = filterCompensations(records, member);
    return filtered.map((option, i) => {
        return compensationMapper(
            option.Compensation_option__r,
            homeClubHasActiveCompensationCampaign.id,
            i
        );
    });
};
