import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { Modal } from '@src/components/Modals/Modal';
import { usePostHandler } from '@src/utils/hooks/api/usePostHandler';
import { useAwaitSpinner } from '@src/utils/hooks/useAwaitSpinner';
import { useSWRConfig } from 'swr';

import { LogoutSecondaryMemberModal } from './LogoutSecondaryMember.modal';

type SecondaryMemberDeactivateAccountProps = {
    isOpen: boolean;
    member: any;
    onClose: () => void;
};

export const SecondaryMemberDeactivateAccount = ({
    member,
    isOpen,
    onClose
}: SecondaryMemberDeactivateAccountProps) => {
    const { t } = useTranslation();
    const { submit } = usePostHandler();
    const { mutate } = useSWRConfig();
    const { awaiting: isLoading, awaitSpin } = useAwaitSpinner();
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
    const [isCancelButtonDisabled, setIsCancelButtonDisabled] = useState(false);
    const [hasSuccessfullyDisabled, setHasSuccessfullyDisabled] = useState(false);
    const [disableError, setDisableError] = useState(false);

    const { id_g: peopleMembershipId } = member || {};

    const onCancelButtonClick = async () => {
        setIsCancelButtonDisabled(true);

        const response = await awaitSpin(() =>
            submit('/secondary-member/disable-secondary-member', {
                peopleMembershipId
            })
        );

        if (response.error) {
            setIsCancelButtonDisabled(false);
            setDisableError(true);
            return;
        }

        await mutate('/secondary-member/get-secondary-member', null);
        setHasSuccessfullyDisabled(true);
        setIsLogoutModalOpen(true);
    };

    return (
        <>
            <Modal isOpen={!isLogoutModalOpen && isOpen} onModalClose={onClose}>
                <Heading size={5}>{t('secondary_member.deactivate_account')}</Heading>
                <Text size={2} className="my-xs">
                    {t('secondary_member.deactivate_account_explanation')}
                </Text>
                <div className="flex flex-col mt-xs">
                    <Button
                        icon={hasSuccessfullyDisabled ? 'tick' : ''}
                        text={
                            hasSuccessfullyDisabled
                                ? t('generic.success')
                                : t('secondary_member.deactivate_account')
                        }
                        data-testid="deactivate-secondary-member__button-submit"
                        onClick={onCancelButtonClick}
                        className="mt-xs"
                        loading={isLoading}
                        disabled={isCancelButtonDisabled}
                    />

                    {disableError && (
                        <Text size={2} className="text-orange mt-xs">
                            {t('secondary_member.account_suspended_error')}
                        </Text>
                    )}
                </div>
            </Modal>

            <LogoutSecondaryMemberModal isOpen={isLogoutModalOpen} />
        </>
    );
};
