type Action = {
    type: 'CHANGE_MEMBERSHIP_OPTIONS';
    // TODO: Define the payload type
    payload: any;
};

export function changeMembershipOptionsReducer(
    state: any = { changeMembershipOptions: false },
    action: Action
) {
    switch (action.type) {
        case 'CHANGE_MEMBERSHIP_OPTIONS':
            return {
                ...state,
                changeMembershipOptions: action.payload
            };
        default:
            return state;
    }
}
