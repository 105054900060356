import { combineReducers, createAction } from '@reduxjs/toolkit';

import { activeCompensations } from './slices/activeCompensations';
import { changeMembershipOptionsReducer } from './slices/changeMembershipOptionsReducer';
import { isAppView } from './slices/isAppView';
import { memberReducer } from './slices/memberReducer';
import { showFreezeMembershipPopup } from './slices/showFreezeMembershipPopup';
import { somethingWrongReducer } from './slices/somethingWrongReducer';

export const resetRootState = createAction('resetRootState');

export const reducers = combineReducers({
    memberReducer,
    somethingWrongReducer,
    changeMembershipOptionsReducer,
    showFreezeMembershipPopup,
    activeCompensations,
    isAppView
});
