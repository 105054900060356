import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { ButtonLink } from '@src/components/Buttons/ButtonLink';
import { Icon } from '@src/components/Icons/Icons';
import { Section } from '@src/components/Layout/Section';
import {
    filterDebits,
    mapTransactions,
    sortTransactions,
    TransactionProps
} from '@src/pages/Payments/utils/transactionHelpers';
import { DebtTransaction, LogTransaction } from '@src/utils/hooks/api/types/transactions';
import { useBalanceSheet } from '@src/utils/hooks/api/useBalanceSheet';
import { useFetch } from '@src/utils/hooks/api/useFetch';
import { v4 as uuidv4 } from 'uuid';

import { Transaction } from './Transaction';
import { TransactionRow } from './TransactionRow';

export const TransactionOverview = (): JSX.Element => {
    const { t } = useTranslation();
    const [numberOfLogs, setNumberOfLogs] = useState(4);
    const [transactions, setTransactions] = useState<TransactionProps[]>([]);
    const [showMorePayments, setShowMorePayments] = useState(false);
    const balanceSheet = useBalanceSheet();

    const { data: logs, isError: transactionsIsError } = useFetch<LogTransaction[]>(
        '/payments/get-transaction-log'
    );

    const { data: debts } = useFetch<DebtTransaction[]>('/payments/debt-transactions');

    const getTransactionType = (type: string) => {
        if (type === 'debt') {
            return t('1976');
        } else if (type === 'paid') {
            return t('1977');
        } else if (type === 'creditNote') {
            return t('1983');
        } else if (type === 'rejection') {
            return t('1979');
        }
        return '';
    };

    useEffect(() => {
        if (logs && debts) {
            const logsByDate = sortTransactions(logs, debts);
            const groupedTransactions = mapTransactions(logsByDate);
            const filteredTransactions = filterDebits(groupedTransactions);
            setTransactions(filteredTransactions);
            setShowMorePayments(filteredTransactions.length > numberOfLogs);
        }
    }, [logs, debts]);

    const showAllPayments = () => {
        setNumberOfLogs(transactions.length);
        setShowMorePayments(false);
    };

    const TransactionTable = () => {
        return (
            <>
                <TransactionRow
                    className="font-heading-treble font-bold mt-xs sm:mt-l"
                    display="header"
                    date={t('89')}
                    title={t('90')}
                    amount={t('1981')}
                    balance={t('1975')}
                />

                {transactions.slice(0, numberOfLogs).map((payment: any) => (
                    <Transaction
                        key={uuidv4()}
                        date={payment.date}
                        title={getTransactionType(payment.type)}
                        amount={{ type: payment.type, value: payment.transaction }}
                        balance={{ flag: payment.redFlag, value: payment.balance }}
                        details={payment.item}
                    />
                ))}

                {showMorePayments && (
                    <div className="text-center mt-l">
                        <ButtonLink
                            onClick={(event) => {
                                event.preventDefault();
                                showAllPayments();
                            }}
                        >
                            {t('1978')}
                        </ButtonLink>
                    </div>
                )}
            </>
        );
    };

    return (
        <Section>
            <Box variant="grey">
                {!balanceSheet.isLoading && !balanceSheet.inDebt && (
                    <div className={'flex flex-col items-center'}>
                        <Icon id={'thumbs-up'} className="text-[4em]" />
                        <Heading size={6} className="mt-s">
                            {t('payment.noDebt', 'Everything is in order!')}
                        </Heading>
                    </div>
                )}

                {transactionsIsError ? (
                    <Text size={2} className="py-xs">
                        {t('1527')}
                    </Text>
                ) : (
                    <TransactionTable />
                )}
            </Box>
        </Section>
    );
};
