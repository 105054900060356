import React, { HTMLAttributes, memo } from 'react';
import { Outline } from '@basic-fit/design-system';

interface ITitle extends Omit<HTMLAttributes<HTMLDivElement>, 'color'> {
    color?: 'white' | 'anthracite';
    stroke?: 'first' | 'last' | 'all' | 'last2';
    text?: string;
    children?: React.ReactNode;
}

const getStroke = (text: string, stroke?: ITitle['stroke']) => {
    if (stroke === 'all') return text;

    let textArr = text.trim().split(' ');
    switch (stroke) {
        case 'first':
            textArr = textArr.slice(0, 1);
            break;
        case 'last':
            textArr = textArr.slice(-1);
            break;
        case 'last2':
            textArr = textArr.slice(-2);
            break;
        default:
            textArr = [];
            break;
    }

    return textArr.join(' ');
};

export const StrokeText = memo(
    ({ children, className = '', text = '', color, stroke, ...rest }: ITitle) => {
        const strokeText = getStroke(text, stroke);

        const getText = () => {
            const trimmedText = text.replace(strokeText, '');

            switch (stroke) {
                case 'first':
                    return (
                        <>
                            <Outline>{strokeText}</Outline>
                            {trimmedText}
                        </>
                    );
                case 'last':
                case 'last2':
                    return (
                        <>
                            {trimmedText}
                            <Outline>{strokeText}</Outline>
                        </>
                    );
                case 'all':
                    return <Outline>{strokeText}</Outline>;
                default:
                    return trimmedText;
            }
        };

        return (
            <div
                className={`
            uppercase font-heading-treble
            font-black text-[32px] leading-[36px]
            md:text-[40px] md:leading-[43px]
            lg:text-[48px] lg:leading-[50px]
            ${color == 'anthracite' ? 'text-anthracite' : 'text-white'}
            ${className}
          `}
                {...rest}
            >
                {getText()}
                {children}
            </div>
        );
    }
);

StrokeText.displayName = 'StrokeText';
