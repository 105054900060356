// Country names as seen in GymManager
export enum CountryName {
    Belgie = 'Belgie',
    France = 'France',
    Germany = 'Germany',
    Luxembourg = 'Luxembourg',
    Nederland = 'Nederland',
    Spain = 'Spain'
}

// Mapping of the country names as seen in GymManager to the country codes as used by the Intl.DisplayNames API.
const countrCodeMap = Object.freeze({
    [CountryName.Belgie]: 'BE' as const,
    [CountryName.France]: 'FR' as const,
    [CountryName.Germany]: 'DE' as const,
    [CountryName.Luxembourg]: 'LU' as const,
    [CountryName.Nederland]: 'NL' as const,
    [CountryName.Spain]: 'ES' as const
});

export const getCountryNameByLocale = (country: CountryName, locale: string) => {
    const countryCode = countrCodeMap[country];
    return new Intl.DisplayNames([locale, 'nl'], { type: 'region' }).of(countryCode) as string;
};
