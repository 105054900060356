import React, { HTMLAttributes, useEffect } from 'react';
import { useFormContext, Validate, ValidationRule, ValidationValueMessage } from 'react-hook-form';
import { Text } from '@basic-fit/design-system';

import { Error, ErrorProps } from '../Error/Error.component';
import { InputLabel } from '../Label/Label.components';

interface DateInputProps extends Omit<HTMLAttributes<HTMLInputElement>, 'onChange'>, ErrorProps {
    name: string;
    label: string;
    required?: ValidationRule<boolean>;
    pattern?: ValidationRule<RegExp>;
    readOnly?: boolean;
    defaultValue?: string;
    instructions?: string | JSX.Element;
    min?: ValidationRule<string>;
    max?: ValidationRule<string>;
    validate?: Validate<any, any> | Record<string, Validate<any, any>>;
}

export const DateInput = ({
    name,
    label,
    className = '',
    required,
    instructions,
    defaultValue,
    min,
    max,
    pattern,
    readOnly,
    error,
    validate,
    ...rest
}: DateInputProps): JSX.Element => {
    const {
        register,
        watch,
        setError,
        formState: { errors: stateErrors }
    } = useFormContext();

    const watchInput = watch(name);

    useEffect(() => {
        if (error) {
            const errorMessage = typeof error === 'string' ? error : '';
            setError(name, { message: errorMessage });
        }
    }, [error]);

    return (
        <div
            className={`
                w-full
                ${readOnly ? 'cursor-not-allowed' : 'cursor-text'}
            `}
        >
            <div
                className={`
                    w-full block relative
                    ${readOnly ? 'pointer-events-none' : ''}
                `}
            >
                <input
                    id={name}
                    type="date"
                    aria-labelledby={`${name}-label`}
                    className={`
                        text-[16px] font-heading-treble font-bold w-full
                        pt-[24px] pb-[10px] border-0 peer !outline-none
                        focus:outline-none focus:border-0
                        transition-shadow duration-300 shadow-transparent shadow-[inset_2px_0_0_0] px-xs 
                        autofill:!shadow-[inset_2px_0_0_0] autofill:hover:!shadow-[inset_2px_0_0_0]
                        autofill:active:!shadow-[inset_2px_0_0_0] autofill:focus:!shadow-[inset_2px_0_0_0]
                        autofill:!shadow-transparent autofill:hover:!shadow-transparent
                        read-only:cursor-not-allowed read-only:text-grey-disabled read-only:text-transparent
                        focus:text-anthracite active:text-anthracite autofill:text-anthracite
                        ${defaultValue ? 'read-only:!text-grey-disabled !text-anthracite' : ''}
                        ${watchInput ? '!text-anthracite' : 'text-transparent'}
                        ${stateErrors[name] ? '!bg-red-error' : '!bg-jersey-grey'}
                        ${
                            stateErrors[name]
                                ? `   
                            focus:shadow-red
                            active:shadow-red
                            autofill:focus:!shadow-red 
                            autofill:active:!shadow-red 
                        `
                                : `
                            focus:shadow-orange
                            active:shadow-orange
                            autofill:focus:!shadow-orange 
                            autofill:active:!shadow-orange 
                        `
                        }
                    `}
                    readOnly={readOnly}
                    min={(min as ValidationValueMessage<string>)?.value || (min as string)}
                    max={(max as ValidationValueMessage<string>)?.value || (max as string)}
                    {...register(name, {
                        required,
                        pattern,
                        min,
                        max,
                        value: defaultValue,
                        validate
                    })}
                    {...rest}
                />

                <span
                    className={`
                        absolute font-heading-double text-anthracite 
                        top-[50%] left-[16px] transition-[all] duration-[300ms]
                        peer-focus:translate-y-[-120%] peer-focus:text-[12px]
                        peer-autofill:translate-y-[-120%] peer-autofill:text-[12px]
                        peer-read-only:text-grey-disabled pointer-events-none
                        ${
                            watchInput || defaultValue
                                ? 'translate-y-[-115%] text-[12px]'
                                : 'translate-y-[calc(-50%+1px)] text-[14px]'
                        }
                        ${className}
                    `}
                >
                    <InputLabel name={name} label={label} required={required} readOnly={readOnly} />
                </span>
            </div>
            <div
                className={`font-heading-double transition-[padding] duration-300 ${
                    instructions || stateErrors[name] ? 'p-xxxs' : 'pt-0'
                }`}
            >
                {instructions && <Text size={2}>{instructions}</Text>}
                <Error error={stateErrors[name]?.message || error} />
            </div>
        </div>
    );
};
