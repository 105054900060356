import React from 'react';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';
import { Icon } from '@src/components/Icons/Icons';

export type SearchOption = {
    label: string;
    value: string;
};

export const SearchIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <Icon id="search" className="w-3" />
        </components.DropdownIndicator>
    );
};

export const NoOptionsMessage = (props: any) => {
    const { t } = useTranslation();
    return (
        <components.NoOptionsMessage {...props}>
            {t('change_home_club.search_no_results')}
        </components.NoOptionsMessage>
    );
};
