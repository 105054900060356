import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
    const { t } = useTranslation();
    const [closed, setClosed] = useState(false);
    const somethingWrongRef = useRef(0);

    useEffect(() => {
        clearTimeout(somethingWrongRef);

        somethingWrongRef.current = setTimeout(() => {
            setClosed(true);
        }, 3000);

        return () => {
            clearTimeout(somethingWrongRef);
        };
    }, []);

    return (
        <div
            className={`
                ${closed ? 'opacity-0' : 'opacity-1'} transition-opacity duration-300
                z-[9999] h-[60px] text-white flex items-center justify-center fixed top-[0px] left-[0px] w-[100%] bg-red
            `}
        >
            {t('238')}
        </div>
    );
};
