import React from 'react';
import { useTranslation } from 'react-i18next';
import { FAQ } from '@src/components/FAQ/FAQ.component';
import { Header } from '@src/components/Header/Header';
import { Section } from '@src/components/Layout/Section';
import { LineSVG } from '@src/components/LineSVG/LineSVG';
import { DebitBlock } from '@src/feature/DebitBlock/DebitBlock';

import MyPersonalInfoTable from './components/MyPersonalInfoTable.myinformation';

export default function MyInformation(): JSX.Element {
    const { t } = useTranslation();

    return (
        <div>
            <DebitBlock />
            <div className="hidden lg:block" data-cy="header">
                <Header
                    title={t('30')}
                    text={t('377')}
                    image={<LineSVG id="eclipse" className="absolute right-0 top-0" />}
                />
            </div>
            <Section className="p-4 lg:p-0">
                <MyPersonalInfoTable />
            </Section>

            <div className="hidden lg:block" data-cy="FAQ">
                <Section>
                    <FAQ category="information" />
                </Section>
            </div>
        </div>
    );
}
