/**
 * https://swr.vercel.app/docs/data-fetching
 */

import { headers } from './fetchOptions';

export const fetcher = async (url: string, withDetails?: boolean) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER || ''}${url}`, {
        headers
    });

    if (!response.ok) {
        if (withDetails) {
            throw {
                error: new Error('An error occurred while fetching the data.'),
                status: response.status,
                data: await response.json()
            };
        }

        throw new Error('An error occurred while fetching the data.');
    }

    if (withDetails) {
        return {
            data: await response.json(),
            status: response.status
        };
    }

    return response.json();
};
