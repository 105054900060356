import type { HTMLAttributes, PropsWithChildren } from 'react';
import React from 'react';

type BoxProps = PropsWithChildren<
    HTMLAttributes<HTMLDivElement> & {
        size?: 'full' | 'small';
        variant?: 'orange' | 'orange-top' | 'mint' | 'mint-top' | 'grey' | 'full-grey' | 'white';
    }
>;

const getBoxStyle = (variant: string) => {
    switch (variant) {
        case 'mint':
            return 'p-xs sm:p-s bg-mint';
        case 'mint-top':
            return 'px-xs pt-xs sm:px-xxl sm:pt-xxl bg-gradient-to-r from-mint to-mint bg-no-repeat bg-box';
        case 'orange':
            return 'p-xs sm:p-s bg-gradient-orange';
        case 'orange-top':
            return 'px-xs pt-xs sm:px-xxl sm:pt-xxl bg-gradient-orange bg-no-repeat bg-box';
        case 'full-grey':
            return 'p-l md:p-xxl bg-secondary-jersey-gray';
        case 'white':
            // remove top padding because we have no background (root is white)
            return 'px-l pb-l md:px-xxl md:pb-xxl bg-white';
        default:
            return 'p-xs sm:p-s bg-secondary-jersey-gray';
    }
};

const getContentStyle = (variant: string) => {
    switch (variant) {
        case 'mint-top':
        case 'orange-top':
            return 'bg-white p-xs sm:p-xxl';
        case 'full-grey':
        case 'white':
            return '';
        default:
            return 'bg-white p-xs sm:p-xl';
    }
};

export const Box = ({
    className = '',
    children,
    size = 'full',
    variant = 'grey',
    ...rest
}: BoxProps) => {
    return (
        <div
            className={`box box--${variant} ${
                size === 'full' ? 'w-full' : 'md:mx-xxl'
            } ${className} ${getBoxStyle(variant)}`}
            {...rest}
        >
            <div className={`w-full h-full ${getContentStyle(variant)}`}>{children}</div>
        </div>
    );
};
