import axios from 'axios';

import { domain, headers } from '.';

export const createSecondaryMember = (data: any) => {
    try {
        return axios({
            method: 'POST',
            url: `${domain}/secondary-member/create-secondary-member`,
            headers,
            data: data
        });
    } catch (e) {
        console.log('Unable to create secondary member');
        throw e;
    }
};
