import * as React from 'react';
import { useParams } from 'react-router-dom';
import { domain, headers } from '@src/services/data';
import { useMember } from '@src/services/member/MemberProvider';
import axios from 'axios';

export const useClearDebtSession = () => {
    const { state: member } = useMember();
    const { id: peopleId } = useParams();

    const [loading, setLoading] = React.useState(false);

    React.useLayoutEffect(() => {
        if (!member || !peopleId) return;
        if (String(member.people_id_g).toLowerCase() === peopleId.toLowerCase()) return;

        async function clearDebtSession() {
            setLoading(true);
            try {
                await axios({
                    method: 'POST',
                    url: `${domain}/session/clear`,
                    headers
                });
                window.location.reload();
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    console.error(error.response?.data);
                } else {
                    console.error(error);
                }
            } finally {
                setLoading(false);
            }
        }
        clearDebtSession();
    }, []);

    return { loading };
};
