import axios from 'axios';

import { domain, headers } from '../get-api-elements';

export default async (id, agreed) => {
    const response = await axios({
        method: 'POST',
        url: `${domain}/extra/update-compensation-agreement`,
        data: { id, agreed },
        headers,
        json: true
    }).catch((err) => {
        console.log(err);
        return err.response;
    });
    return response.status === 200;
};
