import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Icon } from '@src/components/Icons/Icons';
import { Logo } from '@src/components/Logo/Logo';
import MenuItem from '@src/components/Menu/MenuItem.component';
import { LanguageSwitch } from '@src/layouts/Dashboard/components/LanguageSwitch';
import { getMemberRoutes, RouteProps } from '@src/router/routes';
import { useMember } from '@src/services/member/MemberProvider';
import { isEmpty } from '@src/utils/helpers/collections';
import { useFetch } from '@src/utils/hooks/api/useFetch';
import { useMBFFeatures } from '@src/utils/hooks/api/useMBFFeatures';
import { useLogout } from '@src/utils/hooks/useLogout';

import googleTagManagerPush from 'services/google-tag-manager-push';
import { getMemberRole } from '../../../../services/memberRoles';

export const Menu = (): JSX.Element => {
    const { t } = useTranslation();
    const { state: member, isLoaded: memberIsLoaded } = useMember();
    const { data: isMemberGetMemberActive, isLoading: isLoadingMGM } =
        useFetch('/extra/is-mgm-active');
    const MBFFeatures = useMBFFeatures();

    const [handleLogout, setHandleLogout] = useState(false);
    useLogout(handleLogout);

    const [menuItems, setMenuItems] = useState<any[]>([]);

    const allMenuItems = [
        {
            path: '/overview',
            gtmLabel: 'overview',
            text: t('28'),
            mainMenu: true,
            id: 'menuItemOverview',
            name: 'overview'
        },
        {
            path: '/membership',
            gtmLabel: 'membership',
            text: t('40'),
            mainMenu: true,
            id: 'membershipMenuId',
            name: 'membership'
        },
        {
            path: '/payments',
            gtmLabel: 'payments',
            text: t('29'),
            mainMenu: true,
            id: 'paymentMenuId',
            name: 'payment'
        },
        {
            path: '/information',
            gtmLabel: 'information',
            text: t('30'),
            mainMenu: true,
            id: 'informationMenuId',
            name: 'information'
        },
        {
            path: '/service',
            gtmLabel: 'service',
            text: t('32'),
            mainMenu: true,
            id: 'serviceMenuId',
            name: 'service'
        },
        {
            path: '/visits',
            gtmLabel: 'visits',
            text: t('31'),
            mainMenu: true,
            id: 'visitsMenuId',
            name: 'visits'
        },
        {
            path: '/advantage',
            gtmLabel: 'advantage',
            text: t('35'),
            mainMenu: true,
            id: 'advantageMenuId',
            name: 'advantage'
        },
        {
            path: '/gxr',
            gtmLabel: 'gxr',
            text: t('34'),
            mainMenu: true,
            id: 'gxrMenuId',
            name: 'gxr',
            onClickHandler: () => googleTagManagerPush.gxr()
        },
        {
            path: '/friends',
            gtmLabel: 'friends',
            text: t('33'),
            mainMenu: true,
            id: 'friendsMenuId',
            name: 'friends'
        }
    ];

    const menuActions = [
        {
            text: t('440'),
            gtmLabel: 'change-language',
            mainMenu: false,
            id: 'languageMenuId',
            name: 'change-language',
            onClickHandler: () => {
                setSwitchLanguage(true);
            }
        },
        {
            externalLink: 'https://www.basic-fit.com',
            gtmLabel: 'basic-fit',
            text: t('544'),
            mainMenu: false,
            id: 'mainPageId',
            name: 'website'
        },
        {
            text: t('36'),
            gtmLabel: 'logout',
            mainMenu: false,
            id: 'logoutMenuId',
            name: 'logout',
            onClickHandler: () => setHandleLogout(true)
        }
    ];

    /**
     * If all API's are fetched, filter the menu items based on the member role
     */
    useEffect(() => {
        if (!isEmpty(member) && MBFFeatures && !isLoadingMGM && menuItems.length === 0) {
            /**
             * Should display MGM items?
             */
            if (isMemberGetMemberActive) {
                allMenuItems.push({
                    path: '/member-get-member',
                    gtmLabel: 'member-get-member',
                    text: t('1502'),
                    mainMenu: true,
                    id: 'memberGetMemberMenuId',
                    name: 'get-member'
                });
            }

            const routes = getMemberRoutes(getMemberRole(member));
            const items = allMenuItems.filter((item) => {
                if (item.path) {
                    return routes.find((route: RouteProps) =>
                        item.path.includes(route.path.replace('/*', ''))
                    );
                }
            });

            setMenuItems([...items, ...menuActions]);
        }
    }, [memberIsLoaded, isLoadingMGM, MBFFeatures, isMemberGetMemberActive]);

    const [menuOpen, setMenuOpen] = useState(false);
    const [switchLanguage, setSwitchLanguage] = useState(false);

    return (
        <>
            <div className="h-[60px] lg:h-[auto] flex-shrink-0">
                <div
                    className={`
                    fixed w-full left-[0] top-[0] h-full lg:shadow-md z-[999] lg:pointer-events-auto
                    lg:sticky lg:w-[300px] lg:left-auto lg:overflow-x-hidden lg:overflow-y-auto
                    ${menuOpen ? 'pointer-events-auto' : 'pointer-events-none'}
                `}
                >
                    <div
                        className={`
                    flex flex-col w-full lg:w-[300px] h-full
                `}
                    >
                        <div
                            className={`
                        h-[60px] flex py-[15px] px-[24px] items-center bg-white
                        shadow-md lg:shadow-none pointer-events-auto
                    `}
                        >
                            <Logo className="flex-shrink w-[110px]" />
                            <div
                                className={`
                                flex items-center lg:hidden w-full justify-end cursor-pointer
                                font-heading-treble font-bold text-anthracite text-[16px]
                            `}
                                onClick={() => setMenuOpen(!menuOpen)}
                            >
                                <span className="mr-[15px]">Menu</span>
                                {menuOpen ? <Icon id="close" /> : <Icon id="hamburger" />}
                            </div>
                        </div>
                        <div className="relative flex-grow overflow-y-auto overflow-x-hidden lg:overflow-visible">
                            <nav
                                className={`
                            bg-anthracite lg:translate-x-[0%] transition-transform duration-300 ease-out-back
                            absolute w-[100%] right-[100%] lg:right-[auto] top-[0px] h-[100%]
                            before:content-[''] before:absolute before:left-[-199px] before:top-[0]
                            before:w-[200px] before:bg-anthracite before:h-full 
                            ${
                                menuOpen
                                    ? 'translate-x-[100%] before:block'
                                    : 'translate-x-[0%] before:hidden'
                            }
                        `}
                            >
                                <ul>
                                    {menuItems.map((item: any, index: number) => {
                                        return (
                                            <li
                                                key={index}
                                                onClick={(event) => {
                                                    googleTagManagerPush.menuClick(
                                                        item.gtmLabel ||
                                                            item.path?.substring(1) ||
                                                            ''
                                                    );

                                                    if (menuOpen) {
                                                        setMenuOpen(false);
                                                    }

                                                    if (item.onClickHandler) {
                                                        return item.onClickHandler(event);
                                                    }
                                                }}
                                            >
                                                {item.externalLink && (
                                                    <a
                                                        href={item.externalLink}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <MenuItem item={item} />
                                                    </a>
                                                )}

                                                {item.path && (
                                                    <Link to={item.path}>
                                                        <MenuItem item={item} />
                                                    </Link>
                                                )}

                                                {!item.externalLink && !item.path && (
                                                    <MenuItem item={item} />
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            {switchLanguage && (
                <LanguageSwitch isOpen={switchLanguage} onClose={() => setSwitchLanguage(false)} />
            )}
        </>
    );
};
