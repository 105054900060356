import useSWR from 'swr';

import { fetcher } from '../fetcher';

export type Discount = {
    id: string;
    name: string;
    picture_link__c: string;
    has_landingspage__c: boolean;
    description_long__c: string;
    description_short__c: string;
    has_coupon_generator__c: boolean;
    code_generator_title__c?: any;
    external_link__c: string;
    end_date__c: Date;
    has_affiliate_link__c: boolean;
};

export const useDiscounts = () => {
    const { data, error } = useSWR(`/discounts/`, fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false
    });
    return {
        discounts: data as Discount[],
        isLoading: !error && !data,
        isError: error
    };
};
