import React, { useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Heading, Text } from '@basic-fit/design-system';
import { PhoneInput } from '@src/components/Form/PhoneInput/PhoneInput.component';
import { ChangeMembershipContext } from '@src/pages/ChangeMembership/machines/ChangeMembershipProvider';

export const UpdatePhoneNumber = () => {
    const { t } = useTranslation();
    const { changeService } = useContext(ChangeMembershipContext);
    const { watch } = useFormContext();
    const phoneValue = watch('phone');

    /**
     * PhoneInput is controlled by react-hook-form
     * So we need to watch the value instead of using onChange event
     */
    useEffect(() => {
        changeService.send({ type: 'UPDATE.PHONE', value: phoneValue });
    }, [phoneValue]);

    return (
        <div className="mb-m">
            <Heading size={7} className="mb-xxs">
                {t('384')}
            </Heading>
            <Text size={2} className="mb-xxs">
                {t('membership.change.allin.phone')}
            </Text>
            <PhoneInput
                name="phone"
                label={t('membership.change.allin.phone.label')}
                minLength={{
                    value: 9,
                    message: t('membership.change.allin.phone.min_length')
                }}
                required={{
                    value: true,
                    message: t('membership.change.allin.phone.required')
                }}
                validate={(value) =>
                    isValidPhoneNumber(value) || t('membership.change.allin.phone.invalid')
                }
            />
        </div>
    );
};
