type Action = {
    type: 'SET_ACTIVE_COMPENSATIONS';
    // TODO: Define the payload type
    payload: any;
};

export function activeCompensations(state: any = { activeCompensations: [] }, action: Action) {
    switch (action.type) {
        case 'SET_ACTIVE_COMPENSATIONS':
            return {
                ...state,
                activeCompensations: action.payload
            };
        default:
            return state;
    }
}
