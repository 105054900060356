import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Thump } from '../img/hand-thumbsup-large.svg';

export default ({ text = '', success = false, successText = '' }) => {
    const { t } = useTranslation();
    return (
        <div className="loader-float-overlay z-[9999]">
            <div className="float-loader p-[15px]">
                <div className="flex flex-col items-center justify-center">
                    {!success ? (
                        <Fragment>
                            <img
                                className="object-cover object-top h-[140px] w-[100%]"
                                src="./img/loader.gif"
                                alt={t('232')}
                            />
                            <div className="text-[20px] text-orange">{text || t('232')}</div>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className="flex items-center justify-center">
                                <Thump />
                            </div>
                            <div className="under-loader-text with-thump">
                                {successText || t('392')}
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};
