import React from 'react';

import { SecondaryMemberOverviewContent } from './components/Content/SecondaryMemberContent.overview';
import { OverviewFooter } from './components/Footer.overview';
import { OverviewHeader } from './components/Header.overview';

export const SecondaryMemberOverviewPage = (): JSX.Element => {
    return (
        <>
            <OverviewHeader />
            <SecondaryMemberOverviewContent></SecondaryMemberOverviewContent>
            <OverviewFooter />
        </>
    );
};
