import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@src/components/Modals/Modal';
import { StockImage } from '@src/components/StockImage/StockImage';
import { StrokeText } from '@src/components/StrokeText/StrokeText';
import { Text } from '@src/components/Text/Text';
import DOMPurify from 'isomorphic-dompurify';
import { useSWRConfig } from 'swr';

interface ISuccessModal {
    onCloseCb: () => void;
    autoClose?: boolean;
    onOpen?: () => void;
}

export const SuccessModal = ({ onCloseCb, autoClose, onOpen }: ISuccessModal) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { mutate } = useSWRConfig();

    return (
        <Modal
            autoClose={autoClose}
            isOpen={true}
            onModalClose={async () => {
                await mutate('/member/get-member');
                onCloseCb();
                navigate('/information');
            }}
            onModalOpen={onOpen}
        >
            <div className="space-y-5 break-words" data-cy="change-details-success-modal">
                <StrokeText
                    className="text-2xl"
                    text={t('details.change.modal.success.title')}
                    stroke="first"
                    color="anthracite"
                />

                <div className="flex justify-center">
                    <StockImage id="renewed" />
                </div>
                <Text
                    size="m"
                    className="leading-[130%]"
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(t('details.change.modal.success.description'))
                    }}
                />
            </div>
        </Modal>
    );
};
