import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { Modal } from '@src/components/Modals/Modal';
import { useLogout } from '@src/utils/hooks/useLogout';

export const LogoutSecondaryMemberModal = ({ isOpen }: { isOpen: boolean }) => {
    const { t } = useTranslation();

    const [handleLogout, setHandleLogout] = useState(false);
    useLogout(handleLogout);

    return (
        <Modal isOpen={isOpen} closeButton={false}>
            <Heading size={6} className="mb-s">
                {t('secondary_member.account_suspended')}
            </Heading>
            <Text size={2} className="my-xs">
                {t('secondary_member.account_suspended_info')}
            </Text>
            <Button
                text={t('secondary_member.account_suspended_logout')}
                onClick={() => setHandleLogout(true)}
                className="mt-xs"
                data-testid="deactivate-secondary-member__logout-button"
            />
        </Modal>
    );
};
