import { MemberDataProps } from '../types/member.types';

export const languages: Language[] = [
    {
        country: 'Nederland',
        countryForApiRequest: 'Nederland',
        countryShort: 'nl',
        language: 'Nederlands',
        languageShort: 'nl'
    },
    {
        country: 'España',
        countryForApiRequest: 'Spain',
        countryShort: 'es',
        language: 'Español',
        languageShort: 'es'
    },
    {
        country: 'France',
        countryForApiRequest: 'France',
        countryShort: 'fr',
        language: 'Français',
        languageShort: 'fr'
    },
    {
        country: 'Belgique',
        countryForApiRequest: 'Belgie',
        countryShort: 'be',
        language: 'Français',
        languageShort: 'fr'
    },
    {
        country: 'Belgie',
        countryForApiRequest: 'Belgie',
        countryShort: 'be',
        language: 'Nederlands',
        languageShort: 'nl'
    },
    {
        country: 'Luxembourg',
        countryForApiRequest: 'Luxembourg',
        countryShort: 'lu',
        language: 'Français',
        languageShort: 'fr'
    },
    {
        country: 'Germany',
        countryForApiRequest: 'Germany',
        countryShort: 'de',
        language: 'German',
        languageShort: 'de'
    },
    {
        country: 'International',
        countryForApiRequest: 'Nederland',
        countryShort: 'nl',
        language: 'English',
        languageShort: 'en'
    }
];

export type Language = {
    country: string;
    countryForApiRequest: string;
    countryShort: string;
    language: string;
    languageShort: string;
};

export const getCountries = () =>
    languages.filter(
        (value, index, self) =>
            self.findIndex((v) => v.countryForApiRequest === value.countryForApiRequest) === index
    );

export const getLanguages = () =>
    languages.filter(
        (value, index, self) => self.findIndex((v) => v.language === value.language) === index
    );

export const getCurrentLanguage = (): Language => {
    const { language } = window.__MEMBER_CONTEXT__ || {};
    const result = getLanguages().find((lang) => lang.languageShort === language);
    if (result) return result;
    // International is default language
    return languages[languages.length - 1];
};

/**
 * Mapper for correct language by billingCountry
 * In some cases GymManager will return a 500 error
 * For example: /door-policy/get-clubs-by-country?country=Netherlands
 */
export const getCountryForApiRequest = (member: MemberDataProps) => {
    const {
        home_club_s: { billingCountry },
        mailing_country
    } = member || {};

    const result = languages.find(
        (lang) => lang.country === billingCountry || lang.language === billingCountry
    );

    if (result) {
        return result.countryForApiRequest;
    }
    return mailing_country; // fallback
};

export default languages;
