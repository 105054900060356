import { captureException } from '../helpers/newRelic';

export type ConnectProps = {
    domain: string;
    reference: string;
    signature: string;
    value: number;
    peopleId: string;
    refreshTransactions: () => void;
    setNoSupportToWebSocket: (isSupported: boolean) => void;
};

function useWSConnector() {
    function connect({
        domain,
        reference,
        signature,
        value,
        peopleId,
        refreshTransactions,
        setNoSupportToWebSocket
    }: ConnectProps): void {
        if ('WebSocket' in window) {
            const url = domain.replace('http', 'ws');
            const websocket = new WebSocket(
                url +
                    '/openPaymentSocket?reference=' +
                    reference +
                    '&sig=' +
                    signature +
                    '&value=' +
                    value +
                    '&peopleId=' +
                    peopleId +
                    '&addFinancial=true&origin=MBF'
            );

            websocket.onopen = () => {
                websocket.send(reference);
            };

            websocket.onmessage = (event) => {
                if (event.data.startsWith('close:')) {
                    refreshTransactions();
                    websocket.close();
                }
            };
            websocket.onclose = (event) => {
                if (!event.wasClean) {
                    captureException('websocket closed unexpectedly', {
                        fn: 'useWSConnector',
                        code: event.code,
                        reason: event.reason,
                        type: event.type
                    });
                }
            };
        } else {
            captureException('websocket not supported for this browser', {
                fn: 'useWSConnector'
            });
            setNoSupportToWebSocket(true);
        }
    }

    return { connect };
}

export default useWSConnector;
