import React from 'react';

export default function ClosedEye() {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 -50 446 349"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M223 242.2C259.961 242.2 290.199 211.962 290.199 175.001C290.199 166.04 288.519 157.642 285.16 149.802L197.801 237.161C205.641 240.52 214.039 242.2 223 242.2L223 242.2Z" />
            <path d="M354.6 80.36L342.28 92.68C375.882 112.282 404.999 140.282 425.721 175C391.561 231 336.119 270.199 272.841 284.2C314.282 265.161 342.841 223.161 342.841 175C342.841 151.48 336.122 129.078 323.802 110.602L312.599 122.922C321.56 138.043 326.599 155.961 326.599 175C326.599 232.121 280.119 278.6 222.999 278.6C203.96 278.6 186.038 273.561 170.921 264.6L158.601 276.92L155.8 279.717L142.359 293.158C168.121 302.119 195.558 306.599 223 306.599C313.16 306.599 397.72 257.88 443.08 178.919L445.318 174.438L443.08 169.958C421.24 132.997 390.443 102.759 354.603 80.3559L354.6 80.36Z" />
            <path d="M107.64 278.6L48.839 336.838L60.601 348.6L396.601 12.6L384.839 0.838013L322.12 64.119C291.319 50.678 257.722 43.4 222.999 43.4C132.839 43.4 48.279 92.119 2.91902 171.08L0.676819 174.998L2.91512 179.479C28.1181 222.599 64.5171 256.759 107.635 278.599L107.64 278.6ZM309.24 77L301.959 84.2812C293.56 77 284.037 70.8402 273.4 65.8012C285.72 68.602 297.482 71.9614 309.24 77.0002L309.24 77ZM290.201 96.039L264.439 121.801C253.24 112.84 238.677 107.801 222.998 107.801C212.357 107.801 202.838 110.039 193.877 114.52C197.799 118.442 200.596 124.039 200.596 130.2C200.596 142.52 190.518 152.598 178.198 152.598C172.038 152.598 166.436 150.36 162.518 145.879C158.041 154.84 155.803 164.359 155.803 175C155.803 190.68 160.842 204.68 169.803 216.441L144.041 242.199C128.92 224.277 119.4 200.758 119.4 175C119.4 117.879 165.88 71.4 223 71.4C248.762 71.4 271.719 80.9234 290.199 96.041L290.201 96.039ZM172.601 65.801C131.16 84.84 102.601 126.84 102.601 175.001C102.601 205.239 113.8 232.681 132.281 253.962L119.961 266.282C79.082 246.68 43.801 215.321 19.721 175.001C53.877 119.001 109.877 79.802 172.601 65.801Z" />
        </svg>
    );
}
