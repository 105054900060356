import { useEffect, useRef, useState } from 'react';

/**
 * Set a Promise into useAwaitSpinner to determine if a loader needs to be displayed.
 * When the Promise (fetch for example) is running longer than the threshold value,
 * the awaiting state returns true. Use this value as loading prop for your components.
 */

export const useAwaitSpinner = () => {
    const threshold = 400; // ms
    const [awaiting, setAwaiting] = useState<boolean>();
    const timer = useRef<ReturnType<typeof setTimeout>>();

    // cleanup on unmount
    useEffect(() => {
        return () => {
            if (timer.current) {
                clearTimeout(timer.current);
            }
            setAwaiting(false);
        };
    }, []);

    const awaitSpin = async <T>(callback: () => Promise<T>) => {
        timer.current = setTimeout(() => {
            setAwaiting(true);
        }, threshold);

        return await callback().finally(() => {
            if (timer.current) {
                clearTimeout(timer.current);
            }
            setAwaiting(false);
        });
    };

    return { awaiting, awaitSpin };
};
