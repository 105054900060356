import React from 'react';
import Select, { GroupBase, Props } from 'react-select';
import {
    NoOptionsMessage,
    SearchIndicator
} from '@src/components/Form/SearchElement/SearchComponents';

/**
 * Create custom theme for react-select
 * https://react-select.com/styles
 */
const customStyles = {
    color: '2D2D2D',
    control: (base: any) => ({
        ...base,
        background: '#ECE9E8',
        borderRadius: 0,
        fontFamily: 'Heading Pro Treble, sans-serif',
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '48px',
        paddingLeft: '16px'
    }),
    option: (base: any) => ({
        ...base,
        borderRadius: 0,
        fontFamily: 'Heading Pro Treble, sans-serif',
        fontWeight: 700,
        fontSize: '14px',
        padding: '8px 0 8px 20px',
        lineHeight: '24px'
    }),
    valueContainer: (base: any) => ({
        ...base,
        padding: 0
    }),
    dropdownIndicator: (base: any) => ({
        ...base,
        color: '#FE7000'
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    noOptionsMessage: (base: any) => ({
        ...base,
        borderRadius: 0,
        fontFamily: 'Heading Pro Treble, sans-serif',
        fontWeight: 700,
        fontSize: '14px',
        paddingLeft: '20px',
        lineHeight: '48px'
    })
};

/**
 * Forward react-select generics so we can re-use the properties
 * https://react-select.com/typescript#select-generics
 */

export const SearchElement = <
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>(
    props: Props<Option, IsMulti, Group>
) => {
    return (
        <Select
            {...props}
            styles={customStyles}
            components={{ DropdownIndicator: SearchIndicator, NoOptionsMessage }}
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary: '#FE7000',
                    primary25: '#ECE9E8',
                    primary50: '#EFEFEF'
                }
            })}
            isSearchable
            isClearable
        />
    );
};
