/**
 * Global enum for membership types, for now on feature level only
 */

export const enum Membership {
    BASIC = 'basic',
    COMFORT = 'comfort',
    PREMIUM = 'premium',
    ALLIN = 'all-in'
}

export type MembershipType = keyof typeof Membership;

export const enum ContractPeriod {
    FLEX = 'fourWeeks',
    YEARLY = '52weeks'
}

export const enum EventError {
    NO_MEMBERSHIPS_AVAILABLE = 'No change memberships available',
    NO_MEMBERSHIP_SELECTED = 'No membership selected'
}

export const sessionCacheKey = 'changeMembershipCompleted';
