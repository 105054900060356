import React from 'react';

type NavMenuItemProps = {
    className?: string;
    active?: boolean;
    text: string;
    disabled?: boolean;
    onClick?: () => void;
};

export const NavMenuItem = ({
    className = '',
    active,
    text,
    disabled,
    onClick
}: NavMenuItemProps) => {
    return (
        <li
            className={`cursor-pointer list-none font-heading-treble font-bold uppercase px-xs pb-xxs border-b-[2px] text-center sm:min-w-[200px] ${
                active ? 'text-orange border-orange' : 'border-anthracite'
            } ${disabled ? 'text-grey border-grey' : ''} ${className}`}
            onClick={!disabled ? onClick : undefined}
        >
            {text}
        </li>
    );
};
