import React from 'react';
import { Section } from '@src/components/Layout/Section';
import { VisitsMetrics } from '@src/components/Visits/Visits';
import DataManagement from '@src/pages/MyInformation/MyInformation/components/DataManagement.myinformation';
import MyInfoTable from '@src/pages/MyInformation/MyInformation/components/MyInfoTable.myinformation';

import { OverviewFooter } from './components/Footer.overview';
import { OverviewHeader } from './components/Header.overview';

export const GracePeriodMemberOverviewPage = (): JSX.Element => {
    return (
        <>
            <OverviewHeader />

            <Section>
                <MyInfoTable />
            </Section>

            <Section>
                <DataManagement />
            </Section>

            <Section>
                <VisitsMetrics />
            </Section>

            <OverviewFooter />
        </>
    );
};
