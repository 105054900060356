import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Heading, Text } from '@basic-fit/design-system';
import { Modal } from '@src/components/Modals/Modal';
import { StrokeText } from '@src/components/StrokeText/StrokeText';
import { useMBFFeatures } from '@src/utils/hooks/api/useMBFFeatures';

import googleTagManagerPush from '../../../../../../services/google-tag-manager-push';

type ModalProps = {
    isOpen: boolean;
    onClose: () => void;
    frozenCompensation: boolean;
    lang?: string;
};

export const CancelMembershipModal = ({
    isOpen,
    onClose,
    frozenCompensation = false
}: ModalProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showCSLinkCancelMembership } = useMBFFeatures();

    useEffect(() => {
        googleTagManagerPush.cancelMembership('view', 'confirm modal');
    }, []);

    const setFreezePopup = () => {
        dispatch({
            type: 'SHOW_FREEZE_MEMBERSHIP_POPUP',
            payload: true
        });
    };

    return (
        <Modal isOpen={isOpen} onModalClose={onClose}>
            <Heading size={6} className="mb-4">
                <StrokeText
                    className="text-anthracite"
                    stroke="last"
                    color="anthracite"
                    text={t('membership.cancellation.modal_title')}
                />
            </Heading>
            <Text size={2} className="mb-xs">
                {t('membership.cancellation.modal_text')}
            </Text>
            {showCSLinkCancelMembership && <CustomerSupportBlock />}
            <div className="flex flex-col mt-l">
                {frozenCompensation && (
                    <Button className="mb-xs" color="orange" variant="primary" onClick={setFreezePopup}>
                        {t('1524')}
                    </Button>
                )}
                <Button
                    variant="primary"
                    onClick={() => {
                        googleTagManagerPush.cancelMembership('click', 'confirm modal - keep membership');
                        onClose?.();
                    }}
                >
                    {t('membership.cancellation.keep_membership')}
                </Button>
                <Button
                    theme="light"
                    variant="primary"
                    onClick={() => {
                        googleTagManagerPush.cancelMembership(
                            'click',
                            'confirm modal - continue cancel membership'
                        );
                        navigate('/cancel-membership');
                    }}
                    data-cy="cancel-membership__modal__confirm"
                >
                    {t('membership.cancellation.proceed')}
                </Button>
            </div>
        </Modal>
    );
};

const CustomerSupportBlock = () => {
    const { t } = useTranslation();

    return (
        <>
            <Heading size={6} className="mb-xxs">
                {t('membership.cancellation.something_wrong')}
            </Heading>
            <Text size={2}>{t('membership.cancellation.support')}</Text>
            <a
                className="underline text-orange mt-2 mb-8"
                href={t('membership.cancellation.customer_support_link')}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="contact customer support"
                onClick={() =>
                    googleTagManagerPush.cancelMembership('click', 'confirm modal - customer support link')
                }
            >
                {t('membership.cancellation.contact_support')}
            </a>
        </>
    );
};
