import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { Button } from '@src/components/Buttons/Button';
import { Header } from '@src/components/Header/Header';
import { LineSVG } from '@src/components/LineSVG/LineSVG';
import { Skeleton } from '@src/components/Skeletons/Skeleton';
import { MemberHasDebt } from '@src/pages/Payments/components/MemberHasDebt.payment';
import { TransactionOverview } from '@src/pages/Payments/components/TransactionOverview.payment';
import { AUTH_SERVICE_REDIRECT_URI } from '@src/services/utilities/env.service';
import { getPrice } from '@src/utils/helpers/priceHelpers';
import { scrollToAnchor } from '@src/utils/helpers/window';
import { useBalanceSheet } from '@src/utils/hooks/api/useBalanceSheet';
import { useFetch } from '@src/utils/hooks/api/useFetch';

import { DebtMember } from 'types/member.types';
import { AppealForm } from './AppealForm';

export const Debt = () => {
    const {
        t,
        i18n: { language }
    } = useTranslation();
    const { id: peopleId, token } = useParams();

    const [searchParams] = useSearchParams();
    const id = searchParams.get('id') || '';
    const valid = searchParams.get('valid') || '';

    /**
     * Get member session based on given tokens
     */
    const [hasSession, setHasSession] = React.useState(false);
    const [isLoaded, setIsLoaded] = React.useState(false);

    const {
        data: member,
        isLoading: fetchingMember,
        isError: isSessionError
    } = useFetch<DebtMember>(`/debt/status/${peopleId}/${token}?id=${id}&valid=${valid}`);

    useEffect(() => {
        if (isSessionError) {
            location.assign(AUTH_SERVICE_REDIRECT_URI || '/sso'); // redirect to UL
        } else if (member && !fetchingMember) {
            setHasSession(true);
        }
    }, [member, fetchingMember, isSessionError]);

    /**
     * Get reminder status from balance sheet
     * If the member has a different status then allowed statuses, show the appeal form
     */
    const [hasAppealStatus, setHasAppealStatus] = React.useState(false);

    const { balance, inDebt, reminderStatus, isLoading, isError } = useBalanceSheet(hasSession);

    useEffect(() => {
        if (isLoading || isError) return;

        const allowedStatus = ['NoReminders', 'FirstReminder'].includes(reminderStatus);
        setHasAppealStatus(!allowedStatus);

        setIsLoaded(true);
    }, [isLoading, isError]);

    const ButtonDetails = () => (
        <Button
            theme="outline"
            text={t('debt.appeal.button.appeal')}
            onClick={() => {
                scrollToAnchor('#appeal');
            }}
        />
    );

    const PageHeader = () => (
        <Header
            title={t('29')}
            text={t('debt.header.description')}
            image={
                <div>
                    <LineSVG id="white-vertical-lines" height={267} className="hidden xl:block" />
                </div>
            }
        />
    );

    const getDebtStatusText = () => {
        if (!balance) return '';

        const { membershipStatus, peopleMembershipEndDate } = member.membership;
        if (membershipStatus === 'cancelled') {
            return t('debt.summary.cancelled', {
                balance: getPrice(balance, language),
                endDate: peopleMembershipEndDate
            });
        }
        return t('debt.summary.payment', {
            balance: getPrice(balance, language),
            deadline: hasAppealStatus ? 7 : 15
        });
    };

    return (
        <div className="w-full mb-40">
            {!isLoaded ? (
                <Skeleton type={'page'} />
            ) : !inDebt ? (
                <>
                    <PageHeader />
                    <TransactionOverview />
                </>
            ) : (
                <>
                    <PageHeader />
                    <MemberHasDebt
                        optionalButton={hasAppealStatus ? <ButtonDetails /> : null}
                        peopleId={member.people_id_g}
                        translations={{
                            debtStatusText: getDebtStatusText()
                        }}
                    />
                    {hasAppealStatus && <AppealForm />}
                </>
            )}
        </div>
    );
};
