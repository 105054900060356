import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Heading, Link, Text } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { Icon } from '@src/components/Icons/Icons';
import { Section } from '@src/components/Layout/Section';
import { getPrice } from '@src/utils/helpers/priceHelpers';
import { isApp } from '@src/utils/helpers/window';
import { LogTransaction } from '@src/utils/hooks/api/types/transactions';
import { useBalanceSheet } from '@src/utils/hooks/api/useBalanceSheet';
import { useFetch } from '@src/utils/hooks/api/useFetch';
import { useMBFFeatures } from '@src/utils/hooks/api/useMBFFeatures';
import { DateTimeFormatOptions } from 'luxon';

export const TransactionOverviewFrance = (): JSX.Element => {
    return (
        <Section>
            <Box variant="grey">
                <NoDebtCelebration />
                <TransactionsTable />
            </Box>
        </Section>
    );
};

const TransactionsTable = () => {
    const {
        t,
        i18n: { language }
    } = useTranslation();
    const { data: logs, isError } = useFetch<LogTransaction[]>('/payments/get-transaction-log');
    const { notesForFrance } = useMBFFeatures();

    const logPreviews = 4;
    const [showMorePayments, setShowMorePayments] = useState(false);

    const transactions = useMemo(() => {
        if (!logs || logs.length === 0) return [];

        const mapTransactionCode = {
            // Debt codes
            sta: 'debt',
            ab: 'debt',
            rfe: 'debt',
            // Paid codes
            bpm: 'paid',
            pay: 'paid',
            dd: 'paid',
            pca: 'paid',
            //  Credit note codes
            cb: 'creditNote',
            dwo: 'creditNote',
            // Rejection codes
            rej: 'rejection'
        };
        type TransactionCode = keyof typeof mapTransactionCode;

        const dateOptions: DateTimeFormatOptions = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        };

        const filteredTransactions = logs.reduce<LogTransaction[]>((acc, log) => {
            const { amount, date, dueDate, id, description, code } = log;
            // Filter out logs with amount 0
            if (amount === 0) return acc;

            const formattedDate = new Date(date).toLocaleString('nl-NL', dateOptions);
            acc.push({
                id,
                date: formattedDate,
                dueDate,
                amount,
                description,
                code: mapTransactionCode[code as TransactionCode] ?? ''
            });
            return acc;
        }, []);

        setShowMorePayments(filteredTransactions.length <= logPreviews);
        return filteredTransactions;
    }, [logs]);

    // Only show PDF link for transactions with eventTypeID of AB, STA, RFE
    const isPdfAvailable = (code: string): boolean => {
        return code === 'debt' && notesForFrance;
    };

    function onClickHandler(e: React.MouseEvent) {
        if (!isApp() || !window.ReactNativeWebView) return;

        e.preventDefault();
        const url = `${window.location.origin}${e.currentTarget.getAttribute('href')}`;
        const payload = JSON.stringify({ file: 'pdf', url });
        window.ReactNativeWebView.postMessage(payload);
    }

    if (isError) {
        return (
            <Text size={2} className="py-4">
                {t('1527')}
            </Text>
        );
    }

    return (
        <div className="relative w-full overflow-hidden" id="notes" data-cy="notes">
            <table className="w-full text-anthracite" data-cy="notes">
                <thead>
                    <tr className="[&_th]:pb-2 align-middle middle">
                        {/* Date */}
                        <th
                            align="left"
                            scope="col"
                            className="w-[10ch] hidden md:table-cell"
                            colSpan={1}
                        >
                            <Text size={1} bold>
                                {t('89')}
                            </Text>
                        </th>
                        {/* Description */}
                        <th align="left" scope="col" colSpan={1}>
                            <Text size={1} bold>
                                {t('90')}
                            </Text>
                        </th>
                        {/* Transactions */}
                        <th align="right" scope="col" colSpan={1}>
                            <Text size={1} bold>
                                {t('1981')}
                            </Text>
                        </th>
                        {/* PDF */}
                        {notesForFrance && <th className="hidden md:table-cell">&nbsp;</th>}
                    </tr>
                </thead>
                <tbody className="[&_tr]:border-b [&_tr]:border-anthracite-74">
                    {transactions
                        .slice(0, showMorePayments ? undefined : logPreviews)
                        .map(({ id, description, date, code, amount }) => {
                            const pdfLink = `/payments/get-proof/${id}`;
                            return (
                                <tr
                                    data-code={code}
                                    data-cy="note"
                                    key={id}
                                    className="[&_td]:pb-2 [&_td]:pt-4 group"
                                >
                                    {/* Date */}
                                    <td
                                        className="hidden md:table-cell text-nowrap md:pr-1"
                                        align="left"
                                        colSpan={1}
                                        data-cy="note-date"
                                    >
                                        <Text size={2}>{date}</Text>
                                    </td>
                                    {/* Description */}
                                    <td
                                        className="table-cell md:px-1 pl-0 pr-1"
                                        align="left"
                                        colSpan={1}
                                        data-cy="note-description"
                                    >
                                        <div className="flex flex-col justify-between space-y-2 h-full">
                                            <div>
                                                <Text size={2}>{description}</Text>
                                            </div>
                                            <div className="md:hidden justify-self-end">
                                                <Text size={2}>{date}</Text>
                                            </div>
                                        </div>
                                    </td>
                                    {/* Transactions */}
                                    <td
                                        align="right"
                                        colSpan={1}
                                        className="table-cell md:px-1 pl-1 pr-0"
                                        data-cy="note-amount"
                                    >
                                        <div className="flex flex-col items-end justify-between data-[code=paid]:justify-center space-y-2 group">
                                            <div className="py-2 px-2 group-data-[code=paid]:bg-mint font-bold max-w-fit min-w-[11ch]">
                                                <Text
                                                    size={1}
                                                    bold
                                                    color={code === 'paid' ? 'white' : 'anthracite'}
                                                >
                                                    {getPrice(amount, language, {
                                                        format: 'withDigits'
                                                    })}
                                                </Text>
                                            </div>
                                            {isPdfAvailable(code) && (
                                                <div className="block md:hidden group-data-[code=paid]:hidden">
                                                    <Link
                                                        onClick={onClickHandler}
                                                        href={pdfLink}
                                                        target="_blank"
                                                        className="text-orange capitalize text-nowrap"
                                                    >
                                                        {t('view.pdf')}
                                                    </Link>
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    {/* PDF */}
                                    {isPdfAvailable(code) && (
                                        <td
                                            align="right"
                                            colSpan={1}
                                            className="hidden md:table-cell group-data-[code=paid]:hidden pl-1 max-w-fit"
                                        >
                                            <Link
                                                onClick={onClickHandler}
                                                href={pdfLink}
                                                className="text-orange capitalize text-nowrap"
                                                target="_blank"
                                            >
                                                {t('view.pdf')}
                                            </Link>
                                        </td>
                                    )}
                                </tr>
                            );
                        })}
                </tbody>
            </table>

            {!showMorePayments && (
                <div className="text-center mt-8 group w-full">
                    <Button
                        onClick={() => setShowMorePayments(true)}
                        variant="secondary"
                        theme="orange"
                        className="underline underline-offset-2 decoration-1 text-orange m-auto"
                        data-cy="show-more-notes-button"
                    >
                        <div className="flex items-center justify-center space-x-1">
                            <Text size={2} color="orange" bold>
                                {t('1978')}
                            </Text>
                            <Icon
                                id="arrow-right"
                                className="transform transition-transform duration-300 text-[14px] group-hover:translate-x-[4px]"
                            />
                        </div>
                    </Button>
                </div>
            )}
        </div>
    );
};

const NoDebtCelebration = () => {
    const { t } = useTranslation();
    const balanceSheet = useBalanceSheet();
    if (balanceSheet.inDebt) return null;

    return (
        <div className="flex flex-col items-center mb-8">
            <Icon id="thumbs-up" className="text-[4em]" />
            <Heading size={6} className="mt-s">
                {t('payment.noDebt', 'Everything is in order!')}
            </Heading>
        </div>
    );
};
