import { isInGracePeriod, isSecondary } from '@src/utils/helpers/memberHelpers';

export enum MemberRoles {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    GRACE_PERIOD = 'grace_period',
    EMPLOYEE = 'employee',
    ALL_IN_ONLY = 'all_in_only',
    SMART_TV = 'smart_tv'
}

export const getMemberRole = (member: any) => {
    if (member.isSmartTv) {
        return MemberRoles.SMART_TV;
    }
    if (isInGracePeriod(member)) {
        return MemberRoles.GRACE_PERIOD;
    }
    if (isSecondary(member)) {
        return MemberRoles.SECONDARY;
    }
    if (member.isEmployee) {
        return MemberRoles.EMPLOYEE;
    }
    if (member.isRestrictedMembership) {
        return MemberRoles.ALL_IN_ONLY;
    }
    return MemberRoles.PRIMARY;
};
