import { domain, headers } from '@src/services/data';
import { captureException } from '@src/utils/helpers/newRelic';
import axios from 'axios';
import { actions, createMachine } from 'xstate';

import { CHANGE_MEMBER_ERROR_MESSAGE_MAP } from 'types/change-member-response';

type Context = {
    iban: string;
    accountHolder: string;
    emailToken: string;
    emailTokenType: string;
};

export const updateMemberAccountMachine = createMachine<Context>({
    id: 'updateMemberAccount',
    predictableActionArguments: true,
    preserveActionOrder: true,
    context: {
        iban: '',
        accountHolder: '',
        emailToken: '',
        emailTokenType: ''
    },
    initial: 'active',
    states: {
        active: {
            description:
                'we want to immediately make the request to the backend when the machine is invoked',
            invoke: {
                id: 'updateMemberAccountService',
                src: async function updateMemberAccount(ctx) {
                    const { data } = await axios({
                        method: 'POST',
                        url: `${domain}/member/update-member-account-details`,
                        headers,
                        data: {
                            iban: ctx.iban,
                            accountHolder: ctx.accountHolder,
                            emailToken: ctx.emailToken,
                            emailTokenType: ctx.emailTokenType
                        }
                    });

                    if (!data.success) {
                        captureException('unexpected response from verify token request', {
                            fn: 'updateMemberAccount',
                            response: String(data)
                        });
                        throw new Error();
                    }
                },
                onDone: { target: 'success' },
                onError: {
                    description:
                        'handle errors from updateMemberAccount request and escalate to parent',
                    actions: actions.escalate((_, ev) => {
                        if (!axios.isAxiosError(ev.data)) {
                            captureException('failed to complete request', {
                                fn: 'updateMemberAccount'
                            });
                            return { message: CHANGE_MEMBER_ERROR_MESSAGE_MAP.UNEXPECTED_ERROR };
                        }

                        const message = ev.data.response?.data?.message;
                        if (message in CHANGE_MEMBER_ERROR_MESSAGE_MAP) {
                            captureException(message, {
                                fn: 'updateMemberAccount'
                            });
                            return { message };
                        }

                        captureException('unexpected response', {
                            fn: 'updateMemberAccount'
                        });
                        return { message: CHANGE_MEMBER_ERROR_MESSAGE_MAP.UNEXPECTED_ERROR };
                    })
                }
            }
        },
        success: { type: 'final' }
    }
});
