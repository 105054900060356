import React, { ElementType, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { MemberOnlyProvider, MemberProvider } from '@src/services/member/MemberProvider';
import { isApp } from '@src/utils/helpers/window';
import { useFetch } from '@src/utils/hooks/api/useFetch';

import { DashboardRouter } from './Dashboard.router';
import { PublicRouter } from './Public.router';

export interface RouteProps {
    path: string;
    Component: React.ComponentType;
    Layout?: ElementType;
}

/**
 * Check which member provider should be used (based on route)
 */
const MemberDashboard = () => {
    const location = useLocation();
    const memberOnlyRoutes = ['friends', 'payments'];
    const [fetchAll, setFetchAll] = useState<boolean | null>(null);

    useEffect(() => {
        const memberOnly = memberOnlyRoutes.some((route) => location.pathname.includes(route));
        setFetchAll(!memberOnly);
    }, []);

    if (typeof fetchAll === 'boolean') {
        return fetchAll ? (
            <MemberProvider>
                <DashboardRouter />
            </MemberProvider>
        ) : (
            <MemberOnlyProvider>
                <DashboardRouter />
            </MemberOnlyProvider>
        );
    }
    return null;
};

export const AppRouter = (): JSX.Element => {
    const dispatch = useDispatch();
    const { data: isAuth } = useFetch<boolean>('/authentication/is-auth');
    const { hash, pathname } = useLocation();
    const navigate = useNavigate();

    /**
     *  Clear params for clean urls
     *  On the app we need to keep the params because we don't want to rewrite history for a WebView
     *  Also set global state isAppView for other routes when the url changes
     */
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const paramsToKeep = [
            'clubid',
            'timeslot',
            'status',
            'member',
            'name',
            'token',
            'variant',
            'app',
            'langCountry' // language setting from the app
        ];

        // keep params for debt page
        if (pathname.startsWith('/debt')) {
            paramsToKeep.push('id', 'lang', 'lcid', 'valid');
        }

        if (pathname.startsWith('/membership/changed')) {
            paramsToKeep.push('membershipType');
        }

        if (isApp()) {
            dispatch({
                type: 'IS_APP_VIEW',
                payload: true
            });
        } else if (isAuth) {
            Array.from(searchParams.entries()).forEach(([key]) => {
                if (!paramsToKeep.includes(key)) {
                    searchParams.delete(key);
                }
            });
            // update query params
            setSearchParams(searchParams);
        }

        // construct url with allowed params and optional hash
        navigate({ search: searchParams.toString(), hash });
    }, [isAuth]);

    return (
        <>
            <Routes>
                {isAuth ? (
                    <Route path="*" element={<MemberDashboard />} />
                ) : (
                    <Route path="*" element={<PublicRouter />} />
                )}
            </Routes>
        </>
    );
};
