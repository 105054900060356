import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Error } from '@src/components/Form/Error/Error.component';
import { Section } from '@src/components/Layout/Section';
import { Wrapper } from '@src/components/Layout/Wrapper';
import { Skeleton } from '@src/components/Skeletons/Skeleton';
import { DashboardLayout } from '@src/layouts/Dashboard/Dashboard.layout';
import { ErrorPage } from '@src/pages/Error/Error.page';
import { NotFound } from '@src/pages/NotFound/NotFound';
import { getMemberRoutes } from '@src/router/routes';
import { useMember } from '@src/services/member/MemberProvider';
import { isEmpty } from '@src/utils/helpers/collections';

import crossPlatformCookies from '../../services/cross-platform-cookies';
import { getMemberRole, MemberRoles } from '../../services/memberRoles';
import { RouteProps } from '.';

export const DashboardRouter = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    /**
     * Fetch all member related data to build our dashboard
     */
    const { state: member, hasError, isLoaded } = useMember();
    const [isReady, setIsReady] = useState(false);
    const [routes, setRoutes] = useState<RouteProps[]>([]);

    useEffect(() => {
        if (!isLoaded || isEmpty(member) || isReady) return;

        const memberRole = getMemberRole(member);

        if (memberRole === MemberRoles.SMART_TV) {
            window.location.replace('/gxr');
            return;
        }

        if (memberRole === MemberRoles.ALL_IN_ONLY) {
            navigate('/all-in-only');
        }

        setRoutes(getMemberRoutes(memberRole));
        crossPlatformCookies(member);

        setIsReady(true);
    }, [isLoaded, isReady, member]);

    /**
     * Scroll to element if hash is provided
     */
    const { hash } = useLocation();
    const [forceScrollUpdate, setForceScrollUpdate] = useState(false);

    useEffect(() => {
        if (hash && isReady) {
            setForceScrollUpdate(true); // force re-render to scroll to element
        }
    });

    useEffect(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth'
            });
        }
    }, [forceScrollUpdate]);

    if (routes.length === 0)
        return (
            <DashboardLayout>
                <Skeleton type="page" />
            </DashboardLayout>
        );

    return (
        <Routes>
            <Route path="" element={<Navigate to="/overview" />} />
            <Route path="login" element={<Navigate to="/overview" />} />
            <Route path="vgsso" element={<Navigate to="/overview" />} />
            <Route path="sso" element={<Navigate to="/overview" />} />

            {routes.map(({ path, Component, Layout }, index) => (
                <Route
                    path={path}
                    key={`dashboard-${index}`}
                    element={(() => {
                        // Update document title specific to module
                        document.title = 'My Basic-Fit';
                        return Layout ? (
                            <Layout>
                                <Component />
                            </Layout>
                        ) : (
                            <DashboardLayout>
                                {!isReady ? (
                                    <>
                                        <Skeleton type="page" />
                                    </>
                                ) : (
                                    <>
                                        {hasError ? (
                                            <Wrapper>
                                                <Section>
                                                    <Error error={t('1527')} />
                                                </Section>
                                            </Wrapper>
                                        ) : (
                                            <Component />
                                        )}
                                    </>
                                )}
                            </DashboardLayout>
                        );
                    })()}
                />
            ))}

            <Route path="error" element={<ErrorPage />} />
            <Route
                path="not-found"
                element={
                    <DashboardLayout>
                        <NotFound />
                    </DashboardLayout>
                }
            />
            <Route path="*" element={<Navigate to="/not-found" />} />
        </Routes>
    );
};
