import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@basic-fit/design-system';
import { Modal } from '@src/components/Modals/Modal';
import { StockImage } from '@src/components/StockImage/StockImage';
import { StrokeText } from '@src/components/StrokeText/StrokeText';

type ModalProps = {
    isOpen: boolean;
    onClose?: () => void;
};

export const ReauthorizeSuccessModal = ({ isOpen, onClose }: ModalProps) => {
    const { t } = useTranslation();
    return isOpen ? (
        <Modal isOpen={isOpen} onModalClose={onClose}>
            <StrokeText
                stroke="last"
                color="anthracite"
                className="text-anthracite mb-4"
                text={t('debit.block.modal.success.title')}
            />
            <StockImage id="renewed" className="m-auto" />
            <Text size={2}>{t('debit.block.modal.success.description')}</Text>
        </Modal>
    ) : null;
};
