import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@basic-fit/design-system';
import { ToggleButton } from '@src/components/Buttons/ToggleButton';
import { Error } from '@src/components/Form/Error/Error.component';
import { Form } from '@src/components/Form/Form';
import { Header } from '@src/components/Header/Header';
import { LineSVG } from '@src/components/LineSVG/LineSVG';
import { useMember } from '@src/services/member/MemberProvider';
import { usePost } from '@src/utils/hooks/api/usePost';
import { useAwaitSpinner } from '@src/utils/hooks/useAwaitSpinner';
import { usePreventEmployeeChangeDetails } from '@src/utils/hooks/usePreventEmployeeChangeDetails';
import { useSWRConfig } from 'swr';

import googleTagManagerPush from 'services/google-tag-manager-push';

type updateMemberEmailProps =
    | 'comp_select1'
    | 'comp_select2'
    | 'comp_select3'
    | 'comp_select4'
    | 'comp_select5';

interface updateMemberEmailOptins {
    optins: Record<updateMemberEmailProps, boolean>;
}

interface EmailSettingsProps {
    redirectOnChange?: string;
}

export default function MarketingPreferences({
    redirectOnChange
}: EmailSettingsProps): JSX.Element {
    const [sendFormError, setSendFormError] = useState<string | null>(null);
    const [success, setSuccess] = useState(false);

    const { t } = useTranslation();
    const { state: member } = useMember();
    const { mutate } = useSWRConfig();
    const { submit } = usePost();
    const { awaiting: isLoading, awaitSpin } = useAwaitSpinner();

    const navigate = useNavigate();

    usePreventEmployeeChangeDetails();

    const [optins, setOptins] = useState<Record<updateMemberEmailProps, boolean>>({
        comp_select1: member.mail_opt_1_s,
        comp_select2: member.mail_opt_2_s,
        comp_select3: member.mail_opt_3_s,
        comp_select4: member.mail_opt_4_s,
        comp_select5: member.mail_opt_5_s
    });

    const submitOptinsChange = async () => {
        setSuccess(false);
        setSendFormError(null);
        googleTagManagerPush.changeMemberInfo();

        try {
            const updateOptinsResponse: any = await awaitSpin(() =>
                submit<updateMemberEmailOptins>('/member/update-member-info', { optins })
            );
            console.log('updated member:\n', updateOptinsResponse);

            if (updateOptinsResponse) {
                await mutate('/member/get-member');
                setSuccess(true);
                navigate(redirectOnChange || '/information');
            }
        } catch (error: any) {
            console.log("Can't update:", error);
            setSendFormError(error.message ? error.message : t('1527'));
            throw error;
        }
    };

    const onClickHandler = (name: updateMemberEmailProps) => {
        setOptins({
            ...optins,
            [name]: !optins[name]
        });
    };

    return (
        <div className="p-[15px] sm:p-[0] h-full w-full">
            <Header
                title={t('marketing.preferences')}
                text={t('377')}
                image={<LineSVG id="eclipse" className="absolute top-0 right-0" />}
                className={'mb-[70px]'}
            />

            <Form
                name="changePaymentInfo"
                onSubmit={submitOptinsChange}
                className="mt-xl space-y-s"
            >
                <div className="py-[15px] px-[30px] !mb-[4px] !mt-[0] bg-secondary-jersey-gray">
                    <ToggleButton
                        variant="white"
                        className="justify-between mb-[0]"
                        off={t('167')}
                        on={t('167')}
                        active={optins.comp_select1}
                        onClick={() => onClickHandler('comp_select1')}
                        changeOnClick={true}
                    />
                </div>

                <div className="py-[15px] px-[30px] !mb-[4px] !mt-[0] bg-secondary-jersey-gray">
                    <ToggleButton
                        variant="white"
                        className="justify-between mb-[0] mt-[0]"
                        off={t('168')}
                        on={t('168')}
                        active={optins.comp_select2}
                        onClick={() => onClickHandler('comp_select2')}
                        changeOnClick={true}
                    />
                </div>

                <div className="py-[15px] px-[30px] !mb-[4px] !mt-[0] bg-secondary-jersey-gray">
                    <ToggleButton
                        variant="white"
                        className="justify-between mb-[0]"
                        off={t('169')}
                        on={t('169')}
                        active={optins.comp_select3}
                        onClick={() => onClickHandler('comp_select3')}
                        changeOnClick={true}
                    />
                </div>

                <div className="py-[15px] px-[30px] !mb-[4px] !mt-[0] bg-secondary-jersey-gray">
                    <ToggleButton
                        variant="white"
                        className="justify-between mb-[0]"
                        off={t('170')}
                        on={t('170')}
                        active={optins.comp_select4}
                        onClick={() => onClickHandler('comp_select4')}
                        changeOnClick={true}
                    />
                </div>

                <div className="py-[15px] px-[30px] !mb-[4px] !mt-[0] bg-secondary-jersey-gray">
                    <ToggleButton
                        variant="white"
                        className="justify-between mb-[0]"
                        off={t('171')}
                        on={t('171')}
                        active={optins.comp_select5}
                        onClick={() => onClickHandler('comp_select5')}
                        changeOnClick={true}
                    />
                </div>
                <div className="mt-m">
                    <Button
                        type="submit"
                        className="mb-xxs w-full"
                        variant="primary"
                        loading={isLoading}
                        icon={success ? 'tick' : undefined}
                    >
                        {success ? t('242') : t('172')}
                    </Button>
                    {sendFormError && <Error error={sendFormError} />}
                </div>
            </Form>
            <Button variant="primary" onClick={() => navigate(-1)} className="w-full" theme="light">
                {t('162')}
            </Button>
        </div>
    );
}
