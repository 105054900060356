import { createActorContext } from '@xstate/react';
import { isValidIBAN } from 'ibantools';
import { XSTATE_STATECHART_DEBUG } from 'refactor/services/utilities';
import { assign, createMachine } from 'xstate';
import { z } from 'zod';

import googleTagManagerPush from 'services/google-tag-manager-push';
import { CHANGE_MEMBER_ERROR_MESSAGE_MAP } from 'types/change-member-response';
import { FlowTokenType, isRateLimitExceeded, ModalState } from '../machine-helpers';
import { sendVerificationTokenMachine } from '../sendVerificationTokenMachine';
import { updateMemberEmailMachine } from './updateMemberEmail.machine';

type Context = {
    flow: FlowTokenType;
    newEmail: string;
    confirmEmail: string;
    iban: string;
    password: string;
    emailToken: string;
    codeError: CHANGE_MEMBER_ERROR_MESSAGE_MAP | null;
    ibanError: CHANGE_MEMBER_ERROR_MESSAGE_MAP | null;
    modal: ModalState | null;
};

type Events =
    | { type: 'SUBMIT_EMAIL'; newEmail: string; confirmEmail: string }
    | { type: 'SUBMIT_IBAN'; iban: string; password: string }
    | { type: 'SUBMIT_CODE'; code: string }
    | { type: 'BACK' }
    | { type: 'EDITING' }
    | { type: 'OPEN_MODAL'; modal: ModalState }
    | { type: 'CLOSE_MODAL' };

type EventStates =
    | { value: 'email'; context: Context }
    | { value: 'email.idle'; context: Context }
    | { value: 'email.error.invalidEmail'; context: Context }
    | { value: 'email.error.emailMismatch'; context: Context }
    | { value: 'iban'; context: Context }
    | { value: 'iban.idle'; context: Context }
    | { value: 'iban.error'; context: Context }
    | { value: 'iban.verifying'; context: Context }
    | { value: 'code'; context: Context }
    | { value: 'code.idle'; context: Context }
    | { value: 'code.verifying'; context: Context }
    | { value: 'code.success'; context: Context }
    | { value: 'code.error'; context: Context };

export const changeEmailMachine = createMachine<Context, Events, EventStates>({
    id: 'emailChange',
    predictableActionArguments: true,
    preserveActionOrder: true,
    context: {
        flow: FlowTokenType.changeEmailToken,
        newEmail: '',
        confirmEmail: '',
        iban: '',
        password: '',
        emailToken: '',
        codeError: null,
        ibanError: null,
        modal: null
    },
    initial: 'email',
    states: {
        email: {
            description: 'User enters a new email address and a confirmation email.',
            initial: 'idle',
            states: {
                idle: {},
                error: {
                    states: {
                        invalidEmail: {},
                        emailMismatch: {}
                    }
                }
            },
            on: {
                SUBMIT_EMAIL: [
                    {
                        description: 'validate that the email is valid',
                        target: '.error.invalidEmail',
                        cond: function isInvalidEmail(_, evt) {
                            const result = z.string().email().safeParse(evt.newEmail);
                            return result.success === false;
                        }
                    },
                    {
                        description: 'validate that the email and confirm email match',
                        target: '.error.emailMismatch',
                        cond: function isEmailMismatch(_, evt) {
                            return evt.newEmail !== evt.confirmEmail;
                        }
                    },
                    {
                        description: 'email is valid and matches the confirm email, continue',
                        target: 'iban',
                        actions: assign({
                            newEmail: (_, evt) => evt.newEmail,
                            confirmEmail: (_, evt) => evt.confirmEmail
                        })
                    }
                ],
                EDITING: { target: 'email' }
            }
        },
        iban: {
            description:
                'User must enter their current IBAN and password, the IBAN and password must match with the IBAN in GM in order to receive a code',
            initial: 'idle',
            states: {
                idle: {},
                error: {
                    entry: (ctx) => {
                        if (
                            ctx.ibanError === CHANGE_MEMBER_ERROR_MESSAGE_MAP.DETAILS_IBAN_INVALID
                        ) {
                            googleTagManagerPush.errorEvent('iban incorrect');
                        } else if (
                            ctx.ibanError ===
                                CHANGE_MEMBER_ERROR_MESSAGE_MAP.DETAILS_PASSWORD_INVALID ||
                            ctx.ibanError ===
                                CHANGE_MEMBER_ERROR_MESSAGE_MAP.DETAILS_PASSWORD_NO_MATCH
                        ) {
                            googleTagManagerPush.errorEvent('password incorrect');
                        }
                    }
                },
                verifying: {
                    description: 'verify that the iban matches the known iban',
                    invoke: {
                        src: sendVerificationTokenMachine,
                        data: (ctx) => ({
                            iban: ctx.iban,
                            password: ctx.password,
                            email: ctx.newEmail,
                            emailTokenType: ctx.flow
                        }),
                        onError: [
                            {
                                description: 'rate limit exceeded, show modal',
                                cond: isRateLimitExceeded,
                                actions: assign({
                                    ibanError: (_, ev) => ev.data.message,
                                    modal: ModalState.TOO_MANY_TRIES
                                }),
                                target: 'error'
                            },
                            {
                                actions: assign({
                                    ibanError: (_, evt) => evt.data.message
                                }),
                                target: 'error'
                            }
                        ],
                        onDone: { target: '#emailChange.code' }
                    }
                }
            },
            on: {
                SUBMIT_IBAN: [
                    {
                        description: 'validate that the iban is a valid iban before sending',
                        target: '.error',
                        cond: (_, evt) => isValidIBAN(evt.iban) === false,
                        actions: assign({
                            ibanError: CHANGE_MEMBER_ERROR_MESSAGE_MAP.DETAILS_IBAN_INVALID
                        })
                    },
                    {
                        target: '.verifying',
                        actions: assign({
                            iban: (_, evt) => evt.iban,
                            password: (_, evt) => evt.password
                        })
                    }
                ],
                EDITING: { target: '.idle' },
                BACK: { target: 'email' }
            }
        },
        code: {
            description:
                'User must enter their current IBAN, the IBAN must match with the IBAN in GM in order to proceed to the next step',
            initial: 'idle',
            states: {
                idle: {},
                success: {},
                error: {
                    entry: (ctx) => {
                        if (
                            ctx.codeError ===
                            CHANGE_MEMBER_ERROR_MESSAGE_MAP.DETAILS_EMAIL_TOKEN_PASSED_INVALID
                        ) {
                            googleTagManagerPush.errorEvent('verification error');
                        }
                    }
                },
                verifying: {
                    invoke: {
                        id: 'changeMemberEmailMachine',
                        src: updateMemberEmailMachine,
                        data: (ctx) => ({
                            email: ctx.newEmail,
                            emailToken: ctx.emailToken,
                            emailTokenType: ctx.flow
                        }),
                        onError: [
                            {
                                cond: isRateLimitExceeded,
                                actions: assign({
                                    codeError: (_, evt) => evt.data?.message,
                                    modal: ModalState.TOO_MANY_TRIES
                                }),
                                target: 'error'
                            },
                            {
                                actions: assign({
                                    codeError: (_, evt) => evt.data?.message
                                }),
                                target: 'error'
                            }
                        ],
                        onDone: {
                            actions: assign({
                                modal: ModalState.SUCCESS
                            }),
                            target: 'success'
                        }
                    }
                }
            },
            on: {
                SUBMIT_CODE: {
                    target: '.verifying',
                    actions: assign({
                        emailToken: (_, evt) => evt.code
                    })
                },
                EDITING: { target: '.idle' },
                BACK: { target: 'iban' }
            }
        }
    },
    on: {
        OPEN_MODAL: {
            actions: assign({
                modal: (_, evt) => evt.modal
            })
        },
        CLOSE_MODAL: {
            actions: assign({
                modal: null
            })
        }
    }
});

export const ChangeEmailContext = createActorContext(changeEmailMachine, {
    devTools: XSTATE_STATECHART_DEBUG === 'true'
});
