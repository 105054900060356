import React, { useContext, useEffect, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { CheckBox } from '@src/components/Form/Checkbox/Checkbox';
import { Form } from '@src/components/Form/Form';
import { Icon } from '@src/components/Icons/Icons';
import { Section } from '@src/components/Layout/Section';
import { MemberPriceContract } from '@src/components/Price/MemberPriceContract';
import { Table } from '@src/components/Table/Table';
import { Membership } from '@src/pages/ChangeMembership/ChangeMembershipFlow.types';
import { CreditChangeModal } from '@src/pages/ChangeMembership/components/CreditChangeModal';
import { TermsAndConditions } from '@src/pages/ChangeMembership/components/TermsAndConditions';
import { UpdatePhoneNumber } from '@src/pages/ChangeMembership/components/UpdatePhoneNumber';
import { trackPromotion, trackPromotionError } from '@src/pages/ChangeMembership/config/ga_events';
import { ChangeMembershipContext } from '@src/pages/ChangeMembership/machines/ChangeMembershipProvider';
import {
    errorSelector,
    renewSelector
} from '@src/pages/ChangeMembership/machines/changeMembershipSelectors';
import { useMember } from '@src/services/member/MemberProvider';
import { getArticleIntervalText, isOneTimeArticle } from '@src/utils/helpers/articleHelpers';
import { collectFunnelData } from '@src/utils/helpers/newRelic';
import { getPrice, PaymentInterval, priceInterval } from '@src/utils/helpers/priceHelpers';
import { ChangeMembershipProps } from '@src/utils/hooks/api/useChangeMembership';
import { useId } from '@src/utils/hooks/useId';
import { useSelector } from '@xstate/react';
import DOMPurify from 'isomorphic-dompurify';
import { DateTime } from 'luxon';

import { usePaymentAmounts } from '../hooks/usePaymentsAmounts.hook';
import { ItemDetails } from './ItemDetails';

type TableData = Parameters<typeof Table>['0']['data'];

const formatDate = (date: string, locale: string) => {
    return DateTime.fromISO(date).setLocale(locale).toLocaleString();
};

export const Overview = () => {
    const {
        t,
        i18n: { language }
    } = useTranslation();

    const [modalOpen, setModalOpen] = useState(false);
    const [showError, setShowError] = useState('');

    const { state: member } = useMember();

    const { changeService } = useContext(ChangeMembershipContext);
    const { membership: selectedMembership, extras } = useSelector(
        changeService,
        (state) => state.context
    );

    const stateError = useSelector(changeService, errorSelector);
    const renew = useSelector(changeService, renewSelector);

    useEffect(() => {
        if (stateError) {
            setShowError(stateError);
            trackPromotionError(stateError);
        }
    }, [stateError]);

    const { amountToPay, totalPrice } = usePaymentAmounts(renew);

    const membershipInformationData: TableData = [
        // Start date
        [
            t(renew ? 'renewal.extend.startdate' : '43'),
            formatDate(selectedMembership?.peopleMembership.startDate || '', language)
        ],
        // Contract period
        [
            t(renew ? 'renewal.extend.enddate' : '1931'),
            t(selectedMembership?.minimalContractPeriod || '')
        ],
        // End date
        [
            t('membership.change.first_possible_end_date'),
            formatDate(selectedMembership?.peopleMembership.contractEndDate || '', language)
        ]
    ];

    const getCurrentRenewMembershipData = (
        selectedMembership: ChangeMembershipProps | null,
        renew: boolean
    ): TableData => {
        if (!selectedMembership || !renew) return [];
        return [
            [
                <Text
                    key="renew-membership-new-contract-start-title"
                    size={1}
                    className="capitalize text-nav-inactive"
                >
                    {t('43')}
                </Text>,
                <Text
                    key="renew-membership-new-contract-start-date"
                    size={1}
                    className="capitalize text-nav-inactive"
                >
                    {DateTime.fromISO(member.start_date_g).setLocale(language).toLocaleString()}
                </Text>
            ],
            [
                <Text
                    key="renew-membership-contract-end-title"
                    size={1}
                    className="text-nav-inactive capitalize"
                >
                    {t('44')}
                </Text>,
                <Text
                    key="renew-membership-contract-end-date"
                    size={1}
                    className="text-nav-inactive capitalize"
                >
                    {DateTime.fromISO(member.contract_end_date_g)
                        .setLocale(language)
                        .toLocaleString()}
                </Text>
            ]
        ];
    };

    const currentMembershipData: TableData = [
        ...getCurrentRenewMembershipData(selectedMembership, renew),
        [
            <Text
                key={useId()}
                size={1}
                className={`text-nav-inactive capitalize ${!renew ? 'line-through' : ''}`}
            >
                {member.membership_type_g}
            </Text>,
            <MemberPriceContract
                key={useId()}
                style="normal"
                showDiscount={!renew}
                size={2}
                className="justify-end text-nav-inactive "
            />
        ]
    ];

    const getNewRenewMembershipData = (
        selectedMembership: ChangeMembershipProps | null,
        renew: boolean
    ): TableData => {
        if (!selectedMembership || !renew) return [];
        return [
            [
                <Text
                    key="renew-membership-new-contract-start-title"
                    size={1}
                    className="capitalize"
                >
                    {t('renewal.extend.startdate')}
                </Text>,
                <Text key="renew-membership-new-contract-start-date" size={1}>
                    {DateTime.fromISO(selectedMembership.peopleMembership.startDate)
                        .setLocale(language)
                        .toLocaleString()}
                </Text>
            ],
            [
                <Text key="renew-membership-new-contract-end-title" size={1} className="capitalize">
                    {t('renewal.extend.enddate')}
                </Text>,
                <Text key="renew-membership-new-contract-end-date" size={1}>
                    {DateTime.fromISO(selectedMembership.peopleMembership.contractEndDate)
                        .setLocale(language)
                        .toLocaleString()}
                </Text>
            ]
        ];
    };

    const newMembershipData: TableData = [
        ...getNewRenewMembershipData(selectedMembership, renew),
        [
            <Text key={useId()} size={1} className="capitalize">
                {selectedMembership?.membershipType || ''}
            </Text>,
            renew ? (
                <MemberPriceContract
                    key="total-price-renew-membership-overview"
                    style="normal"
                    showDiscount={false}
                    size={2}
                    className="justify-end text-nav-inactive "
                />
            ) : (
                `${getPrice(selectedMembership?.termFee || 0, language)} / ${t(
                    selectedMembership?.paymentInterval === PaymentInterval.FourWeeks
                        ? 'per4Weeks'
                        : '370'
                )}`
            )
        ]
    ];

    const totalPriceData: TableData = [
        // Total price

        renew
            ? [
                  <ItemDetails
                      key="overview-total-price-renew-membership"
                      type="heading"
                      title={t('445')}
                      price={getPrice(totalPrice, language)}
                      intervalSeparator={!renew}
                      interval={
                          renew
                              ? priceInterval(member.payment_plan.interval as PaymentInterval)
                              : t(
                                    selectedMembership?.paymentInterval ===
                                        PaymentInterval.FourWeeks
                                        ? 'per4Weeks'
                                        : '370'
                                )
                      }
                  />
              ]
            : [
                  <strong
                      key="overview-total-price-title"
                      className="font-heading-treble font-bold capitalize"
                  >
                      {t('445')}
                  </strong>,
                  `${getPrice(totalPrice, language)} / ${t(
                      selectedMembership?.paymentInterval === PaymentInterval.FourWeeks
                          ? 'per4Weeks'
                          : '370'
                  )}`
              ],
        // Credit after change
        selectedMembership?.correctionAmount !== 0
            ? [
                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  <span key={useId()} className="whitespace-normal">
                      {t('1663')}
                      <Icon
                          id="question"
                          className="mx-xxs cursor-pointer w-3 h-3"
                          onClick={() => setModalOpen(true)}
                      />
                      <br />
                      {(selectedMembership?.correctionAmount || 0) < 0 &&
                          t('membership.change.overcharged')}
                  </span>,
                  `${getPrice(selectedMembership?.correctionAmount || 0, language, {
                      format: 'withDigits'
                  })} ${t('78')}`
              ]
            : []
    ];

    const payNowData: TableData = [
        // Amount to be deducted from the next invoice (if any)
        [
            <strong key={useId()}>{amountToPay < 0 ? t('1670') : t('1665')}</strong>,
            `${getPrice(amountToPay, language)} ${t('78')}`
        ]
    ];

    async function onSubmit({ sepaAgreement, termsAgreement }: FieldValues) {
        const skipSEPA = renew || !!member.payment_plan.cancels_automatically;
        const isValidSubmit = skipSEPA ? !!termsAgreement : !!sepaAgreement && !!termsAgreement;

        const newrelicMessage = renew ? 'prepaid renewal call' : 'change membership call';
        const flow = renew ? 'prepaid-renewal' : 'change-membership';

        collectFunnelData({
            event: 'submit',
            message: newrelicMessage,
            flow,
            component: 'Overview',
            peopleId: member.people_id_g,
            renew
        });

        if (isValidSubmit) changeService.send({ type: 'SUBMIT' });
    }

    useEffect(() => {
        if (selectedMembership && amountToPay) {
            trackPromotion(
                'begin_checkout',
                selectedMembership.membershipType,
                Number(amountToPay.toFixed(2)),
                member.home_club,
                member.home_club_id,
                extras.map((extra) => extra.artc_short_description)
            );
        }
    }, [selectedMembership, amountToPay]);

    return (
        <>
            <Section>
                <Box variant="mint-top">
                    <Heading size={6} className="mb-m">
                        {t('1631')}
                    </Heading>
                    {/* Current Membership Data */}
                    <Heading size={7} className="mb-xxs text-nav-inactive">
                        {t('1633')}
                    </Heading>
                    <Table data={currentMembershipData} className="mb-xs" />
                    {/* New Membership Data */}
                    <Heading size={7} className="mb-xxs">
                        {t(renew ? 'renewal.extend.membership' : '1629')}
                    </Heading>
                    {renew && (
                        <Text size={2} className="italic mb-xxs text-anthracite">
                            {t('renewal.extend.description')}
                        </Text>
                    )}
                    <Table data={newMembershipData} />
                    {extras.length > 0 && (
                        <>
                            {/* Extras */}
                            <Text size={1} className="mb-xxs">
                                <strong className="font-heading-treble font-bold capitalize">
                                    {t('104')}
                                </strong>
                            </Text>

                            {extras?.map((item) => (
                                <ItemDetails
                                    key={item.artc_id}
                                    title={item.artc_short_description || ''}
                                    price={getPrice(item.artc_price, language)}
                                    intervalSeparator={!renew}
                                    interval={
                                        renew
                                            ? isOneTimeArticle(item)
                                                ? t('78')
                                                : priceInterval(
                                                      member.payment_plan
                                                          .interval as PaymentInterval
                                                  )
                                            : t(getArticleIntervalText(item, selectedMembership))
                                    }
                                />
                            ))}

                            <hr className="w-full my-xxs bg-anthracite" />
                        </>
                    )}
                    {/* Total Price */}
                    <Table data={totalPriceData} border={false} />
                    <hr className="w-full my-xxs bg-anthracite" />
                    <Table data={payNowData} border={false} />
                    {!renew && (
                        <Text size={2} className="mb-m">
                            {amountToPay > 0 ? t('1664') : t('1666')}
                        </Text>
                    )}
                    {/* Membership Information */}
                    <Heading size={7} className="mb-xxs">
                        {t('membership.change.membershipInformation')}
                    </Heading>
                    <Table
                        data={membershipInformationData.map((row) =>
                            // wrap every second element for each row in a <strong> tag
                            row.map((cell, idx) =>
                                idx % 2 === 0 ? cell : <strong key={idx}>{cell as string}</strong>
                            )
                        )}
                        className="mb-m"
                        border={false}
                    />

                    <Form
                        onSubmit={onSubmit}
                        name="confirmMembershipChange"
                        // Id is used to identify the form in the DOM and allow submitting it outside of the form
                        id="change-membership-form"
                    >
                        {!member.tel_home &&
                            selectedMembership?.membershipType === Membership.ALLIN && (
                                <UpdatePhoneNumber />
                            )}

                        {!renew && !member.payment_plan.cancels_automatically && (
                            <CheckBox
                                name="sepaAgreement"
                                label={
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(t('1671'))
                                        }}
                                    />
                                }
                                required={{
                                    value: true,
                                    message: t('membership.change.sepa.required')
                                }}
                            />
                        )}

                        <CheckBox
                            name="termsAgreement"
                            label={<TermsAndConditions />}
                            required={{
                                value: true,
                                message: t(
                                    'secondary_member.form.errors.terms_and_conditions_required'
                                )
                            }}
                        />
                        {showError && (
                            <Text size={2} className="text-orange mt-xs">
                                {showError}
                            </Text>
                        )}
                    </Form>
                </Box>
            </Section>
            {modalOpen && (
                <CreditChangeModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />
            )}
        </>
    );
};
