import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from '@src/components/Header/Header';
import { Section } from '@src/components/Layout/Section';
import { LineSVG } from '@src/components/LineSVG/LineSVG';
import { StrokeText } from '@src/components/StrokeText/StrokeText';
import { useMember } from '@src/services/member/MemberProvider';
import { captureException } from '@src/utils/helpers/newRelic';
import { usePreventEmployeeChangeDetails } from '@src/utils/hooks/usePreventEmployeeChangeDetails';

import { ChangePaymentInformationContext } from './changePaymentInfo.machine';
import { UpdatePaymentInformationComponent } from './UpdatePaymentInfo.component';
import { VerifyCode } from './VerifyCode.component';

export default function ChangePaymentInfo(): JSX.Element {
    const { t } = useTranslation();
    const { state: member } = useMember();

    usePreventEmployeeChangeDetails();

    return (
        <ChangePaymentInformationContext.Provider options={{ context: { email: member.email } }}>
            <div className="h-full">
                <div className="hidden lg:block">
                    <Header
                        title={t('395')}
                        text={t('399')}
                        image={<LineSVG id="eclipse" className="absolute right-0 top-0" />}
                    />
                </div>
                <Section className="p-4 lg:p-0">
                    <div className="mint-top" data-cy="member-email-block">
                        <StrokeText
                            stroke="last2"
                            color="anthracite"
                            className="mb-m"
                            text={t('details.change.payment.title')}
                        />
                        <ChangePaymentInformationFlow />
                    </div>
                </Section>
            </div>
        </ChangePaymentInformationContext.Provider>
    );
}

const ChangePaymentInformationFlow = () => {
    const shouldShowPaymentInfo = ChangePaymentInformationContext.useSelector((state) => {
        return state.matches('paymentInfo');
    });
    const shouldShowCode = ChangePaymentInformationContext.useSelector((state) => {
        return state.matches('code');
    });
    const currentState = ChangePaymentInformationContext.useSelector((state) => state.value);

    if (shouldShowPaymentInfo) return <UpdatePaymentInformationComponent />;
    if (shouldShowCode) return <VerifyCode />;

    captureException(new Error('invalid state'), {
        fn: 'ChangePaymentInformationFlow',
        currentState: String(currentState)
    });

    return null;
};
