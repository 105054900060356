import { captureException } from './newRelic';

export const redirect = (url: string) => {
    const domainWhitelist =
        window.__DOMAIN_WHITELIST__
            .split(',')
            .filter((host) => host !== '{__DOMAIN_WHITELIST__}') || [];

    if (!domainWhitelist.length) {
        return (window.location.href = url);
    }

    if (!/^https?:\/\//.test(url)) {
        return (window.location.href = url);
    }

    const host = new URL(url).host;

    if (domainWhitelist.includes(host)) {
        return (window.location.href = url);
    }

    captureException(new Error('failed to redirect'), { url });
};
