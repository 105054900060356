import { SecondaryMemberProps } from '@src/utils/hooks/api/types/secondaryMember';

export const getSecondaryMemberName = (member: SecondaryMemberProps) => {
    return `${member?.firstName || ''} ${member?.lastName || ''}`;
};

const secondaryMemberArticleIds = [
    'df083c91-99be-46cc-ad2d-6331a25fe427',
    'eee2ed2b-5a6a-4e3f-9c2b-3cad3edb0517',
    '083a860f-df7a-4d34-82b8-923bee230030',
    '10c93be7-c9c1-4d9d-afea-cd8bd72fc52c',
    '070a726a-364e-4379-a621-4286544b1145'
];

export const canUseSecondaryMemberFeature = (memberArticleIds: string[] | undefined) => {
    return secondaryMemberArticleIds.some((secondaryMemberArticleId) =>
        memberArticleIds?.includes(secondaryMemberArticleId)
    );
};

export const generateInviteLink = (token: string) => {
    return `${window.location.origin}/secondary-member/register?token=${token}`;
};
