import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@basic-fit/design-system';
import { ButtonLink } from '@src/components/Buttons/ButtonLink';
import { CopySsoLinkButton } from '@src/components/Buttons/CopySsoLinkButton';
import { Bold } from '@src/components/Text/Bold';
import { getDayAndMonth, getWeekdayFromString } from '@src/utils/helpers/date';
import { isApp, isIOS } from '@src/utils/helpers/window';
import { NoteArticle } from '@src/utils/hooks/api/types/debits';
import { useSsoUrl } from '@src/utils/hooks/api/useSsoUrl';

import titleCase from 'services/get-title-case';
import { UpcomingPaymentsModal } from '../modals/UpcomingPayments.modal';
import { Debit } from './Debit';

export const NotesIOS = () => {
    const { t } = useTranslation();
    return (
        <>
            <Text size={2} className="my-xs">
                {t('notes.ios.description')}
            </Text>
            <CopySsoLinkButton
                textStart={t('generic.copy_link')}
                textDone={t('generic.link_copied')}
            />
        </>
    );
};

export const NotesAndroid = () => {
    const { t } = useTranslation();
    const { ssoUrl } = useSsoUrl(isApp() && !isIOS()); // prefetch for android

    const getAppText = () => {
        const appText = t('notes.android.description');
        const link = (
            <a
                className="cursor-pointer text-orange"
                onClick={() => window.open(ssoUrl)}
                data-cy="notes-link"
            >
                {appText.match(/\[(.*?)\]/)?.[1] || ''} {/* Extracts text inside brackets */}
            </a>
        );

        const textBeforeLink = appText.split('[')[0];
        const textAfterLink = appText.split(']')[1];

        return (
            <>
                {textBeforeLink}
                {link}
                {textAfterLink}
            </>
        );
    };

    return (
        <>
            <Text size={2} className="my-xs">
                <span>{getAppText()}</span>
            </Text>
        </>
    );
};

export const NotesOverview = ({
    tableData,
    dates
}: {
    tableData: NoteArticle[];
    dates: string[];
}) => {
    const { t } = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);

    const getFullDate = (date: string) => {
        if (!date) {
            return 'unknown date';
        }
        const weekday = getWeekdayFromString(date);
        const dayAndMonth = getDayAndMonth(date, false);
        return `${weekday} ${dayAndMonth}`;
    };

    return (
        <>
            {tableData.length > 0 ? (
                <>
                    <Debit data={tableData} />

                    <Text size={2} className="mt-xs" data-testid={`next-debit-${dates[0] ?? 'na'}`}>
                        <Text className="first-letter:uppercase">
                            {t('payments.next.period')}{' '}
                            <Bold>{titleCase(getFullDate(dates[0]))}</Bold>
                        </Text>
                    </Text>

                    <ButtonLink
                        to="#"
                        className="mt-xxs first-letter:uppercase"
                        onClick={(event) => {
                            event.preventDefault();
                            setModalOpen(true);
                        }}
                        data-testid="debit-more-dates-link"
                    >
                        {t('payments.future.periods')}
                    </ButtonLink>

                    <UpcomingPaymentsModal
                        isOpen={modalOpen}
                        onClose={() => setModalOpen(false)}
                        dates={dates}
                    />
                </>
            ) : (
                <Text size={2} className="mt-xs first-letter:uppercase" data-testid="no-debits">
                    {t('payments.future.periods.no.fees')}
                </Text>
            )}
        </>
    );
};
