import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@basic-fit/design-system';
import { StockImage } from '@src/components/StockImage/StockImage';
import { StrokeText } from '@src/components/StrokeText/StrokeText';

export const AllInRestrictedPage = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <section>
            <StockImage id="bike" className="w-full" />
            <div className="mt-[16px]">
                <StrokeText
                    className="!text-[32px] !leading-[36px]"
                    color="anthracite"
                    text={t('all-in-only.no-access.title')}
                />
                <StrokeText
                    className="!text-[32px] !leading-[36px]"
                    color="anthracite"
                    stroke="all"
                    text={t('all-in-only.no-access.my-basic-fit')}
                />
            </div>
            <Text className="mt-[16px]" size={2}>
                {t('all-in-only.no-access.description')}
            </Text>
        </section>
    );
};
