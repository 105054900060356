import React from 'react';
import Select, { GroupBase, Props } from 'react-select';

/**
 * Create custom theme for react-select
 * https://react-select.com/styles
 */

const defaultFont = {
    fontFamily: 'Heading Pro Treble, sans-serif',
    fontWeight: 700
};

const customStyles = {
    control: (base: any) => ({
        ...base,
        ...defaultFont,
        background: '#ECE9E8',
        borderRadius: 0,
        fontSize: '18px',
        lineHeight: '56px',
        paddingLeft: '16px'
    }),
    option: (base: any) => ({
        ...base,
        ...defaultFont,
        borderRadius: 0,
        fontSize: '18px',
        paddingLeft: '20px',
        lineHeight: '56px'
    }),
    valueContainer: (base: any) => ({
        ...base,
        padding: 0
    }),
    dropdownIndicator: (base: any) => ({
        ...base,
        color: '#FE7000'
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 })
};

export const selectStylesWhite = {
    control: (base: any) => ({
        ...base,
        ...defaultFont,
        background: '#ffffff',
        borderRadius: 0,
        fontSize: '14px',
        lineHeight: '40px',
        paddingLeft: '16px'
    }),
    option: (base: any) => ({
        ...base,
        ...defaultFont,
        borderRadius: 0,
        fontSize: '14px',
        paddingLeft: '20px',
        lineHeight: '34px'
    }),
    valueContainer: (base: any) => ({
        ...base,
        padding: 0
    }),
    indicatorSeparator: (base: any) => ({
        ...base,
        visibility: 'hidden'
    }),
    dropdownIndicator: (base: any) => ({
        ...base,
        color: '#2D2D2D'
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    noOptionsMessage: (base: any) => ({
        ...base,
        ...defaultFont,
        fontSize: '14px'
    })
};

/**
 * Forward react-select generics so we can re-use the properties
 * https://react-select.com/typescript#select-generics
 */

export const SelectElement = <
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>(
    props: Props<Option, IsMulti, Group>
) => {
    return (
        <Select
            {...props}
            styles={props.styles || customStyles}
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary: '#FE7000',
                    primary25: '#ECE9E8',
                    primary50: '#EFEFEF'
                }
            })}
            isSearchable={false}
        />
    );
};
