import React from 'react';

export const WhipMint10 = () => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 281 65"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M223.1 58.5398C185.92 58.5398 185.92 5 148.74 5C111.56 5 111.56 58.5398 74.37 58.5398C37.18 58.5398 37.19 5 0 5"
            stroke="#7ADEC9"
            strokeWidth="10"
            strokeMiterlimit="10"
        />
        <path
            d="M280.18 59.0496C243 59.0496 243 5.50977 205.82 5.50977C168.64 5.50977 168.63 59.0496 131.45 59.0496C94.2701 59.0496 94.2701 5.50977 57.0801 5.50977"
            stroke="white"
            strokeWidth="10"
            strokeMiterlimit="10"
        />
    </svg>
);
