import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Loader } from '@src/components/Loader/Loader';
import { I18Provider } from '@src/services/translations/I18Provider';
import { NODE_ENV } from 'refactor/services/utilities';
import { createGlobalStyle } from 'styled-components';

import { store } from 'store/store';
import { App } from './App';

const GlobalStyles = createGlobalStyle`
  :root {
    --color-black: #000;
    --color-white: #fff;
    --color-anthracite: #2D2D2D;
    --color-anthracite-26: #424242;
    --color-anthracite-34: #575757;
    --color-anthracite-42: #6B6B6B;
    --color-anthracite-50: #808080;
    --color-anthracite-58: #949494;
    --color-anthracite-66: #A8A8A8;
    --color-anthracite-74: #BDBDBD;
    --color-cod-grey: #121212;
    --color-jersey-grey: #EFEFEF;
    --color-orange: #FE7000;
    --color-mint: #7ADEC9;
    --color-red: #EE3444;
    --color-primary: #6131AD;
    --color-primary-hover: #8c57cd;
    --color-primary-disabled: #7322C9;
    --font-heading-pro-treble: 'Heading Pro Treble';
    --font-heading-pro-double: 'Heading Pro Double';
    --width-left-nav: 290px;
    --width-content: 880px;
    --box-shadow-left-nav: 0 0 8px rgba(0,0,0,0.25);
    --devices-sm-max: 767px;
    --devices-md-min: 768px;
    --animation-time: 0.25s;
    --gradient-orange: linear-gradient(135deg, #FFAA30 0%, #FE7000 36.14%, #FE7000 100%);
    --gradient-anthracite: linear-gradient(135deg, #2D2D2D 31.77%, #121212 100%);
    --gradient-mint: linear-gradient(135deg, #CAFCF2 0%, #7ADEC9 36.14%, #7ADEC9 100%);
  }
`;

(() => {
    /**
     * Redirect port 3000 to 3001 for dev
     */
    if (NODE_ENV === 'development' && location.port === '3000') {
        location.port = '3001';
        return;
    }
    const domNode = document.getElementById('root') as HTMLElement;
    const root = createRoot(domNode);

    root.render(
        <React.StrictMode>
            <Suspense
                fallback={
                    <div className="flex absolute justify-center items-center w-screen h-screen bg-white">
                        <Loader delay={true} />
                    </div>
                }
            >
                <BrowserRouter>
                    <I18Provider>
                        <Provider store={store}>
                            <GlobalStyles />
                            <App />
                        </Provider>
                    </I18Provider>
                </BrowserRouter>
            </Suspense>
        </React.StrictMode>
    );
})();
