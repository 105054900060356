type Action = {
    type: 'MEMBER_DATA';
    // TODO: Define the payload type
    payload: any;
};

export function memberReducer(state = { member: false }, action: Action) {
    switch (action.type) {
        case 'MEMBER_DATA':
            return {
                ...state,
                member: action.payload
            };
        default:
            return state;
    }
}
