import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { QRCodeAccessModal } from '@src/pages/Membership/Membership/components/Modals/QRCodeAccess.modal';
import { RemoveDeviceModal } from '@src/pages/Membership/Membership/components/Modals/RemoveDevice.modal';
import { useMember } from '@src/services/member/MemberProvider';
import { GetDeviceInfoProps } from '@src/utils/hooks/api/types/getDeviceInfo';
import { ValidateDeviceSwapProps } from '@src/utils/hooks/api/types/validateDeviceSwap';
import { useFetch } from '@src/utils/hooks/api/useFetch';

import { ChangeAccessBody } from './ChangeAccessBody';
import { ChangeAccessButton } from './ChangeAccessButton';
import { ChangeAccessCardWarning } from './ChangeAccessCardWarning';
import { ChangeAccessTable } from './ChangeAccessTable';
import { ChangeAccessWarning } from './ChangeAccessWarning';

export const ChangeAccess = () => {
    const [removeDeviceOpen, setRemoveDeviceOpen] = useState(false);
    const [qrCodeAccessOpen, setQRCodeAccessOpen] = useState(false);
    const { t } = useTranslation();

    const {
        state: { accessType }
    } = useMember();

    const { data: validateDeviceSwap } = useFetch<ValidateDeviceSwapProps>(
        '/member/validate-device-swap'
    );
    const shouldGetDeviceInfo = accessType !== 'card';

    const { data: deviceInfo } = useFetch<GetDeviceInfoProps>(
        shouldGetDeviceInfo ? '/member/get-device-info' : null
    );

    const canSwapDevice =
        validateDeviceSwap?.swapCountLeft != 0 || validateDeviceSwap?.allowMshpDeviceSwap;

    if (accessType !== 'cardandqr' && accessType !== 'qr' && shouldGetDeviceInfo) return null;

    const isCardType = accessType === 'card';
    const handleClick = () => {
        if (isCardType) {
            setQRCodeAccessOpen(true);
        }

        if (!isCardType && deviceInfo?.deviceDescription && canSwapDevice) {
            setRemoveDeviceOpen(true);
        }
    };

    return (
        <>
            <Box id="change-access" variant="orange" size="small" className="mb-xxl">
                <Heading size={5} className="mb-xxs">
                    {t('access-change.title')}
                </Heading>
                <ChangeAccessBody deviceInfo={deviceInfo} validateDeviceSwap={validateDeviceSwap} />
                <ChangeAccessWarning
                    deviceInfo={deviceInfo}
                    validateDeviceSwap={validateDeviceSwap}
                />
                <ChangeAccessTable deviceInfo={deviceInfo} />
                <ChangeAccessCardWarning />
                <ChangeAccessButton
                    onClick={handleClick}
                    canSwapDevice={canSwapDevice}
                    deviceInfo={deviceInfo}
                />
            </Box>

            {canSwapDevice && (
                <RemoveDeviceModal
                    isOpen={removeDeviceOpen}
                    nextSwapDate={validateDeviceSwap?.nextSwapDate}
                    swapCountLeft={validateDeviceSwap?.swapCountLeft}
                    shouldShowWarning={validateDeviceSwap?.allowMshpDeviceSwap === false}
                    onClose={() => setRemoveDeviceOpen(false)}
                />
            )}

            <QRCodeAccessModal
                isOpen={qrCodeAccessOpen}
                onClose={() => setQRCodeAccessOpen(false)}
            />
        </>
    );
};
