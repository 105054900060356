import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FAQ } from '@src/components/FAQ/FAQ.component';
import { Header } from '@src/components/Header/Header';
import { Section } from '@src/components/Layout/Section';
import { LineSVG } from '@src/components/LineSVG/LineSVG';
import { DebitBlock } from '@src/feature/DebitBlock/DebitBlock';
import { Notes } from '@src/pages/Payments/components/Notes';
import { ProofOfPayment } from '@src/pages/Payments/components/ProofOfPayment';
import { useMember } from '@src/services/member/MemberProvider';
import { useMBFFeatures } from '@src/utils/hooks/api/useMBFFeatures';
import { useClearDebtSession } from '@src/utils/hooks/useClearDebtSession';

import { DisabledPaymentModal } from './components/DisabledPaymentModal.payment';
import { MemberHasDebt } from './components/MemberHasDebt.payment';
import { TransactionOverview } from './components/TransactionOverview.payment';
import { TransactionOverviewFrance } from './components/TransactionOverviewFrance.payment';

export const PaymentPage = (): JSX.Element => {
    const { t } = useTranslation();
    const { state: member } = useMember();
    const { notesForFrance } = useMBFFeatures();

    const [paymentsDisabled, setPaymentsDisabled] = useState(false);
    const { loading } = useClearDebtSession();

    useEffect(() => {
        if (member) {
            const { paymentsDisabled } = member.home_club_s;
            setPaymentsDisabled(paymentsDisabled);
        }
    }, [member]);

    if (loading) return <></>;

    const shouldShowFranceNotes = Boolean(notesForFrance && member?.mailing_country === 'France');

    return (
        <div className="p-4 sm:p-0 h-full w-full">
            {/* MODAL */}
            {paymentsDisabled && <DisabledPaymentModal />}

            {/* HEADER */}
            <Header
                title={t('29')}
                text={t('1967')}
                image={
                    <LineSVG
                        id="white-vertical-lines"
                        height={267}
                        className="hidden xl:block absolute right-0 top-0"
                    />
                }
            />

            <DebitBlock />

            {/* MEMBER HAS DEBT */}
            <MemberHasDebt peopleId={member.people_id_g} />

            <Section>
                <Notes />
            </Section>

            {member.mailing_country === 'France' && (
                <Section>
                    <ProofOfPayment />
                </Section>
            )}

            {/* TRANSACTION OVERVIEW */}
            {shouldShowFranceNotes ? <TransactionOverviewFrance /> : <TransactionOverview />}


            {/* FAQ */}
            <Section className="mb-xl">
                <FAQ category="payments" />
            </Section>
        </div>
    );
};
