import React from 'react';

export default function CalendarIcon() {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 1 1"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="scale-[0.8] transition-[transform] duration-[300ms] group-hover:scale-[0.9]"
        >
            <line y1=".5" y2=".5" x2="1" stroke="currentColor" strokeWidth="0.2" />
            <line x1=".5" y2="1" x2=".5" stroke="currentColor" strokeWidth="0.2" />
        </svg>
    );
}
