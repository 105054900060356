import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '@src/components/Heading/Heading';
import { MyInformationBlock } from '@src/components/MyInformation/MyInformationBlock';
import { Table } from '@src/components/Table/Table';
import { Text } from '@src/components/Text/Text';
import { useMember } from '@src/services/member/MemberProvider';
import { getMemberName } from '@src/utils/helpers/memberHelpers';

import { toLocal } from 'services/date-converter';
import googleTagManagerPush from 'services/google-tag-manager-push';
import PaymentInformation from './PaymentInformation.myinformation';
import PersonalDataManagement from './PersonalDataManagement.myinformation';

export default function MyPersonalInfoTable(): JSX.Element {
    const { state: member } = useMember();
    const { t } = useTranslation();

    const effectRan = useRef(false);

    useEffect(() => {
        if (!effectRan.current) {
            googleTagManagerPush.viewPageEvent('view personal information');
        }
        effectRan.current = true;
    }, []);

    const nonChangeableData = [
        [
            t('151'),
            <strong key="" data-cy="member-name">
                {getMemberName(member)}
            </strong>
        ],
        [
            t('152'),
            <strong key="" data-cy="member-birthday">
                {toLocal(member.birth_date)}
            </strong>
        ]
    ];

    const isEmployee = member.isEmployee;
    const hasAddress = Boolean(member.mailing_street && member.mailing_house_number);
    const hasPostCodeAndCity = Boolean(member.poste_code && member.mailing_city);

    return (
        <div className="mint-top">
            <Heading size="xs" className="!text-[28px] leading-8 mb-6 font-extrabold">
                {t('my.details')}
            </Heading>
            <Text
                size="m"
                className="!text-regular-m lowercase first-letter:uppercase font-bold font-heading-treble"
            >
                {t('30')}
            </Text>
            <Table
                data={nonChangeableData}
                style="white"
                className="border-b border-anthracite-74 mb-2 font-medium"
            />

            <div className="text-grey-disabled text-xs leading-4">{t('not.allowed.to.change')}</div>

            <div
                data-cy="personal-data-management"
                className="divide-y divide-anthracite-74 border-b border-anthracite-74 pt-8"
            >
                <MyInformationBlock
                    title={t('9')}
                    dataCy="member-email-block"
                    buttonTo="/information/change-email"
                    shouldSendGA={true}
                >
                    <Text size="s" className="text-grey-disabled" data-cy="member-email">
                        {member.email ?? ''}
                    </Text>
                </MyInformationBlock>

                <MyInformationBlock
                    title={t('394')}
                    buttonTo="/information/change-phone"
                    dataCy="member-phone-block"
                >
                    <Text size="s" className="text-grey-disabled" data-cy="member-phone">
                        {member.tel_home ?? ''}
                    </Text>
                </MyInformationBlock>

                <MyInformationBlock
                    title={t('153')}
                    buttonTo="/information/change-address"
                    dataCy="member-address-block"
                >
                    <Text size="s" className="text-grey-disabled" data-cy="member-address">
                        {hasAddress && member.mailing_street + ' ' + member.mailing_house_number}
                        {hasAddress && hasPostCodeAndCity && <br />}
                        {hasPostCodeAndCity && member.poste_code + ' ' + member.mailing_city}
                    </Text>
                </MyInformationBlock>

                {!isEmployee && <PaymentInformation />}
            </div>

            <PersonalDataManagement />
        </div>
    );
}
