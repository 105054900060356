import React from 'react';
import styled from 'styled-components';

const Box = styled.div`
  font-family: var(--font-heading-pro-double);
  ${({ fontSize, lineHeight, textAlign, color, boldTag, styles, bottomMargin = true }) => `
    ${fontSize ? `font-size: ${fontSize}` : `font-size: 18px`};
    ${lineHeight ? `line-height: ${lineHeight}` : `line-height: 27px`};
    ${textAlign ? `text-align: ${textAlign}` : ''};
    ${color ? `color: ${color}` : `color: var(--color-black)`};
    ${bottomMargin ? `margin-bottom: ${bottomMargin}` : 'margin-bottom: 16px;'};
    ${boldTag ? `font-family: ArgumentumMedium;margin-top: -12px;` : ''};
    ${styles ? styles : ``}
  `}
`;

const ParagraphNormal = ({ text = '', dangerouslySetInnerHTML = '', ...rest }) => {
    return !dangerouslySetInnerHTML ? (
        <Box {...rest}>{text}</Box>
    ) : (
        <Box {...rest}>
            <div dangerouslySetInnerHTML={{ __html: text }} />
        </Box>
    );
};

export default ParagraphNormal;
