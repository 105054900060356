import React from 'react';
import styled from 'styled-components';

export default ({ size = '', title = '', onClick = () => {}, width = '', top = '' }) => {
    const Container = styled.div`
    margin: auto;
    text-align: center;
    font-family: Impact;
    font-size: ${size ? size : '16px'};
    text-transform: uppercase;
    cursor: pointer;
    margin-top: ${top ? top : '8px'};
    height: 40px;
    line-height: 40px;
    width: ${width ? width : '30%'};
  `;

    return <Container onClick={onClick}>{title}</Container>;
};
