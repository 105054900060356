import React, { HTMLAttributes } from 'react';
import { Heading } from '@basic-fit/design-system';
import { VisitSquare } from '@src/components/Visits/VisitSquare';
import { ButtonLink } from 'refactor/components/Buttons/ButtonLink';

export interface IVisitBlock extends HTMLAttributes<HTMLDivElement> {
    data: { title: string; number: number | string; tag: string }[];
    title: string;
    buttonText: string;
    buttonLink: string;
}

export const VisitBlock = ({
    className = '',
    data,
    title,
    buttonText,
    buttonLink,
    ...rest
}: IVisitBlock): JSX.Element => {
    return (
        <div className={`bg-mint p-[30px] md:p-[60px] ${className}`} {...rest}>
            <Heading size={5} color="white" className="mb-[35px]">
                <span className="text-white">{title}</span>
            </Heading>
            <div className="grid grid-cols-2 grid-flow-row auto-rows-auto w-full sm:grid-cols-3 xl:grid-cols-4 mbf-visits gap-[30px] mb-[0] sm:mb-[0] lg:mb-[0] xl:gap-[30px]">
                {data.map((item, index) => (
                    <VisitSquare {...item} key={index} />
                ))}
            </div>

            <div className="flex justify-end w-full">
                <ButtonLink type="link" to={buttonLink} className="text-white mt-[20px]">
                    {buttonText}
                </ButtonLink>
            </div>
        </div>
    );
};
