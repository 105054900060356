export const availableAddons = {
  LIVE_GX: 'LiveGX',
  PERSONAL_ONLINE_COACH: 'Personal Online Coach',
  PERSONAL_TRAINING_INTRO: 'Personal Training Intro',
  PRO_COACH: 'Pro Coach',
  YANGA: 'Yanga Sportswater',
  ALL_IN_APP: 'All-In App',
  BIKE: 'Bike',
  PICK_UP: 'Pickup',
  DELIVERY: 'Delivery',
  MESSAGE_CHAIR: 'Massagechair',
  PRO_APP: 'Pro-App'
};
