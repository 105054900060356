import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@basic-fit/design-system';
import { MemberPriceContract } from '@src/components/Price/MemberPriceContract';
import { TableFlex } from '@src/components/TableFlex/TableFlex';
import { Bold } from '@src/components/Text/Bold';
import { useMember } from '@src/services/member/MemberProvider';
import { hasAccessCard, hasMembership } from '@src/utils/helpers/memberHelpers';
import { v4 as uuidv4 } from 'uuid';

import { toLocal } from 'services/date-converter';

export const CancelledMembershipTable = () => {
  const { t } = useTranslation();
  const { state: member } = useMember();

  const data = [
    [t('40'), <Bold key={uuidv4()}>{member.membership.display_name}</Bold>],
    [t('41'), <Bold key={uuidv4()}>{member.membership.display_duration}</Bold>],
    [t('43'), <Bold key={uuidv4()}>{toLocal(member.membership.start_date_g)}</Bold>],
    [t('100'), <MemberPriceContract key={uuidv4()} className="justify-end" />],
    [t('454'), <Bold key={uuidv4()}>{member.membership_number_s}</Bold>]
  ];

  if (hasAccessCard(member)) {
    data.push([t('409'), <Bold key={uuidv4()}>{member.card_number_s}</Bold>]);
  }

    return hasMembership(member) ? (
        <TableFlex data={data} size="s" />
    ) : (
        <Text size={2}>{t('451')}</Text>
    );
};
