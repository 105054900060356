import React, { ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface TableProps extends Omit<React.HTMLAttributes<HTMLTableElement>, 'size' | 'style'> {
    data: ReactNode[][];
    size?: 's' | 'm';
    style?: 'white' | 'grey';
    border?: boolean;
}

export const Table = ({
    data,
    size = 's',
    style = 'white',
    border = true,
    ...rest
}: TableProps) => {
    return (
        <table
            className={`w-full table-fixed ${
                size === 's' ? 'text-regular-s' : 'text-regular-s sm:text-regular-m'
            } ${style === 'grey' ? 'bg-jersey-grey' : 'bg-white'} ${rest.className ?? ''}`}
        >
            <tbody>
                {data.map((item, index) => (
                    <tr
                        key={`row-${index}`}
                        className={
                            border
                                ? 'border-b border-anthracite-74 last-of-type:border-none'
                                : undefined
                        }
                    >
                        {item.map((value) => (
                            <td
                                key={uuidv4()}
                                className={`
                                    py-xxs last:text-right truncate
                                    ${style === 'grey' ? 'px-xs' : ''}
                                `}
                            >
                                <>{value}</>
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
