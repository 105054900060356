import React, { useEffect, useState } from 'react';
import { Button } from '@src/components/Buttons/Button';
import { useSsoUrl } from '@src/utils/hooks/api/useSsoUrl';

type ButtonProps = {
    textStart: string;
    textDone: string;
    theme?: 'solid' | 'outline';
};

export const CopySsoLinkButton = ({ textStart, textDone, theme, ...rest }: ButtonProps) => {
    const [linkCopied, setLinkCopied] = useState(false);
    const [getToken, setGetToken] = useState(false);

    const { ssoUrl } = useSsoUrl(getToken);

    useEffect(() => {
        if (ssoUrl) {
            navigator?.clipboard?.writeText(ssoUrl);
            setLinkCopied(true);
        }
    }, [ssoUrl]);

    const copyUrl = () => {
        setGetToken(true);
    };

    return (
        <Button
            text={linkCopied ? textDone : textStart}
            className="mt-m w-auto p-s m-auto"
            onClick={copyUrl}
            theme={theme}
            {...rest}
        />
    );
};
