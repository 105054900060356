import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '@src/components/Buttons/Button';
import { ModalErrorState } from '@src/components/Modals/ModalErrorState';

interface IReverseCancellationErrorModalProps {
    error: string;
}

export const ReverseCancellationErrorModal = ({ error }: IReverseCancellationErrorModalProps) => {
    const { t } = useTranslation();

    const response = {
        text: t('reverseCancellation.modal.errorBody500'),
        showCustomerSupportButton: true,
        showDebtButton: false
    };

    switch (error) {
        case 'ERR_NEAR_END_DATE':
            response.text = t('reverseCancellation.modal.errorBody573');
            break;
        case 'ERR_REMINDER_STATUS_9':
            response.text = t('reverseCancellation.modal.errorBody574');
            response.showCustomerSupportButton = false;
            response.showDebtButton = true;
            break;
        case 'ERR_GM_UNHANDLED_ERROR':
            response.text = t('reverseCancellation.modal.errorBodyGymManager');
            break;
    }

    return (
        <span data-cy="reverse-cancellation__modal-error">
            <ModalErrorState
                body={response.text}
                hideCustomerSupportButton={!response.showCustomerSupportButton}
            />
            {response.showDebtButton && (
                <Link to="/payments">
                    <Button
                        text={t('reverseCancellation.modal.debtButton')}
                        className="mt-xs"
                        theme="outline"
                    />
                </Link>
            )}
        </span>
    );
};
