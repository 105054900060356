import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import updateCompensationAgreement from '../../services/API/update-compensation-agreement';
import ButtonSmall from '../partials/ButtonSmall';
import Gap from '../partials/Gap';
import PageHead from '../partials/PageHead';
import ParagraphNormalMulti from '../partials/ParagraphNormalMulti';
import DisagreePopup from './DisagreePopup';
import GetCompensationIcon from './GetCompensationIcon';

const Box = styled.div`
  background: #fff;
  position: relative;
  padding-right: 16px;
  padding-left: 16px;
  margin-bottom: 24px;
`;

const Title = styled.span`
  font-family: ArgumentumMedium;
  font-size: 16px;
  color: #313136;
  margin-bottom: 4px;
`;

const Emoji = styled.span`
  margin-left: 7px;
  vertical-align: middle;
`;

const Subtitle = styled.div`
  font-family: Argumentum;
  font-size: 14px;
  color: #adadd0;
  display: block;
`;

const RightBoxSwitch = styled.div`
  background: red;
  position: absolute;
  top: 0px;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
`;

const TitleSection = styled.div`
  display: block;
  position: relative;
`;

const DescriptionSection = styled.div`
  overflow: hidden;
  font-family: Argumentum;
  font-size: 16px;
  line-height: 18px;
  padding-top: 13px;
  padding-bottom: 13px;
  position: relative;
`;

const Icon = styled.div`
  float: left;
  width: 27%;
  text-align: center;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`;

const Description = styled.div`
  width: 73%;
  float: right;
`;

const ChosenBtn = styled.div`
  text-transform: uppercase;
  font-family: Impact;
  font-style: normal;
  font-size: 16px;
  text-align: center;
  color: white;
  background: #ababab;
  letter-spacing: 0.1em;
  padding: 2px;
`;

export default ({ compensation, compensations, index, onChooseFunction, choose, chosen }) => {
    const { t } = useTranslation();
    const [showDisagreePopup, setShowDisagreePopup] = useState(false);
    const [showAgreePopup, setShowAgreePopup] = useState(false);
    const [error, setError] = useState(false);
    const [changeClicked, setChangeClicked] = useState(false);
    const screenWidth1 = useMediaQuery({ maxWidth: '679px', minWidth: '555px' });
    const screenWidth2 = useMediaQuery({ maxWidth: '554px', minWidth: '480px' });
    const screenWidth3 = useMediaQuery({ maxWidth: '479px', minWidth: '320px' });
    const screenWidth4 = useMediaQuery({ maxWidth: '319px', minWidth: '50px' });
    const leftBoxMaxWidth = screenWidth1
        ? '75%'
        : screenWidth2
        ? '70%'
        : screenWidth3
        ? '60%'
        : screenWidth4
        ? '55%'
        : '70%';

    const dispatch = useDispatch();

    const { member } = useSelector((state) => ({
        ...state.memberReducer
    }));

    const LeftBoxTitle = styled.div`
    display: block;
    padding-top: 13px;
    position: relative;
    max-width: ${leftBoxMaxWidth};
  `;

    /** height edit coz the last divider disappears! */
    const varHeight =
        (compensations && compensations.length === index + 1) || chosen ? '2px' : '1px';
    const Divider = styled.div`
    height: ${varHeight};
    background: #d5d5e5;
  `;

    const handleChange = async (agreed) => {
        if (!changeClicked) {
            setChangeClicked(true);
            if (await updateCompensationAgreement(compensation.chosenCompensation.id, agreed)) {
                const copy = Object.assign(Object.create(Object.getPrototypeOf(member)), member);
                await copy.getChosenCompensationsAndSetIsFrozen();
                dispatch({
                    type: 'MEMBER_DATA',
                    payload: copy
                });
                setShowAgreePopup(false);
                setShowDisagreePopup(false);
            } else {
                setError(t('238')); // something wrong
            }
            setChangeClicked(false);
        }
    };

    if (compensation?.chosenCompensation?.member_disagreed__c) {
        return (
            <>
                <PageHead
                    title={t(compensation.disagreeTitleLabel) || t('1706') || 'missing label'}
                    size="16px"
                />
                <ParagraphNormalMulti
                    text={t(compensation.disagreeDescriptionLabel) || t('1703') || 'missing label'}
                />
            </>
        );
    }

    return (
        <>
            {showAgreePopup && (
                <DisagreePopup
                    cancelFunction={() => {
                        setShowAgreePopup(false);
                    }}
                    confirmFunction={() => {
                        handleChange(true);
                    }}
                    title={t(compensation.agreeTitleLabel) || t('1706') || 'missing label'}
                    description={
                        t(compensation.agreeDescriptionLabel) || t('1707') || 'missing label'
                    }
                    disabled={changeClicked}
                    error={error}
                />
            )}
            {showDisagreePopup && (
                <DisagreePopup
                    cancelFunction={() => {
                        setShowDisagreePopup(false);
                    }}
                    confirmFunction={() => {
                        handleChange(false);
                    }}
                    title={t(compensation.disagreeTitleLabel) || t('1702') || 'missing label'}
                    description={
                        t(compensation.disagreeDescriptionLabel) || t('1703') || 'missing label'
                    }
                    disabled={changeClicked}
                    error={error}
                />
            )}
            <Box key={index}>
                <TitleSection>
                    <LeftBoxTitle>
                        <Title>{compensation.title}</Title>
                        <Emoji>
                            <GetCompensationIcon icon={compensation.emoji} />
                        </Emoji>

                        <Subtitle>{compensation.subTitle}</Subtitle>
                    </LeftBoxTitle>

                    <RightBoxSwitch>
                        {!chosen ? (
                            <ButtonSmall
                                text={choose ? choose.toUpperCase() : 'CHOOSE'}
                                onClick={onChooseFunction && onChooseFunction}
                                subject={compensation.icon}
                            />
                        ) : (
                            <>
                                {compensation.allowToDisagree &&
                                !compensation.chosenCompensation.member_agreed__c ? (
                                    <>
                                        <ChosenBtn
                                            style={{ background: '#7322C9' }}
                                            onClick={() => {
                                                setShowAgreePopup(true);
                                            }}
                                        >
                                            {t('1705') || 1705}
                                        </ChosenBtn>
                                        <ChosenBtn
                                            onClick={() => {
                                                setShowDisagreePopup(true);
                                            }}
                                        >
                                            {t('1701') || 1701}
                                        </ChosenBtn>
                                    </>
                                ) : (
                                    <ChosenBtn style={{ lineHeight: '32px' }}>{chosen}</ChosenBtn>
                                )}
                            </>
                        )}
                    </RightBoxSwitch>
                </TitleSection>

                <Gap height="3px" />
                <Divider />

                <DescriptionSection>
                    <Icon>
                        <img
                            src={`img/icons/${compensation.icon}.jpg`}
                            alt="Smiley face"
                            height="50"
                        />
                    </Icon>
                    <Description>{compensation.description}</Description>
                </DescriptionSection>
            </Box>
        </>
    );
};
