import axios from 'axios';

import { domain, headers } from '.';

export const deleteFriend = async (friend: any) => {
    try {
        await axios({
            method: 'POST',
            url: `${domain}/friends/delete-friend`,
            headers,
            data: {
                friend_id: friend.id_g,
                date_of_birth: friend.birth_date_g,
                first_name: friend.first_name_g,
                last_name: friend.last_name_g,
                gender: friend.gender_g
            }
        });
    } catch (e) {
        console.log('Unable to delete friend');
        throw e;
    }
};
