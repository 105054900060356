import React, { useEffect, useId, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@src/components/Buttons/Button';
import { Form } from '@src/components/Form/Form';
import { TextInput } from '@src/components/Form/TextInput/TextInput';
import { TableFlex } from '@src/components/TableFlex/TableFlex';
import { Bold } from '@src/components/Text/Bold';
import { Text } from '@src/components/Text/Text';
import { useMember } from '@src/services/member/MemberProvider';

import googleTagManagerPush from 'services/google-tag-manager-push';
import { ChangeEmailContext } from './changeEmail.machine';

export const NewEmail = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state: member } = useMember();
    const [state, send] = ChangeEmailContext.useActor();

    const effectRan = useRef(false);

    useEffect(() => {
        if (!effectRan.current) {
            googleTagManagerPush.viewPageEvent('view email page');
        }
        effectRan.current = true;
    }, []);

    const emailError = ChangeEmailContext.useSelector((ctx) => {
        if (ctx.matches('email.error.invalidEmail')) {
            return t('details.change.email.email.invalid');
        }
        return undefined;
    });

    const confirmEmailError = ChangeEmailContext.useSelector((ctx) => {
        if (ctx.matches('email.error.emailMismatch')) {
            return t('details.change.email.email.mismatch');
        }
        return undefined;
    });

    const emailTable = [[t('9'), <Bold key={useId()}>{member.email}</Bold>]];

    return (
        <div data-cy="change-email-email">
            <Text size="m" className="!text-regular-m lowercase first-letter:uppercase font-bold">
                {t('details.change.email.email.tableHeader')}
            </Text>
            <TableFlex size="s" data={emailTable} className="border-b border-anthracite-74" />
            <Form
                onSubmit={({ newEmail, confirmEmail }) => {
                    return send({ type: 'SUBMIT_EMAIL', newEmail, confirmEmail });
                }}
                name="email"
                className="space-y-3 pt-6"
            >
                <Text
                    size="m"
                    className="!text-regular-m lowercase first-letter:uppercase font-bold"
                >
                    {t('details.change.email.email.formHeader')}
                </Text>
                <TextInput
                    required
                    type="email"
                    name="newEmail"
                    label={t('details.change.email.email.emailLabel')}
                    error={emailError}
                    defaultValue={state.context.newEmail}
                    onInput={() => send('EDITING')}
                    data-cy="new-email"
                />
                <TextInput
                    onPaste={(e) => e.preventDefault()}
                    required
                    type="email"
                    name="confirmEmail"
                    label={t('details.change.email.email.confirmEmailLabel')}
                    error={confirmEmailError}
                    defaultValue={state.context.confirmEmail}
                    onInput={() => send('EDITING')}
                    data-cy="confirm-email"
                />
                <SubmitButton />
                <Button
                    type="button"
                    onClick={() => navigate('/information')}
                    className="!bg-white hover:bg-white !text-anthracite"
                    text={t('162')}
                    data-cy="back"
                />
            </Form>
        </div>
    );
};

const SubmitButton = () => {
    const { t } = useTranslation();
    const { state: member } = useMember();
    const { watch } = useFormContext();
    const newEmailValue = watch('newEmail');

    return (
        <Button
            type="submit"
            text={t('128')}
            data-cy="submit-email"
            disabled={newEmailValue === member.email}
            onClick={() => googleTagManagerPush.clickEvent('confirm new email')}
        />
    );
};
