import { useContext, useEffect, useState } from 'react';
import { ChangeMembershipContext } from '@src/pages/ChangeMembership/machines/ChangeMembershipProvider';
import { isEmpty } from '@src/utils/helpers/collections';
import { captureException } from '@src/utils/helpers/newRelic';
import { ArticleByPaymentSchedule } from '@src/utils/hooks/api/types/articleByPaymentSchedule';
import { useFetch } from '@src/utils/hooks/api/useFetch';
import { useSelector } from '@xstate/react';

import { extrasSelector, membershipSelector } from '../machines/changeMembershipSelectors';

// Hook that returns all the payment amounts for the selected membership
export const usePaymentAmounts = (renew = false) => {
    const {
        data: promotion,
        isLoading,
        isError
    } = useFetch<{ hasPromotion: boolean; amount: number }>(
        renew ? '/memberships/get-promotion' : null
    );

    useEffect(() => {
        if (isError) {
            captureException('failed to fetch promotion', {
                fn: 'usePaymentAmounts',
                renew: String(renew)
            });
        }
    }, [isError, renew]);

    const [discountPrice, setDiscountPrice] = useState(0);

    useEffect(() => {
        if (!isEmpty(promotion) && !isLoading) {
            setDiscountPrice(promotion?.amount || 0);
        }
    }, [promotion, isLoading]);

    const membershipService = useContext(ChangeMembershipContext);
    const selectedMembership = useSelector(membershipService.changeService, membershipSelector);
    const selectedExtras: ArticleByPaymentSchedule[] = useSelector(
        membershipService.changeService,
        extrasSelector
    );

    const totalPriceExtras = Object.values(selectedExtras).reduce(
        (total, current) => total + (current.artc_periodical ? current.artc_price : 0),
        0
    );
    const oneTimePrice = Object.values(selectedExtras).reduce(
        (total, current) => total + (!current.artc_periodical ? current.artc_price : 0),
        0
    );

    const selectedTermfee = selectedMembership?.termFee || 0;
    const termfee = renew ? selectedTermfee - discountPrice : selectedTermfee;
    const totalPrice = totalPriceExtras + termfee;

    const amountToPay =
        totalPrice +
        oneTimePrice +
        (selectedMembership?.prorataAmount || 0) +
        (selectedMembership?.correctionAmount || 0);

    return {
        totalPrice,
        totalPriceExtras,
        oneTimePrice,
        amountToPay
    };
};
