import useSWR from 'swr';

/**
 * Call /logout based on conditional fetching
 * We want to use SWR error retry when the /logout call fails for whatever reason
 * https://swr.vercel.app/docs/error-handling#error-retrynit
 *
 * todo: There is no UX handling for isLogoutError
 */
export const useLogout = (init: boolean) => {
    const fetcher = (url: string) => fetch(url, { method: 'POST' }).then((res) => res.json());

    const { data, error } = useSWR(init ? '/authentication/logout' : null, fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
            if (error.status === 404) return;
            if (retryCount >= 3) return;
            setTimeout(() => revalidate({ retryCount }), 1000);
        }
    });

    if (data?.logoutUrl) {
        window.location.assign(data.logoutUrl);
    }

    return {
        isLogoutError: error
    };
};
