import { domain, headers } from '@src/services/data';
import { captureException } from '@src/utils/helpers/newRelic';
import axios from 'axios';
import { actions, createMachine } from 'xstate';

import { CHANGE_MEMBER_ERROR_MESSAGE_MAP } from 'types/change-member-response';

async function sendVerificationToken(ctx: Record<string, string>) {
    const { data } = await axios({
        method: 'POST',
        url: `${domain}/member/validate-send-token`,
        headers,
        data: ctx
    });

    if (!data.success) {
        captureException({
            message: data.message ?? 'n/a',
            name: 'sendVerificationToken'
        });
        throw new Error();
    }
}

export const sendVerificationTokenMachine = createMachine(
    {
        id: 'sendVerificationToken',
        predictableActionArguments: true,
        preserveActionOrder: true,
        context: {} as Record<string, string>,
        initial: 'active',
        states: {
            active: {
                description:
                    'we want to immediately make the request to send the verification token',
                invoke: {
                    id: 'sendVerificationTokenService',
                    src: 'sendVerificationToken',
                    onDone: { target: 'success' },
                    onError: {
                        description:
                            'handle errors from sendVerificationToken request and escalate to parent',
                        actions: actions.escalate((_, ev) => {
                            if (!axios.isAxiosError(ev.data)) {
                                captureException('failed to complete request', {
                                    fn: 'sendVerificationToken'
                                });
                                return {
                                    message: CHANGE_MEMBER_ERROR_MESSAGE_MAP.UNEXPECTED_ERROR
                                };
                            }

                            const message = ev.data.response?.data?.message;
                            if (message in CHANGE_MEMBER_ERROR_MESSAGE_MAP) {
                                captureException(message, {
                                    fn: 'sendVerificationToken'
                                });
                                return { message };
                            }

                            captureException('unexpected response', {
                                fn: 'sendVerificationToken'
                            });
                            return { message: CHANGE_MEMBER_ERROR_MESSAGE_MAP.UNEXPECTED_ERROR };
                        })
                    }
                }
            },
            success: { type: 'final' }
        }
    },
    {
        services: { sendVerificationToken }
    }
);
