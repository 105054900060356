import axios from 'axios';

import { domain, headers } from '../get-api-elements';

let submitResult = false;

export default async(choice, campaignId) => {
    return new Promise((resolve, reject) => {
        if (submitResult) {
            return resolve(submitResult);
        }

        const options = {
            method: 'POST',
            url: `${domain}/extra/submit-compensation-choice`,
            data: { choice, campaignId },
            headers,
            json: true
        };

        axios(options)
            .then((res) => {
                submitResult = res;
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
};