import React, { ReactElement, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { CancelMembershipProvider } from '@src/components/ContextProvider/CancellationContextProvider';
import { Section } from '@src/components/Layout/Section';
import { Loader } from '@src/components/Loader/Loader';
import { CancellationData } from '@src/pages/CancelMembership/CancelMembership.types';
import CancelMembershipCheck from '@src/pages/CancelMembership/CancelMembershipCheck.page';
import CancelMembershipConfirmation from '@src/pages/CancelMembership/CancelMembershipConfirmation.page';
import CancelMembershipDates from '@src/pages/CancelMembership/CancelMembershipDates.page';
import CancelMembershipReasons from '@src/pages/CancelMembership/CancelMembershipReasons.page';
import { useMember } from '@src/services/member/MemberProvider';
import { useFetch } from '@src/utils/hooks/api/useFetch';

export const enum Path {
    Reasons = '/reasons',
    Dates = '/dates',
    Check = '/check',
    Confirmation = '/confirmation',
    Base = '/cancel-membership'
}

export const CancelMembershipRouter = (): ReactElement => {
    const { state: member } = useMember();
    const { isLoading } = useFetch<CancellationData>(
        `/memberships/get-cancel-membership-data?membershipId=${member.id_g}`
    );
    const navigate = useNavigate();

    const isMemberFrozen = member?.peopleMembership?.membershipStatus === 'Frozen';

    useEffect(() => {
        if (isMemberFrozen) {
            navigate('/membership');
            return;
        }
    }, []);

    if (isLoading) {
        return (
            <Section className="flex flex-col items-center justify-center">
                <Loader />
            </Section>
        );
    }

    return (
        <CancelMembershipProvider>
            <Routes>
                <Route path={Path.Reasons} element={<CancelMembershipReasons />} />
                <Route path={Path.Dates} element={<CancelMembershipDates />} />
                <Route path={Path.Check} element={<CancelMembershipCheck />} />
                <Route path={Path.Confirmation} element={<CancelMembershipConfirmation />} />
                <Route path="*" element={<Navigate to="reasons" />} />
            </Routes>
        </CancelMembershipProvider>
    );
};
