import axios from 'axios';

import { domain, headers } from '../get-api-elements';
import getMember from './get-member';

export default async (languageObject) => {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            url: `${domain}/member/set-language`,
            headers,
            data: {
                country: languageObject.country,
                language: languageObject.language
            },
            json: true
        };

        axios(options)
            .then(async (res) => {
                // update member language
                await getMember(true);

                // empty cached translations
                for (const key in localStorage) {
                    if (key.includes('i18next') || key.includes('localeID')) {
                        localStorage.removeItem(key);
                    }
                }
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
