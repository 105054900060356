import React from 'react';
import { components, SingleValueProps } from 'react-select';
import { Icon } from '@src/components/Icons/Icons';

const { Option, SingleValue } = components;

export type LanguageLabelProps = {
    value: LanguageProps;
    label: string;
    language: string;
};

export type LanguageProps = {
    country: string;
    language: string;
};

export const LanguageLabel = ({ language, label }: { language: string; label: string }) => {
    return (
        <>
            <Icon id={language} className="mr-xs text-heading-m" />
            {label}
        </>
    );
};

export const OptionWithLanguage = (props: any) => (
    <Option {...props}>
        <LanguageLabel label={props.data.label} language={props.data.language} />
    </Option>
);

export const SelectedWithLanguage = ({ ...props }: SingleValueProps<LanguageLabelProps>) => (
    <SingleValue {...props}>
        <LanguageLabel label={props.data.label} language={props.data.language} />
    </SingleValue>
);
