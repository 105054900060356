import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Text } from '@basic-fit/design-system';

export const TermsAndConditions = () => {
    const { t } = useTranslation();

    // get translated texts that should be replaced with links
    const text = t('terms.and.conditions');
    const match = text.match(new RegExp(/(%%)(.*?)(%%)/g));

    // replace the texts with a tag, so we can split the text on it
    const splitText = match?.reduce((acc: string, key: string) => {
        return acc.replace(key, '<url>');
    }, text);

    const html = splitText?.split('<url>').map((item: string, index: number) => {
        if (match?.[index]) {
            const text = match?.[index].replace(/%%/g, '');
            const url = index === 0 ? t('link.terms.conditions') : t('link.house.rules');
            return (
                <>
                    {item}
                    <Link className="underline" rel="noopener noreferrer" to={url} target="_blank">
                        {text}
                    </Link>
                </>
            );
        }
        return item;
    });

    return (
        <Text size={2} as="span">
            {html?.map((item: string | React.ReactNode) => item)}
        </Text>
    );
};
