import React from 'react';

export default function ChangeIcon() {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 55 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.328 18.967h-.816a5.394 5.394 0 0 1-3.785-1.553 5.27 5.27 0 0 1-1.57-3.741V5.294a5.27 5.27 0 0 1 1.57-3.741A5.394 5.394 0 0 1 12.512 0h.816c1.42.001 2.782.56 3.785 1.552a5.268 5.268 0 0 1 1.57 3.742v8.379a5.271 5.271 0 0 1-1.57 3.741 5.394 5.394 0 0 1-3.785 1.553ZM12.512 3.06a2.28 2.28 0 0 0-1.598.656 2.227 2.227 0 0 0-.663 1.58v8.378c.001.592.24 1.16.664 1.58.423.418.998.654 1.597.655h.816c.6 0 1.175-.236 1.598-.655.424-.42.663-.987.663-1.58V5.294c0-.593-.239-1.16-.663-1.58a2.276 2.276 0 0 0-1.598-.655h-.816ZM37.871 34.614h-.826a5.393 5.393 0 0 1-3.784-1.552 5.271 5.271 0 0 1-1.57-3.742v-8.378a5.268 5.268 0 0 1 1.569-3.743 5.391 5.391 0 0 1 3.785-1.552h.826c1.42.002 2.78.56 3.785 1.553a5.271 5.271 0 0 1 1.57 3.742v8.378a5.27 5.27 0 0 1-1.57 3.742 5.393 5.393 0 0 1-3.785 1.553Zm-.826-15.908c-.6 0-1.174.237-1.598.656a2.224 2.224 0 0 0-.663 1.58v8.378c0 .593.24 1.161.663 1.58.424.42.999.655 1.598.656h.826a2.279 2.279 0 0 0 1.597-.656c.424-.42.663-.987.664-1.58v-8.378a2.227 2.227 0 0 0-.663-1.58 2.279 2.279 0 0 0-1.598-.656h-.826ZM7.535 50H6.71a5.392 5.392 0 0 1-3.785-1.552 5.269 5.269 0 0 1-1.57-3.742v-8.379a5.27 5.27 0 0 1 1.57-3.741 5.393 5.393 0 0 1 3.785-1.553h.826c1.42.002 2.78.56 3.785 1.553a5.271 5.271 0 0 1 1.57 3.741v8.38a5.271 5.271 0 0 1-1.57 3.74A5.394 5.394 0 0 1 7.535 50ZM6.71 34.092c-.6 0-1.174.236-1.598.655-.424.42-.662.988-.663 1.58v8.38c.001.592.24 1.16.663 1.579.424.42.999.655 1.598.655h.826c.6 0 1.174-.236 1.598-.655.424-.419.663-.987.663-1.58v-8.379c0-.592-.239-1.16-.663-1.58a2.277 2.277 0 0 0-1.598-.655H6.71Z"
                fill="#7ADEC9"
            />
            <path d="M54.87 8.085H21.68v3.059h33.19V8.085Z" fill="url(#a)" />
            <path d="M4.067 8.085H0v3.059h4.067V8.085Z" fill="url(#b)" />
            <path d="M55 39.117H15.968v3.059H55v-3.059Z" fill="url(#c)" />
            <path d="M55 23.731h-8.806v3.06H55v-3.06Z" fill="url(#d)" />
            <path d="M28.58 23.731H.13v3.06h28.448v-3.06Z" fill="url(#e)" />
            <defs>
                <linearGradient
                    id="a"
                    x1="54.867"
                    y1="9.614"
                    x2="21.68"
                    y2="9.614"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFAA30" />
                    <stop offset=".08" stopColor="#FF9D26" />
                    <stop offset=".24" stopColor="#FE8915" />
                    <stop offset=".42" stopColor="#FE7B09" />
                    <stop offset=".64" stopColor="#FE7302" />
                    <stop offset="1" stopColor="#FE7000" />
                </linearGradient>
                <linearGradient
                    id="b"
                    x1="69.513"
                    y1="133.069"
                    x2="0"
                    y2="133.069"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFAA30" />
                    <stop offset=".08" stopColor="#FF9D26" />
                    <stop offset=".24" stopColor="#FE8915" />
                    <stop offset=".42" stopColor="#FE7B09" />
                    <stop offset=".64" stopColor="#FE7302" />
                    <stop offset="1" stopColor="#FE7000" />
                </linearGradient>
                <linearGradient
                    id="c"
                    x1="9035.97"
                    y1="567.528"
                    x2="2634.65"
                    y2="567.528"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFAA30" />
                    <stop offset=".08" stopColor="#FF9D26" />
                    <stop offset=".24" stopColor="#FE8915" />
                    <stop offset=".42" stopColor="#FE7B09" />
                    <stop offset=".64" stopColor="#FE7302" />
                    <stop offset="1" stopColor="#FE7000" />
                </linearGradient>
                <linearGradient
                    id="d"
                    x1="2081.19"
                    y1="352.126"
                    x2="1755.37"
                    y2="352.126"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFAA30" />
                    <stop offset=".08" stopColor="#FF9D26" />
                    <stop offset=".24" stopColor="#FE8915" />
                    <stop offset=".42" stopColor="#FE7B09" />
                    <stop offset=".64" stopColor="#FE7302" />
                    <stop offset="1" stopColor="#FE7000" />
                </linearGradient>
                <linearGradient
                    id="e"
                    x1="3416.5"
                    y1="352.126"
                    x2="15.778"
                    y2="352.126"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFAA30" />
                    <stop offset=".08" stopColor="#FF9D26" />
                    <stop offset=".24" stopColor="#FE8915" />
                    <stop offset=".42" stopColor="#FE7B09" />
                    <stop offset=".64" stopColor="#FE7302" />
                    <stop offset="1" stopColor="#FE7000" />
                </linearGradient>
            </defs>
        </svg>
    );
}
