import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Text } from '@basic-fit/design-system';
import ArrowRightIcon from '@src/components/Icons/icons/ArrowRight.icon';
import { useMember } from '@src/services/member/MemberProvider';

import googleTagManagerPush from 'services/google-tag-manager-push';

export default function PaymentInformation(): JSX.Element {
    const { state: member } = useMember();
    const { t } = useTranslation();

    const tagManagerChangePaymentInfo = () => {
        googleTagManagerPush.changePaymentInfo();
    };

    const shouldShowPaymentInfo = Boolean(member.iban_account_holder && member.iban);

    return (
        <div
            id="payment-details"
            data-cy="payment-details"
            className="flex justify-between items-center py-3"
        >
            <Link
                to="/information/change-payment-info"
                onClick={tagManagerChangePaymentInfo}
                className="grow flex flex-col"
            >
                <Text size={2} bold>
                    {t('401')}
                </Text>
                {shouldShowPaymentInfo && (
                    <Text size={2} className="text-grey-disabled">
                        {member.iban_account_holder} <br />
                        {member.iban}
                    </Text>
                )}
            </Link>
            <div className="text-orange w-6 h-6 p-1">
                <ArrowRightIcon />
            </div>
        </div>
    );
}
