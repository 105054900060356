import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SingleValue } from 'react-select';
import { Button, Heading, Icon, Text } from '@basic-fit/design-system';
import { ButtonLink } from '@src/components/Buttons/ButtonLink';
import { SelectElement } from '@src/components/Form/SelectElement/SelectElement';
import { Header } from '@src/components/Header/Header';
import { Section } from '@src/components/Layout/Section';
import { Wrapper } from '@src/components/Layout/Wrapper';
import { Modal } from '@src/components/Modals/Modal';
import { TableFlex } from '@src/components/TableFlex/TableFlex';
import { ReactComponent as HeaderImage } from '@src/pages/Membership/Membership/images/header.svg';
import { useMember } from '@src/services/member/MemberProvider';
import { isMobile } from '@src/utils/helpers/device';
import DOMPurify from 'isomorphic-dompurify';

interface FreezeDateProps {
    value: string;
    label: string;
    startDate: string;
    endDate: string;
    id: string;
}
const MS_MODAL_TIMEOUT = 10_000;
const MAX_FREEZES = 2;

export const FreezeMembershipPage = () => {
    const { t } = useTranslation();
    const [freezeDates, setFreezeDates] = useState<[FreezeDateProps]>();
    const [selectedFreezePeriod, setSelectedFreezePeriod] = useState<FreezeDateProps | null>();
    const [isLoading, setIsLoading] = useState(true);
    const [usedFreezes, setUsedFreezes] = useState<number>(0);
    const [frozenDates, setFrozenDates] = useState<[FreezeDateProps]>();
    const [freezeHistory, setFreezeHistory] = useState<[FreezeDateProps]>();
    const [deleteFreezePeriod, setDeleteFreezePeriod] = useState<FreezeDateProps>();
    const [activeFreeze, setActiveFreeze] = useState(false);
    const [showModal, setShowModal] = useState<'confirm' | 'removed' | 'remove' | 'error' | null>(
        null
    );
    const navigate = useNavigate();

    const { state: member } = useMember();

    const allowedToDeleteCurrentFreeze = member?.freeze?.allowedToDeleteCurrentFreeze;

    const isMemberAllowedToFreeze =
        member?.membership_options_g?.LinkedServices?.includes('freezeAllowed') ?? false;

    const modalsToTimeOut = ['confirm', 'removed'];

    useEffect(() => {
        if (!isMemberAllowedToFreeze) {
            navigate('/membership');
            return;
        }
        getFreezeDates();
    }, []);

    useEffect(() => {
        if (showModal && modalsToTimeOut.includes(showModal)) {
            const timeoutId = setTimeout(() => setShowModal(null), MS_MODAL_TIMEOUT);
            return () => clearTimeout(timeoutId);
        }
    }, [showModal]);

    const getFreezeDates = async () => {
        try {
            const response = await fetch('/freeze/get-dates');
            if (!response.ok) {
                const data = await response.json();
                if (data.message === 'no freeze allowed') {
                    navigate('/membership');
                }
                throw new Error('Error fetching freeze dates');
            }

            const data = await response.json();
            const now = new Date();

            const freezeHistory = data.frozenDates.filter(
                (date: FreezeDateProps) => new Date(date.endDate) < now
            );

            const freezeScheduled = data.frozenDates.filter(
                (date: FreezeDateProps) => new Date(date.endDate) > now
            );

            const activeFreeze = await freezeScheduled.some(
                (date: FreezeDateProps) =>
                    new Date(date.startDate) < now && new Date(date.endDate) > now
            );

            setActiveFreeze(activeFreeze);
            setFrozenDates(freezeScheduled);
            setFreezeHistory(freezeHistory);
            setUsedFreezes(data.usedFreezes);

            const dates = data.availableDates.map((date: any) => ({
                value: date.startDate,
                label: `${new Date(date.startDate).toLocaleDateString('nl-NL')} ${t(
                    'freeze.page.till'
                )} ${new Date(date.endDate).toLocaleDateString('nl-NL')}`,
                startDate: date.startDate,
                endDate: date.endDate
            }));

            setFreezeDates(dates);
            setIsLoading(false);
        } catch (error) {
            setShowModal('error');
        }
    };

    const changeFreezePeriodHandler = (option: SingleValue<FreezeDateProps>) => {
        if (option) {
            setSelectedFreezePeriod(option as FreezeDateProps);
        }
    };

    const confirmFreezeHandler = async () => {
        if (!selectedFreezePeriod) return;

        try {
            const response = await fetch('/freeze/create-freeze', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    startDate: selectedFreezePeriod.startDate,
                    endDate: selectedFreezePeriod.endDate
                })
            });

            if (!response.ok) throw new Error('Error creating freeze period');

            await getFreezeDates();
            setShowModal('confirm');

            setSelectedFreezePeriod(null);
        } catch (error) {
            setShowModal('error');
        }
    };

    const removeFreezePeriodHandler = (key: number) => {
        if (frozenDates?.[key]?.id) {
            setDeleteFreezePeriod(frozenDates[key]);
            setShowModal('remove');
        }
    };

    const removeFreezePeriod = async () => {
        if (!deleteFreezePeriod) return;

        try {
            const response = await fetch(
                `/freeze/delete-freeze?freezeId=${deleteFreezePeriod.id}`,
                {
                    method: 'DELETE'
                }
            );

            if (!response.ok) throw new Error('Error deleting freeze period');

            setShowModal('removed');
            await getFreezeDates();
        } catch (error) {
            setShowModal('error');
        }
    };

    return (
        <>
            {showModal !== null && (
                <Modal isOpen={showModal !== null} onModalClose={() => setShowModal(null)}>
                    <Heading size={3} className="mb-2" data-cy={`freeze-modal-${showModal}`}>
                        {t(`freeze.modal.${showModal}.title`)}
                    </Heading>
                    {modalsToTimeOut.includes(showModal) && (
                        <div className="w-full flex justify-center">
                            <img src="/img/confirm.png" alt="confirm" />
                        </div>
                    )}
                    <Text size={2}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    t(`freeze.modal.${showModal}.text`)
                                        .replace(
                                            '{{startDate}}',
                                            deleteFreezePeriod
                                                ? new Date(
                                                      deleteFreezePeriod?.startDate
                                                  ).toLocaleDateString('nl-NL')
                                                : ''
                                        )
                                        .replace(
                                            '{{endDate}}',
                                            deleteFreezePeriod
                                                ? new Date(
                                                      deleteFreezePeriod?.endDate
                                                  ).toLocaleDateString('nl-NL')
                                                : ''
                                        )
                                )
                            }}
                        />
                    </Text>
                    {showModal === 'remove' && (
                        <>
                            <Button
                                className="mt-4"
                                fullwidth
                                onClick={removeFreezePeriod}
                                data-cy="freeze-modal-delete-button"
                            >
                                {t('freeze.modal.remove.button.confirm')}
                            </Button>
                            <Button
                                className="mt-4"
                                theme="light"
                                fullwidth
                                data-cy="freeze-modal-cancel-button"
                                onClick={() => setShowModal(null)}
                            >
                                {t('freeze.modal.remove.button.cancel')}
                            </Button>
                        </>
                    )}
                </Modal>
            )}
            {!isMobile() ? (
                <Header
                    title={t('freeze.page.title')}
                    image={
                        <div className="absolute top-0 right-0">
                            <HeaderImage />
                        </div>
                    }
                />
            ) : (
                <Wrapper>
                    <Section>
                        <Heading size={2}>{t('freeze.page.title')}</Heading>
                    </Section>
                </Wrapper>
            )}

            <Wrapper>
                <Section>
                    <Text data-cy="freeze-header-text" size={2}>
                        {t('freeze.page.headertext')}
                    </Text>
                </Section>
            </Wrapper>

            {/* Freeze History */}
            <Wrapper data-cy="freeze-header-history">
                <Section>
                    <Heading size={5} className="mb-2">
                        {t('freeze.page.history.title')}
                    </Heading>
                    {freezeHistory && freezeHistory.length > 0 && (
                        <>
                            <TableFlex
                                size="s"
                                data-cy="freeze-history-table"
                                className="border-solid border-b-[1px] border-grey mb-s"
                                data={freezeHistory.map((date) => [
                                    `${new Date(date.startDate).toLocaleDateString('nl-NL')} ${t(
                                        'freeze.page.till'
                                    )} ${new Date(date.endDate).toLocaleDateString('nl-NL')}`,
                                    ''
                                ])}
                            />
                        </>
                    )}
                    {!freezeHistory?.length && (
                        <Text size={2} data-cy="freeze-no-history">
                            {t('freeze.page.history.nohistory')}
                        </Text>
                    )}
                </Section>
            </Wrapper>

            {/* Freeze Scheduled */}
            <Wrapper data-cy="freeze-header-scheduled">
                <Section>
                    <Heading size={5} className="mb-2">
                        {t('freeze.page.scheduled.title')}
                    </Heading>
                    {frozenDates && frozenDates.length > 0 && (
                        <>
                            <TableFlex
                                size="s"
                                data-cy="freeze-scheduled-table"
                                className="border-solid border-b-[1px] border-grey mb-s"
                                data={frozenDates.map((date, index) => [
                                    `${new Date(date.startDate).toLocaleDateString('nl-NL')} ${t(
                                        'freeze.page.till'
                                    )} ${new Date(date.endDate).toLocaleDateString('nl-NL')}`,
                                    <>
                                        {index !== 0 ||
                                        (index === 0 && !activeFreeze) ||
                                        (allowedToDeleteCurrentFreeze && activeFreeze) ? (
                                            <Icon
                                                key={`remove-${index}-button`}
                                                data-cy="freeze-scheduled-remove"
                                                className="text-[18px] text-orange"
                                                id={'bin'}
                                                onClick={() => removeFreezePeriodHandler(index)}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </>
                                ])}
                            />
                            <ButtonLink to="/payments">
                                {t('freeze.page.scheduled.nextpaymentdate')}
                            </ButtonLink>
                        </>
                    )}
                    {!frozenDates?.length && (
                        <Text size={2} data-cy="freeze-no-scheduled">
                            {t('freeze.page.scheduled.noscheduled')}
                        </Text>
                    )}
                </Section>
            </Wrapper>

            {/* Freeze Schedule */}
            <Wrapper data-cy="freeze-header-schedule">
                <Section>
                    <Heading size={5} className="mb-2">
                        {t('freeze.page.schedule.title')}
                    </Heading>
                    <Text size={2}>
                        {usedFreezes === MAX_FREEZES
                            ? t('freeze.page.schedule.nofreezeleft')
                            : t('freeze.page.schedule.text')}
                    </Text>
                    <TableFlex
                        size="s"
                        className="border-solid border-b-[1px] border-grey mb-s"
                        data={[
                            [
                                t('freeze.page.schedule.freezeleft.text'),
                                <div key="1" className="font-heading-treble font-bold">{`${
                                    MAX_FREEZES - usedFreezes
                                } ${t(
                                    'freeze.page.schedule.freezeleft.outof'
                                )} ${MAX_FREEZES}`}</div>
                            ]
                        ]}
                    />
                    {usedFreezes < MAX_FREEZES && (
                        <>
                            <div className="mt-4" data-cy="freeze-select">
                                <SelectElement
                                    className="w-full"
                                    styles={{
                                        control: (base: any) => ({
                                            ...base,
                                            background: '#ffffff',
                                            paddingLeft: '16px',
                                            borderRadius: 0,
                                            paddingTop: '8px',
                                            paddingBottom: '8px'
                                        })
                                    }}
                                    options={freezeDates}
                                    isSearchable={false}
                                    menuPosition={'fixed'}
                                    onChange={changeFreezePeriodHandler}
                                    placeholder={t('freeze.page.select.placeholder')}
                                    value={selectedFreezePeriod}
                                    isDisabled={isLoading}
                                    noOptionsMessage={() => t('payments.download.modal.no_data')}
                                />
                            </div>

                            <Button
                                className="mt-4"
                                onClick={confirmFreezeHandler}
                                fullwidth
                                data-cy="freeze-confirm-button"
                            >
                                {t('freeze.page.confirm.button')}
                            </Button>
                            <Button
                                className="mt-4"
                                onClick={() => navigate('/membership')}
                                theme="light"
                                fullwidth
                                data-cy="freeze-back-button"
                            >
                                {t('freeze.page.cancel.button')}
                            </Button>
                        </>
                    )}
                </Section>
            </Wrapper>
        </>
    );
};
