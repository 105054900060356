import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { ButtonLink } from '@src/components/Buttons/ButtonLink';

export const Friends = (): JSX.Element => {
    const { t } = useTranslation();
    return (
        <Box>
            <Text size={1} className="mb-[15px]">
                {t('38')}
            </Text>
            <ButtonLink to="/friends" className="mt-xxs">
                {t('39')}
            </ButtonLink>
        </Box>
    );
};
