import type { VisitsResponse } from '@src/utils/hooks/api/types/visits';
import { useFetch } from '@src/utils/hooks/api/useFetch';
import { DateTime } from 'luxon';

type WeeklyVisits = {
    visitsThisWeek: number;
    visitsLastWeek: number;
};

export const useVisits = () => {
    const now = DateTime.now();
    const lastWeek = now.minus({ weeks: 1 });
    const { data, isError, isLoading } = useFetch<VisitsResponse>('/member/visits');
    const { recentVisits = [], totalVisits = 0 } = data || {};

    const { visitsThisWeek, visitsLastWeek } = recentVisits.reduce<WeeklyVisits>(
        (acc, visit) => {
            const visitDate = DateTime.fromISO(visit.timestamp);

            if (visitDate.hasSame(now, 'week')) acc.visitsThisWeek++;
            if (visitDate.hasSame(lastWeek, 'week')) acc.visitsLastWeek++;

            return acc;
        },
        { visitsThisWeek: 0, visitsLastWeek: 0 }
    );

    return {
        isError,
        isLoading,
        recentVisits,
        totalVisits,
        visitsLastWeek,
        visitsThisWeek
    };
};
