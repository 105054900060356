import React from 'react';

type HeadingProps = {
    className?: string;
    children: React.ReactNode;
    size: 'xl' | 'l' | 'm' | 's' | 'xs' | 'xxs';
    'data-testid'?: string;
};

enum HeadingSizes {
    xl = 'h1', // desk = 48/50 - mob = 32/36
    l = 'h2', // desk = 32/36 - mob = 24/28
    m = 'h3', // desk = 24/28 - mob = 20/24
    s = 'h4', // desk = 20/24
    xs = 'h5', // desk = 16/28
    xxs = 'h6' // desk = 14/24
}

/**
 * Tailwind doesn't support dynamic classnames
 * Create a helper function that returns the class as whole string
 * https://tailwindcss.com/docs/content-configuration#dynamic-class-names
 */
const getClass = (size: string) => {
    switch (size) {
        case 'xl':
            return 'text-heading-m sm:text-heading-l sm:text-heading-xl font-extrabold';
        case 'l':
            return 'text-heading-s sm:text-heading-m md:text-heading-l font-extrabold';
        case 'm':
            return 'text-heading-xs sm:text-heading-s md:text-heading-m font-extrabold';
        case 's':
            return 'text-heading-xs sm:text-heading-s font-extrabold';
        case 'xs':
            return 'text-heading-xs font-extrabold';
        case 'xxs':
            return 'text-heading-xxs font-extrabold';
    }
};

export const Heading = (props: HeadingProps) => {
    return React.createElement(
        HeadingSizes[props.size],
        {
            ...props,
            className: `${getClass(props.size)} font-heading-treble m-0 uppercase ${
                props.className
            }`
        },
        props.children
    );
};
