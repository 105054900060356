import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMember } from '@src/services/member/MemberProvider';

export const usePreventEmployeeChangeDetails = () => {
    const { state: member } = useMember();
    const navigate = useNavigate();

    useLayoutEffect(() => {
        if (member?.isEmployee) {
            navigate('/information');
        }
    }, []);
};
