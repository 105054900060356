/**
 * Funnel tracking Change Membership
 * https://docs.google.com/document/d/1S18X6sDYYog7Dv0ClIZWrQX_EhfSzPmLMjxwYWAsW4k/edit
 *
 * If these events are used outside this flow, move them to:
 * src/services/google-tag-manager-push.js
 */

const getItem = (
    name: string,
    id: string,
    price: number,
    clubName: string,
    clubId: string,
    products: string[]
) => ({
    item_name: name,
    item_id: id,
    price: price,
    item_brand: clubName,
    item_category: 'subscription',
    item_category2: products.join('|'),
    item_category3: clubId,
    item_category4: '',
    item_category5: '',
    item_variant: 'web',
    quantity: 1
});

export const trackPromotion = (
    event: 'view_promotion' | 'select_promotion' | 'begin_checkout' | 'add_payment_info',
    membership: string,
    price: number,
    clubName: string,
    clubId: string,
    products?: string[]
) => {
    if (event === 'begin_checkout') {
        window.dataLayer.push({ ecommerce: null });
    }
    const eventData = {
        event,
        ecommerce: {
            currency:
                event === 'begin_checkout' || event === 'add_payment_info' ? 'EUR' : undefined,
            payment_type: event === 'add_payment_info' ? 'Adyen' : undefined,
            items: [
                getItem(
                    membership || 'change membership promo',
                    event === 'begin_checkout' || event === 'add_payment_info'
                        ? 'd2febdef7fc0469f821e93f3b3952ff8'
                        : 'd2febdef7fc0469f821e93f3b3913RFxd1',
                    price,
                    clubName,
                    clubId,
                    products || []
                )
            ]
        }
    };
    // use JSON parse to clean up undefined values
    window.dataLayer.push(JSON.parse(JSON.stringify(eventData)));
};

export const trackPromotionError = (message: string) => {
    window.dataLayer.push({
        event: 'error',
        error: {
            error_type: 'checkout',
            error_message: message
        }
    });
};
