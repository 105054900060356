import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from 'components/partials/Button';
import Container from 'components/partials/Container';
import Text from 'components/partials/Text';
import Title from 'components/partials/Title';
import getBrowser from '../../services/get-browser';

export default (props) => {
    const { t } = useTranslation();
    const { homeClubHasActiveFrozenCampaign } = useSelector((state) => ({
        ...state.homeClubHasActiveFrozenCampaignReducer
    }));

    const { member } = useSelector((state) => ({
        ...state.memberReducer
    }));

    const browser = getBrowser();
    const isSafari = browser.includes('safari');

    const handleFrozenClicked = () => {
        window.open(homeClubHasActiveFrozenCampaign.frozenLink, isSafari ? '_self' : '_blank');
    };

    return (
        <>
            {homeClubHasActiveFrozenCampaign &&
                !member.membershipIsFrozen &&
                homeClubHasActiveFrozenCampaign.active && (
                    <Section>
                        <Container type="panel">
                            <Container
                                styles={`background: var(--color-white); padding: 15px`}
                                mdStyles={`padding: 40px;`}
                            >
                                <Title
                                    text={`${t('1515')}`}
                                    styles={`color: var(--color-anthracite); margin: 0 0 16px;`}
                                    size="M"
                                />
                                <Text styles={`margin: 0 0 24px;`}>{t('1516')}</Text>
                                {props.purpleButton && (
                                    <Button onClick={handleFrozenClicked}>{t('1524')}</Button>
                                )}
                                {!props.purpleButton && (
                                    <Button type="link" onClick={handleFrozenClicked}>
                                        {t('1518')}
                                    </Button>
                                )}
                            </Container>
                        </Container>
                        <Container
                            className="mbf-spacing"
                            styles={`height: 50px;`}
                            mdStyles={`height: 60px;`}
                        />
                    </Section>
                )}
        </>
    );
};
