import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Heading } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { useCancellationContext } from '@src/components/ContextProvider/CancellationContextProvider';
import { FAQ } from '@src/components/FAQ/FAQ.component';
import { Header } from '@src/components/Header/Header';
import { Section } from '@src/components/Layout/Section';
import { CancelledMembership } from '@src/pages/Membership/Membership/components/CancelledMembership/CancelledMembership';
import { ActiveMembershipTable } from '@src/pages/Membership/Membership/components/MembershipTable/ActiveMembershipTable';
import { ReactComponent as HeaderImage } from '@src/pages/Membership/Membership/images/header.svg';
import { Path } from '@src/router/CancelMembership.router';
import { useArticles } from '@src/utils/hooks/api/useArticles';

import googleTagManagerPush from '../../../services/google-tag-manager-push';

const Image = () => {
    return (
        <div className="absolute top-0 right-0">
            <HeaderImage />
        </div>
    );
};

export default function CancelMemberConfirmation(): JSX.Element {
    const { t } = useTranslation();
    const { mutateArticles } = useArticles();
    const { resetCancelMembershipCache, chosenDate, chosenReason } = useCancellationContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (!chosenDate?.cancelDate || !chosenReason?.reason) navigate(Path.Base + Path.Reasons);
        googleTagManagerPush.cancelMembership('view', 'page confirmation');
        mutateArticles();
        resetCancelMembershipCache();
    }, []);

    return (
        <div data-cy="cancel-membership__confirmation">
            <Header
                title={t('40')}
                image={<Image />}
                text={
                    // Set width explicitly to prevent image from overlapping text
                    <span className="inline-block w-5/6">
                        {t('cancel_membership.confirmation.header_subtitle')}
                    </span>
                }
            />
            <Section data-cy="cancel-membership_confirmation">
                <Box variant="mint-top">
                    <CancelledMembership />
                    <Heading size={5} className="mb-m">
                        {t('30')}
                    </Heading>
                    <ActiveMembershipTable />
                </Box>
            </Section>

            <Section>
                <FAQ category="cancellation" />
            </Section>
        </div>
    );
}
