import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { Modal } from '@src/components/Modals/Modal';
import { StrokeText } from '@src/components/StrokeText/StrokeText';

type ModalProps = {
    isOpen: boolean;
    onClose?: () => void;
};

export const ReauthorizeFailModal = ({ isOpen, onClose }: ModalProps) => {
    const { t } = useTranslation();
    return isOpen ? (
        <Modal isOpen={isOpen} onModalClose={onClose}>
            <StrokeText
                stroke="last"
                color="anthracite"
                className="text-anthracite mb-4"
                text={t('238')}
            />
            <Text size={2} className="mb-s">
                {t('debit.block.modal.fail.description')}
            </Text>
            <Button
                theme="outline"
                text={t('debit.block.modal.fail.submit')}
                onClick={() => location.assign(t('customer.support.url'))}
            />
        </Modal>
    ) : null;
};
