import { changeMembershipMachine } from '@src/pages/ChangeMembership/machines/changeMembershipMachine';

/**
 * https://xstate.js.org/docs/recipes/react.html#global-state-react-context
 */

export const intervalSelector = (state: typeof changeMembershipMachine) =>
    state.context.membership?.paymentInterval === 'fourWeeks' ? 0 : 1;

export const membershipSelector = (state: typeof changeMembershipMachine) =>
    state.context.membership;

export const extrasSelector = (state: typeof changeMembershipMachine) =>
    state.context.extras.sort((a, b) =>
        b.artc_short_description.localeCompare(a.artc_short_description)
    );

export const renewSelector = (state: typeof changeMembershipMachine) => state.context.renew;

export const errorSelector = (state: typeof changeMembershipMachine) => state.context.error;
