import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { AdyenPaymentButton } from '@src/components/Buttons/AdyenPaymentButton';
import { ButtonLink } from '@src/components/Buttons/ButtonLink';
import { Icon } from '@src/components/Icons/Icons';
import { Table } from '@src/components/Table/Table';
import { hasValue } from '@src/services/utilities/helpers';
import { getPrice } from '@src/utils/helpers/priceHelpers';
import { useMBFFeatures } from '@src/utils/hooks/api/useMBFFeatures';

import { toLocal } from '../../../../../../services/date-converter';

export const MemberDetails = ({ member }: { member: any }) => {
    const {
        t,
        i18n: { language }
    } = useTranslation();

    const memberDetails = [
        {
            key: t('151'),
            value: `${member.first_name || ''} ${member.middle_name || ''} ${
                member.last_name || ''
            }`
        },
        { key: t('152'), value: toLocal(member.birth_date) },
        {
            key: t('153'),
            value: member.mailing_street + ' ' + member.mailing_house_number
        },
        { key: t('154'), value: member.poste_code },
        { key: t('155'), value: member.mailing_city },
        { key: t('9'), value: member.email },
        { key: t('394'), value: member.tel_home }
    ]
        .filter(({ value }) => hasValue(value))
        .map((item) => [
            item.key,
            // eslint-disable-next-line react/jsx-key
            <strong className="font-heading-treble font-bold">{item.value}</strong>
        ]);

    const { paymentDelay } = useMBFFeatures();
    const PayDebt = () => {
        return (
            <>
                <Text size={2}>{t('73')}</Text>
                <Heading size={7} className="normal-case my-xs">{`${t('1970')}:`}</Heading>
                <Heading size={3} className="flex-grow mb-m">{`${getPrice(
                    member.balance,
                    language,
                    {
                        format: 'withDigits'
                    }
                )}`}</Heading>

                {paymentDelay && (
                    <>
                        <Text size={2} className="text-red">
                            {t('payment.issues.delay')}
                        </Text>
                        <br />
                    </>
                )}
                <AdyenPaymentButton peopleId={member.people_id_g} amount={member.balance} />
            </>
        );
    };

    const NoDebt = () => {
        return (
            <div className={'flex flex-col items-center mt-xs'}>
                <Icon id={'thumbs-up'} className="text-[4em]" />
                <Heading size={6} className="mt-s">
                    {t('payment.noDebt', 'Everything is in order!')}
                </Heading>
            </div>
        );
    };

    return (
        <Box variant="orange">
            <div className="flex flex-1 flex-col xl:flex-row">
                <div className="w-full xl:w-1/2 content-start mb-l xl:mb-0">
                    <Heading size={5} className="mb-xxxs">
                        {t('30')}
                    </Heading>
                    <Table data={memberDetails} style="white" />
                </div>
                <div className="w-full xl:w-1/2 content-start xl:ml-xxl">
                    <Heading size={5} className="mb-xxxs">
                        {t('29')}
                    </Heading>
                    <ButtonLink to={'/payments'} className="mb-xs">
                        {t('63')}
                    </ButtonLink>
                    {member.inDebt ? <PayDebt /> : <NoDebt />}
                </div>
            </div>
        </Box>
    );
};
