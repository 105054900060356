import React from 'react';

import { OverviewContent } from './components/Content/Content.overview';
import { OverviewFooter } from './components/Footer.overview';
import { OverviewHeader } from './components/Header.overview';

export const OverviewPage = (): JSX.Element => {
    return (
        <>
            <OverviewHeader />
            <OverviewContent />
            <OverviewFooter />
        </>
    );
};
