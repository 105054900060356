import React, { useLayoutEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PaymentDocument, PaymentDocumentProps } from '@src/services/pdf/PaymentDocument';
import axios from 'axios';

export const ProofOfPayment = () => {
    const [searchParams] = useSearchParams();
    const [data, setData] = useState<PaymentDocumentProps | null>(null);

    useLayoutEffect(() => {
        if (!searchParams.has('token')) return;
        if (data) return;

        async function fetchProofOfPayment() {
            const token = searchParams.get('token');
            try {
                const { data, status } = await axios.post(
                    '/payments/provide-statement',
                    { token },
                    { timeout: 15_000 }
                );
                if (status !== 200) {
                    return console.error('Failed to fetch proof of payment, with status:', status);
                }
                setData(data);
                document.body.classList.add('proof-of-payment');
            } catch (error) {
                console.error('Failed to fetch proof of payment', error);
            }
        }

        fetchProofOfPayment();
    }, []);

    if (!data) return null;

    return <PaymentDocument member={data.member} transactions={data.transactions} />;
};
