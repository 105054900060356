import axios from 'axios';

import { domain, headers } from '../get-api-elements';
import cacheHelper from './cache-helper';

let memberGlobalRequested = false;

export default async (fresh) => {
    return new Promise((resolve, _reject) => {
        const cache = cacheHelper.get('_member');
        if (!fresh && memberGlobalRequested && cache) {
            try {
                return resolve(JSON.parse(cache));
            } catch {
                console.log("Can't parse member. Getting fresh...");
            }
        }

        const options = {
            method: 'GET',
            url: `${domain}/member/get-member`,
            headers
        };

        if (!memberGlobalRequested || fresh) {
            memberGlobalRequested = true;
            axios(options)
                .then((res) => {
                    cacheHelper.set('_member', res.data);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Can't get member:", err);
                    resolve(false);
                });
        }
    });
};
