import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Membership } from '@src/pages/ChangeMembership/ChangeMembershipFlow.types';
import { useMember } from '@src/services/member/MemberProvider';
import { PaymentInterval } from '@src/utils/helpers/priceHelpers';
import { useChangeMembership } from '@src/utils/hooks/api/useChangeMembership';

import { captureException } from '../helpers/newRelic';

export const enum RejectReason {
    NO_MEMBERSHIP_OPTIONS = 'no_membership_options',
    HAS_DEBT = 'has_debt',
    ALL_IN_MEMBERSHIP = 'all_in_membership',
    HAS_RENEWAL_MEMBERSHIP = 'has_renewal_membership',
    IS_FROZEN = 'is_frozen'
}

export const useChangeMembershipGuard = (renew = false) => {
    const { state: member } = useMember();
    const {
        id_g,
        membership_type_g,
        payment_plan: { minimal_contract_period },
        renewal_contract,
        inDebt,
        peopleMembership
    } = member || {};

    const navigate = useNavigate();

    const [reason, setReason] = useState(() => {
        if (renewal_contract) return RejectReason.HAS_RENEWAL_MEMBERSHIP;
        if (!renew && membership_type_g === Membership.ALLIN) {
            return RejectReason.ALL_IN_MEMBERSHIP;
        }
    });

    const { membershipOptions, isError: isChangeMembershipError } = useChangeMembership({
        id: id_g,
        membership: membership_type_g,
        interval: PaymentInterval.FourWeeks,
        contractPeriod: minimal_contract_period,
        renew: renew || !!reason
    });

    useEffect(() => {
        if (membershipOptions?.length === 0) {
            setReason(RejectReason.NO_MEMBERSHIP_OPTIONS);
        } else if (inDebt) {
            setReason(RejectReason.HAS_DEBT);
        } else if (peopleMembership?.membershipStatus === 'Frozen') {
            setReason(RejectReason.IS_FROZEN);
        } else if (isChangeMembershipError) {
            setReason(RejectReason.NO_MEMBERSHIP_OPTIONS);
            captureException('failed to get membership options', {
                peopleId: member.people_id_g,
                id: member.id_g,
                membership: member.membership_type_g,
                interval: PaymentInterval.FourWeeks,
                contractPeriod: member.payment_plan.minimal_contract_period,
                renew: String(renew || !!reason)
            });
        }
    }, [
        reason,
        membershipOptions,
        inDebt,
        isChangeMembershipError,
        peopleMembership?.membershipStatus
    ]);

    useEffect(() => {
        if (reason) {
            navigate('/membership', {
                state: {
                    changeMembershipGuard: reason
                }
            });
        }
    }, [reason]);
};
