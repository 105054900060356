import { Membership } from './ChangeMembershipFlow.types';

export function isDowngradePath(from: string, to: Membership) {
    switch (from) {
        case Membership.PREMIUM:
            return [Membership.COMFORT, Membership.BASIC].includes(to);
        default:
            return false;
    }
}
