import React from 'react';
import { useTranslation } from 'react-i18next';

import BackCancel from '../partials/BackCancel';
import ButtonPrimary from '../partials/ButtonPrimary';
import Gap from '../partials/Gap';
import Popup from '../partials/Popup';
import PopupBodyText from '../partials/PopupBodyText';
import PopupTitle from '../partials/PopupTitle';

export default ({ chosenCompensation, setShowPopup, confirmSetCompensation }) => {
    const { t } = useTranslation();
    return (
        <Popup
            Contents={() => {
                return (
                    <>
                        <PopupTitle
                            text={t('385')}
                            onClick={() => {
                                setShowPopup(false);
                            }}
                        />

                        <PopupBodyText text={t('571')} />

                        <div className="compensation-box">
                            <div className="tick-mark-box">
                                <img
                                    src={'/img/icons/' + chosenCompensation.icon + '.jpg'}
                                    alt={chosenCompensation.icon}
                                    width="43px"
                                />
                            </div>

                            <div className="compensation-text">{chosenCompensation.title}</div>
                        </div>

                        <Gap />

                        <ButtonPrimary
                            btnId="compensationConfirmationBtn"
                            text={t('385')}
                            background="#7322C9"
                            type="text"
                            disabled={false}
                            onClick={() => {
                                confirmSetCompensation(chosenCompensation);
                            }}
                        />

                        <BackCancel
                            title={t('25')}
                            onClick={() => {
                                setShowPopup(false);
                            }}
                            width="120px"
                        />
                    </>
                );
            }}
        />
    );
};
