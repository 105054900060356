import cacheHelper from './API/cache-helper';
import { getActiveMembership } from '../refactor/services/membership.service.ts';
import cookies from './cookies';

export default (memberInfo) => {
    setMemberCookies(memberInfo);
};

const expiredCookieName = 'cookiesExpired';
const cookieNames = ['homeclub', 'membership', 'addons', 'gender', 'birthdate'];
const cookieExpireDays = 30;

const setMemberCookies = (member) => {
    const membership = getActiveMembership(member);
    const allArticles = cacheHelper.get('_articles');
    const relevantArticles =
        allArticles && allArticles.boughtArticles ? allArticles.boughtArticles.join('|') : 'N/A';

    if (!cookiesAreSetRecently()) {
        cookies.setCookie(expiredCookieName, new Date(), 1);
        cookieNames.forEach((cookie) => {
            switch (cookie) {
                case 'homeclub':
                    cookies.setCookie(cookie, member.home_club, cookieExpireDays);
                    break;
                case 'membership':
                    cookies.setCookie(cookie, JSON.stringify(membership), cookieExpireDays);
                    break;
                case 'addons':
                    cookies.setCookie(cookie, relevantArticles, cookieExpireDays);
                    break;
                case 'gender':
                    cookies.setCookie(cookie, member.gender_s, cookieExpireDays);
                    break;
                case 'birthdate':
                    cookies.setCookie(cookie, member.birth_date, cookieExpireDays);
                    break;
                default:
                    break;
            }
        });
    }
};

const cookiesAreSetRecently = () => {
    const ex = cookies.getCookie([expiredCookieName]);
    return ex && !olderThanOneDay(ex);
};

const olderThanOneDay = (date) => {
    const oneDayTime = 1000 * 60 * 60 * 24;
    const oneDayAgo = Date.now() - oneDayTime;
    return Date.parse(date) < oneDayAgo;
};
