import React, { MouseEvent } from 'react';
import { Text } from '@basic-fit/design-system';
import { Heading } from '@basic-fit/design-system';
import { ButtonLink } from '@src/components/Buttons/ButtonLink';

interface ICTACard {
    image: string;
    title: string;
    caption?: string;
    content: string;
    button: {
        onClick?: (event: MouseEvent) => void;
        to?: string;
        text: string;
    };
}

export const CTACard = ({ image, title, caption = '', content, button }: ICTACard): JSX.Element => {
    return (
        <div className="w-full h-full flex flex-col items-center md:items-start">
            <img src={image} className="w-full" alt={title} />
            <div className="h-full flex flex-col flex-wrap mt-[-50px] md:self-end p-xs w-[calc(100%-40px)] md:w-[calc(100%-60px)] bg-white">
                <Heading data-testid={`${title}-cta-card-title`} size={7}>
                    {title}
                </Heading>
                {caption && (
                    <Text size={2} className="text-grey mb-xxs">
                        {caption}
                    </Text>
                )}
                <Text size={2}>{content}</Text>
                <div className="flex justify-end w-full mt-auto">
                    <ButtonLink
                        data-testid={`${title}-cta-card-link`}
                        onClick={button.onClick}
                        to={button.to}
                        className="mt-xxs"
                    >
                        {button.text}
                    </ButtonLink>
                </div>
            </div>
        </div>
    );
};
