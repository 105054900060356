import React from 'react';
import styled from 'styled-components';

export default ({
    text,
    background,
    type,
    disabled,
    onClick,
    border = '',
    color = '',
    focus = '',
    btnId,
    ...rest
}) => {
    const ButtonBox = styled.button`
    position: relative;
    height: 60px;
    width: 100%;
    background: ${background || '#7322C9'};
    margin-bottom: 16px;
    ${border ? border : 'none'};
    font-family: Impact;
    font-style: normal;
    font-size: 20px;
    text-transform: uppercase;
    color: ${color ? color : 'white'};
    cursor: pointer;
  `;

    return (
        <ButtonBox
            type={type}
            disabled={disabled}
            onClick={onClick}
            autoFocus={focus}
            id={btnId}
            {...rest}
        >
            {text}
        </ButtonBox>
    );
};
