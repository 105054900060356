import React from 'react';

export default function ArrowRightIcon() {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 15 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m14.735 5.838-4.485 4.486a.772.772 0 0 1-1.103 0 .772.772 0 0 1 0-1.103l3.162-3.162H.779A.785.785 0 0 1 0 5.279C0 4.853.353 4.5.78 4.5h11.514L9.147 1.338a.772.772 0 0 1 0-1.103A.804.804 0 0 1 9.706 0c.206 0 .397.074.559.235l4.5 4.5a.803.803 0 0 1 .235.56.998.998 0 0 1-.265.543Z"
                fill="currentColor"
            />
        </svg>
    );
}
