import React from 'react';

export default function TickIcon() {
    return (
        <svg viewBox="0 0 33 25" height="100%" width="100%" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M28.2664 0L12.828 15.4947L4.73359 7.37079L0 12.1252L8.09441 20.2456L12.828 25L17.5616 20.2456L33 4.75086L28.2664 0Z"
                fill="currentColor"
            />
        </svg>
    );
}
