import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { Modal } from '@src/components/Modals/Modal';
import { StrokeText } from '@src/components/StrokeText/StrokeText';
import DOMPurify from 'isomorphic-dompurify';

export const SmartBikeModal = ({ isOpen, onClose }: { isOpen: boolean; onClose?: () => void }) => {
    const { t } = useTranslation();
    return (
        <Modal isOpen={isOpen} onModalClose={onClose}>
            <StrokeText
                stroke="last"
                color="anthracite"
                className="text-anthracite mb-4"
                text={t('membership.change.modal.bike.title')}
            />
            <Text size={2}>
                <span
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(t('membership.change.modal.bike.text'))
                    }}
                />
            </Text>
            <Text size={2}>
                <span
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(t('membership.change.modal.bike.link'))
                    }}
                />
            </Text>
            <Button
                text={t('membership.change.modal.bike.button')}
                className="mt-s"
                onClick={onClose}
            />
        </Modal>
    );
};
