import React, { ReactElement } from 'react';

type StepsProps = {
    className?: string;
    children: React.ReactNode;
    activeId?: number;
};

export const Steps = ({ className, children, activeId = 0 }: StepsProps) => {
    return (
        <nav className={`flex items-center justify-between ${className}`}>
            {React.Children.map(children, (child, index) => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child as ReactElement, {
                        id: index + 1,
                        active: index === activeId,
                        disabled: index > activeId,
                        navigationIntent: activeId - index
                    });
                }
            })}
        </nav>
    );
};
