import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Text } from '@basic-fit/design-system';
import { trackPromotion } from '@src/pages/ChangeMembership/config/ga_events';
import { useMember } from '@src/services/member/MemberProvider';

export const UpgradeBanner = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { state: member } = useMember();

    useEffect(() => {
        trackPromotion('view_promotion', '', 0, member.home_club, member.home_club_id);
    }, []);

    const handleClick = () => {
        trackPromotion('select_promotion', '', 0, member.home_club, member.home_club_id);
        navigate('/change-membership');
    };

    return (
        <>
            <Text size={2}>{t('375')}</Text>
            <Button variant="primary" className="mt-s w-full" onClick={handleClick}>
                {t('376')}
            </Button>
        </>
    );
};
