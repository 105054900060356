import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { Button } from '@src/components/Buttons/Button';
import { Wrapper } from '@src/components/Layout/Wrapper';

export const FriendLinkExpired = () => {
    const { t } = useTranslation();
    return (
        <Wrapper>
            <Box>
                <Text size={2}>{t('254')}</Text>

                <Button
                    className="mt-xs"
                    onClick={() => window.open('https://www.basic-fit.com')}
                    text={t('521')}
                />
            </Box>
        </Wrapper>
    );
};
