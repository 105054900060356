import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Heading, Text } from '@basic-fit/design-system';
import { ButtonList } from '@src/components/ButtonList/ButtonList';
import { IconButton } from '@src/components/Buttons/IconButton';
import { Icon } from '@src/components/Icons/Icons';
import { CancelMembershipModal } from '@src/pages/Membership/Membership/components/Modals/CancelMembership.modal';
import { getLocalDateString } from '@src/utils/helpers/date';
import {
    canChangeMembership,
    changeClubIsAllowed,
    isGerman
} from '@src/utils/helpers/memberHelpers';
import { useFetch } from '@src/utils/hooks/api/useFetch';

import googleTagManagerPush from '../../../services/google-tag-manager-push';
import { MemberDataProps } from '../../../types/member.types';
import { Modal } from '../Modals/Modal';

export const ChangeMembership = ({ member }: { member: MemberDataProps }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { data: compensations } = useFetch<any[]>('/extra/get-compensation-options');

    const [isCancelMembershipModalOpen, setIsCancelMembershipModalOpen] = useState(false);
    const [isMembershipFrozenModalOpen, setIsMembershipFrozenModalOpen] = useState(false);
    const [hasFrozenCompensation, setHasFrozenCompensation] = useState(false);

    const isMemberFrozen = member?.peopleMembership?.membershipStatus === 'Frozen';

    useEffect(() => {
        if (compensations) {
            const hasFreeze = compensations.find(({ choice }: { choice: string }) =>
                choice?.toLowerCase().includes('freeze')
            );
            setHasFrozenCompensation(hasFreeze && !member.membershipIsFrozen);
        }
    }, [compensations]);

    if (!canChangeMembership(member)) return null;

    return (
        <div className="mt-xxl">
            <ButtonList title={t('1633')}>
                {member.membership_type_g === 'basic' && (
                    <IconButton
                        icon={<Icon id="home-club" />}
                        title={t('change_home_club.title')}
                        disabled={!changeClubIsAllowed(member.clubChangePossibleFrom)}
                        subtitle={
                            !changeClubIsAllowed(member.clubChangePossibleFrom) &&
                            member.clubChangePossibleFrom
                                ? t('change_home_club.not_allowed_untill').replace(
                                      '%date%',
                                      getLocalDateString(member.clubChangePossibleFrom)
                                  )
                                : ''
                        }
                        onClick={() => navigate('/membership/change-home-club')}
                    />
                )}
                <IconButton
                    icon={<Icon id="change" />}
                    data-cy="change-membership-button"
                    title={t('1625') || t('1625')}
                    onClick={() => navigate('/change-membership')}
                />

                {member.can_cancel_membership && !member.end_date_g && (
                    <IconButton
                        data-cy="cancel-membership-button"
                        className={isGerman(member) ? 'order-first' : ''}
                        icon={<Icon id="cancel" />}
                        title={t('102')}
                        onClick={() => {
                            isMemberFrozen
                                ? setIsMembershipFrozenModalOpen(true)
                                : setIsCancelMembershipModalOpen(true);
                        }}
                    />
                )}
            </ButtonList>

            {isMembershipFrozenModalOpen && (
                <Modal
                    isOpen={isMembershipFrozenModalOpen}
                    onModalClose={() => setIsMembershipFrozenModalOpen(false)}
                    overlayClick
                >
                    <Heading size={5} className="mb-s">
                        {t('freeze.modal.membership.frozen.title')}
                    </Heading>
                    <Text size={2}>{t('freeze.modal.membership.frozen.text')}</Text>
                </Modal>
            )}
            {isCancelMembershipModalOpen && (
                <CancelMembershipModal
                    isOpen={isCancelMembershipModalOpen}
                    onClose={() => {
                        googleTagManagerPush.cancelMembership('click', 'confirm modal - close');
                        setIsCancelMembershipModalOpen(false);
                    }}
                    frozenCompensation={hasFrozenCompensation}
                />
            )}
        </div>
    );
};
