import { domain, headers } from '../fetchOptions';

interface UsePostReturnProps<T> {
    submit: <K = Record<string, any>>(url: string, args: K) => Promise<T>;
}

export const usePost = <T = any>(): UsePostReturnProps<T> => {
    const submit = async <K = Record<string, any>>(url: string, bodyData: K) => {
        let statusCode;
        try {
            const response = await fetch(`${domain + url}`, {
                method: 'POST',
                headers,
                body: JSON.stringify(bodyData)
            });

            const resolvedResponse = await response.json();
            statusCode = resolvedResponse.code;

            if (!response.ok) {
                throw resolvedResponse;
            }

            return resolvedResponse;
        } catch (error: any) {
            reportError(error, statusCode);

            return {
                status: error?.response?.data?.status,
                message: error?.response?.data?.message
            };
        }
    };

    return {
        submit
    };
};

const reportError = (err: any, httpStatusCode: number) => {
    if (err instanceof Error) {
        console.log(
            `Error for the API: ${err.name}`,
            `Error code: ${httpStatusCode}`,
            `Error message: ${err.message}`
        );
    } else {
        console.log(`The error: ${err}`, `Error code: ${httpStatusCode}`);
    }
};
