import { getParam } from '@src/services/utilities/params.service';

/**
 * Overflow is set on DashboardLayout
 * Use this reference to set scroll position
 */
export const scrollToTop = () => {
    document.querySelector('#page')?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
};

export const scrollToAnchor = (id: string) => {
    const pageTop = document.querySelector<HTMLElement>('#page')?.offsetTop || 0;
    const elementTop = document.querySelector<HTMLElement>(id)?.offsetTop || 0;
    const margin = 20;

    document
        .querySelector('#page')
        ?.scrollTo({ top: Math.abs(elementTop - pageTop) - margin, behavior: 'smooth' });
};

export const isApp = () => getParam('app') === 'true' || !!window.ReactNativeWebView;

export const isIOS = () => /iPhone|iPad|iPod/i.test(navigator.userAgent);
