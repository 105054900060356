import React, { HTMLAttributes } from 'react';

type ISection = HTMLAttributes<HTMLDivElement>;

export const Section = ({ children, className = '', ...rest }: ISection): JSX.Element => {
    return (
        <section className={`mbf-section mt-[30px] md:mt-[60px] ${className ?? ''}`} {...rest}>
            {children}
        </section>
    );
};
