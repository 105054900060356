import React from 'react';

import { ReactComponent as FH } from './../img/face-with-heart-eyes-small.svg';
import { ReactComponent as FS } from './../img/face-with-star-eyes-small.svg';
import { ReactComponent as FF } from './../img/freezing-face-small.svg';
import { ReactComponent as GS } from './../img/gift-small.svg';
import { ReactComponent as OH } from './../img/orange-heart-small.svg';

export default ({ icon }) => {
    switch (icon) {
        case 'face-with-star-eyes':
            return <FS />;
        case 'face-with-heart-eyes':
            return <FH />;
        case 'orange-heart':
            return <OH />;
        case 'freezing-face':
            return <FF />;
        case 'gift':
            return <GS />;
        default:
            return <GS />;
    }
};
