import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from '@src/components/Header/Header';
import { Section } from '@src/components/Layout/Section';
import { LineSVG } from '@src/components/LineSVG/LineSVG';
import { StrokeText } from '@src/components/StrokeText/StrokeText';
import { useMember } from '@src/services/member/MemberProvider';
import { captureException } from '@src/utils/helpers/newRelic';
import { usePreventEmployeeChangeDetails } from '@src/utils/hooks/usePreventEmployeeChangeDetails';

import { ChangePhoneNumberContext } from './changePhoneNumber.machine';
import { NewPhoneNumber } from './NewPhoneNumber.component';
import { VerifyCode } from './VerifyCode.component';

export default function ChangePhoneNumberPage() {
    const { t } = useTranslation();
    const { state: member } = useMember();

    usePreventEmployeeChangeDetails();

    return (
        <ChangePhoneNumberContext.Provider options={{ context: { email: member.email } }}>
            <div className="h-full">
                <div className="hidden lg:block">
                    <Header
                        title={t('30')}
                        text={t('377')}
                        image={<LineSVG id="eclipse" className="absolute right-0 top-0" />}
                    />
                </div>
                <Section className="p-4 lg:p-0">
                    <div className="mint-top" data-cy="member-email-block">
                        <StrokeText
                            stroke="last2"
                            color="anthracite"
                            className="mb-m"
                            text={t('details.change.phone.title')}
                        />
                        <ChangePhoneNumberFlow />
                    </div>
                </Section>
            </div>
        </ChangePhoneNumberContext.Provider>
    );
}

const ChangePhoneNumberFlow = () => {
    const shouldShowNewPhone = ChangePhoneNumberContext.useSelector((state) =>
        state.matches('phone')
    );
    const shouldShowCode = ChangePhoneNumberContext.useSelector((state) => state.matches('code'));
    const currentState = ChangePhoneNumberContext.useSelector((state) => state.value);

    if (shouldShowNewPhone) return <NewPhoneNumber />;
    if (shouldShowCode) return <VerifyCode />;

    captureException(new Error('invalid state'), {
        fn: 'ChangePhoneNumberFlow',
        currentState: String(currentState)
    });

    return null;
};
