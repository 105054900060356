export const setToSessionCache = (key: string, value: unknown) => {
    try {
        const parsedValue = typeof value === 'string' ? value : JSON.stringify(value);
        sessionStorage.setItem(key, parsedValue);
    } catch (e) {
        console.log(e);
    }
};

export const getFromSessionCache = (key: string) => {
    try {
        return sessionStorage.getItem(key);
    } catch (e) {
        console.log(e);
        return null;
    }
};
export const removeFromSessionCache = (key: string) => {
    try {
        sessionStorage.removeItem(key);
    } catch (e) {
        console.log(e);
        return null;
    }
};

export const clearSessionCache = () => {
    try {
        sessionStorage.clear();
    } catch (e) {
        console.log(e);
    }
};
