import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { Section } from '@src/components/Layout/Section';
import { useVisits } from '@src/utils/hooks/useVisits';

export const VisitsMessage = () => {
    const { t } = useTranslation();
    const { visitsLastWeek, visitsThisWeek } = useVisits();
    const isActiveMember = visitsLastWeek + visitsThisWeek >= 4;

    return (
        <Section>
            {/* good job || time to get back on track */}
            <Heading size={7}>{isActiveMember ? t('418') : t('175')} </Heading>
            {/* We are glad to see that your exercise pattern || We are happy to help you with your fitness goals */}
            <Text size={1}>{isActiveMember ? t('417') : t('176')} </Text>
        </Section>
    );
};
