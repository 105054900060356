import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableFlex } from '@src/components/TableFlex/TableFlex';
import { Bold } from '@src/components/Text/Bold';
import { useMember } from '@src/services/member/MemberProvider';
import { GetDeviceInfoProps } from '@src/utils/hooks/api/types/getDeviceInfo';

interface IChangeAccessTable {
    deviceInfo: GetDeviceInfoProps;
}

export const ChangeAccessTable = ({ deviceInfo }: IChangeAccessTable) => {
    const { t } = useTranslation();

    const [table, setTable] = useState<(string | JSX.Element)[][]>([]);

    const {
        state: { accessType }
    } = useMember();

    const { deviceId, deviceDescription } = deviceInfo || {};

    const isCardType = accessType === 'card';

    if (!isCardType && deviceId) {
        deviceInfo.deviceDescription ||= t('phone');
    }

    useEffect(() => {
        if (isCardType && !deviceInfo) {
            setTable([
                [
                    t('change-access.card.current-method'),
                    <Bold key={'card'}>{t('access-change.table.card')}</Bold>
                ],
                [
                    t('access-change.table.device'),
                    <Bold key={'qr'}>{t('access-change.table.no-device')}</Bold>
                ]
            ]);
            return;
        }
        if (!deviceDescription) {
            setTable([
                [
                    t('change-access.card.current-method'),
                    <Bold data-cy="accesstype-qr" key={'qr'}>
                        {t('access-change.table.qr')}
                    </Bold>
                ],
                [
                    t('access-change.table.device'),
                    <Bold data-cy="accesstype-no-device" key={'qr'}>
                        {t('access-change.table.no-device')}
                    </Bold>
                ]
            ]);
            return;
        }
        setTable([
            [
                t('change-access.card.current-method'),
                <Bold key={'qr'}>{t('access-change.table.qr')}</Bold>
            ],
            [t('access-change.table.device'), <Bold key={'qr'}>{deviceDescription}</Bold>]
        ]);
    }, [isCardType, deviceDescription]);

    return <TableFlex className="mb-xs" data={table} />;
};
