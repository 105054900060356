import React from 'react';
import { useTranslation } from 'react-i18next';
import { Logo } from '@src/components/Logo/Logo';
import { ILayout } from '@src/types/layout.type';

export const RestrictedLayout = ({ children }: ILayout): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className="flex items-center flex-col flex-grow w-full h-full">
            <div
                className={`
                    w-full relative
                    flex items-center justify-center py-[18px] px-[15px] shadow-md
                    md:px-[24px] z-[99]
                    before:content-[''] before:absolute before:top-0
                    before:left-0 before:w-full before:h-[3px] before:bg-orange
                `}
            >
                <Logo className="h-full w-[110px]" />
            </div>
            <div className="flex justify-start items-center flex-col flex-grow w-full overflow-x-hidden overflow-y-auto p-[30px] md:p-[60px] pb-[30px] md:pb-[30px]">
                <div className="max-w-sm flex flex-col flex-1">
                    <div className="flex flex-col flex-1">{children}</div>
                    <ul className="flex flex-wrap justify-center gap-x-6 mt-8 text-anthracite">
                        <li>&#169; Basic-Fit</li>
                        <a
                            href="https://www.basic-fit.com/en-nl/about-basic-fit/privacy-statement"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <li>{t('5')}</li>
                        </a>
                        <a
                            href="https://www.basic-fit.com/en-nl/about-basic-fit/terms-and-conditions"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <li>{t('6')}</li>
                        </a>
                    </ul>
                </div>
            </div>
        </div>
    );
};
