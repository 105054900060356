import React from 'react';

export default function BinIcon() {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="-5 -5 55 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="scale-[1.3]"
        >
            <path
                id="bin"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.3314 30.7829L22.6378 68.0943H26.4135L26.7198 30.7829H22.3314ZM32.0173 30.7829L31.1099 68.0929L34.8845 68.0728L36.4068 30.7828H32.0167L32.0173 30.7829ZM43.289 30.7829L40.3721 72.5129H8.20641L5.29069 30.7829H3.95922L3.15676 24.9257H45.1753L44.5554 30.7829H43.2881H43.289ZM11.1104 30.7829L13.6115 68.0986L17.323 68.1053L15.4983 30.7824L11.1104 30.7829Z"
                fill="currentColor"
            />
            <path
                className="origin-[center] transition-[transform] duration-[300ms] delay-[100ms] group-hover:delay[0ms] group-hover:translate-y-[-25%] group-hover:rotate-[-40deg]"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.5862 18.7758C16.3584 14.317 15.6228 14.0597 23.9281 14.1759C31.526 14.282 32.0229 13.4828 32.6661 18.7828C46.3984 19.127 45.3196 17.9832 45.1732 24.9657L3.15459 24.9483C3.26633 17.5949 3.08185 18.9453 15.5861 18.7759L15.5862 18.7758ZM18.5691 18.7767L29.6834 18.7816C29.3088 15.8023 29.3528 16.3434 23.9689 16.3336C18.6529 16.4055 18.9791 15.9608 18.5687 18.7762L18.5691 18.7767Z"
                fill="currentColor"
            />
            <path
                className="origin-[center] opacity-[0] transition-[opacity] duration-[300ms] delay-[0] group-hover:opacity-[1] group-hover:delay-[100ms]"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34.6072 4.29357C36.7283 4.29357 38.4493 6.06642 38.4493 8.25171C38.4493 10.437 36.7289 12.2099 34.6072 12.2099C32.486 12.2099 30.765 10.437 30.765 8.25171C30.765 6.06642 32.4854 4.29357 34.6072 4.29357ZM23.2814 9.207C25.5522 9.207 27.3936 11.1043 27.3936 13.443C27.3936 15.7823 25.552 17.679 23.2814 17.679C21.0119 17.679 19.1704 15.7829 19.1704 13.443C19.1704 11.1043 21.012 9.207 23.2814 9.207ZM34.0006 18.0753C34.0006 16.5652 32.812 15.3414 31.3472 15.3414C29.8823 15.3414 28.6937 16.5652 28.6937 18.0753C28.6937 19.5854 29.8823 20.8091 31.3472 20.8091C32.812 20.8091 34.0006 19.5854 34.0006 18.0753Z"
                fill="currentColor"
            />
        </svg>
    );
}
