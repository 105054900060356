import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select/dist/declarations/src/types';
import { Heading, Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { SelectElement, selectStylesWhite } from '@src/components/Form/SelectElement/SelectElement';
import { Modal } from '@src/components/Modals/Modal';
import { useMember } from '@src/services/member/MemberProvider';
import { getMemberName } from '@src/utils/helpers/memberHelpers';
import { getPaymentDates } from '@src/utils/helpers/transactionHelpers';
import { LogTransaction } from '@src/utils/hooks/api/types/transactions';
import { useFetch } from '@src/utils/hooks/api/useFetch';
import { useAwaitSpinner } from '@src/utils/hooks/useAwaitSpinner';
import { saveAs } from 'file-saver';
import { DateTime, MonthNumbers } from 'luxon';

import googleTagManagerPush from 'services/google-tag-manager-push';

type ModalProps = {
    isOpen: boolean;
    onClose?: () => void;
};

type MonthOption = { value: MonthNumbers; label: string | null };

type YearOption = {
    value: number;
    label: number;
};

export const ProofOfPaymentModal = ({ isOpen, onClose }: ModalProps) => {
    const { awaiting: isLoading, awaitSpin } = useAwaitSpinner();
    const [disabled, setDisabled] = useState(false);
    const [success, setSuccess] = useState(false);
    const [apiError, setApiError] = useState(false);

    const { t } = useTranslation();
    const { state: member } = useMember();

    const { data: transactions, isError } = useFetch<LogTransaction[]>(
        '/payments/get-transaction-log'
    );

    const resetModalState = () => {
        setDisabled(false);
        setSuccess(false);
        setApiError(false);
    };

    const handleModalClose = () => {
        resetModalState();
        onClose?.();
    };

    const getDate = (date: string) => DateTime.fromFormat(date, 'M/yyyy');

    /**
     * Get available months/years from transaction log
     * Use these as options for select inputs
     */
    const [history, setHistory] = useState<string[]>([]);
    const [months, setMonths] = useState<MonthOption[]>();
    const [years, setYears] = useState<YearOption[]>();
    const [month, setMonth] = useState<MonthOption>();
    const [year, setYear] = useState<YearOption>();

    useEffect(() => {
        if (transactions) {
            const history: string[] = [];
            const filtered = getPaymentDates(member, transactions);
            filtered.map((transaction) => {
                const period = DateTime.fromISO(transaction.date).toFormat('M/yyyy');
                if (!history.includes(period)) {
                    history.push(period);
                }
            });
            setHistory(history);
        }
    }, [transactions]);

    /**
     * Update available months when year is selected
     * When a member joined in april, we don't want to show months before this date
     */
    useEffect(() => {
        if (history.length) {
            const years = getYears();
            const months = getMonths(years[0].value);
            setMonth(months[0]);
            setYear(years[0]);
            setMonths(months);
            setYears(years);
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [history]);

    const getMonths = (year: number): MonthOption[] => {
        return history
            .filter((period: string) => getDate(period).year === year)
            .map((period) => {
                const date = getDate(period);
                return {
                    value: date.month as MonthNumbers,
                    label: date.monthLong
                };
            });
    };

    const getYears = () => {
        const years = history.map((period) => getDate(period).year);
        const unique = Array.from(new Set(years));
        return unique.map((year) => {
            return {
                value: year,
                label: year
            };
        });
    };

    const changedMonth = (option: SingleValue<MonthOption>) => {
        if (option) {
            setMonth(option);
            resetModalState();
        }
    };

    const changedYear = (option: SingleValue<YearOption>) => {
        if (option) {
            const months = getMonths(option.value);
            setYear(option);
            setMonth(months[0]);
            setMonths(months);
            resetModalState();
        }
    };

    /**
     * Set loading state and error handling when requesting the statement
     */
    const getPaymentPDF = async () => {
        try {
            setDisabled(true);
            await awaitSpin(() => startDownload());
            setSuccess(true);
        } catch (e) {
            setApiError(true);
            setDisabled(false);
        }
    };

    const startDownload = async () => {
        const response = await fetch(
            `/payments/get-statement?month=${month?.value}&year=${year?.value}`
        );
        if (response.ok) {
            const filename = `${getMemberName(member).replace(' ', '-')}-${year?.value}${
                month?.value
            }.pdf`;
            const file = await response.blob();
            const url = URL.createObjectURL(file);
            saveAs(url, filename);
            googleTagManagerPush.proofOfPaymentDownload();
        } else {
            googleTagManagerPush.proofOfPaymentError(response.statusText);
            throw Error(response.statusText);
        }
    };

    return (
        <Modal isOpen={isOpen} onModalClose={handleModalClose} overlayClick={!isLoading}>
            <Heading size={5} className="mb-s">
                {t('payments.download.modal.title')}
            </Heading>
            <Text size={2} className="my-xs">
                {t('payments.download.modal.text')}
            </Text>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="w-full">
                    <Text size={2} className="my-xxxs">
                        {t('payments.download.modal.select_month')}
                    </Text>
                    <SelectElement
                        className="w-full"
                        styles={selectStylesWhite}
                        options={months}
                        isSearchable={false}
                        menuPosition={'fixed'}
                        onChange={changedMonth}
                        value={month}
                        isDisabled={isLoading}
                        noOptionsMessage={() => t('payments.download.modal.no_data')}
                    />
                </div>
                <div className="w-full">
                    <Text size={2} className="my-xxxs">
                        {t('payments.download.modal.select_year')}
                    </Text>
                    <SelectElement
                        className="w-full"
                        styles={selectStylesWhite}
                        options={years}
                        isSearchable={false}
                        menuPosition={'fixed'}
                        onChange={changedYear}
                        value={year}
                        isDisabled={isLoading}
                        noOptionsMessage={() => t('payments.download.modal.no_data')}
                    />
                </div>
            </div>

            <Button
                icon={success ? 'tick' : ''}
                text={success ? t('242') : t('payments.download.modal.download')}
                onClick={getPaymentPDF}
                className="mt-s"
                data-cy="proof-of-payment__download-button"
                loading={isLoading}
                disabled={disabled || isError}
            />

            {isError && (
                <Text size={2} className="text-orange mt-xs">
                    {t('payments.download.modal.error')}
                </Text>
            )}

            {apiError && (
                <Text size={2} className="text-orange mt-xs">
                    {t('payments.download.modal.error_pdf')}
                </Text>
            )}

            <Text size={2} className="mt-xs">
                {t('payments.download.modal.explanation')}
            </Text>
        </Modal>
    );
};
