import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box } from '@src/components/Box/Box';
import { Header } from '@src/components/Header/Header';
import { Section } from '@src/components/Layout/Section';
import { StockImage } from '@src/components/StockImage/StockImage';
import { getParam as getQueryParam } from '@src/services/utilities/params.service';

import ParagraphNormal from 'components/partials/ParagraphNormal';
import {
    RegistrationError,
    SecondaryMemberRegisterError
} from './components/SecondaryMemberRegisterError.component';
import { SecondaryMemberRegistrationForm } from './components/SecondaryMemberRegistrationForm.component';

export const SecondaryMemberRegister = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const token = getQueryParam('token');
    const status = getQueryParam('status');

    const onRegistrationSuccess = () => {
        const query = new URLSearchParams({ token: token || '', status: 'success' });
        navigate(`/secondary-member/register?${query.toString()}`);
    };

    const onRegistrationError = (errorCode: RegistrationError) => {
        const query = new URLSearchParams({
            token: token || '',
            status: 'error',
            errorCode: errorCode
        });
        navigate(`/secondary-member/register?${query.toString()}`);
    };

    React.useEffect(() => {
        if (!status && !token) {
            onRegistrationError(RegistrationError.INVALID_TOKEN);
        }
    }, []);

    return (
        <div className="w-full">
            <Header
                title={t('secondary_member.register.heading')}
                image={<StockImage id="friends" className="h-full w-full !object-cover" />}
            />
            <Section>
                <Box variant="mint-top">
                    {!status && token && (
                        <SecondaryMemberRegistrationForm
                            token={token}
                            onRegistrationSuccess={onRegistrationSuccess}
                            onRegistrationError={onRegistrationError}
                        />
                    )}

                    {status === 'success' && (
                        <ParagraphNormal
                            text={t('secondary_member.register.success.heading')}
                        ></ParagraphNormal>
                    )}

                    {status === 'error' && (
                        <SecondaryMemberRegisterError></SecondaryMemberRegisterError>
                    )}
                </Box>
            </Section>
        </div>
    );
};
