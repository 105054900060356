import React from 'react';
import { StrokeText } from '@src/components/StrokeText/StrokeText';

interface VisitSquareProps {
    title: string;
    number: number | string;
    tag: string;
}

export const VisitSquare = ({ title, number, tag }: VisitSquareProps): JSX.Element => {
    return (
        <div className={'w-full pt-[100%] relative'}>
            <div className="absolute top-[0] left-[0] w-[100%] h-[100%] bg-white p-[15px] flex flex-col justify-evenly">
                <span
                    className={
                        'text-anthracite text-[3vw] leading-[3vw] uppercase font-bold sm:text-[1.4vw] sm:leading-[1.4vw] lg:text-[1vw] lg:leading-[1vw] xl:text-[14px] xl:leading-[16px]'
                    }
                >
                    {title}
                </span>
                <span
                    className={` text-[10vw] leading-[10vw] text-anthracite font-heading-treble font-extrabold sm:leading-[48px] md:leading-[6vw] lg:leading-[4vw] xl:leading-[48px]
                                    ${+number >= 1000
                            ? `
                                            text-[8vw]
                                            sm:text-[28px]
                                            md:text-[4.2vw]
                                            lg:text-[2.8vw]
                                            xl:text-[34px]
                                        `
                            : `
                                            sm:text-[48px] 
                                            md:text-[6vw]
                                            lg:text-[4vw]
                                            xl:text-[48px] 
                                        `
                        }
                                `}
                >
                    <StrokeText
                        text={+number > 9999 ? '9999+' : number.toString()}
                        stroke="first"
                        color="anthracite"
                    />
                </span>
                <span
                    className={
                        'text-anthracite text-[3vw] font-bold leading-[3vw] sm:text-[1.4vw] sm:leading-[1.4vw] lg:text-[1vw] lg:leading-[1vw] xl:text-[14px] xl:leading-[16px]'
                    }
                >
                    {tag}
                </span>
            </div>
        </div>
    );
};
