import React, { useState } from 'react';
import { Accordion, Button, Heading, Skeleton, Text } from '@basic-fit/design-system';
import { ToggleButton, ToggleButtonProps } from '@src/components/Buttons/ToggleButton';
import { StockImage } from '@src/components/StockImage/StockImage';
import { Price } from '@src/pages/ChangeMembership/components/Price';

interface AddOnProps {
    image: {
        id: string;
        backgroundColor: string;
    };
    title: string;
    caption?: string;
    discountText?: string;
    originalPrice?: number;
    content?: string;
    price: number;
    period: string;
    onetime?: boolean;
    toggle: ToggleButtonProps;
    button: {
        variant?: 'primary' | 'secondary';
        text: string;
    };
    onClick?: () => void;
    details?: CollapseProps;
}

type CollapseProps = {
    closed: string;
    open: string;
    text: string;
};

export const AddOn = ({
    image,
    title,
    caption = '',
    discountText = '',
    originalPrice,
    content = '',
    price,
    period,
    onetime = true,
    toggle,
    button: { variant: buttonVariant = 'primary', text: buttonText = '' },
    onClick,
    details
}: AddOnProps) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <div
            data-testid={title.split(' ').join('-').toLowerCase()}
            className="w-full h-full flex flex-col items-center md:items-start"
        >
            <div
                className={`flex flex-none justify-center w-full h-[300px] ${image.backgroundColor}`}
            >
                <StockImage className="mt-[-40px] h-full" id={image.id} />
            </div>
            <div className="flex flex-col h-full mt-[-50px] md:self-end w-[calc(100%-40px)] md:w-[calc(100%-60px)]">
                {discountText && (
                    <div className="w-full bg-orange text-white font-heading-treble font-extrabold text-[14px] text-center py-xxxs">
                        {discountText}
                    </div>
                )}
                <div className="flex flex-col h-full bg-white p-xs">
                    <Heading size={7}>{title}</Heading>
                    {caption && (
                        <Text size={2} className="text-grey mb-xxs">
                            {caption}
                        </Text>
                    )}
                    {content && <Text size={2}>{content}</Text>}
                    <div className={`my-xs flex items-start ${details ? '' : 'grow'}`}>
                        <Price
                            amount={price}
                            caption={`/ ${period}`}
                            originalPrice={originalPrice}
                            className="mt-xxxs"
                        />
                    </div>
                    <div data-cy="addon-trigger" className="flex justify-end w-full">
                        {onetime ? (
                            <Button className="w-full" variant={buttonVariant} onClick={onClick}>
                                {buttonText}
                            </Button>
                        ) : (
                            <ToggleButton
                                on={toggle.on}
                                off={toggle.off}
                                active={toggle.active}
                                onClick={onClick}
                                changeOnClick={toggle.changeOnClick}
                            />
                        )}
                    </div>
                    {details && (
                        <Accordion
                            onClick={() => setExpanded(!expanded)}
                            title={expanded ? details?.open : details?.closed}
                        >
                            {expanded ? details?.text : <Skeleton type="text" lines={1} />}
                        </Accordion>
                    )}
                </div>
            </div>
        </div>
    );
};
