import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@basic-fit/design-system';
import { Icon } from '@src/components/Icons/Icons';
import { getPrice } from '@src/utils/helpers/priceHelpers';
import { t } from 'i18next';
import { v4 as uuidv4 } from 'uuid';

import { TransactionRow } from './TransactionRow';

type TransactionProps = {
    date: string;
    title: string;
    amount: { type: string; value: number };
    balance: { flag: boolean; value: number };
    details?: { description: string; amount: number }[];
};

export const Transaction = ({ date, title, amount, balance, details }: TransactionProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const {
        i18n: { language }
    } = useTranslation();

    const getDetailTitle = (title: string) => {
        const stornoCode = title.match(/[A-Z]{2}[0-9]{2}/g);
        if (stornoCode) {
            return t(`payments.storno.${stornoCode[0]}`, { defaultValue: title });
        }
        return title;
    };

    return (
        <div
            className="border-solid border-b-[1px] border-grey py-xxs"
            onClick={() => setIsOpen(!isOpen)}
            data-testid="transaction"
        >
            <TransactionRow
                date={<Text size={2}>{date}</Text>}
                title={
                    <>
                        <Text size={2}>{title}</Text>
                        <Icon
                            className="hidden md:block text-[10px] ml-xxs"
                            id={isOpen ? 'chevron-up' : 'chevron-down'}
                        />
                    </>
                }
                amount={
                    <div
                        className={`py-1 px-2 font-heading-treble text-regular-s md:text-regular-m font-bold inline-flex ${
                            amount.type === 'paid' || amount.value > 0 ? 'bg-mint text-white' : ''
                        }`}
                    >
                        {getPrice(amount.value, language, { format: 'withDigits' })}
                    </div>
                }
                balance={
                    <div
                        className={`py-1 px-2 font-heading-treble text-regular-s md:text-regular-m font-bold inline-flex ${
                            balance.flag ? 'bg-status-red text-white' : ''
                        }`}
                    >
                        {getPrice(balance.value, language, { format: 'withDigits' })}
                    </div>
                }
            />

            <div className={isOpen ? 'visible pt-xs' : 'hidden'}>
                {details?.map((item) => (
                    <TransactionRow
                        key={uuidv4()}
                        className="pb-xxs"
                        title={<Text size={2}>{getDetailTitle(item.description)}</Text>}
                        amount={
                            <span className="px-2 font-extrabold text-[13px]">
                                {getPrice(item.amount, language, {
                                    format: 'withDigits'
                                })}
                            </span>
                        }
                    />
                ))}
            </div>
        </div>
    );
};
