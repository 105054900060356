import React, { useRef } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MultiValue } from 'react-select';
import { Heading, Text } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { Button } from '@src/components/Buttons/Button';
import { Form } from '@src/components/Form/Form';
import { SearchOption } from '@src/components/Form/SearchElement/SearchComponents';
import { SelectElement } from '@src/components/Form/SelectElement/SelectElement';
import { TextArea } from '@src/components/Form/TextArea/TextArea';
import { InformationBlock } from '@src/components/InformationBlock/InformationBlock';
import { Section } from '@src/components/Layout/Section';
import { headers } from '@src/utils/hooks/fetchOptions';

type FormData = {
    reason: string;
    description: string;
    orgid: string;
};

export const AppealForm = () => {
    const { t } = useTranslation();

    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [isCaseCreated, setIsCaseCreated] = React.useState(false);
    const [hasError, setHasError] = React.useState(false);

    const formRef = useRef<UseFormReturn<FormData>>(null);

    const submitForm = async (values: any) => {
        setHasError(false);
        setIsSubmitting(true);

        try {
            const response = await fetch('/debt/appeal', {
                method: 'POST',
                headers,
                body: JSON.stringify(values)
            });

            if (!response.ok) {
                return setHasError(true);
            }

            const insert = await response.json();
            setIsCaseCreated(!!insert?.status);
        } catch (error) {
            setHasError(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    const reasons: MultiValue<SearchOption> = [
        {
            label: t('debt.appeal.reason_cancelled'),
            value: 'cancelled'
        },
        {
            label: t('debt.appeal.reason_personal_circumstances'),
            value: 'personal_circumstances'
        },
        {
            label: t('debt.appeal.reason_dont_understand'),
            value: 'dont_understand'
        },
        {
            label: t('debt.appeal.reason_club_closed'),
            value: 'club_closed'
        },
        {
            label: t('debt.appeal.reason_other'),
            value: 'other'
        }
    ];

    return (
        <Section id="appeal">
            <Box variant="grey" size="small" className="sm:-mb-xl">
                <Heading size={5}>{t('debt.appeal.title')}</Heading>
                <Text size={2} className="mt-xxs">
                    {t('debt.appeal.description')}
                </Text>

                {isCaseCreated ? (
                    <InformationBlock className="mt-s">
                        <Heading size={6}>{t('debt.appeal.case_created')}</Heading>
                        <Text size={2}>{t('debt.appeal.case_created_description')}</Text>
                    </InformationBlock>
                ) : (
                    <Form<FormData>
                        instance={formRef}
                        name="appealForm"
                        onSubmit={submitForm}
                        useCache={false}
                        className="mt-s space-y-s"
                    >
                        <Controller
                            control={formRef.current?.control}
                            name="reason"
                            rules={{
                                required: true
                            }}
                            render={({ field: { onChange }, fieldState: { error } }) => (
                                <>
                                    <SelectElement
                                        className="mt-m mb-xxs"
                                        options={reasons}
                                        isSearchable={false}
                                        menuPosition={'fixed'}
                                        placeholder={t('debt.appeal.reason')}
                                        onChange={onChange}
                                    />
                                    {error && (
                                        <span className="text-regular-s text-red">
                                            {t('debt.appeal.error_reason')}
                                        </span>
                                    )}
                                </>
                            )}
                        />
                        <TextArea
                            name={'description'}
                            label={t('debt.appeal.message')}
                            required={true}
                        />
                        <Button
                            text={t('debt.appeal.submit')}
                            type="submit"
                            loading={isSubmitting}
                            disabled={isSubmitting}
                        />

                        {hasError && (
                            <Text size={2} className="text-red">
                                {t('debt.appeal.error')}
                            </Text>
                        )}
                    </Form>
                )}
            </Box>
        </Section>
    );
};
