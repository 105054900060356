export const isMobile = () => {
    let hasTouchScreen = false;

    if ('ontouchstart' in window) {
        hasTouchScreen = navigator.maxTouchPoints > 0;
    } else {
        const mQ = matchMedia('(pointer:coarse)');
        if (mQ && mQ.media === '(pointer:coarse)') {
            hasTouchScreen = !!mQ.matches;
        } else if ('orientation' in window) {
            hasTouchScreen = true;
        } else {
            hasTouchScreen =
                /\b(BlackBerry|webOS|iPhone|IEMobile|Android|Windows Phone|iPad|iPod)\b/i.test(
                    navigator.userAgent
                );
        }
    }

    return hasTouchScreen;
};
